import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as S from "./style";
import { usePermissions } from "hooks";

import { UpdateDeliveryVoucherActions } from "store/ducks/tracking/delivery-vouchers";
import {
  DownloadDeliveryVoucherActions,
  DownloadDeliveryVoucherState,
} from "store/ducks/tracking/delivery-vouchers";
interface IProps {
  closeModal: () => void;
  invoice: any;
}

export const InvoiceModalShowDeliveryVoucher: React.FC<IProps> = ({
  closeModal,
  invoice,
}) => {
  const { hasAnyPermission } = usePermissions();
  const dispatch = useDispatch();
  const voucher = invoice.deviveryVoucher[0];
  const {
    data: dataDownloadDeliveryVoucher,
    loading: loadingDownloadDeliveryVoucher,
  } = useSelector<RootStateOrAny>(
    (state) => state.downloadDeliveryVoucher
  ) as DownloadDeliveryVoucherState;

  const handleDownload = useCallback(
    (voucher_id, file_name) => {
      dispatch(DownloadDeliveryVoucherActions.request({ file_name }));
    },
    [dispatch]
  );

  const downloadFile = () => {
    const name = voucher.file_name;
    let extension = '';
    
    if(name.indexOf('.pdf') !== -1) {
      extension = '.pdf';
    } 
    else if(name.indexOf('.jpg') !== -1) {
      extension = '.jpg';
    } 
    else if(name.indexOf('.png') !== -1) {
      extension = '.png';
    } 
    else {
      extension = '.jpeg';
    }
    
    const fileName = `Comprovante_Invoice_${voucher.tracking_invoice_id}${extension}`;
    
    if (dataDownloadDeliveryVoucher) {
      const b64 = dataDownloadDeliveryVoucher.url;

      fetch(b64)
        .then((response) => response.blob())
        .then((blob) => {
          var link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  
  }

  const approvedDeliveryVoucher = () => {
    const data = {
      approved_at: new Date(),
    };

    handleUpdate(voucher.id, data);
  };

  const rejectedDeliveryVoucher = () => {
    const data = {
      rejected_at: new Date(),
    };

    handleUpdate(voucher.id, data);
  };

  const handleUpdate = useCallback(
    (id, data) => {
      dispatch(UpdateDeliveryVoucherActions.request(id, data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (voucher) {
      handleDownload(voucher.id, voucher.file_name);
    }
  }, [handleDownload, voucher]);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderItem>
          <S.IconHeader />
          <S.Title>Comprovante de Entrega</S.Title>
        </S.HeaderItem>
      </S.Header>
      <S.ContainerImg>
        {voucher ? (
          !loadingDownloadDeliveryVoucher && dataDownloadDeliveryVoucher ? (
            dataDownloadDeliveryVoucher.extensao !== "pdf" ? (
              <img src={dataDownloadDeliveryVoucher.url} alt="" />
            ) : (
              <iframe
                width="100%"
                height="100%"
                src={dataDownloadDeliveryVoucher.url}
                title={voucher.file_name}
              ></iframe>
            )
          ) : (
            <p> Carregando comprovante {loadingDownloadDeliveryVoucher && <S.LoadingPage />}</p>
          )
        ) : (
          <p> Nenhum comprovante foi enviado ainda</p>
        )}
      </S.ContainerImg>
      <S.ButtonsWrapper>
        <S.Button btStyle="cancel" onClick={closeModal}>
          Fechar
        </S.Button>
        {hasAnyPermission(["Administrador", "Customer Service"]) &&
        voucher &&
        !voucher?.approved_at &&
        !voucher?.rejected_at ? (
          <S.Button btStyle="danger" onClick={rejectedDeliveryVoucher}>
            Reprovar
          </S.Button>
        ) : (
          ""
        )}
        {hasAnyPermission(["Administrador", "Customer Service"]) &&
        voucher &&
        !voucher?.approved_at &&
        !voucher?.rejected_at ? (
          <S.Button btStyle="primary" onClick={approvedDeliveryVoucher}>
            Aprovar
          </S.Button>
        ) : (
          ""
        )}
          {
        voucher && !loadingDownloadDeliveryVoucher && dataDownloadDeliveryVoucher ?
        <S.Button btStyle="primary" className="download" onClick={downloadFile} >
            Download
          </S.Button>
        : <></>
      }

      </S.ButtonsWrapper>

      
    
    </S.Container>
  );
};
