import React, { useState, useRef, useCallback } from "react";
import * as S from "./styles";

interface PopOverProps {
  handler: any;
  message: string | JSX.Element;
  placement: "left" | "right";
}
export const Popover: React.FC<PopOverProps> = ({
  handler,
  message,
  placement = "left",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const messageRef = useRef(null);

  const MainComponent = useCallback(() => {
    const triggerHevavior = {
      onMouseEnter: () => setIsVisible(true),
      onMouseLeave: () => setIsVisible(false),
    };

    if (handler) {
      return (
        <S.HandlerContainer {...triggerHevavior}>
          {handler}
          <S.Hint
            placement={placement}
            ref={messageRef}
            isVisible={isVisible}
          >
            <S.Triangle />
            <S.Message>{message}</S.Message>
          </S.Hint>
        </S.HandlerContainer>
      );
    }

    return (
      <S.Bubble {...triggerHevavior}>
        <span>?</span>
        <S.Hint
          placement={placement}
          ref={messageRef}
          isVisible={isVisible}
        >
          <S.Triangle />
          <S.Message>{message}</S.Message>
        </S.Hint>
      </S.Bubble>
    );
  }, [handler, isVisible, message, placement]);

  return (
    <>
      <MainComponent />
    </>
  );
};
