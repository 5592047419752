import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as S from "./style";
import { FileInput } from "components/shared/Form";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "hooks";

import {
  CreateDeliveryVoucherActions,
  CreateDeliveryVoucherState,
} from "store/ducks/tracking/delivery-vouchers";

interface IProps {
  closeModal: () => void;
  invoiceID: number | null;
}

export const InvoiceModalUploadDeliveryVoucher: React.FC<IProps> = ({
  closeModal,
  invoiceID,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const [canAddVoucher, setCanAddVoucher] = useState(false);

  const { loading: loadingCreateVoucher } = useSelector<RootStateOrAny>(
    (state) => state.createDeliveryVoucher
  ) as CreateDeliveryVoucherState;

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          image: Yup.string().required(""),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append("image", data.image);
        formData.append("invoice_id", `${invoiceID}`);

        dispatch(CreateDeliveryVoucherActions.request(formData));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, invoiceID]
  );

  useEffect(() => {
    loadingCreateVoucher ? setCanAddVoucher(false) : setCanAddVoucher(true);
  }, [loadingCreateVoucher]);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderItem>
          <S.IconHeader />
          <S.Title>Upload Comprovante</S.Title>
        </S.HeaderItem>
      </S.Header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.FormRow>
          <FileInput
            name="image"
            label="Arquivo do canhoto (.jpg, .png ou .pdf)"
            containerStyle={{ marginBottom: 0 }}
          />
        </S.FormRow>

        <S.ButtonsWrapper>
          <S.Button btStyle="cancel" type="button" onClick={closeModal}>
            Fechar
          </S.Button>

          <S.SubmitButton className="submitButton" disabled={!canAddVoucher}>
            {loadingCreateVoucher ? <S.Loading /> : "Enviar"}
          </S.SubmitButton>
        </S.ButtonsWrapper>
      </Form>
    </S.Container>
  );
};
