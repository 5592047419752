import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import {
  ListHolidays,
  NewHoliday,
  EditHoliday,
  ListTransits,
  NewTransit,
  EditTransit,
  ImportTransit,
  ListInternacionalTransits,
  NewInternacionalTransit,
  EditInternacionalTransit,
  ListJustificationTypes,
  NewJustificationType,
  EditJustificationType,
  ListOperationTypes,
  NewOperationType,
  EditOperationType,
  ListOperationCfops,
  NewOperationCfop,
  EditOperationCfop,
} from "pages/Settings";
import { EditJustificationCustomerType, ListJustificationCustomerTypes, NewJustificationCustomerType } from "pages/Settings/JustificationCostumerTypes";

interface Props {}

export const SettingsRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/settings/holidays"
        component={ListHolidays}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/holiday/new"
        component={NewHoliday}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/holiday/:id"
        component={EditHoliday}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/transits"
        component={ListTransits}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/transit/new"
        component={NewTransit}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/transit/import"
        component={ImportTransit}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/transit/:id"
        component={EditTransit}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/internacional-transits"
        component={ListInternacionalTransits}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/internacional-transit/new"
        component={NewInternacionalTransit}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/internacional-transit/:id"
        component={EditInternacionalTransit}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/justification-types"
        component={ListJustificationTypes}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/justification-types/new"
        component={NewJustificationType}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/justification-types/:id"
        component={EditJustificationType}
        hasEveryPermission={["Administrador"]}
      />

      <PrivateRoute
        exact
        path="/settings/justificationCustomer-types"
        component={ListJustificationCustomerTypes}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/justificationCustomer-types/new"
        component={NewJustificationCustomerType}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/justificationCustomer-types/:id"
        component={EditJustificationCustomerType}
        hasEveryPermission={["Administrador"]}
      />

      <PrivateRoute
        exact
        path="/settings/operation-types"
        component={ListOperationTypes}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/operation-types/new"
        component={NewOperationType}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/operation-types/:id"
        component={EditOperationType}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/cfops-restrictions"
        component={ListOperationCfops}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/cfops-restrictions/new"
        component={NewOperationCfop}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/settings/cfops-restrictions/:id"
        component={EditOperationCfop}
      />
    </Switch>
  );
};
