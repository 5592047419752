import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { ListInvoices, ShowInvoice, ListTrips, ShowTrips, ListIndent, ShowIndent } from "pages/Tracking";

interface Props {}

export const TrackingRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/tracking" component={ListInvoices} />
      <PrivateRoute exact path="/invoice/:id" component={ShowInvoice} />
      <PrivateRoute exact path="/trip" component={ListTrips} />
      <PrivateRoute exact path="/trip/:id" component={ShowTrips} />
      <PrivateRoute exact path="/indent" component={ListIndent} />
      <PrivateRoute exact path="/indent/:id" component={ShowIndent} />
    </Switch>
  );
};
