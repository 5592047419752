import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateTripsActions } from "store/ducks/tracking/trips";

export function* paginateTripsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/transits?${queryString}`
    );

    const { transits, pagination, indicators } = responseBody;
    yield put(
      PaginateTripsActions.success(transits, pagination, indicators)
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateTripsActions.failure(errorMessage));
  }
}
