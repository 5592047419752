export const translations = {
  nomeFeriado: {
    pt: 'Nome do Feriado',
    en: 'Holiday name',
    de: 'Feiertagsname',
    es: 'Nombre de vacaciones',
  },
  dataFeriado: {
    pt: 'Data do Feriado',
    en: 'Holiday Date',
    de: 'Feiertagsdatum',
    es: 'Fecha de vacaciones',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },

};