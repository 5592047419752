import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/list-duck";
import { JustificationCustomerType } from "interfaces/justificationCustomer-type";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_JUSTIFICATION_CUSTOMER_TYPES_" }
);

export interface ListJustificationCustomerTypesState extends IState {
  data: JustificationCustomerType[];
}

interface ISuccessListJustificationCustomerTypesAction extends ISuccessAction {
  data: JustificationCustomerType[];
}

const INITIAL_STATE: ListJustificationCustomerTypesState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ListJustificationCustomerTypesState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: ListJustificationCustomerTypesState,
  action: ISuccessListJustificationCustomerTypesAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: ListJustificationCustomerTypesState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listJustificationCustomerTypes = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListJustificationCustomerTypesTypes = Types;
export const ListJustificationCustomerTypesActions = Creators;
