export const translations = {
  comprovanteAguardando: {
    pt: 'Aguardando comprovante',
    en: 'Waiting for proof',
    de: 'Warten auf Beweis',
    es: 'Esperando pruebas',
  },
  comprovanteAprovado: {
    pt: 'Comprovante aprovado',
    en: 'Approved proofe',
    de: 'Anerkannter Nachweis',
    es: 'Comprobante aprobado',
  },
  comprovanteReprovado: {
    pt: 'Comprovante reprovado',
    en: 'Failed proof',
    de: 'Fehlgeschlagener Beweis',
    es: 'Cupón fallido',
  },
  aguardandoAprovado: {
    pt: 'Aguardando aprovação',
    en: 'Waiting for approval',
    de: 'Warten auf die Bestätigung',
    es: 'Esperando aprobación',
  },
  
};