import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { format, compareDesc } from 'date-fns';
import { TripsStatusLabel } from 'components/tracking';
import { ITrip, IInvoiceClient } from 'interfaces/trips';
import { moeda, peso } from 'utils/formatters';
import { useTranslation } from 'hooks';
import { translations } from './translations';

import * as S from './styles';

interface IProps {
  trip: ITrip;
}

export const TripsTrackingHeader: React.FC<IProps> = ({ trip }) => {
  const [info, setInfo] = useState<any>(null);
  const { getTranslation } = useTranslation(translations);

  const buildInfo = useCallback(() => {
    let amount = 0;
    let qtdDelivered = 0;
    let totalNetWeight = 0;
    let totalGrossWeight = 0;
    let dateDeliveryEnd = null;

    const uniqueClients: IInvoiceClient[] = [];

    trip.invoices.map((invoice) => {
      qtdDelivered += invoice.delivery_date ? 1 : 0;
      totalNetWeight += invoice.transp_peso_liquido_kg;
      totalGrossWeight += invoice.transp_peso_bruto;
      amount += invoice.total_valor_total_nf;

      if (
        uniqueClients.filter((unique) => unique.id === invoice.client.id)
          .length === 0
      ) {
        return uniqueClients.push(invoice.client);
      }

      return {};
    });

    if (trip.invoices.length === qtdDelivered) {
      const dateDeliveries = trip.invoices.map(
        (invoice) => new Date(invoice.delivery_date)
      );

      dateDeliveryEnd = dateDeliveries.sort(compareDesc);
    }

    setInfo({
      id: trip.id,
      qtd: uniqueClients.length,
      delivered: qtdDelivered,
      weightNet: peso(totalNetWeight),
      weightGross: peso(totalGrossWeight),
      amount: moeda(amount),
      inicio: format(new Date(trip.exit_at), 'dd/MM/yyyy HH:mm'),
      fim: dateDeliveryEnd?.length
        ? format(dateDeliveryEnd[0], 'dd/MM/yyyy HH:mm')
        : '---',
      status: { qtd: trip.invoices.length, delivered: qtdDelivered },
    });
  }, [trip]);

  useEffect(() => {
    buildInfo();
  }, [buildInfo]);

  return (
    <S.Container>
      {info ? (
        <div>
          <S.Wrapper>
            <TripsStatusLabel status={info.status} />

            <S.Item>
              <S.Label>{getTranslation('pesoBruto')}</S.Label>
              <S.Value>{info.weightGross || '---'}</S.Value>
            </S.Item>

            <S.Item>
              <S.Label>{getTranslation('pesoLiquido')}</S.Label>
              <S.Value>{info.weightNet || '---'}</S.Value>
            </S.Item>

            <S.Item>
              <S.Label>{getTranslation('valor')}</S.Label>
              <S.Value>{info.amount || '---'}</S.Value>
            </S.Item>

            <S.Item>
              <S.Label>{getTranslation('qCliente')}</S.Label>
              <S.Value>{info.qtd || '---'}</S.Value>
            </S.Item>
            <S.Item>
              <S.Label>{getTranslation('inicio')}</S.Label>
              <S.Value>{info.inicio || '---'}</S.Value>
            </S.Item>

            <S.Item>
              <S.Label>{getTranslation('fim')}</S.Label>
              <S.Value>{info.fim || '---'}</S.Value>
            </S.Item>
          </S.Wrapper>
        </div>
      ) : (
        ''
      )}
    </S.Container>
  );
};
