export const colors = {
  white: "#ffffff",
  lightGray: "#f5f5f5",
  gray: "#eaeaea",
  gray1: "#CECECE",
  gray2: "#E8E8E8",
  gray3: "#777777",
  gray4: "#444444",
  black: "#2B2B2B",
  dark: "#272727",
  darkRgba15: "rgba(41,45,65, 0.15)",
  darkRgba25: "rgba(41,45,65, 0.25)",
  darkRgba8: "rgba(41,45,65, 0.08)",
  darkRgba5: "rgba(41,45,65, 0.05)",
  navyBlue: "#09005D",
  lightBlueGray: "#C8CFD4",
  line: "#D8DAE8",
  deepPurple: "#991781",
  green: "#64B32D",
  petrol: "#0D8E9D",
  orange: "#ED6C1F",
  warmGray6: "#A59C94",
  warmGray10: "#796E65",
  brown: "#6F453E",
  yellow: "#DBAC29",
  darkGreen: "#70982B",
  darkPetrol: "#065A4D",
  red: "#9B0833",
  blue: "#023B69",
  midBlue: "#0067A0",
  lightBlue: "#71A4CF",
  sigmalYellow: "#FFDF35",
};

export const colorScheme = {
  primary: colors.deepPurple,
  secondary: colors.gray4,
  warning: colors.orange,
  text: colors.dark,
};
