import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  FetchOperationCfopState,
  FetchOperationCfopActions,
  UpdateOperationCfopActions,
  UpdateOperationCfopState,
  DeleteOperationCfopActions,
  DeleteOperationCfopState,
} from "store/ducks/settings/operation-cfop";
import {
  ListOperationTypesActions,
  ListOperationTypesState,
} from "store/ducks/settings/operation-type";
import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold, Modal, Alert } from "components/shared";
import { Input, Select, ToggleInput } from "components/shared/Form";

interface IParams {
  id: string;
}

interface FormData {
  cfop: number;
  category: string;
  description: string;
  operation_type_id: number;
  agendamento: "0" | "1";
  checkfrete: "0" | "1";
  roteirizador: "0" | "1";
  tracking: "0" | "1";
}

export const EditOperationCfop: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: dataFetchOperationCfop, loading } = useSelector<RootStateOrAny>(
    (state) => state.fetchOperationCfop
  ) as FetchOperationCfopState;

  const { loading: loadingDeleteOperationCfop } = useSelector<RootStateOrAny>(
    (state) => state.deleteOperationCfop
  ) as DeleteOperationCfopState;

  const { loading: loadingUpdateOperationCfop } = useSelector<RootStateOrAny>(
    (state) => state.updateOperationCfop
  ) as UpdateOperationCfopState;

  const { data: dataOperationTypes, loading: loadingOperationTypes } =
    useSelector<RootStateOrAny>(
      (state) => state.listOperationTypes
    ) as ListOperationTypesState;

  const onSuccess = useCallback(() => {
    history.push("/settings/cfops-restrictions");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(
    async (data) => {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        cfop: Yup.number().required("Obrigatório"),
        category: Yup.string().required("Obrigatório"),
        description: Yup.string().required("Obrigatório"),
      });
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateOperationCfopActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, id, onSuccess]
  );

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      if (data.type) {
        data.operation_type_id = {
          label: data.type.name,
          value: data.type.id,
        };
      }
      formRef.current.setData(data);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchOperationCfopActions.request(id, onFetchSuccess));
      dispatch(ListOperationTypesActions.request({ all: true }));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteOperationCfopActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchData();

  }, [fetchData]);

  return (
    <Scaffold>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`Remover Operação CFOP ${dataFetchOperationCfop?.cfop}`}
          text="Deseja realmente remover essa operação?"
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteOperationCfop}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Editar Operação CFOP</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input 
                name="cfop"
                label="Código CFOP"
                type="number"
                step="1"
                min="1"
              />
              <Select
                name="operation_type_id"
                label="Tipo de operação"
                disabled={loadingOperationTypes}
                isLoading={loadingOperationTypes}
                options={dataOperationTypes}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="category" label="Categoria" multiline />
              <Input name="description" label="Descrição" multiline />
            </S.FormRow>
            <S.FormRow>
              <ToggleInput
                name="tracking_ignored"
                label="Ignorar esse CFOP no Tracking"
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Remover
              </S.Button>
              <S.Button type="submit">
                {loadingUpdateOperationCfop ? <S.Loading /> : "Salvar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
