export const translations = {
  emissao: {
    pt: 'Emissão',
    en: 'Issue',
    de: 'Problem',
    es: 'Asunto',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  entrega: {
    pt: 'Entrega',
    en: 'Delivery',
    de: 'Lieferung',
    es: 'Entrega',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  },
};