import styled from "styled-components";
import { Truck } from "@styled-icons/boxicons-solid";
import { Check } from "@styled-icons/boxicons-regular";
import { Cancel } from "@styled-icons/typicons";
import { colors, fonts } from "styles";
import { TTripStatus } from "./index";

interface StatusStyle {
  status?: TTripStatus;
}
const backgroundStatus = {
  transito: `${colors.orange}`,
  entregue: `${colors.green}`,
};

export const Hint = styled.span`
  position: absolute;
  top: -24px;
  display: none;
  padding: 4px 8px;
  background-color: ${colors.gray4};
  border-radius: 4px;
  font-family: ${fonts.GilroySemiBold};
  font-size: 11px;
  text-align: center;
  color: white;
  width: max-content;
`;

export const Container = styled.div<StatusStyle>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => status && backgroundStatus[status]};

  &:hover {
    ${Hint} {
      display: flex;
    }
  }
`;

export const IconTransito = styled(Truck).attrs({ size: 16 })`
  color: white;
`;
export const IconEntregue = styled(Check).attrs({ size: 16 })`
  color: white;
`;
export const IconCancel = styled(Cancel).attrs({ size: 24 })`
  color: white;
`;
