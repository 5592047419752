import styled, { css } from "styled-components";
import { colors, fonts } from "styles";
import { ReactComponent as PopoverTriangleImage } from "assets/images/left-popover-triangle.svg";

export const HandlerContainer = styled.span`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
  cursor: pointer;
`;

export const Bubble = styled.span`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${colors.blue};
  color: #fff;
  font-size: 10px;
  cursor: pointer;
`;

const getPlacement = (placement: any) => {
  if (placement === "left") {
    return css`
      right: calc(100% + 8px);
      .triangle {
        order: 2;
        transform: rotate(180deg);
      }
      .message {
        order: 1;
      }
    `;
  }
  return css`
    left: calc(100% + 8px);
    .triangle {
      order: 1;
    }
    .message {
      order: 2;
    }
  `;
};

const visibleState = css`
  opacity: 1;
  display: flex;
`;

interface HintProps {
  placement: any;
  isVisible: boolean;
}
export const Hint = styled.div<HintProps>`
  position: absolute;
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  ${({ placement }) => getPlacement(placement)};
  ${({ isVisible }) => isVisible && visibleState};
  max-width: 400px;
  z-index: 2;
  @media (max-width: 640px) {
    max-width: 60vw;
  }
`;

export const Triangle = styled(PopoverTriangleImage).attrs({
  className: "triangle",
})`
  order: 1;
  fill: ${colors.gray4};
  stroke: ${colors.gray4};
  align-self: center;
`;

export const Message = styled.div.attrs({ className: "message" })`
  width: max-content;
  padding: 16px;
  text-align: left;
  background-color: ${colors.gray4};
  color: #fff;
  border-radius: 4px;
  font-family: ${fonts.OpenSans};
  font-weight: 400;
  font-size: 13px;
  box-shadow: 0px 8px 16px 4px #3333;
  p:not(:last-child) {
    margin-bottom: 8px;
  }
`;
