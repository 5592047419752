import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/export-duck";
import { Indent } from "interfaces/indent";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "EXPORT_INDENTS_" }
);

export interface ExportIndentsState extends IState {
  data: Indent[];
}

interface ISuccessExportIndentsAction extends ISuccessAction {
  data: Indent[];
}

const INITIAL_STATE: ExportIndentsState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ExportIndentsState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: ExportIndentsState,
  action: ISuccessExportIndentsAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: ExportIndentsState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const exportIndents = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ExportIndentsTypes = Types;
export const ExportIndentsActions = Creators;
