import styled, { css } from "styled-components";
import illustrationBg1 from "assets/images/illustration-bg-1.png";
import illustrationBg2 from "assets/images/illustration-bg-2.png";
import illustrationBg3 from "assets/images/illustration-bg-3.png";
import illustrationPacks from "assets/images/illustration-packs.png";
import illustrationTree1 from "assets/images/illustration-tree-1.png";
import illustrationTree2 from "assets/images/illustration-tree-2.png";

import { colors } from "styles";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 230px;
  position: relative;
  margin-bottom: 120px;
  z-index: 0;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Item = styled.div`
  transition: 2s ease;
  animation-delay: 1s;
  position: relative;
  display: flex;
`;

export const DistanceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: -16px;
  background-color: #ccc;
`;

export const Line = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${colors.navyBlue};
  position: relative;
  z-index: 10;
`;

interface IDistanceLine {
  full: boolean;
}
export const DistanceLine = styled.div<IDistanceLine>`
  width: 100%;
  height: 2px;
  background-color: ${colors.gray3};

  ${({ full }) =>
    full &&
    css`
      background-color: ${colors.deepPurple};
    `}
`;

export const Client = styled.div`
  display: flex;
  flex-direction: column;
  width: 112px;
  align-items: center;
`;

export const Ball = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

export const BallItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
`;
interface IBallItem {
  check?: boolean;
}
export const BallItem = styled(Ball)<IBallItem>`
  position: relative;
  z-index: 2;
  background-color: ${colors.gray3};
  top: -3px;
  margin-bottom: 8px;

  ${({ check }) =>
    check &&
    css`
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: ${colors.deepPurple};
    `}
`;

export const Label = styled.h6`
  font-size: 12px;
  color: ${colors.gray3};
`;

export const LabelStart = styled(Label)`
  position: absolute;
  left: 0;
  bottom: -24px;
`;

export const LabelEnd = styled(Label)`
  position: absolute;
  right: 0;
  bottom: -24px;
`;

interface ILabelItem {
  active?: boolean;
}
export const LabelItem = styled(Label)<ILabelItem>`
  position: relative;
  text-align: center;

  ${({ active }) =>
    active &&
    css`
      color: ${colors.deepPurple};
    `}
`;

export const LabelPositionToFinished = styled(Label)<{ distance: number }>`
  position: absolute;
  top: -20px;
  right: 45%;
  text-align: center;
  color: ${colors.orange};
`;

export const IllustrationBg1 = styled.img.attrs({ src: illustrationBg1 })`
  position: absolute;
  left: 0;
  z-index: 0;
`;

export const IllustrationBg2 = styled.img.attrs({ src: illustrationBg2 })`
  position: absolute;
  z-index: 0;
`;

export const IllustrationBg3 = styled.img.attrs({ src: illustrationBg3 })`
  position: absolute;
  right: 0;
  z-index: 0;
`;

export const IllustrationTree1 = styled.img.attrs({ src: illustrationTree1 })`
  position: absolute;
  left: 15%;
  z-index: 2;
`;

export const IllustrationTree2 = styled.img.attrs({ src: illustrationTree2 })`
  position: absolute;
  right: 25%;
  z-index: 2;
`;

export const IllustrationPacks = styled.img.attrs({ src: illustrationPacks })`
  position: relative;
  bottom: 0;
`;
