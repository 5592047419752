import React from "react";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface IProps {
  status: any;
}

export const DeliveryVoucherStatusIcon: React.FC<IProps> = ({ status }) => {
    const { getTranslation } = useTranslation(translations);
    let renderStatus;

    if(status === "Comprovante aprovado"){
      renderStatus = { label: getTranslation('comprovanteAprovado'), icon: <S.IconAprovado/> };
    } else if(status === "Comprovante reprovado") {
      renderStatus = { label: getTranslation('comprovanteReprovado'), icon: <S.IconReprovado /> };
    } else if(status === "Aguardando comprovante") {
      renderStatus = { label: getTranslation('comprovanteAguardando'), icon: <S.IconAguardandoComp /> };
    } else {
      renderStatus = { label: getTranslation('aguardandoAprovado'), icon: <S.IconAguardandoAprov /> };
    }
    
  return (
    <S.Container status={status}>
        <S.Hint>{renderStatus ? renderStatus.label : getTranslation('semStatus')}</S.Hint>
        {renderStatus.icon}
    </S.Container>
  );
};
