import React from "react";
import { Signature } from "components/shared";
import * as S from "./styles";

export const Footer: React.FC = () => {
  return (
    <S.Container>
      <Signature />
    </S.Container>
  );
};
