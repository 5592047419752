export const translations = {
  comprovantes: {
    pt: 'Comprovantes',
    en: 'Proof',
    de: 'Lieferschein',
    es: 'Recibo'
  },
  notaFiscal: {
    pt: 'Tracking por Notas Fiscais',
    en: 'Tracking by Invoices',
    de: 'Tracking nach Rechnungen',
    es: 'Seguimiento por Facturas',
  },
  codigo_P: {
    pt: 'Código do Picking',
    en: 'Picking code',
    de: 'Auswahlcode',
    es: 'Código de picking',
  },
};