export const translations= {
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origen',
  },  
  bl: {
    pt: 'BL',
    en: 'BL',
    de: 'Geschäftszweig',
    es: 'línea de negocio',
  }, 
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  }, 
  dataEmissao: {
    pt: 'Data de Emissão',
    en: 'Date of issue',
    de: 'Ausgabedatum',
    es: 'Fecha de emisión',
  }, 
  prazoC: {
    pt: 'Prazo contratado',
    en: 'Contracted term',
    de: 'Vertragslaufzeit',
    es: 'Plazo contratado',
  }, 
  previsaoEntrega: {
    pt: 'Previsão de entrega',
    en: 'Delivery forecast',
    de: 'Lieferprognose',
    es: 'Previsión de entrega',
  }, 
  dataEntrega: {
    pt: 'Data de entrega',
    en: 'Delivery date',
    de: 'Liefertermin',
    es: 'Fecha de entrega',
  }, 
  dataDevolucao: {
    pt: 'Data devolução',
    en: 'return date',
    de: 'Rückflugdatum',
    es: 'Fecha de regreso',
  }, 
  localizacao: {
    pt: 'Localização',
    en: 'Localization',
    de: 'Lokalisierung',
    es: 'Localización',
  }, 
};