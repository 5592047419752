import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateIgnoredCfopActions } from "store/ducks/settings/ignoredCfop";

export function* createIgnoredCfopRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      api.post,
      `/ignored-cfops`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateIgnoredCfopActions.success(data));
    notify("success", "Restrição de CFOP cadastrado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateIgnoredCfopActions.failure(errorMessage));
  }
}
