import React, { useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import {
  ImportTransitActions,
  ImportTransitState,
} from "store/ducks/settings/transits";

import * as S from "./styles";
import { Scaffold } from "components/shared";

interface IFile { 
  [index: number]:{
    id?: number;
    carrier?: {
      trade_name: string;
    };
    error?: string;
  };
  map: Function;
};

interface IMessage { 
  [index: number]:{};
  foundList?: any;
  notFoundList?: any;
  map?: Function;
};


export const ImportTransit: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.importTransit
  ) as ImportTransitState;

  const [fileData, setFileData] = useState<IFile>([]);
  const [messageList, setMessageList] = useState<IMessage>([]);
  const [loadingImport, setLoadingImport] = useState(false);

  const getWorkbookFromFile = async (excelFile:any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event:any) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary", cellDates: true });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleImport = async (file: any) => {
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setLoadingImport(true);
      setFileData([]);
      const workbook:any = await getWorkbookFromFile(file[0]);
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data:any = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });

      if(data && data[0]) {
        const foundList:any = [];
        const notFoundList:any = [];
        const fileContent = {
          carrier_cnpj: data[0]['TRANSPORTADORA'],
          type: data[0]['TIPO'],
          ibge_origin: data[0]['IBGE_ORIGEM'],
          ibge_destiny: data[0]['IBGE_DESTINO'],
          km_final: data[0]['KM_FIM'],
          weight: data[0]['PESO'],
          deadline_fractional: data[0]['PRAZO_FRACIONADO'],
          deadline_dedicated: data[0]['PRAZO_LOTACAO'],
        }

        if(fileContent.carrier_cnpj) {
          foundList.push('TRANSPORTADORA');
        } else {
          notFoundList.push('TRANSPORTADORA');
        }

        if(fileContent.type) {
          foundList.push('TIPO');
        } else {
          notFoundList.push('TIPO');
        }

        if(fileContent.ibge_origin) {
          foundList.push('IBGE_ORIGEM');
        } else {
          notFoundList.push('IBGE_ORIGEM');
        }

        if(fileContent.ibge_destiny || fileContent.km_final) {
          foundList.push('IBGE_DESTINO ou KM_FIM');
        } else {
          notFoundList.push('IBGE_DESTINO ou KM_FIM');
        }

        if(fileContent.weight) {
          foundList.push('PESO');
        } else {
          notFoundList.push('PESO');
        }

        if(fileContent.deadline_fractional) {
          foundList.push('PRAZO_FRACIONADO');
        } else {
          notFoundList.push('PRAZO_FRACIONADO');
        }

        if(fileContent.deadline_dedicated) {
          foundList.push('PRAZO_LOTACAO');
        } else {
          notFoundList.push('PRAZO_LOTACAO');
        }


        setMessageList({
          foundList,
          notFoundList,
        });

        if(!notFoundList.length) {
          await sendImport(data);
        }
        setLoadingImport(false);
      } else {
        setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
        setLoadingImport(false);
      }

    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
      setLoadingImport(false);
    }
  };

  const onSuccess = (data: any) => {
    setFileData(data);
  }

  const onFailure = () => {
    setFileData([
      { error: "Erro no envio da planilha. Favor tentar novamente!" },
    ]);
  }

  const sendImport = async (data:any) => {
    dispatch(ImportTransitActions.request(data, onSuccess, onFailure));
  };

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Importação de Tempo de Trânsito</span>
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        {!loadingImport && !loading && (
          <input
            type="file"
            onChange={(event: any) =>
              event.target.files[0] && handleImport(event.target.files)
            }
            name=""
            placeholder=""
          />
        )}
        <S.ListAlerts>
          {fileData[0] && (
            <>
              {fileData[0].error ? (
                <>
                  <ul>
                    {fileData.map((data: any) => (
                      <li key={data.codigo}>
                        {!data.error ? (
                          <>
                            <S.ErroTxt>ERROR</S.ErroTxt>
                            <p>
                              Transportadora: <strong>{data.carrier_cnpj}</strong>
                            </p>
                          </>
                        ) : (
                          <>
                            <S.ErroTxt>{data.error}</S.ErroTxt>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <big>Transit Time Cadastrado</big>
                  {fileData.map((data: any) => (
                    <div key={data.id}>
                      <S.BoxTitle>
                        <p>
                          Transportadora: <strong>{data.carrier_cnpj}</strong>
                        </p>
                      </S.BoxTitle>
                    </div>
                  ))}
                </>
              )}
            </>
          )}

          {
            messageList &&
            messageList.notFoundList &&
            messageList.notFoundList[0] && (
              <div>
                <S.MessageList>Dados NÃO Encontrados</S.MessageList>
                <ul>
                  {
                    messageList.notFoundList.map((item: any) => (
                      <S.MessageItem key={item} background="#9B0833">
                        {item}
                      </S.MessageItem>
                    ))
                  }
                </ul>
              </div>
            )
          }

          {!fileData[0] && (loading || loadingImport) && <S.LoadingPage />}
        </S.ListAlerts>
      </S.PageContent>
    </Scaffold>
  );
};
