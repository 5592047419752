import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as S from "./style";
import { Scaffold, Paginator, Modal } from "components/shared";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useTranslation } from "hooks";
import { translations } from "./translations";
import {
  InvoiceDeliveryVoucherActions,
  InvoiceDeliveryVoucherState,
  ExportInvoiceDeliveryVoucherState,
  ExportInvoiceDeliveryVoucherActions
} from "store/ducks/tracking/invoices";

import {
  GridInvoicesDeliveryVoucher,
  InvoiceFilterDeliveryVoucher,
  InvoiceModalShowDeliveryVoucher,
  InvoiceModalUploadDeliveryVoucher,
  InvoiceIndicatorsDeliveryVoucher,
} from "components/tracking";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";
import { format } from "date-fns";

export const ListDeliveryVoucher: React.FC = () => {
  const dispatch = useDispatch();

  const { data, loading, pagination, indicators } = useSelector<RootStateOrAny>(
    (state) => state.InvoiceDeliveryVoucher
  ) as InvoiceDeliveryVoucherState;

  const { data: fileData, loading: fileLoading } = useSelector<
    RootStateOrAny,
    ExportInvoiceDeliveryVoucherState
  >(state => state.ExportInvoiceDeliveryVoucher);

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const [dataSelected, setDataSelected] = useState<any>(null);
  const [modalUpload, setModalUpload] = useState<boolean>(false);
  const [modalExpand, setModalExpand] = useState<boolean>(false);
  const [indicatorFilter, setIndicatorFilter] = useState<boolean>(false);
  const [exportActive, setExportActive] = useState(false);
  const { getTranslation } = useTranslation(translations);
  
  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        ...value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
    },
    [ dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
    },
    [dispatch, dataFilter]
  );

  const getData = useCallback(() => {
    (dataFilter?.indicators)?setIndicatorFilter(true) : setIndicatorFilter(false)
    dispatch(
      InvoiceDeliveryVoucherActions.request({...dataFilter })
    );
  }, [dispatch, dataFilter]);

  const getFileData = async () => {
    dispatch(ExportInvoiceDeliveryVoucherActions.request({ all: true, ...dataFilter }));
    setExportActive(true);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const handUpdateList = () => {
    getData();
  };

  const openModalUpload = (index: number) => {
    setDataSelected(data[index]);
    setModalUpload(true);
  };

  const openModalExpand = (index: number) => {
    setDataSelected(data[index]);
    setModalExpand(true);
  };

  const closeModalUpload = () => {
    handUpdateList();
    setModalUpload(false);
  };

  const closeModalExpand = () => {
    handUpdateList();
    setModalExpand(false);
  };

  const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const generateFile = () => {
    if(fileData){
      const newFile: any[] = [];
      const fileName = `TRACKING_DELIVERY_VOUCHERS`;

      fileData.forEach(line => {
        const {
          ide_numero_nf,
          ide_serie,
          ide_data_emissao,
          deadline_date,
          delivery_date,
          emit_nome_municipio,
          dest_nome_municipio,
          transp_razao_social,
          company,
          client,
          deviveryVoucher,
          picking,
        } = line;

        const NF = `${ide_numero_nf} - ${ide_serie}`;
        const CENTRO = company && company.code && company.trade_name ? `${company.code} - ${company.trade_name}`: '---';
        const EMISSAO = ide_data_emissao && format(new Date(ide_data_emissao), 'dd/MM/yyyy');
        const PRAZO = deadline_date && format(new Date(deadline_date), 'dd/MM/yyyy');
        const ENTREGA = delivery_date && format(new Date(delivery_date), 'dd/MM/yyyy');
        const ORIGEM = emit_nome_municipio ? emit_nome_municipio : '---';
        const DESTINO = dest_nome_municipio ? dest_nome_municipio : '---';
        const TRANSPORTADORA = transp_razao_social ? transp_razao_social : '---';
        const CLIENTE = client && client.trade_name ? client.trade_name : '---';
        const STATUS = deviveryVoucher.length <= 0 ? 'Aguardando Comprovante' : deviveryVoucher && deviveryVoucher[0]?.approved_at ? 'Aprovado' : deviveryVoucher && deviveryVoucher[0]?.rejected_at ? 'Reprovado' : 'Aguardando Aprovação';
        const lastVoucher = deviveryVoucher.length > 0 ? 
          deviveryVoucher[0] : null;
        const sendedAt = lastVoucher ? 
          format(new Date(lastVoucher.created_at), 'dd/MM/yyyy - HH:mm') : '---';
        const COD_PICKING = picking && picking.code ? picking.code : '---';

        const fileLine = {
          NF,
          CENTRO,
          EMISSAO,
          PRAZO,
          ENTREGA,
          ORIGEM,
          DESTINO,
          TRANSPORTADORA,
          CLIENTE,
          COD_PICKING,
          'DATA/HORA DO ENVIO': sendedAt,
          STATUS
        }
      
      newFile.push(fileLine);
      });
      exportToCSV(newFile, fileName);
    }
  }

  useEffect(() => {
    if (!fileLoading && exportActive) {
      generateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLoading, exportActive]);


  return (
    <Scaffold>
      {dataSelected ? (
        <div>
          <Modal isOpen={modalUpload}>
            <InvoiceModalUploadDeliveryVoucher
              closeModal={closeModalUpload}
              invoiceID={dataSelected.id}
            />
          </Modal>

          <Modal isOpen={modalExpand}>
            <InvoiceModalShowDeliveryVoucher
              closeModal={closeModalExpand}
              invoice={dataSelected}
            />
          </Modal>
        </div>
      ) : (
        ""
      )}
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          {getTranslation("comprovantes")} <span>{getTranslation("notaFiscal")}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <InvoiceFilterDeliveryVoucher 
          onFilter={handleSubmitFilter}  
          indicatorFilter={indicatorFilter}
          fileLoading={fileLoading}
          onExport={() => getFileData()}
          />
      </S.PageHeader>

      <S.PageContent>
        <InvoiceIndicatorsDeliveryVoucher
          indicators={indicators}
          onFilterRequest={handleSubmitFilter}
        />
        <GridInvoicesDeliveryVoucher
          invoices={data}
          onClickUpload={openModalUpload}
          onClickExpand={openModalExpand}
        />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
