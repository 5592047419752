import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import {
  IState,
  ISuccessAction,
  IFailureAction,
} from "interfaces/paginate-duck";
import { Invoice, Indicators } from "interfaces/invoice";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination", "indicators"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PAGINATE_INVOICE_" }
);

export interface PaginateInvoicesState extends IState {
  data: Invoice[] | Record<string, any>[];
  indicators: Indicators | null;
}

export interface SuccessPaginateInvoicesAction extends ISuccessAction {
  indicators: Indicators;
}

const INITIAL_STATE: PaginateInvoicesState = {
  data: [],
  indicators: null,
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: PaginateInvoicesState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: PaginateInvoicesState,
  action: SuccessPaginateInvoicesAction
) =>
  update(state, {
    data: { $set: action.data },
    indicators: { $set: action.indicators },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (state: PaginateInvoicesState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateInvoices = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateInvoicesTypes = Types;
export const PaginateInvoicesActions = Creators;
