export const translations = {
  processo: {
    pt: 'Processo automatico',
    en: 'Automatic process',
    de: 'Automatischer Prozess',
    es: 'Proceso automático',
  },
  atividades: {
    pt: 'Atividades',
    en: 'Activities',
    de: 'Aktivitäten',
    es: 'Actividades',
  },
  justificativas: {
    pt: 'Justificativas',
    en: 'Justifications',
    de: 'Begründungen',
    es: 'Justificaciones',
  },
};