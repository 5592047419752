export const translations = {
  localizacoes: {
    pt: 'Localizações',
    en: 'Locations',
    de: 'Standorte',
    es: 'Ubicaciones',
  },
  justificativaTrans: {
    pt: 'Justificativas transportadora',
    en: 'Carrier justifications',
    de: 'Träger Begründungen',
    es: 'justificaciones del transportador',
  },
  justificativaCustomer: {
    pt: 'Justificativas Customer',
    en: 'Customer justifications',
    de: 'Kundenbegründungen',
    es: 'Justificaciones del cliente',
  },
  dataEntrega: {
    pt: 'Data Entrega',
    en: 'Delivery date',
    de: 'Liefertermin',
    es: 'Fecha de entrega',
  },
  businessLine: {
    pt: 'Business Line',
    en: 'Business line',
    de: 'Geschäftslinie',
    es: 'Línea de negocios',
  },
  recalculaPrazo: {
    pt: 'Recalcular prazo',
    en: 'Recalculate deadline',
    de: 'Frist neu berechnen',
    es: 'Recalcular plazo',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
};