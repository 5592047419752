import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { format } from "date-fns";
import { useValidation } from "hooks";

import { apiGeneral } from "services/api";

import {
  FetchTransitState,
  FetchTransitActions,
  UpdateTransitActions,
  UpdateTransitState,
  DeleteTransitActions,
  DeleteTransitState,
} from "store/ducks/settings/transits";

import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";

import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";

import { Input, InputMask, Select } from "components/shared/Form";
import { Scaffold, Modal, Alert } from "components/shared";
import { statesOptions } from "utils/data/states";
import { typesOptions } from "utils/data/types";
import { countSelectData } from "../Data/countSelect";

import * as S from "./styles";

interface IParams {
  id: string;
}

export const EditTransit: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [queryType, setQueryType] = useState("");
  const [originOptions, setOriginOptions] = useState([{}]);
  const [destinyOptions, setDestinyOptions] = useState([{}]);
  const [originValue, setOriginValue] = useState("");
  const [destinyValue, setDestinyValue] = useState("");
  const [typeValue, setTypeValue] = useState(null);

  const { data: dataCarriers, loading: loadingCarriers } = useSelector<
    RootStateOrAny,
    ListCarriersState
  >((state) => state.listCarriers);

  const { data: dataCities, loading: loadingCities } = useSelector<
    RootStateOrAny,
    ListCitiesState
  >((state) => state.listCities);

  const { data: dataFetchTransit, loading } = useSelector<
    RootStateOrAny,
    FetchTransitState
  >((state) => state.fetchTransit);

  const { loading: loadingDeleteTransit } = useSelector<
    RootStateOrAny,
    DeleteTransitState
  >((state) => state.deleteTransit);

  const { loading: loadingUpdateTransit } = useSelector<RootStateOrAny>(
    (state) => state.updateTransit
  ) as UpdateTransitState;

  const onSuccess = useCallback(() => {
    history.push("/settings/transits");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          carrier_id: Yup.string().required("Obrigatório"),
          type: Yup.string().required("Obrigatório"),
          ibge_origin: Yup.string().required("Obrigatório"),
          weight: Yup.string().required("Obrigatório"),
          start: Yup.string().required("Obrigatório"),
          count_fractional: Yup.string().required("Obrigatório"),
          deadline_fractional: Yup.string().required("Obrigatório"),
          count_dedicated: Yup.string().required("Obrigatório"),
          deadline_dedicated: Yup.string().required("Obrigatório"),
          valid_until: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        delete data.address_origin;
        delete data.address_destiny;

        if (data.type === "CIDADE") {
          data.ibge_origin = originValue;
          data.ibge_destiny = destinyValue;
          data.km_initial = null;
          data.km_final = null;
        }

        if (data.type === "KM") {
          data.ibge_origin = originValue;
          data.ibge_destiny = null;
        }

        dispatch(UpdateTransitActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [destinyValue, dispatch, handleFormErrors, id, onSuccess, originValue]
  );

  const fetchCities = async (
    address_origin: string,
    address_destiny: string
  ) => {
    const origins = await apiGeneral.get(`/cities?state=${address_origin}`);
    const originValues = origins.data.map(
      (origin: { name: string; ibge: string }) => ({
        value: origin.name,
        label: origin.name,
        ibge: origin.ibge,
      })
    );
    setOriginOptions(originValues);

    if (address_destiny) {
      const destinations = await apiGeneral.get(
        `/cities?state=${address_destiny}`
      );
      const destinyValues = destinations.data.map(
        (destiny: { name: string; ibge: string }) => ({
          value: destiny.name,
          label: destiny.name,
          ibge: destiny.ibge,
        })
      );
      setDestinyOptions(destinyValues);
    }
  };

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
      setQueryType("origin");
    },
    [dispatch]
  );

  const fetchDestinations = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
      setQueryType("destiny");
    },
    [dispatch]
  );

  const setCarrier = useCallback((id) => {
    if (formRef.current) return formRef.current.setFieldValue("carrier_id", id);
  }, []);

  const setType = useCallback((type) => {
    setTypeValue(type.value);
    if (formRef.current) return formRef.current.setFieldValue("type", type);
  }, []);

  const setOrigin = useCallback((ibge) => {
    setOriginValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_origin", ibge);
  }, []);

  const setDestiny = useCallback((ibge) => {
    setDestinyValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_destiny", ibge);
  }, []);

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      data.carrier_id = {
        label: data.carrier.trade_name,
        value: data.carrier.id,
      };
      data.type = {
        label: data.type,
        value: data.type,
      };
      data.count_fractional = {
        label: data.count_fractional,
        value: data.count_fractional,
      };
      data.count_dedicated = {
        label: data.count_dedicated,
        value: data.count_dedicated,
      };
      data.address_origin = {
        label: data.origin.state,
        value: data.origin.state,
      };
      data.ibge_origin = {
        label: data.origin.name,
        value: data.origin.name,
        ibge: data.origin.ibge,
      };
      setOriginValue(data.origin.ibge);

      if (data.destiny) {
        data.address_destiny = {
          label: data.destiny.state,
          value: data.destiny.state,
        };
        data.ibge_destiny = {
          label: data.destiny.name,
          value: data.destiny.name,
          ibge: data.destiny.ibge,
        };
        setDestinyValue(data.destiny.ibge);
      }

      data.cut_hour = data.cut_hour || "";
      data.valid_until =
        data.valid_until && format(new Date(data.valid_until), "yyyy-MM-dd");
      formRef.current.setData(data);

      const origin = data.address_origin.value;
      const destiny = data.address_destiny ? data.address_destiny.value : null;
      fetchCities(origin, destiny);
      setTypeValue(data.type.value);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchTransitActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteTransitActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (queryType === "origin") {
      setOriginOptions(dataCities);
    } else if (queryType === "destiny") {
      setDestinyOptions(dataCities);
    }
  }, [dataCities, loadingCities, queryType]);

  return (
    <Scaffold>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`Remover Tempo de Trânsito ${dataFetchTransit?.name}`}
          text="Deseja realmente remover esse tempo de trânsito?"
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteTransit}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Transit time</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Select
                name="carrier_id"
                label="Transportadora"
                isDisabled={loadingCarriers}
                isLoading={loadingCarriers}
                options={dataCarriers}
                onChange={(e: any) => setCarrier(e.value)}
              />
              <Select
                name="type"
                label="Tipo"
                options={typesOptions}
                onChange={(e) => setType(e)}
              />
              <Select
                name="address_origin"
                label="UF"
                options={statesOptions}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="ibge_origin"
                label="Origem"
                isDisabled={!originOptions}
                options={originOptions}
                onChange={(e: any) => setOrigin(e.ibge)}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="address_destiny"
                label="UF"
                disabled={!originOptions || typeValue !== "CIDADE"}
                options={statesOptions}
                onChange={(e) => fetchDestinations(e)}
              />
              <Select
                name="ibge_destiny"
                label="Destino"
                isDisabled={!destinyOptions || typeValue !== "CIDADE"}
                options={destinyOptions}
                onChange={(e: any) => setDestiny(e.ibge)}
              />
              <Input
                disabled={typeValue !== "KM"}
                name="km_initial"
                type="number"
                label="KM Inicial"
              />
              <Input
                disabled={typeValue !== "KM"}
                name="km_final"
                type="number"
                label="KM Final"
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="weight" label="Peso (Kg)" />
              <Input name="start" label="Início (Dias)" />
              <InputMask name="cut_hour" label="Hora de Corte" mask="99:99" />
              <Input name="valid_until" type="date" label="Validade" />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="count_fractional"
                label="Contagem Fracionado"
                options={countSelectData}
              />
              <Input
                name="deadline_fractional"
                type="number"
                label="Prazo para Fracionado"
              />
              <Select
                name="count_dedicated"
                label="Contagem Lotação"
                options={countSelectData}
              />
              <Input
                name="deadline_dedicated"
                type="number"
                label="Prazo para Lotação"
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Remover
              </S.Button>
              <S.Button type="submit">
                {loadingUpdateTransit ? <S.Loading /> : "Salvar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
