import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation, usePermissions } from "hooks";

import {
  FilterTripsState,
  FilterTripsActions,
} from "store/ducks/tracking/trips";
import {
  ListCompaniesActions,
  ListCompaniesState,
} from "store/ducks/settings/companies";
import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import {
  ListClientsActions,
  ListClientsState,
} from "store/ducks/settings/clients";
import {
  ListBusinessActions,
  ListBusinessState,
} from "store/ducks/settings/business";
import { Input, Select } from "components/shared/Form";
import { Modal } from "components/shared";
import { ICarrierOptions } from "interfaces/carrier";
import { ICompanyOptions } from "interfaces/company";
import * as S from "./styles";
import { IClientOption } from "interfaces/client";
import { IBusinessUnitOptions } from "interfaces/business-unit";

export const TripFilter: React.FC = () => {
  const { getTranslation } = useTranslation(translations);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { hasAnyPermission } = usePermissions();

  const { data: Filterdata } = useSelector<RootStateOrAny, FilterTripsState>(
    (state) => state.filterTrips
  );

  const { data: listCompaniesData, loading: listCompaniesLoading } =
    useSelector<RootStateOrAny, ListCompaniesState>(
      (state) => state.listCompanies
    );

  const { data: listCarriersData, loading: listCarriersLoading } = useSelector<
    RootStateOrAny,
    ListCarriersState
  >((state) => state.listCarriers);

  const { data: listClientsData, loading: listClientsLoading } = useSelector<
    RootStateOrAny,
    ListClientsState
  >((state) => state.listClients);

  const { data: listBusinessData, loading: listBusinessLoading } = useSelector<
    RootStateOrAny,
    ListBusinessState
  >((state) => state.listBusiness);

  const onSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          emission_date: Yup.string(),
          emission_end: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(FilterTripsActions.success(data));
        setModalIsOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("general_company_id", "");
      formRef.current.setFieldValue("general_carrier_ids", "");
      formRef.current.setFieldValue("general_client_ids", "");
      formRef.current.setFieldValue("general_business_line_id", "");
      formRef.current.reset();
    }
    dispatch(FilterTripsActions.reset());
  }, [dispatch]);

  const renderButtonCleanFilter = useCallback(() => {
    if (Filterdata) {
      const { limit, page, ...rest } = Filterdata;

      const showClearFilter = Object.values(rest).some(
        (value) => ![value !== null, value !== ""].includes(false)
      );

      if (showClearFilter) {
        return (
          <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
            {getTranslation('limparFiltro')}
          </S.ButtonMini>
        );
      }
    }
  }, [Filterdata, getTranslation, handleFilterClean]);

  const getListCompanies = useCallback(() => {
    dispatch(ListCompaniesActions.request({ all: true }));
  }, [dispatch]);

  const getListCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }, () => {}, true));
  }, [dispatch]);

  const getListClients = useCallback(() => {
    dispatch(ListClientsActions.request({ all: true }, () => {}, true));
  }, [dispatch]);

  const getListBusiness = useCallback(() => {
    dispatch(ListBusinessActions.request({ all: true }));
  }, [dispatch]);

  const onFetchFilter = useCallback(() => {
    if (
      formRef.current &&
      listClientsData &&
      listCarriersData &&
      listBusinessData &&
      Filterdata
    ) {
      formRef.current.setFieldValue(
        "emission_start",
        Filterdata.emission_start
      );
      formRef.current.setFieldValue("emission_end", Filterdata.emission_end);
      formRef.current.setFieldValue("ide_numero_nf", Filterdata.ide_numero_nf);
      formRef.current.setFieldValue(
        "prod_numero_pedido_compra",
        Filterdata.prod_numero_pedido_compra
      );

      if (Filterdata.general_company_id) {
        const companyOption = listCompaniesData.filter(
          (company: ICompanyOptions) =>
            company.value === Filterdata.general_company_id
        );
        formRef.current.setFieldValue("general_company_id", companyOption[0]);
      }

      if (Filterdata.general_carrier_ids) {
        const carrierOption = listCarriersData.filter(
          (carrier: ICarrierOptions) =>
            carrier.value.includes(Filterdata.general_carrier_ids)
        );
        formRef.current.setFieldValue("general_carrier_id", carrierOption[0]);
      }

      if (Filterdata.general_client_ids) {
        const clientOption = listClientsData.filter((client: IClientOption) =>
          client.value.includes(Filterdata.general_client_ids)
        );
        formRef.current.setFieldValue("general_client_id", clientOption[0]);
      }

      if (Filterdata.general_business_line_id) {
        const businessOption = listBusinessData.filter(
          (business: IBusinessUnitOptions) =>
            business.value === Filterdata.general_business_line_id
        );
        formRef.current.setFieldValue(
          "general_business_line_id",
          businessOption[0]
        );
      }
    }
  }, [
    Filterdata,
    listBusinessData,
    listCarriersData,
    listClientsData,
    listCompaniesData,
  ]);

  useEffect(() => {
    onFetchFilter();
  }, [onFetchFilter]);

  useEffect(() => {
    getListCompanies();
  }, [getListCompanies]);

  useEffect(() => {
    getListCarriers();
  }, [getListCarriers]);

  useEffect(() => {
    getListClients();
  }, [getListClients]);

  useEffect(() => {
    getListBusiness();
  }, [getListBusiness]);

  return (
    <S.Container>
      <Modal isOpen={modalIsOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation('filtrar')}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.FormRow>
              <Select
                name="general_company_id"
                label={getTranslation('centro')}
                placeholder={getTranslation('selecione')}
                options={listCompaniesData}
                loading={listCompaniesLoading}
              />
              {hasAnyPermission(["Administrador", "Customer Service"]) && (
                <Select
                  name="general_carrier_ids"
                  label={getTranslation('transportadora')}
                  placeholder={getTranslation('selecione')}
                  options={listCarriersData}
                  loading={listCarriersLoading}
                />
              )}
            </S.FormRow>
            <S.FormRow>
              {!hasAnyPermission(["Cliente"]) && (
                <Select
                  name="general_client_ids"
                  label={getTranslation('cliente')}
                  placeholder={getTranslation('selecione')}
                  options={listClientsData}
                  loading={listClientsLoading}
                />
              )}
              <Select
                name="general_business_line_id"
                label={getTranslation('bl')}
                placeholder={getTranslation('selecione')}
                options={listBusinessData}
                loading={listBusinessLoading}
              />
            </S.FormRow>
            <S.FormRow>
              <Input
                name="emission_start"
                label={getTranslation('emissaoDe')}
                type="date"
              />
              <Input name="emission_end" label={getTranslation('emissaoAte')} type="date" />
            </S.FormRow>
            <S.FormRow>
              <Input name="ide_numero_nf" label={getTranslation('numeroNota')} />
              <Input name="prod_numero_pedido_compra" label={getTranslation('numeroPedido')} />
            </S.FormRow>
            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setModalIsOpen(false)}
              >
                {getTranslation('cancelar')}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation('limparFiltro')}
              </S.Button>
              <S.Button type="submit">{getTranslation('filtrar')}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>
      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalIsOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
