import React, { useState } from "react";
import { format } from "date-fns";
import { v4 } from "uuid";

import { Modal } from "components/shared";
import { Geolocation } from "interfaces/geolocation";
import { InvoiceOccurrence } from "interfaces/invoice-occurrences";
import { useTranslation } from 'hooks';
import { translations } from './translations';
import * as S from "./styles";

interface Props {
  geolocations: Geolocation[] | null;
  occurrences: InvoiceOccurrence[] | null;
  isOpen: boolean;
  close: () => void;
}

type Option = "geolocations" | "occurrences";

export const ListGeolocations: React.FC<Props> = ({
  isOpen,
  close,
  geolocations,
  occurrences,
}) => {
  const { getTranslation } = useTranslation(translations);
  const [selected, setSelected] = useState<Option>("geolocations");
  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.Icon />
            {selected === "geolocations" && getTranslation('localizacoesGPS')}
            {selected === "occurrences" && getTranslation('oco')}
          </S.Title>
          <S.HeaderButtons>
            <S.ButtonMini
              btStyle={selected === "geolocations" ? "secundary" : "cancel"}
              onClick={() => setSelected("geolocations")}
            >
              {getTranslation('localizacoes')}
            </S.ButtonMini>
            <S.ButtonMini
              btStyle={selected === "occurrences" ? "secundary" : "cancel"}
              onClick={() => setSelected("occurrences")}
            >
              {getTranslation('oco')}
            </S.ButtonMini>
          </S.HeaderButtons>
        </S.Header>
        <S.GeolocationWrapper active={selected === "geolocations"}>
          {geolocations &&
            geolocations.map((geolocation) => (
              <S.Geolocation key={v4()}>
                <S.Value>
                  {geolocation.geolocation_date &&
                  geolocation.geolocation_date !== "0000-00-00 00:00:00"
                    ? format(
                        new Date(geolocation.geolocation_date),
                        "dd/MM/yyyy HH:mm"
                      )
                    : format(
                        new Date(geolocation.created_at),
                        "dd/MM/yyyy HH:mm"
                      )}
                </S.Value>
                <S.Value>
                  {geolocation.latitude || "---"}{" "}
                  {geolocation.longitude || "---"}{" "}
                </S.Value>
                <S.Value>{geolocation.location_description}</S.Value>
              </S.Geolocation>
            ))}
        </S.GeolocationWrapper>
        <S.OccurrenceWrapper active={selected === "occurrences"}>
          {occurrences &&
            occurrences.map((occurrence) => (
              <S.Occurrence key={occurrence.id}>
                <S.Value>
                  {occurrence.geolocation?.geolocation_date &&
                  occurrence.geolocation?.geolocation_date !==
                    "0000-00-00 00:00:00"
                    ? format(
                        new Date(occurrence.geolocation?.geolocation_date),
                        "dd/MM/yyyy HH:mm"
                      )
                    : format(
                        new Date(occurrence.created_at),
                        "dd/MM/yyyy HH:mm"
                      )}
                </S.Value>
                <S.Value>
                  {occurrence.geolocation?.latitude || "---"}{" "}
                  {occurrence.geolocation?.longitude || "---"}{" "}
                </S.Value>
                <S.Value>
                  {occurrence.occurrence_type_id} -{" "}
                  {occurrence.occurrenceType?.name}
                </S.Value>
              </S.Occurrence>
            ))}
        </S.OccurrenceWrapper>
        <S.ButtonsWrapper>
          <S.Button btStyle="cancel" onClick={close}>
            {getTranslation('fechar')}
          </S.Button>
        </S.ButtonsWrapper>
      </S.Container>
    </Modal>
  );
};
