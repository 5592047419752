import styled from 'styled-components';
import { Truck } from '@styled-icons/boxicons-solid';
import { Check, RotateLeft } from '@styled-icons/boxicons-regular';
import { Cancel } from '@styled-icons/typicons';
import { colors, fonts } from 'styles';
import { StatusOptions } from 'interfaces/invoice';

interface StatusStyle {
  status?: StatusOptions | undefined;
}
const backgroundStatus = {
  transito: `${colors.orange}`,
  'transito-atraso': `${colors.red}`,
  'transito-sem-prazo': `${colors.orange}`,
  entregue: `${colors.green}`,
  'entregue-cliente': `${colors.green}`,
  'entregue-atraso': `${colors.green}`,
  'entregue-sem-prazo': `${colors.green}`,
  'sem-status': `${colors.gray}`,
  'sem-status-atraso': `${colors.gray}`,
  'sem-status-sem-prazo': `${colors.gray}`,
  cancelada: `${colors.red}`,
  devolucao: `${colors.red}`,
};

export const Container = styled.div<StatusStyle>`
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: white;
  background-color: ${({ status }) => status && backgroundStatus[status]};
`;

export const IconTransito = styled(Truck).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
export const IconEntregue = styled(Check).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
export const IconCancel = styled(Cancel).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
export const IconDevolucao = styled(RotateLeft).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
