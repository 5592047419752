export const translations = {
  emissaoDe: {
    pt: 'Data emissão de',
    en: 'Issue date of',
    de: 'Ausgabedatum vom',
    es: 'Fecha de emisión de',
  },
  numeroNota: {
    pt: 'Número nota fiscal',
    en: 'Invoice number',
    de: 'Rechnungsnummer',
    es: 'número de factura',
  },
  numeroPedido: {
    pt: 'Número pedido',
    en: 'Order number',
    de: 'Bestellnummer',
    es: 'Número de orden',
  },
  emissaoAte: {
    pt: 'Data emissão até',
    en: 'Issue date until',
    de: 'Ausstellungsdatum bis',
    es: 'Fecha de emisión hasta',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  },
  bl: {
    pt: 'BL',
    en: 'BL',
    de: 'Geschäftszweig',
    es: 'línea de negocio',
  }, 
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
};