export const translations = {
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  operacoes: {
    pt: 'Operações CFOPs',
    en: 'CFOPs Operations',
    de: 'CFOPs-Operationen',
    es: 'Operaciones CFOP',
  },
  novasOperacoes: {
    pt: 'Novo Tipo de Operação',
    en: 'New Type of Operation',
    de: 'Neue Art der Operation',
    es: 'Nuevo tipo de operación',
  },
};