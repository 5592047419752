import { combineReducers } from "redux";
import { language } from "./language";
import { auth, forgot } from "./auth";
import { selectedFilter } from "./filter";
import {
  createAlert,
  deleteAlert,
  fetchAlert,
  listAlerts,
  paginateAlerts,
  updateAlert,
} from "./settings/alerts";
import {
  createUserAlert,
  deleteUserAlert,
  fetchUserAlert,
  listUserAlerts,
  paginateUserAlerts,
  updateUserAlert,
} from "./settings/user-alerts";
import {
  createCompany,
  deleteCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
} from "./settings/companies";
import {
  createCarrier,
  deleteCarrier,
  fetchCarrier,
  listCarriers,
  paginateCarriers,
  updateCarrier,
  listCarriersTradeName,
} from "./settings/carriers";
import {
  createClient,
  deleteClient,
  fetchClient,
  listClients,
  paginateClients,
  updateClient,
} from "./settings/clients";
import {
  createClientType,
  deleteClientType,
  fetchClientType,
  listClientTypes,
  paginateClientTypes,
  updateClientType,
} from "./settings/client-types";
import { listCities } from "./cities";
import { listRoles } from "./roles";
import { listModules } from "./modules";

import { cep } from "./cep";
import {
  paginateInvoices,
  exportInvoices,
  fetchInvoice,
  updateInvoice,
  InvoiceDeliveryVoucher,
  ExportInvoiceDeliveryVoucher,
  invoiceCountries,
} from "./tracking/invoices";
import {
  paginateIndents,
  exportIndents,
  fetchIndent,
  updateIndent,
} from "./tracking/indents";
import { fetchTrips, filterTrips, paginateTrips } from "./tracking/trips";

import { fetchInvoiceFilter } from "./tracking/invoice-filters";
import { fetchIndentFilter } from "./tracking/indent-filters";
import {
  createHoliday,
  deleteHoliday,
  fetchHoliday,
  paginateHolidays,
  updateHoliday,
} from "./settings/holidays";
import {
  createIgnoredCfop,
  deleteIgnoredCfop,
  fetchIgnoredCfop,
  paginateIgnoredCfops,
  updateIgnoredCfop,
} from "./settings/ignoredCfop";
import { listRegions } from "./settings/regions";
import {
  createJustificationType,
  deleteJustificationType,
  fetchJustificationType,
  listJustificationTypes,
  paginateJustificationTypes,
  updateJustificationType,
} from "./settings/justification-types";
import {
  createJustificationCustomerType,
  deleteJustificationCustomerType,
  fetchJustificationCustomerType,
  listJustificationCustomerTypes,
  paginateJustificationCustomerTypes,
  updateJustificationCustomerType,
} from "./settings/justificationCustomer-types";
import {
  createTransit,
  deleteTransit,
  fetchTransit,
  exportTransits,
  paginateTransits,
  updateTransit,
  importTransit,
} from "./settings/transits";
import {
  createInternacionalTransit,
  deleteInternacionalTransit,
  fetchInternacionalTransit,
  exportInternacionalTransits,
  paginateInternacionalTransits,
  updateInternacionalTransit,
  importInternacionalTransit,
} from "./settings/internacional-transits";
import {
  createBusiness,
  deleteBusiness,
  fetchBusiness,
  listBusiness,
  paginateBusiness,
  updateBusiness,
} from "./settings/business";
import {
  createBusinessUnit,
  deleteBusinessUnit,
  fetchBusinessUnit,
  listBusinessUnits,
  paginateBusinessUnits,
  updateBusinessUnit,
} from "./settings/business-unit";
import {
  createOperationType,
  deleteOperationType,
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
  updateOperationType,
} from "./settings/operation-type";
import { listTypeCfops } from "./settings/type-cfop";
import {
  createCountry,
  deleteCountry,
  fetchCountry,
  listCountries,
  paginateCountries,
  updateCountry,
} from "./settings/countries";
import {
  createHarbor,
  deleteHarbor,
  fetchHarbor,
  listHarbors,
  paginateHarbors,
  updateHarbor,
} from "./settings/harbors";
import {
  createProduct,
  deleteProduct,
  fetchProduct,
  listProducts,
  paginateProducts,
  updateProduct,
} from "./settings/products";
import {
  createProductCompany,
  deleteProductCompany,
  fetchProductCompany,
  listProductCompanies,
  paginateProductCompanies,
  updateProductCompany,
} from "./settings/product-companies";
import {
  createOperationCfop,
  deleteOperationCfop,
  fetchOperationCfop,
  listOperationCfops,
  paginateOperationCfops,
  updateOperationCfop,
} from "./settings/operation-cfop";
import {
  createUserBusinessLine,
  createUserCompany,
  createUserGroup,
  createUser,
  deleteUserBusinessLine,
  deleteUserCompany,
  deleteUserGroup,
  deleteUser,
  fetchUser,
  listUsers,
  paginateUsers,
  updateUser,
} from "./settings/users";
import {
  createDeliveryVoucher,
  downloadDeliveryVoucher,
  deleteDeliveryVoucher,
  listDeliveryVouchers,
  paginateDeliveryVouchers,
  updateDeliveryVoucher,
} from "./tracking/delivery-vouchers";
import {
  createInvoiceJustification,
  listInvoiceJustifications,
  paginateInvoiceJustifications,
  updateInvoiceJustification,
} from "./tracking/invoice-justifications";

import {
  createInvoiceJustificationCustomer,
  listInvoiceJustificationCustomer,
  paginateInvoiceJustificationCustomer,
  updateInvoiceJustificationCustomer,
} from "./tracking/invoice-justificationCustomer";

import {
  createIndentJustification,
  listIndentJustifications,
  paginateIndentJustifications,
  updateIndentJustification,
} from "./tracking/indent-justifications";

import {
  createIndentJustificationCustomer,
  listIndentJustificationCustomer,
  paginateIndentJustificationCustomer,
  updateIndentJustificationCustomer,
} from "./tracking/indent-justificationCustomer";

import { fetchRecalculateDeadline } from "./tracking/recalculate-deadline";
import { fetchIndentRecalculateDeadline } from "./tracking/indent-recalculate-deadline";
import { updateDeliveryDate } from "./tracking/update-delivery-date";
import { updateIndentDeliveryDate } from "./tracking/upIndent-delivery-date";
import { listGroups } from "./settings/groups";
import { listDistance } from "./distance";

import { listDownTime } from "./report/downtime";

const reducers = combineReducers({
  language,
  auth,
  forgot,
  cep,
  listCities,
  createCarrier,
  deleteCarrier,
  fetchCarrier,
  listCarriers,
  listCarriersTradeName,
  paginateCarriers,
  updateCarrier,
  createAlert,
  deleteAlert,
  fetchAlert,
  listAlerts,
  paginateAlerts,
  updateAlert,
  createUserAlert,
  deleteUserAlert,
  fetchUserAlert,
  listUserAlerts,
  paginateUserAlerts,
  updateUserAlert,
  createCompany,
  deleteCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
  createClient,
  deleteClient,
  fetchClient,
  listClients,
  paginateClients,
  updateClient,
  paginateInvoices,
  InvoiceDeliveryVoucher,
  ExportInvoiceDeliveryVoucher,
  invoiceCountries,
  fetchInvoice,
  fetchTrips,
  filterTrips,
  paginateTrips,
  updateInvoice,
  exportInvoices,
  fetchInvoiceFilter,
  paginateIndents,
  fetchIndent,
  updateIndent,
  exportIndents,
  fetchIndentFilter,
  createHoliday,
  deleteHoliday,
  fetchHoliday,
  paginateHolidays,
  updateHoliday,
  createIgnoredCfop,
  deleteIgnoredCfop,
  fetchIgnoredCfop,
  paginateIgnoredCfops,
  updateIgnoredCfop,
  listRegions,
  createTransit,
  deleteTransit,
  fetchTransit,
  exportTransits,
  paginateTransits,
  updateTransit,
  importTransit,
  createInternacionalTransit,
  deleteInternacionalTransit,
  fetchInternacionalTransit,
  exportInternacionalTransits,
  paginateInternacionalTransits,
  updateInternacionalTransit,
  importInternacionalTransit,
  createBusiness,
  deleteBusiness,
  fetchBusiness,
  createJustificationType,
  deleteJustificationType,
  fetchJustificationType,
  listJustificationTypes,
  paginateJustificationTypes,
  updateJustificationType,
  createJustificationCustomerType,
  deleteJustificationCustomerType,
  fetchJustificationCustomerType,
  listJustificationCustomerTypes,
  paginateJustificationCustomerTypes,
  updateJustificationCustomerType,
  listBusiness,
  paginateBusiness,
  updateBusiness,
  createBusinessUnit,
  deleteBusinessUnit,
  listBusinessUnits,
  fetchBusinessUnit,
  paginateBusinessUnits,
  updateBusinessUnit,
  listRoles,
  createUserBusinessLine,
  createUserCompany,
  createUserGroup,
  createUser,
  deleteUserBusinessLine,
  deleteUserCompany,
  deleteUserGroup,
  deleteUser,
  fetchUser,
  listUsers,
  paginateUsers,
  updateUser,
  createDeliveryVoucher,
  downloadDeliveryVoucher,
  deleteDeliveryVoucher,
  listDeliveryVouchers,
  paginateDeliveryVouchers,
  updateDeliveryVoucher,
  createInvoiceJustification,
  listInvoiceJustifications,
  paginateInvoiceJustifications,
  updateInvoiceJustification,
  createInvoiceJustificationCustomer,
  listInvoiceJustificationCustomer,
  paginateInvoiceJustificationCustomer,
  updateInvoiceJustificationCustomer,
  createClientType,
  deleteClientType,
  fetchClientType,
  listClientTypes,
  paginateClientTypes,
  updateClientType,
  createOperationType,
  deleteOperationType,
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
  updateOperationType,
  listTypeCfops,
  createProduct,
  deleteProduct,
  fetchProduct,
  listProducts,
  createCountry,
  deleteCountry,
  fetchCountry,
  listCountries,
  paginateCountries,
  updateCountry,
  createHarbor,
  deleteHarbor,
  fetchHarbor,
  listHarbors,
  paginateHarbors,
  updateHarbor,
  paginateProducts,
  updateProduct,
  createProductCompany,
  deleteProductCompany,
  fetchProductCompany,
  listProductCompanies,
  paginateProductCompanies,
  updateProductCompany,
  createOperationCfop,
  deleteOperationCfop,
  fetchOperationCfop,
  listOperationCfops,
  paginateOperationCfops,
  updateOperationCfop,
  listGroups,
  listDistance,
  fetchRecalculateDeadline,
  updateDeliveryDate,
  listModules,
  selectedFilter,
  listDownTime,
  createIndentJustification,
  listIndentJustifications,
  paginateIndentJustifications,
  updateIndentJustification,
  createIndentJustificationCustomer,
  listIndentJustificationCustomer,
  paginateIndentJustificationCustomer,
  updateIndentJustificationCustomer,
  updateIndentDeliveryDate,
  fetchIndentRecalculateDeadline
});

export default reducers;
