import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useTranslation,useValidation } from "hooks";
import { translations } from "./translations";
import {
  ListJustificationCustomerTypesActions,
  ListJustificationCustomerTypesState,
} from "store/ducks/settings/justificationCustomer-types";
import {
  CreateIndentJustificationCustomerActions,
  CreateIndentJustificationCustomerState,
} from "store/ducks/tracking/indent-justificationCustomer";

import * as S from "./styles";
import { Modal } from "components/shared";
import { Select, Textarea, Input } from "components/shared/Form";

interface Props {
  indentId: number;
  isOpen: boolean;
  close: () => void;
  onActionSuccess: () => void;
}

export const NewIndentJustificationCustomer: React.FC<Props> = ({
  isOpen,
  close,
  indentId,
  onActionSuccess,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);

  const { data: dataJustificationCustomerType, loading: loadingJustificationCustomerType } =
    useSelector<RootStateOrAny>(
      (state) => state.listJustificationCustomerTypes
    ) as ListJustificationCustomerTypesState;

  const { loading: loadingCreateIndentJustificationCustomer } =
    useSelector<RootStateOrAny>(
      (state) => state.createIndentJustificationCustomer
    ) as CreateIndentJustificationCustomerState;

  const onSuccess = useCallback(
    (reset) => {
      reset();
      close();
      onActionSuccess();
    },
    [close, onActionSuccess]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data, { reset }) => {

      const selectedDatetime = data.interval && new Date(data.interval)
      const dateNow = new Date();

      if(selectedDatetime){
        const diferenceTime = Math.round((selectedDatetime.getTime() - dateNow.getTime()) / 1000 / 60);
        data.interval = diferenceTime;
      }
      
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          justification_customer_type_id: Yup.string().required(getTranslation('obrigatorio')),
          interval: Yup.string().required(getTranslation('obrigatorio')),
          commentary: Yup.string().required(getTranslation('obrigatorio')),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        data.indent_id = indentId;
        dispatch(
          CreateIndentJustificationCustomerActions.request(data, () =>
            onSuccess(reset)
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, indentId, onSuccess]
  );

  const getListJustificationTypes = useCallback(() => {
    dispatch(ListJustificationCustomerTypesActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => getListJustificationTypes(), [getListJustificationTypes]);

  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.IconMessage />
            {getTranslation('novaJustificativaCustomer')}
          </S.Title>
        </S.Header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.FormRow>
            <Select
              name="justification_customer_type_id"
              label={getTranslation('justificativaCustomer')}
              isLoading={loadingJustificationCustomerType}
              isDisabled={loadingJustificationCustomerType}
              options={dataJustificationCustomerType}
            />
          </S.FormRow>
          <S.FormRow>
            <Input type="datetime-local" min={new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0,16)} name="interval" label="Prorrogação do alerta" />
          </S.FormRow>
          <S.FormRow>
            <Textarea name="commentary" label={getTranslation('comentario')} />
          </S.FormRow>
          <S.ButtonsWrapper>
            <S.Button btStyle="cancel" onClick={close} type="button">
              {getTranslation('cancelar')}
            </S.Button>
            <S.Button type="submit">
              {loadingCreateIndentJustificationCustomer ? <S.Loading /> : getTranslation('salvar')}
            </S.Button>
          </S.ButtonsWrapper>
        </Form>
      </S.Container>
    </Modal>
  );
};
