import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/list-duck";
import { IndentJustificationCustomer } from "interfaces/indent-justificationCustomer";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_INDENT_JUSTIFICATION_CUSTOMER" }
);

export interface ListIndentJustificationCustomerState extends IState {
  data: IndentJustificationCustomer[];
}

interface ISuccessListIndentJustificartionsAction extends ISuccessAction {
  data: IndentJustificationCustomer[];
}

const INITIAL_STATE: ListIndentJustificationCustomerState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ListIndentJustificationCustomerState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: ListIndentJustificationCustomerState,
  action: ISuccessListIndentJustificartionsAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (
  state: ListIndentJustificationCustomerState,
  action: IFailureAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listIndentJustificationCustomer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListIndentJustificationCustomerTypes = Types;
export const ListIndentJustificationCustomerActions = Creators;
