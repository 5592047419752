import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateTransitActions } from "store/ducks/settings/transits";

export function* createTransitRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(api.post, `/evonik-transit-times`, postData);

    const { data } = responseBody;
    yield put(CreateTransitActions.success(data));
    notify("success", "Tempo de trânsito cadastrado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateTransitActions.failure(errorMessage));
  }
}
