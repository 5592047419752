import React, { useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api';
import { v4 } from 'uuid';

import marker from 'assets/images/markerTruck.png';
import markerWarehouse from 'assets/images/marker-warehouse.png';
import markerClient from 'assets/images/marker-client.png';
import markerClientCheck from 'assets/images/marker-client-check.png';

import { IInvoice, ITrip } from 'interfaces/trips';
import * as S from './styles';

interface IProps {
  trip: ITrip;
}

const containerMapStyle = {
  width: '100%',
  height: '100%',
};

interface IDirectionOptions {
  origin: any;
  destination: any;
  waypoints: any[];
  optimizeWaypoints: boolean;
  travelMode: string;
}

interface IPosition {
  lat: any;
  lng: any;
}

interface IInfoInvoice {
  position: IPosition;
  invoice: IInvoice;
}

const KEY = process.env.REACT_APP_GOOGLE_API_KEY_SINERGIA;

export const MapTrackingTrip: React.FC<IProps> = ({ trip }) => {
  const [directions, setDirections] = useState<any>(null);
  const [route, setRoute] = useState<any>(null);
  const [pointMarkers, setPointMarkers] = useState<any>(null);
  const [infoStart, setInfoStart] = useState<boolean>(false);
  const [infoInvoices, setInfoInvoices] = useState<IInfoInvoice | null>(null);
  const [lastPosition, setLastPosition] = useState<IPosition | null>(null);
  const [truckPositions, setTruckPositions] = useState<IPosition[]>([]);
  const [infoCarrier, setInfoCarrier] = useState<boolean>(false);

  const [directionOptions, setDirectionOptions] =
    useState<IDirectionOptions | null>(null);

  const onDirectionService = useCallback((result) => {
    if (result?.status === 'OK') {
      const legs = result.routes[0].legs[0];
      const startLocation = {
        lat: legs.start_location.lat(),
        lng: legs.start_location.lng(),
      };
      const endLocation = {
        lat: legs.end_location.lat(),
        lng: legs.end_location.lng(),
      };

      const waypointsLocation = legs.via_waypoints.map((el: any) => {
        return {
          lat: el.lat(),
          lng: el.lng(),
        };
      });

      setRoute({
        start: startLocation,
        end: endLocation,
        waypointsInfo: waypointsLocation,
      });
      setDirections(result);
    }
  }, []);

  const buildRouter = useCallback(() => {
    const invoicesLocation = trip.invoices.map((invoice) => {
      const emit_cep = invoice.emit_cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
      const dest_cep = invoice.dest_cep.replace(/^(\d{5})(\d{3})/, '$1-$2');

      const locationEmit = `${invoice.emit_logradouro}, ${invoice.emit_numero} - ${invoice.emit_bairro}, ${invoice.emit_nome_municipio} - ${invoice.emit_uf}, ${emit_cep}`;
      let locationDest = '';
      if (invoice.redespacho_id !== null || invoice.redespacho === 1) {
        locationDest = `${invoice.transp_razao_social}, ${invoice.transp_endereco_completo}, ${invoice.transp_nome_municipio} - ${invoice.transp_uf}`;
      } else {
        locationDest = `${invoice.dest_logradouro}, ${invoice.dest_numero} - ${invoice.dest_bairro}, ${invoice.dest_nome_municipio} - ${invoice.dest_uf}, ${dest_cep}`;
      }

      return {
        emit: locationEmit,
        dest: locationDest,
        status: invoice.delivery_date ? true : false,
        invoice,
      };
    });

    return {
      start: invoicesLocation[0],
      end: invoicesLocation.pop(),
      waypointsArray: invoicesLocation,
    };
  }, [trip]);

  const fetchDirectionOptions = useCallback(() => {
    if (trip) {
      const points = buildRouter();
      setPointMarkers(points);
      const options = {
        origin: points.start?.emit,
        destination: points.end?.dest,
        waypoints:
          points.waypointsArray.length > 0
            ? points.waypointsArray.map((el: any) => {
                return {
                  location: el.dest,
                  stopover: false,
                };
              })
            : [],
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      };
      setDirectionOptions(options);
    }
  }, [trip, buildRouter]);

  const handleInvoiceMarker = useCallback(
    (el: IPosition, invoice: IInvoice) => {
      setInfoInvoices({
        position: el,
        invoice,
      });
    },
    []
  );

  const renderInfoStartLocation = useCallback(() => {
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoStart(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>Centro</S.Label>
        <S.Value>{trip.company?.trade_name}</S.Value>
        <S.Label>Endereço</S.Label>
        <S.Value>
          {`${trip.company?.address_street}, ${trip.company?.address_number}, ${trip.company?.address_city}/${trip.company?.address_state}`}
        </S.Value>
      </S.Box>
    );
  }, [trip]);

  const renderInfoInvoiceLocation = useCallback(() => {
    if (infoInvoices) {
      let destiny = '';
      let destinyEnd = '';
      const { invoice } = infoInvoices;
      if (
        invoice.redespacho_id === null &&
        invoice.redespacho === 0 &&
        invoice.transp_razao_social
      ) {
        destiny = invoice.client.trade_name;
        destinyEnd = `${invoice.dest_logradouro}, ${invoice.dest_numero}, ${invoice.dest_nome_municipio}/${invoice.dest_uf}`;
      } else {
        destiny = invoice.transp_razao_social;
        destinyEnd = `${invoice.transp_endereco_completo}, ${invoice.transp_nome_municipio} - ${invoice.transp_uf}`;
      }
      return (
        <OverlayView mapPaneName="floatPane" position={infoInvoices.position}>
          <S.Box>
            <S.ButtonClose onClick={() => setInfoInvoices(null)}>
              <S.IconClose />
            </S.ButtonClose>
            <S.Label>Nota fiscal</S.Label>
            <S.Value>
              {invoice.ide_numero_nf}-{invoice.ide_serie}
            </S.Value>
            <S.Label>{invoice.redespacho ? 'Redespacho' : 'Cliente'}</S.Label>
            <S.Value>{destiny}</S.Value>
            <S.Label>Endereço</S.Label>
            <S.Value>{destinyEnd}</S.Value>
          </S.Box>
        </OverlayView>
      );
    }
  }, [infoInvoices]);

  const renderInfoCarrier = useCallback(() => {
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoCarrier(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>Transportadora</S.Label>
        <S.Value>{trip.carrier?.trade_name}</S.Value>
      </S.Box>
    );
  }, [trip]);

  const getLastPosition = useCallback(() => {
    if (trip.invoices.length > 0) {
      const transitInvoices = trip.invoices.filter(
        (invoice) => invoice.delivery_date === null
      );

      if (transitInvoices.length > 0) {
        if (transitInvoices[0].geolocation.length > 0) {
          const position = {
            lat: Number(transitInvoices[0].geolocation[0].latitude),
            lng: Number(transitInvoices[0].geolocation[0].longitude),
          };
          setLastPosition(position);
        }
      } else {
        if (route) {
          const lastDelivery = route.end;
          setLastPosition(lastDelivery);
        }
      }
    }
  }, [route, trip.invoices]);

  const getTruckPosition = useCallback(() => {
    if (trip.invoices.length > 0 && route) {
      let geolocations: IPosition[] = [];

      geolocations.push(route.start);

      trip.invoices.forEach((invoice) => {
        if (invoice.geolocation.length > 0) {
          invoice.geolocation.reverse().forEach((geolocation) => {
            if (geolocation.latitude && geolocation.longitude) {
              if (
                Number(geolocation.longitude) !== 0 ||
                Number(geolocation.latitude) !== 0
              ) {
                geolocations.push({
                  lat: Number(geolocation.latitude),
                  lng: Number(geolocation.longitude),
                });
              }
            }
          });
        }
      });

      const allDelivered = trip.invoices.every(
        (invoice) => invoice.delivery_date !== null
      );

      const uniqueGeolocations: IPosition[] = [];
      geolocations.forEach((geolocation) => {
        const geolocationString = JSON.stringify(geolocation);
        const exist = uniqueGeolocations.some(
          (geolocation) => geolocationString === JSON.stringify(geolocation)
        );

        if (!exist) {
          uniqueGeolocations.push(geolocation);
        }
      });

      if (allDelivered) {
        uniqueGeolocations.push(route.end);
      }
      setTruckPositions(uniqueGeolocations);
    }
  }, [route, trip]);

  useEffect(() => {
    fetchDirectionOptions();
  }, [fetchDirectionOptions]);

  useEffect(() => {
    getLastPosition();
  }, [getLastPosition]);

  useEffect(() => {
    getTruckPosition();
  }, [getTruckPosition]);

  return (
    <S.Container>
      {KEY && (
        <LoadScript googleMapsApiKey={KEY} mapIds={['87412a572f003751']}>
          <GoogleMap
            mapContainerStyle={containerMapStyle}
            zoom={10}
            clickableIcons={false}
            center={{ lat: Number(-22.69), lng: Number(-47.0189) }}
            options={{ mapId: '87412a572f003751' }}
          >
            {route && (
              <>
                {infoStart && (
                  <OverlayView mapPaneName="floatPane" position={route.start}>
                    {renderInfoStartLocation()}
                  </OverlayView>
                )}
                {infoInvoices && renderInfoInvoiceLocation()}
                <Marker
                  position={route.start}
                  icon={markerWarehouse}
                  zIndex={992}
                  onClick={() => setInfoStart(true)}
                />
                <Marker
                  position={route.end}
                  icon={
                    pointMarkers.end.status ? markerClientCheck : markerClient
                  }
                  zIndex={992}
                  onClick={() =>
                    handleInvoiceMarker(route.end, pointMarkers.end.invoice)
                  }
                />

                {route.waypointsInfo.map((el: IPosition, index: number) => {
                  return (
                    <Marker
                      key={v4()}
                      position={el}
                      icon={
                        pointMarkers.waypointsArray[index]?.status
                          ? markerClientCheck
                          : markerClient
                      }
                      zIndex={992}
                      onClick={() =>
                        handleInvoiceMarker(
                          el,
                          pointMarkers.waypointsArray[index].invoice
                        )
                      }
                    />
                  );
                })}
              </>
            )}

            {lastPosition && (
              <>
                {infoCarrier && (
                  <OverlayView mapPaneName="floatPane" position={lastPosition}>
                    {renderInfoCarrier()}
                  </OverlayView>
                )}
                <Marker
                  position={lastPosition}
                  icon={marker}
                  zIndex={999}
                  clickable
                  onClick={() => setInfoCarrier(true)}
                />
              </>
            )}

            {directions && (
              <DirectionsRenderer options={{ directions: directions }} />
            )}
            {directionOptions && (
              <DirectionsService
                options={directionOptions}
                callback={onDirectionService}
              />
            )}

            {truckPositions.length > 0 && (
              <>
                <Polyline
                  visible={true}
                  path={truckPositions}
                  options={{
                    geodesic: true,
                    strokeColor: '#991781',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                  }}
                />
              </>
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </S.Container>
  );
};
