import styled from "styled-components";
import { colors, fonts } from "styles";
import { Button } from "styles/styled-components";
import { FileImage, FileCheckFill, FileXFill } from "@styled-icons/bootstrap/";

import { Loading } from "styles/styled-components";

export {
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
  ButtonMini,
} from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto 48px auto;
`;

export const Title = styled.h2`
  font-size: 18px;
  color: ${colors.gray4};
  margin-bottom: 24px;
`;

export const SubmitButton = styled(Button)`
  height: 48px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin-bottom: 8px; */
`;

export const ItemContent = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto 200px 210px 210px 24px;
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  transition: 200ms ease;
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  color: ${colors.gray4};
`;

export const IconFile = styled(FileImage).attrs({ size: 24 })``;
export const IconFileCheck = styled(FileCheckFill).attrs({ size: 24 })<{
  active?: boolean;
}>`
  color: ${({ active }) => (active ? colors.green : colors.gray)};
`;

export const IconFileX = styled(FileXFill).attrs({ size: 24 })`
  color: ${colors.red};
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button:last-child {
    margin-left: 8px;
  }
`;

export const ButtonDownload = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(0.95);
  }
`;

export const LoadingAction = styled(Loading).attrs({ size: 16 })``;

export const Commentary = styled.article`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  background-color: ${colors.darkRgba5};
  padding: 16px;
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  font-family: ${fonts.OpenSans};
`;
