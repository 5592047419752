import React, { useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api';

import marker from 'assets/images/markerTruck.png';
import markerWarehouse from 'assets/images/marker-warehouse.png';
import markerClient from 'assets/images/marker-client.png';
import { useTranslation } from 'hooks';
import { translations } from './translations';
import * as S from './styles';
import { Indent } from 'interfaces/indent';
import { cnpj } from 'utils';

interface IProps {
  indent: Indent;
}

const containerMapStyle = {
  width: '100%',
  height: '100%',
};

interface ITruckPosition {
  lat: number;
  lng: number;
}

interface IDirectionOptions {
  origin: string;
  destination: string;
  travelMode: string;
}

const KEY = process.env.REACT_APP_GOOGLE_API_KEY_SINERGIA;

export const MapTrackingIndent: React.FC<IProps> = ({ indent }) => {
  const [truckPositions, setTruckPositions] = useState<ITruckPosition[]>([]);
  const [directions, setDirections] = useState<any>(null);
  const [route, setRoute] = useState<any>(null);
  const [lastPosition, setLastPosition] = useState<any>(null);
  const [destinationMarker, setDestinationMarker] = useState<any>(null);
  const [infoStart, setInfoStart] = useState<boolean>(false);
  const [infoEnd, setInfoEnd] = useState<boolean>(false);
  const [infoCarrier, setInfoCarrier] = useState<boolean>(false);
  const [realDestiny, setRealDestiny] = useState<any>('');
  const [isRedespacho, ] = useState(false);
  const { getTranslation } = useTranslation(translations);

  const [directionOptions, setDirectionOptions] =
    useState<IDirectionOptions | null>(null);

  const fetchTruckPositions = useCallback(() => {
    if (indent.geolocation) {
      let latlngs: ITruckPosition[] = [];
      indent.geolocation.map((geolocation) => {
        if (geolocation.latitude && geolocation.longitude) {
          if (
            Number(geolocation.latitude) !== 0 &&
            Number(geolocation.longitude) !== 0
          ) {
            latlngs.push({
              lat: parseFloat(geolocation.latitude),
              lng: parseFloat(geolocation.longitude),
            });
          }
        }
        return false;
      });
      return setTruckPositions(latlngs);
    }
  }, [indent]);

  const onDirectionService = useCallback((result) => {
    if (result?.status === 'OK') {
      const startLocation = {
        lat: result.routes[0].legs[0].start_location.lat(),
        lng: result.routes[0].legs[0].start_location.lng(),
      };

      const endLocation = {
        lat: result.routes[0].legs[0].end_location.lat(),
        lng: result.routes[0].legs[0].end_location.lng(),
      };
      setRoute({ startLocation, endLocation });
      setDirections(result);
    }
  }, []);

  const fetchLastPosition = useCallback(() => {
    if (indent) {
      if (indent.geolocation?.length) {
        const position = {
          lat: Number(indent.geolocation[0].latitude),
          lng: Number(indent.geolocation[0].longitude),
        };
        setLastPosition(position);
      }
    }
  }, [indent]);

  const fetchDirectionOptions = useCallback(
    (destination: any) => {
      const options = {
        origin: `brasil,${
          indent.emit_cep && indent.emit_cep.replace(/^(\d{5})(\d{3})/, '$1-$2')
        }}`,
        destination,
        travelMode: 'DRIVING',
      };
      setDirectionOptions(options);
    },
    [indent]
  );

  const renderInfoStartLocation = useCallback(() => {
    const { 
      rem_nome_fantasia,
      rem_cnpj,
      rem_logradouro,
      rem_numero,
      rem_nome_municipio,
      rem_uf,
      rem_cep,
    } = indent;
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoStart(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>{getTranslation('remetente')}</S.Label>
        <S.Value>
          {rem_nome_fantasia} - {cnpj(rem_cnpj)}
        </S.Value>
        <S.Label>{getTranslation('endereco')}</S.Label>
        <S.Value>
          {realDestiny
            ? realDestiny
            : `${rem_logradouro}, ${rem_numero}, ${rem_nome_municipio}/${rem_uf}, ${rem_cep}`}
        </S.Value>
      </S.Box>
    );
  }, [getTranslation, indent, realDestiny]);

  const renderInfoEndLocation = useCallback(() => {
    const { dest_nome_fantasia, dest_cnpj } = indent;
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoEnd(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>{getTranslation('destinatario')}</S.Label>
        <S.Value>
          {dest_nome_fantasia} - {cnpj(dest_cnpj)}
        </S.Value>
        <S.Label>{getTranslation('endereco')}</S.Label>
        <S.Value>{realDestiny}</S.Value>
      </S.Box>
    );
  }, [getTranslation, indent, realDestiny]);

  const renderInfoCarrier = useCallback(() => {
    const {
      emit_nome_fantasia,
      emit_cnpj,
      emit_nome_municipio,
      emit_uf,
      emit_cep,
    } = indent;
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoCarrier(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>{getTranslation('transportadora')}</S.Label>
        <S.Value>
          {emit_nome_fantasia} - {cnpj(emit_cnpj)}
        </S.Value>
        <S.Label>{getTranslation('endereco')}</S.Label>
        <S.Value>
          {`${emit_nome_municipio}/${emit_uf}, ${emit_cep}`}
        </S.Value>
      </S.Box>
    );
  }, [getTranslation, indent]);

  useEffect(() => {
    if (indent) {
      let destination: any = null;
      let destination_adress: any = null;

      if (indent.dest_cep) {
        destination = `${indent.dest_nome_pais}, ${
          indent.dest_cep && indent.dest_cep.replace(/^(\d{5})(\d{3})/, '$1-$2')
        }`;

        destination_adress = `${indent.dest_logradouro}, ${indent.dest_numero}, ${indent.dest_nome_municipio}/${indent.dest_uf}, ${indent.dest_cep}`;
      }

      setRealDestiny(destination_adress);
      fetchDirectionOptions(destination);
    }
  }, [indent, fetchDirectionOptions]);

  useEffect(() => {
    fetchLastPosition();
  }, [fetchLastPosition]);

  useEffect(() => {
    fetchTruckPositions();
  }, [fetchTruckPositions]);

  return (
    <S.Container>
      <S.LegendBox>
        <S.Legend>
          <S.Line className="blue" />
          {getTranslation('rotaG')}
        </S.Legend>
        <S.Legend>
          <S.Line className="purple" />
          {getTranslation('rotaTransportadora')}
        </S.Legend>
      </S.LegendBox>
      {KEY && (
        <LoadScript googleMapsApiKey={KEY} mapIds={['87412a572f003751']}>
          <GoogleMap
            mapContainerStyle={containerMapStyle}
            zoom={10}
            clickableIcons={false}
            options={{ mapId: '87412a572f003751' }}
            center={
              lastPosition || { lat: Number(-22.69), lng: Number(-47.0189) }
            }
          >
            {route && (
              <>
                {infoStart && (
                  <OverlayView
                    mapPaneName="floatPane"
                    position={route.startLocation}
                  >
                    {renderInfoStartLocation()}
                  </OverlayView>
                )}
                <Marker
                  position={route.startLocation}
                  icon={markerWarehouse}
                  zIndex={990}
                  clickable
                  onClick={() => setInfoStart(true)}
                />
                {infoEnd && (
                  <OverlayView
                    mapPaneName="floatPane"
                    position={route.endLocation}
                  >
                    {renderInfoEndLocation()}
                  </OverlayView>
                )}
                <Marker
                  position={route.endLocation}
                  icon={isRedespacho ? markerWarehouse : markerClient}
                  zIndex={991}
                  clickable
                  onClick={() => setInfoEnd(true)}
                />
                <Marker
                  position={route.startLocation}
                  icon={markerWarehouse}
                  zIndex={990}
                  clickable
                  onClick={() => setInfoStart(true)}
                />
                {/* {indent.redespacho && (
                  <Marker
                    position={destinationMarker}
                    icon={markerClient}
                    zIndex={990}
                  />
                )} */}
              </>
            )}
            {lastPosition && (
              <>
                {infoCarrier && (
                  <OverlayView mapPaneName="floatPane" position={lastPosition}>
                    {renderInfoCarrier()}
                  </OverlayView>
                )}
                <Marker
                  position={lastPosition}
                  icon={marker}
                  zIndex={992}
                  clickable
                  onClick={() => setInfoCarrier(true)}
                />
              </>
            )}
            {directions && (
              <DirectionsRenderer options={{ directions: directions }} />
            )}
            {directionOptions && (
              <>
                <DirectionsService
                  options={directionOptions}
                  callback={onDirectionService}
                />
                <DirectionsService
                  options={{
                    origin: `brasil,${
                      indent.emit_cep &&
                      indent.emit_cep.replace(/^(\d{5})(\d{3})/, '$1-$2')
                    }}`,
                    destination: `${indent.dest_nome_pais},${
                      indent.dest_cep &&
                      indent.dest_cep.replace(/^(\d{5})(\d{3})/, '$1-$2')
                    }}`,
                    travelMode: 'DRIVING',
                  }}
                  callback={(result) => {
                    if (result?.status === 'OK' && !destinationMarker) {
                      const location = {
                        lat: result.routes[0].legs[0].end_location.lat(),
                        lng: result.routes[0].legs[0].end_location.lng(),
                      };
                      setDestinationMarker(location);
                    }
                  }}
                />
              </>
            )}
            {truckPositions.length > 0 && (
              <Polyline
                visible={true}
                path={truckPositions}
                options={{
                  geodesic: true,
                  strokeColor: '#991781',
                  strokeOpacity: 1.0,
                  strokeWeight: 4,
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </S.Container>
  );
};
