import React from "react";

import * as S from "./styles";

import { StatusOptions } from "interfaces/indent";

interface IProps {
  status: StatusOptions;
}

export const IndentStatusLabel: React.FC<IProps> = ({ status }) => {
  
  const renderStatus = {
    "EM TRANSITO": { label: "Trânsito", icon: <S.IconTransito /> },
    "ENTREGUE": { label: "Entregue", icon: <S.IconEntregue /> },
    "SEM STATUS": {label: "Sem status", icon: <></>},
  };
  
  return (
    <S.Container status={status}>
      {status && (
        <>
          {renderStatus[status].icon}
          {renderStatus[status].label}
        </>
      )}
    </S.Container>
  );
};
