import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as S from "./styles";

import { ListDistanceActions } from "store/ducks/distance";
import { Indent } from "interfaces/indent";
import { Geolocation } from "interfaces/geolocation";
interface IProps {
  indent: Indent;
}

export const IndentTrackingProgress: React.FC<IProps> = ({ indent }) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState<number>(0);
  const [positionToFinished, setPositionToFinished] = useState<number>(0);
  const [truckDistance, setTruckDistance] = useState<number>(0);
  const [distanceToFinished, setDistanceToFinished] = useState<number>(0);

  const onSuccess = useCallback(
    (data) => {
      const { distance, duration } = data;

      if (distance && duration && indent.distance) {
        const distanceCompleted = indent.distance - distance;

        if (distanceCompleted > 0) {
          const formattedDistance = Number(distanceCompleted.toFixed(2));

          setTruckDistance(formattedDistance);
        } else {
          setTruckDistance(0);
        }

        const formattedDistance = Number(distance.toFixed(2));
        setDistanceToFinished(formattedDistance);

        const position = Number(
          ((distanceCompleted * 100) / indent.distance).toFixed(2)
        );

        if (position < 0) return setPosition(0);
        setPositionToFinished(100 - position);
        setPosition(position);
      }
    },
    [indent]
  );

  const getDistance = useCallback(() => {
    function getLastGeolocation(geolocations: Geolocation[]): string | null {
      for (let geolocation of geolocations) {
        if (geolocation.latitude && geolocation.longitude) {
          return `${geolocation.latitude}, ${geolocation.longitude}`;
        }
      }
      return null;
    }
    const deliveredStatus = [
      "entregue-cliente",
      "entregue",
      "entregue-atraso",
      "entregue-sem-prazo",
    ];
    if (indent.status && deliveredStatus.includes(indent.status)) {
      setPositionToFinished(0);
      setPosition(100);
    } else {
      if (indent.geolocation && indent.geolocation.length > 0) {
        const origins = getLastGeolocation(indent.geolocation);
        const destinations = `${indent.dest_nome_pais || "Brasil"}, ${
          indent.dest_cep &&
          indent.dest_cep.replace(/^(\d{5})(\d{3})/, "$1-$2")
        }`;
        if (origins && destinations) {
          dispatch(
            ListDistanceActions.request({ origins, destinations }, onSuccess)
          );
        }
      }
    }
  }, [dispatch, indent, onSuccess]);

  useEffect(() => {
    getDistance();
  }, [getDistance]);

  return (
    <S.Container>
      <S.IllustrationPin />
      <S.IllustrationTree2 />
      <S.IllustrationTree1 />
      <S.IllustrationPacks />
      <S.IllustrationBuildings />
      <S.IllustrationBg1 />
      <S.IllustrationBg2 />
      <S.IllustrationBg3 />
      <S.Truck position={position}>
        <S.IllustrationTruck />
      </S.Truck>
      <S.Line />
      <S.DistanceContainer>
        <S.BallTruck position={position} />
        <S.LabelTruck position={position}>{truckDistance} km</S.LabelTruck>
        <S.BallStart />
        <S.LabelStart>0</S.LabelStart>
        <S.BallEnd />
        <S.LabelEnd>
          {indent.distance ? `${indent.distance} km` : "---"}
        </S.LabelEnd>
        <S.DistanceToFinishedLine position={positionToFinished}>
          <S.LabelPositionToFinished distance={distanceToFinished}>
            {distanceToFinished} km
          </S.LabelPositionToFinished>
        </S.DistanceToFinishedLine>
        <S.DistanceLine />
      </S.DistanceContainer>
    </S.Container>
  );
};
