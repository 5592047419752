export const translations = {
  dashboard: {
    pt: 'Dashboard',
    en: 'Dashboard',
    de: 'Armaturenbrett',
    es: 'Tablero',
  },
  nf: {
    pt: 'NF',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Facturas',
  },
  emissao: {
    pt: 'Emissão',
    en: 'Issue',
    de: 'Problem',
    es: 'Asunto'
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite'
  },
  entrega: {
    pt: 'Entrega',
    en: 'Delivery',
    de: 'Lieferung',
    es: 'Entrega',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  businessLine: {
    pt: 'Business Line',
    en: 'Business Line',
    de: 'Geschäftslinie',
    es: 'Línea de negocios',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  },
  picking: {
    pt: 'Cód. Picking',
    en: 'Picking Code',
    de: 'Code Pflücken',
    es: 'Código cosecha',
  },
};
