import React from "react";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";
import { Indicators, IndicatorsProps } from "interfaces/invoice";

interface Props {
  indicators: Indicators | null;
  onFilterRequest: Function;
}

interface BoxProps {
  title: string;
  indicators: IndicatorsProps | undefined;
  prefix: string;
  onFilter: () => void;
}

const Box: React.FC<BoxProps> = ({ title, indicators, prefix, onFilter }) => {

  if (!indicators) return <></>;
  return (
    <S.BoxContainer onClick={() => onFilter()}>
      <S.BoxIcon status={prefix}>
        <S.IconVoucher />
      </S.BoxIcon>
      <S.Boxinfo>
        <S.Title>{title}</S.Title>
        <S.BackgroundProgressBar>
          <S.ProgressBar status={prefix} progress={`${indicators?.percent}%`} />
        </S.BackgroundProgressBar>
        <S.BoxNumberInfo>
          <span>{indicators.qtd}</span>
          <span>{indicators.percent}%</span>
        </S.BoxNumberInfo>
      </S.Boxinfo>
    </S.BoxContainer>
  );
};

export const InvoiceIndicatorsDeliveryVoucher: React.FC<Props> = ({
  indicators,
  onFilterRequest,
}) => {
  const { getTranslation } = useTranslation(translations);

  function handleFilter(prefix: string) {
    if (onFilterRequest) onFilterRequest({ indicators: prefix });
  }
  return (
    <S.Container>
      <Box
        title={getTranslation("comprovanteAprovado")}
        indicators={indicators?.approved}
        prefix="approved"
        onFilter={() => handleFilter("approved")}
      />
      <Box
        title={getTranslation("aguardandoAprovado")}
        indicators={indicators?.waitingApproval}
        prefix="waitingApproval"
        onFilter={() => handleFilter("waitingApproval")}
      />
      <Box
        title={getTranslation("comprovanteAguardando")}
        indicators={indicators?.waitingVoucher}
        prefix="waitingVoucher"
        onFilter={() => handleFilter("waitingVoucher")}
      />
      <Box
        title={getTranslation("comprovanteReprovado")}
        indicators={indicators?.voucherFailed}
        prefix="voucherFailed"
        onFilter={() => handleFilter("voucherFailed")}
      />
    </S.Container>
  );
};
