import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { DeleteIgnoredCfopActions } from "store/ducks/settings/ignoredCfop";

export function* deleteIgnoredCfopRequest(action: any) {
  try {
    const { id, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.delete,
      `/ignored-cfops/${id}`
    );

    const { data } = responseBody;
    yield put(DeleteIgnoredCfopActions.success(data));
    notify("success", "Restrição de CFOP deletado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(DeleteIgnoredCfopActions.failure(errorMessage));
  }
}
