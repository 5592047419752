import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateJustificationTypesActions } from "store/ducks/settings/justification-types";

export function* paginateJustificationTypesRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/justification-types?${queryString}`
    );

    const { justificationTypes, pagination } = responseBody;
    yield put(PaginateJustificationTypesActions.success(justificationTypes, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateJustificationTypesActions.failure(errorMessage));
  }
}
