import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateOperationTypeActions } from "store/ducks/settings/operation-type";

export function* createOperationTypeRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      api.post,
      `/operation-types`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateOperationTypeActions.success(data));
    notify("success", "Tipo cadastrado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateOperationTypeActions.failure(errorMessage));
  }
}
