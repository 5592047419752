import styled from "styled-components";
import { PackageDependents } from "@styled-icons/octicons";
import { Truck, Map, Crown } from "@styled-icons/boxicons-solid";
import { colors, fonts } from "styles";
import alarmIcon from "../../../assets/images/alarm-exclamation.png"

export const Container = styled.div`
  width: 100%;
  min-width: 270px;
  min-height: 270px;
  padding: 32px;
  border-radius: 4px;
  background-color: white;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px ${colors.darkRgba25} solid;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const HeaderItem = styled.div`
  display: flex;
`;

export const Title = styled.h2`
  font-family: ${fonts.GilroyBold};
  font-size: 16px;
  color: ${colors.gray4};
`;

export const Label = styled.h6`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray4};
  margin-bottom: 4px;
`;

export const Value = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.gray4};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const IconSender = styled(PackageDependents).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconCarrier = styled(Truck).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconTomador = styled(Crown).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconRecipient = styled(Map).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconDetail = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: ${colors.gray4};
  background-image: url(${alarmIcon});
`;

export const ContainerIconDetail = styled.div`
  display: flex;

`;
