export const translations = {
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'Id',
    es: 'Id',
  },
  veiculo: {
    pt: 'Veículo',
    en: 'Vehicle',
    de: 'Fahrzeug',
    es: 'Vehículo',
  },
  placa: {
    pt: 'Placa',
    en: 'Plate',
    de: 'Teller',
    es: 'Placa',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Portador',
  },
  inicio: {
    pt: 'Inicio',
    en: 'Start',
    de: 'Anfang',
    es: 'Inicio',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  entregues: {
    pt: 'Entregues',
    en: 'Handed out',
    de: 'Verteilt',
    es: 'Repartido',
  },
  pesoTotal: {
    pt: 'Peso total',
    en: 'Total weight',
    de: 'Gesamtgewicht',
    es: 'Peso total',
  },
  valorNF: {
    pt: 'Valor NFS',
    en: 'Invalue',
    de: 'Rechnungswert',
    es: 'Valor de la factura',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Auftraggeber',
    es: 'Cliente',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  nf: {
    pt: 'NF',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Factura',
  },
  emissao: {
    pt: 'Emissão',
    en: 'Issue',
    de: 'Problem',
    es: 'Asunto',
  },
};