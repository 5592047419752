import React from "react";
import {useDispatch} from "react-redux";
import * as S from "./styles";
import { OperationCfop } from "interfaces/operation-cfop";

import { 
  UpdateOperationCfopActions, 
} from "store/ducks/settings/operation-cfop";

interface IGridOperationCfopsProps {
  cfops: OperationCfop[] | Record<string, any>[];
  loading: boolean;
  onDelete: any;
}

interface IOperationCfopProps {
  cfop: OperationCfop | Record<string, any>;
  onDelete: any;
}

const Item: React.FC<IOperationCfopProps> = ({ cfop, onDelete }) => {
  const dispatch = useDispatch();
  const handleDelete = async (cfop:any) => {
    const data = {
      operation_type_id: null,
    }

    dispatch(UpdateOperationCfopActions.request(cfop.id, data, onDelete));
  }

  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.ItemValue>{cfop.cfop || "--"}</S.ItemValue>
        <S.ItemValue>
          <div className="ellipsis">{cfop.description || "--"}</div>
        </S.ItemValue>
        <S.Button
          btStyle="danger"
          type="button"
          onClick={() => handleDelete(cfop)}
          >
          Deletar
        </S.Button>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridCfops: React.FC<IGridOperationCfopsProps> = ({
  cfops = [],
  loading = false,
  onDelete,
}) => {
  return (
    <S.Container>
      { loading ? (
          <S.Loading/>
        ) : (
          <>
            {cfops.length > 0 && (
              <>
                <S.Header>
                  <S.Label>CFOP</S.Label>
                  <S.Label>Descrição</S.Label>
                </S.Header>
                {cfops.map((cfop) => <Item cfop={cfop} key={cfop.id} onDelete={onDelete}/>)}
              </>
            )}
          </>
        )
      }
    </S.Container>
  );
};
