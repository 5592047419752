import styled from "styled-components";
import { colors } from "styles";

export {
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
  ButtonMini,
} from "styles/styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  /* max-width: 680px;
  max-height: 90vh; */
  /* flex-direction: column; */
  /* background-color: ${colors.white}; */
  border-radius: 4px;
  /* padding: 40px; */
  margin-left: 32px;
`;

export const FileInput = styled.input.attrs({type: "file"})`
  /* display: flex; */
`;

export const IconContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  font-size: 1rem;
  padding: 4px;
  background-color: ${colors.white};
  border-radius: 6px;
  position: absolute;
  bottom: 30px;
  left: 75%;
  z-index: 2;
  box-shadow: 0 0 0 1px ${colors.gray2};
  cursor: pointer;
`;