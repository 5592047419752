import React, { PropsWithChildren } from "react";

import { Header, Footer } from "components/shared";
import * as S from "./styles";

interface ScaffoldPropd extends PropsWithChildren<any> {}

export const Scaffold: React.FC<ScaffoldPropd> = ({ children }) => {
  return (
    <S.Container>
      <Header />
      {children}
      <Footer />
    </S.Container>
  );
};
