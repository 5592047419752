import React, { useState, useRef,  useCallback } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useValidation } from "hooks";

import * as S from "./styles";

import { Modal } from "components/shared";
import { Select, Input } from "components/shared/Form";

import { statusOptions } from "utils/data/status";

interface IFilterProps {
  onFilter: Function;
}

export const FilterJustificationTypes: React.FC<IFilterProps> = ({
  onFilter,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cleanShow, setCleanShow] = useState<boolean>(false);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        onFilter(data);

        setCleanShow(true);
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, onFilter]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("name", "");
      formRef.current.setFieldValue("status", "");

      onFilter({ name: "", status: "" });
    }

    setCleanShow(false);
    setModalOpen(false);
  }, [onFilter]);

  const renderButtonCleanFilter = () => {
    if (cleanShow) {
      return (
        <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
          Limpar filtro
        </S.ButtonMini>
      );
    }
  };

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>Filtrar</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="name" label="Nome" />
            </S.FormRow>
            <S.FormRow>
              <Select name="status" label="Status de aprovação" options={statusOptions} />

              <S.FormRow></S.FormRow>
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                Limpar filtro
              </S.Button>
              <S.Button type="submit">Filtrar</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>

      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
