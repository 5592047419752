import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/export-duck";
import { Invoice, Indicators } from "interfaces/invoice";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data", "indicators"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "EXPORT_INVOICE_DELIVERY_VOUCHER_" }
);

export interface ExportInvoiceDeliveryVoucherState extends IState {
  data: Invoice[];
  indicators: Indicators | null;
}

export interface SuccessExportInvoiceDeliveryVoucherAction extends ISuccessAction {
  indicators: Indicators;
}

const INITIAL_STATE: ExportInvoiceDeliveryVoucherState = {
  data: [],
  indicators: null,
  loading: false,
  error: null,
};

const request = (state: ExportInvoiceDeliveryVoucherState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: ExportInvoiceDeliveryVoucherState,
  action: SuccessExportInvoiceDeliveryVoucherAction
) =>
  update(state, {
    data: { $set: action.data as Invoice[] },
    indicators: { $set: action.indicators },
    loading: { $set: false },
  });

const failure = (state: ExportInvoiceDeliveryVoucherState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const ExportInvoiceDeliveryVoucher = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ExportInvoiceDeliveryVoucherTypes = Types;
export const ExportInvoiceDeliveryVoucherActions = Creators;
