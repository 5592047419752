import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "hooks";
import { translations } from "./translations";

import {
  PaginateOperationCfopsActions,
  PaginateOperationCfopsState,
} from "store/ducks/settings/operation-cfop";

import * as S from "./styles";
import { Scaffold, Paginator, Search } from "components/shared";
import { GridOperationCfops } from "components/settings";

export const ListOperationCfops: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [query, setQuery] = useState({
    search: "",
    limit: 10,
    page: 1,
  });

  const { data, loading, pagination } = useSelector<
    RootStateOrAny,
    PaginateOperationCfopsState
  >(state => state.paginateOperationCfops);

  const handleSearchChange = useCallback((value) => {
    setQuery((state) => ({ ...state, search: value, page: 1 }));
  }, []);

  const handlePageChange = useCallback((value) => {
    setQuery((state) => ({ ...state, page: value }));
  }, []);

  const getData = useCallback(() => {
    dispatch(PaginateOperationCfopsActions.request({ ...query }));
  }, [dispatch, query]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation('titulo')}<span>{getTranslation('operacoes')}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <Search onSearch={handleSearchChange} />
        <S.ButtonMini
          onClick={() => history.push("/settings/cfops-restrictions/new")}
        >
          {getTranslation('novasOperacoes')}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <GridOperationCfops cfops={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
