import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { FetchOperationTypeActions } from "store/ducks/settings/operation-type";

export function* fetchOperationTypeRequest(action: any) {
  try {
    const { id, onSuccess } = action as IFechRequest;
    const { data } = yield call(api.get, `/operation-types/${id}`);

    yield put(FetchOperationTypeActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchOperationTypeActions.failure(errorMessage));
  }
}
