import styled from "styled-components";
import { colors } from "styles";
import alarmIcon from "../../../assets/images/alarm-exclamation.png"
import calendar from "../../../assets/images/calendar-exclamation.png"
export { Button } from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 680px;
  max-height: 95vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px ${colors.darkRgba25} solid;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const HeaderItem = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
  margin-left: 16px;
`;

export const TitleInfo = styled.h1`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.deepPurple};
`;

export const Text = styled.div`
  font-size: 16px;
  color: ${colors.dark};
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  border-right: none;

  button:first-child {
    margin-right: 16px;
    background: ${colors.warmGray6};
    color: #000;
  }
`;

export const IconAlarm = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: ${colors.gray4};
  background-image: url(${alarmIcon});
  background-repeat: no-repeat;
`;

export const IconCalendar = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: ${colors.gray4};
  background-image: url(${calendar});
  background-repeat: no-repeat;
`;

export const ContainerTable = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display:flex;
  flex-direction: column;
  height: 80%;

  .center{
    text-align: center;
  }

  .rigth{
    text-align: right;
  }
`;

export const ItemTableNotBorder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .weight{
    font-weight: 700;
  }
`;

export const ItemTable = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px ${colors.darkRgba25} solid;
`;