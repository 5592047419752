import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { Holiday } from "interfaces/holiday";
import { format } from "date-fns";
import { useTranslation } from "hooks";
import { translations } from "./translations";

interface IGridHolidaysProps {
  holidays: Holiday[] | Record<string, any>[];
}

interface IHolidayProps {
  holiday: Holiday | Record<string, any>;
}

const Item: React.FC<IHolidayProps> = ({ holiday }) => {
  const history = useHistory();
  return (
    <S.ItemContainer
      onClick={() => history.push(`/settings/holiday/${holiday.id}`)}
    >
      <S.ItemContent>
        <S.ItemValue>{holiday.id || "--"}</S.ItemValue>
        <S.ItemValue>
          {format(new Date(holiday.holiday_date), "dd/MM/yyyy") || "--"}
        </S.ItemValue>
        <S.ItemValue>{holiday.name || "--"}</S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridHolidays: React.FC<IGridHolidaysProps> = ({
  holidays = [],
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation('id')}</S.Label>
        <S.Label>{getTranslation('data')}</S.Label>
        <S.Label>{getTranslation('nome')}</S.Label>
      </S.Header>
      {holidays.length > 0 &&
        holidays.map((holiday) => <Item holiday={holiday} key={holiday.id} />)}
    </S.Container>
  );
};
