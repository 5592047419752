import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { format, addMinutes } from "date-fns";

import {
  UpdateInvoiceJustificationCustomerActions,
} from "store/ducks/tracking/invoice-justificationCustomer";

import * as S from "./styles";
import { InvoiceJustificationCustomer } from "interfaces/invoice-justificationCustomer";

interface ItemProps {
  invoiceJustification: InvoiceJustificationCustomer;
  onUpdate: Function;
}

interface Props {
  justifications: InvoiceJustificationCustomer[];
  onUpdate: () => void;
}

const Item: React.FC<ItemProps> = ({ invoiceJustification }) => {

  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.ItemValue>{invoiceJustification.justificationCustomerType.description}</S.ItemValue>
        <S.ItemValue>
          Criado em:{" "}
          {format(new Date(invoiceJustification.created_at), "dd/MM/yyyy HH:mm")}
        </S.ItemValue>
        <S.ItemValue>
          {invoiceJustification.user && invoiceJustification.user.name ? `Usuário: ${invoiceJustification.user.name}` : 'Usuário: -'}
        </S.ItemValue>
        <S.ItemValue>
          {`Prorrogação: ${format(addMinutes(new Date(invoiceJustification.created_at), parseInt(invoiceJustification.interval)), "dd/MM/yyyy HH:mm")}`}
        </S.ItemValue>
        {!invoiceJustification.approved_at &&
          !invoiceJustification.rejected_at && <S.IconFileCheck />}
        {invoiceJustification.approved_at && <S.IconFileCheck active />}
        {invoiceJustification.rejected_at && <S.IconFileX />}
      </S.ItemContent>
      {invoiceJustification.commentary && (
        <S.Commentary>{invoiceJustification.commentary}</S.Commentary>
      )}
    </S.ItemContainer>
  );
};

export const ListInvoiceJustificationsCustomer: React.FC<Props> = ({
  justifications,
  onUpdate,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    (id, data) => {
      dispatch(UpdateInvoiceJustificationCustomerActions.request(id, data, onUpdate));
    },
    [dispatch, onUpdate]
  );
  return (
    <S.Container>
      <S.Title>Justificativas Customer</S.Title>
      {justifications && (
        <S.ItemsWrapper>
          {justifications.map((invoiceJustification) => (
            <Item
              key={invoiceJustification.id}
              invoiceJustification={invoiceJustification}
              onUpdate={handleUpdate}
            />
          ))}
        </S.ItemsWrapper>
      )}
    </S.Container>
  );
};
