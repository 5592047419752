import React from "react";

import * as S from "./styles";

import { StatusOptions } from "interfaces/indent";
import { useTranslation } from "hooks";
import { translations } from "./translations";

interface IProps {
  status: StatusOptions;
}

export const IndentStatusIcon: React.FC<IProps> = ({ status }) => {
  const { getTranslation } = useTranslation(translations);
  
  const renderStatus = {
    "EM TRANSITO": { label: getTranslation('transito'), icon: <S.IconTransito /> },
    "ENTREGUE": { label: getTranslation('entregue'), icon: <S.IconEntregue /> },
    "SEM STATUS": {label: getTranslation('semStatus'), icon: <></>},
  };

  return (
    <S.Container status={status}>
      <S.Hint>{status ? renderStatus[status].label : getTranslation('semStatus')}</S.Hint>
      {status && renderStatus[status].icon}
    </S.Container>
  );
};
