export const translations= {
  codigoP: {
    pt: 'Codigo Prod.',
    en: 'Product code',
    de: 'Produktcode',
    es: 'Código Producto',
  },
  pesoLiquido: {
    pt: 'Peso Líquido',
    en: 'Net weight',
    de: 'Reingewicht',
    es: 'Peso neto',
  }, 
  pesoBruto: {
    pt: 'Peso Bruto',
    en: 'Gross weight',
    de: 'Bruttogewicht',
    es: 'Peso bruto',
  },
  volume: {
    pt: 'Volume',
    en: 'Volume',
    de: 'Volumen',
    es: 'Volumen',
  },
  desc: {
    pt: 'Descrição do produto',
    en: 'Product Description',
    de: 'Produktbeschreibung',
    es: 'Descripción del producto',
  },  
  lote: {
    pt: 'Lote',
    en: 'Batch',
    de: 'Charge',
    es: 'Lote',
  },     
  loteQuant: {
    pt: 'Lote Qtd.',
    en: 'batch quantity',
    de: 'Menge Menge',
    es: 'Cantidad de lote',
  },  
  quantidade: {
    pt: 'QTD',
    en: 'Amount',
    de: 'Die Summe',
    es: 'La cantidad',
  }, 
  valorICMS: {
    pt: 'ICMS',
    en: 'ICMS',
    de: 'ICMS',
    es: 'ICMS',
  }, 
  pedido: {
    pt: 'Pedido',
    en: 'Request',
    de: 'Anfrage',
    es: 'Solicitud',
  }, 
  ipi: {
    pt: 'IPI',
    en: 'IPI',
    de: 'IPI',
    es: 'IPI',
  }, 
  valorPis: {
    pt: 'PIS',
    en: 'PIS',
    de: 'PIS',
    es: 'PIS',
  }, 
  valorConfins: {
    pt: 'COFINS',
    en: 'COFINS ',
    de: 'COFINS',
    es: 'COFINS',
  }, 
  valorUnitario: {
    pt: 'Valor unitário',
    en: 'Unitary value',
    de: 'Einheitswert',
    es: 'Valor unitario',
  },
  valorTotal: {
    pt: 'Valor Total',
    en: 'Amount',
    de: 'Menge',
    es: 'Valor Total',
  },
};