import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { format, subDays } from "date-fns";
const todayDate = new Date();
const todayDateFormated = format(todayDate,'yyy-MM-dd');  
const thirtyDaysAgo = format(subDays(todayDate, 30),'yyy-MM-dd');

const { Types, Creators } = createActions(
  {
    success: ["data"],
    reset: [],
  },
  { prefix: "SELECTED_FILTER_" }
);

export interface DeliveryVoucherFilter {
  limit: number;
  page: number;
  ide_data_emissao_inicio: string | null;
  ide_data_emissao_final: string | null;
  ide_numero_nf: number | null;
  general_client_id: number | null;
  general_carrier_id: number | null;
  carrier_trade_name: string | null;
  carrier_raiz: number | null;
  general_company_id: number | null;
  general_business_line_id: number | null;
  delivery_date_inicio: string | null;
  picking_code: number | null;
  delivery_date_final: string | null;
  indicators?: any;
}
export interface SelectedFilterState {
  data: DeliveryVoucherFilter;
}

interface SuccessSelectedFilter {
  data: any;
}

const INITIAL_STATE: SelectedFilterState = {
  data: {
    limit: 10,
    page: 1,
    ide_data_emissao_inicio: thirtyDaysAgo,
    ide_data_emissao_final: todayDateFormated,
    ide_numero_nf: null,
    general_client_id: null,
    general_carrier_id: null,
    carrier_trade_name: null,
    carrier_raiz: null,
    general_company_id: null,
    general_business_line_id: null,
    delivery_date_inicio: null,
    picking_code: null,
    delivery_date_final: null,
  },
};

const success = (state: SelectedFilterState, action: SuccessSelectedFilter) =>
  update(state, {
    data: { $set: action.data },
  });

const reset = () => INITIAL_STATE;

export const selectedFilter = createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: success,
  [Types.RESET]: reset,
});

export const SelectedFilterTypes = Types;
export const SelectedFilterActions = Creators;
