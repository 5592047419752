import React from "react";

import * as S from "./styles";
import Menu from "./Menu";
import User from "./User";
import LanguageSelector from "./LanguageSelector";

export const Header: React.FC = () => {

  return (
    <S.Container>
      <a href={process.env.REACT_APP_SITE_BASE_URL}><S.Logo /></a>
      <Menu />
      <S.Wrapper>
        <LanguageSelector />
        <User />
      </S.Wrapper>
    </S.Container>
  );
};
