export const translations = {
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  redespacho: {
    pt: 'Redespacho',
    en: 'Batch',
    de: 'Charge',
    es: 'Lote',
  },
  entrega: {
    pt: 'Entrega',
    en: 'Delivery',
    de: 'Die Zustellung',
    es: 'Entrega',
  },  
  notaFiscal: {
    pt: 'Nota Fiscal',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Nota fiscal',
  },
  destinatario: {
    pt: 'Destinatário',
    en: 'Recipient',
    de: 'Empfänger',
    es: 'Recipiente',
  },
  remetente: {
    pt: 'Remetente',
    en: 'Sender',
    de: 'Absender',
    es: 'Remitente',
  },
};