import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateIndentJustificationActions } from "store/ducks/tracking/indent-justifications";

export function* createIndentJustificationRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data: responseBody } = yield call(
      api.post,
      `/indent-justifications`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateIndentJustificationActions.success(data));
    notify("success", "Justificativa cadastrada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateIndentJustificationActions.failure(errorMessage));
  }
}
