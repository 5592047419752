export const translations = {
  transitoAtraso: {
    pt: 'Trânsito em atraso',
    en: 'Delayed transit',
    de: 'verspäteter Transit',
    es: 'Trânsito retrasado',
  },
  transito: {
    pt: 'Trânsito',
    en: 'Transit',
    de: 'Transit',
    es: 'Tránsito',
  },
  entregue: {
    pt: 'Entregue',
    en: 'Delivered',
    de: 'Geliefert',
    es: 'Entregado',
  },
  entregueCliente: {
    pt: 'Entregue ao cliente',
    en: 'Delivered',
    de: 'Geliefert',
    es: 'Entregado',
  },
  semStatus: {
    pt: 'Sem Status ',
    en: 'Without status',
    de: 'Ohne Status',
    es: 'sin estatus',
  }, 
  semStatusAtraso: {
    pt: 'Sem Status atraso',
    en: 'No delay status',
    de: 'Kein Verzögerungsstatus',
    es: 'Sin estado de retraso',
  }, 
  semStatusprazo: {
    pt: 'Sem status/prazo',
    en: 'No Status/Deadline',
    de: 'Kein Status/Termin',
    es: 'Sin estado/fecha límite',
  },
  cancelada: {
    pt: 'Cancelada',
    en: 'Canceled',
    de: 'Abgesagt',
    es: 'Cancelada',
  },
  devolucao: {
    pt: 'Devolução',
    en: 'Devolution',
    de: 'Übertragung',
    es: 'Devolúcia',
  },
};