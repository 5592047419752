import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { requestErrorHandler } from "utils";
import {
  ListCarriersActions,
  IListCarrierRequest,
} from "store/ducks/settings/carriers";

import { Carrier } from "interfaces/carrier";

export function* listCarriersRequest(action: any) {
  try {
    const { query = {}, onSuccess, group } = action as IListCarrierRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/carriers?${queryString}`);
    let comboOptions: any[] = [];

    if (group) {
      data.map((carrier: Carrier) => {
        const sameCarriers = data.filter(
          (sameCarrier: Carrier) => sameCarrier.cnpj_root === carrier.cnpj_root
        );
        let carriersIds: number[] = [];
        sameCarriers.map((sameCarrier: Carrier) =>
          carriersIds.push(sameCarrier.id)
        );

        if (
          comboOptions.filter((option) => option.cnpjRoot === carrier.cnpj_root)
            .length === 0
        ) {
          return comboOptions.push({
            cnpjRoot: carrier.cnpj_root,
            label: carrier.trade_name,
            value: carriersIds,
          });
        }
        return carrier;
      });
    } else {
      comboOptions = data.map((carrier: Carrier) => ({
        value: carrier.id,
        label: `${carrier.trade_name} - ${carrier.address_city}/${carrier.address_state}`,
      }));
    }

    yield put(ListCarriersActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListCarriersActions.failure(errorMessage));
  }
}
