import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateOperationCfopActions } from "store/ducks/settings/operation-cfop";

export function* updateOperationCfopRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.put,
      `/operation-cfops/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateOperationCfopActions.success(data));
    notify("success", "CFOPs atualizados com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateOperationCfopActions.failure(errorMessage));
  }
}
