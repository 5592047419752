import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import {
  PaginateInternacionalTransitsActions,
  PaginateInternacionalTransitsState,
  ExportInternacionalTransitsActions,
  ExportInternacionalTransitsState,
} from "store/ducks/settings/internacional-transits";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import * as S from "./styles";
import { Scaffold, Paginator } from "components/shared";
import {
  GridInternacionalTransits,
  FilterInternacionalTransits,
} from "components/settings";

export const ListInternacionalTransits: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [exportActive, setExportActive] = useState(false);
  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
  });
  const { data, loading, pagination } = useSelector<
    RootStateOrAny,
    PaginateInternacionalTransitsState
  >((state) => state.paginateInternacionalTransits);

  const { data: fileData, loading: fileLoading } = useSelector<
    RootStateOrAny,
    ExportInternacionalTransitsState
  >((state) => state.exportInternacionalTransits);

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        ...value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const getData = useCallback(() => {
    dispatch(PaginateInternacionalTransitsActions.request({ ...query, ...dataFilter }));
  }, [dispatch, query, dataFilter]);

  const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getFileData = async () => {
    dispatch(ExportInternacionalTransitsActions.request({ all: true, ...query, ...dataFilter }));
    setExportActive(true);
  };

  const generateFile = () => {
    if (fileData) {
      const newFile: any[] = [];
      const fileName = `TEMPO_DE_TRÂNSITO`;

      fileData.forEach(line => {
        const { carrier, origin, harbor, country, dead_line } = line;

        const TRANSPORTADORA = carrier.trade_name;
        const CNPJ_TRANSPORTADORA = carrier.cnpj;
        const ORIGEM = origin.name;
        const PORTO = harbor ? harbor.description : "-";
        const PAIS = country ? country.description : "-";
        const PRAZO = dead_line;

        const fileLine = {
          TRANSPORTADORA,
          'CNPJ TRANSPORTADORA': CNPJ_TRANSPORTADORA,
          ORIGEM,
          PORTO,
          PAIS,
          PRAZO,
        };

        newFile.push(fileLine);
      });

      exportToCSV(newFile, fileName);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!fileLoading && exportActive) {
      generateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLoading, exportActive]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation('titulo')} <span>{getTranslation('internationalTransitTime')}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini onClick={() => getFileData()} disabled={fileLoading}>
            {fileLoading ? (
              <ReactLoading type="spin" color="#fff" height={20} width={20} />
            ) : (
              getTranslation('csv')
            )}
          </S.ButtonMini>
          <S.ButtonMini
            onClick={() => history.push("/settings/internacional-transit/new")}
          >
            {getTranslation('novoTempo')}
          </S.ButtonMini>
          <FilterInternacionalTransits onFilter={handleSubmitFilter} />
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <GridInternacionalTransits transits={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
