import { all, takeLatest } from "redux-saga/effects";

// Types
import { AuthTypes, ForgotTypes } from "store/ducks/auth";

import {
  CreateAlertTypes,
  DeleteAlertTypes,
  FetchAlertTypes,
  ListAlertsTypes,
  PaginateAlertsTypes,
  UpdateAlertTypes,
} from "store/ducks/settings/alerts";

import {
  CreateUserAlertTypes,
  DeleteUserAlertTypes,
  FetchUserAlertTypes,
  ListUserAlertsTypes,
  PaginateUserAlertsTypes,
  UpdateUserAlertTypes,
} from "store/ducks/settings/user-alerts";

import {
  CreateCompanyTypes,
  DeleteCompanyTypes,
  FetchCompanyTypes,
  ListCompaniesTypes,
  PaginateCompaniesTypes,
  UpdateCompanyTypes,
} from "store/ducks/settings/companies";

import {
  CreateCarrierTypes,
  DeleteCarrierTypes,
  FetchCarrierTypes,
  ListCarriersTypes,
  PaginateCarriersTypes,
  UpdateCarrierTypes,
  ListCarriersTradeNameTypes,
} from "store/ducks/settings/carriers";

import {
  CreateClientTypes,
  DeleteClientTypes,
  FetchClientTypes,
  ListClientsTypes,
  PaginateClientsTypes,
  UpdateClientTypes,
} from "store/ducks/settings/clients";

import {
  CreateClientTypeTypes,
  DeleteClientTypeTypes,
  FetchClientTypeTypes,
  ListClientTypesTypes,
  PaginateClientTypesTypes,
  UpdateClientTypeTypes,
} from "store/ducks/settings/client-types";

import { ListGroupsTypes } from "store/ducks/settings/groups";

import { ListCitiesTypes } from "store/ducks/cities";
import { ListRolesTypes } from "store/ducks/roles";
import { CepTypes } from "store/ducks/cep";
import { ListModulesTypes } from "store/ducks/modules";

import {
  PaginateInvoicesTypes,
  FetchInvoiceTypes,
  ExportInvoicesTypes,
  ExportInvoiceDeliveryVoucherTypes,
  UpdateInvoiceTypes,
  InvoiceDeliveryVoucherTypes,
  InvoiceCountriesTypes,
} from "store/ducks/tracking/invoices";

import {
  PaginateIndentsTypes,
  FetchIndentTypes,
  ExportIndentsTypes,
  UpdateIndentTypes,
} from "store/ducks/tracking/indents";

import {
  FetchTripsTypes,
  PaginateTripsTypes,
} from "store/ducks/tracking/trips";

import {
  CreateDeliveryVoucherTypes,
  DownloadDeliveryVoucherTypes,
  DeleteDeliveryVoucherTypes,
  ListDeliveryVouchersTypes,
  PaginateDeliveryVouchersTypes,
  UpdateDeliveryVoucherTypes,
} from "store/ducks/tracking/delivery-vouchers";

import {
  CreateInvoiceJustificationTypes,
  ListInvoiceJustificationsTypes,
  PaginateInvoiceJustificationsTypes,
  UpdateInvoiceJustificationTypes,
} from "store/ducks/tracking/invoice-justifications";

import {
  CreateInvoiceJustificationCustomerTypes,
  ListInvoiceJustificationCustomerTypes,
  PaginateInvoiceJustificationCustomerTypes,
  UpdateInvoiceJustificationCustomerTypes,
} from "store/ducks/tracking/invoice-justificationCustomer";

import {
  CreateIndentJustificationTypes,
  ListIndentJustificationsTypes,
  PaginateIndentJustificationsTypes,
  UpdateIndentJustificationTypes,
} from "store/ducks/tracking/indent-justifications";

import {
  CreateIndentJustificationCustomerTypes,
  ListIndentJustificationCustomerTypes,
  PaginateIndentJustificationCustomerTypes,
  UpdateIndentJustificationCustomerTypes,
} from "store/ducks/tracking/indent-justificationCustomer";

import { FetchRecalculateDeadlineTypes } from "store/ducks/tracking/recalculate-deadline";
import { FetchIndentRecalculateDeadlineTypes } from "store/ducks/tracking/indent-recalculate-deadline";

import { UpdateDeliveryDateTypes } from "store/ducks/tracking/update-delivery-date";
import { UpdateIndentDeliveryDateTypes } from "store/ducks/tracking/upIndent-delivery-date";

import {
  CreateHolidayTypes,
  DeleteHolidayTypes,
  FetchHolidayTypes,
  PaginateHolidaysTypes,
  UpdateHolidayTypes,
} from "store/ducks/settings/holidays";

import {
  CreateIgnoredCfopTypes,
  DeleteIgnoredCfopTypes,
  FetchIgnoredCfopTypes,
  PaginateIgnoredCfopsTypes,
  UpdateIgnoredCfopTypes,
} from "store/ducks/settings/ignoredCfop";

import { ListRegionsTypes } from "store/ducks/settings/regions";

import {
  CreateJustificationTypeTypes,
  DeleteJustificationTypeTypes,
  FetchJustificationTypeTypes,
  ListJustificationTypesTypes,
  PaginateJustificationTypesTypes,
  UpdateJustificationTypeTypes,
} from "store/ducks/settings/justification-types";
import {
  CreateJustificationCustomerTypeTypes,
  DeleteJustificationCustomerTypeTypes,
  FetchJustificationCustomerTypeTypes,
  ListJustificationCustomerTypesTypes,
  PaginateJustificationCustomerTypesTypes,
  UpdateJustificationCustomerTypeTypes,
} from "store/ducks/settings/justificationCustomer-types";

import {
  CreateTransitTypes,
  DeleteTransitTypes,
  FetchTransitTypes,
  ExportTransitsTypes,
  PaginateTransitsTypes,
  UpdateTransitTypes,
  ImportTransitTypes,
} from "store/ducks/settings/transits";

import {
  CreateInternacionalTransitTypes,
  DeleteInternacionalTransitTypes,
  FetchInternacionalTransitTypes,
  ExportInternacionalTransitsTypes,
  PaginateInternacionalTransitsTypes,
  UpdateInternacionalTransitTypes,
  ImportInternacionalTransitTypes,
} from "store/ducks/settings/internacional-transits";

import {
  CreateBusinessTypes,
  DeleteBusinessTypes,
  FetchBusinessTypes,
  ListBusinessTypes,
  PaginateBusinessTypes,
  UpdateBusinessTypes,
} from "store/ducks/settings/business";

import {
  CreateBusinessUnitTypes,
  DeleteBusinessUnitTypes,
  FetchBusinessUnitTypes,
  ListBusinessUnitsTypes,
  PaginateBusinessUnitsTypes,
  UpdateBusinessUnitTypes,
} from "store/ducks/settings/business-unit";

import {
  CreateOperationTypeTypes,
  DeleteOperationTypeTypes,
  FetchOperationTypeTypes,
  ListOperationTypesTypes,
  PaginateOperationTypesTypes,
  UpdateOperationTypeTypes,
} from "store/ducks/settings/operation-type";

import { ListTypeCfopsTypes } from "store/ducks/settings/type-cfop";

import {
  CreateOperationCfopTypes,
  DeleteOperationCfopTypes,
  FetchOperationCfopTypes,
  ListOperationCfopsTypes,
  PaginateOperationCfopsTypes,
  UpdateOperationCfopTypes,
} from "store/ducks/settings/operation-cfop";

import {
  CreateProductTypes,
  DeleteProductTypes,
  FetchProductTypes,
  ListProductsTypes,
  PaginateProductsTypes,
  UpdateProductTypes,
} from "store/ducks/settings/products";

import {
  CreateHarborTypes,
  DeleteHarborTypes,
  FetchHarborTypes,
  ListHarborsTypes,
  PaginateHarborsTypes,
  UpdateHarborTypes,
} from "store/ducks/settings/harbors";

import {
  CreateCountryTypes,
  DeleteCountryTypes,
  FetchCountryTypes,
  ListCountriesTypes,
  PaginateCountriesTypes,
  UpdateCountryTypes,
} from "store/ducks/settings/countries";

import {
  CreateProductCompanyTypes,
  DeleteProductCompanyTypes,
  FetchProductCompanyTypes,
  ListProductCompaniesTypes,
  PaginateProductCompaniesTypes,
  UpdateProductCompanyTypes,
} from "store/ducks/settings/product-companies";

import {
  CreateUserBusinessLineTypes,
  CreateUserCompanyTypes,
  CreateUserGroupTypes,
  CreateUserTypes,
  DeleteUserBusinessLineTypes,
  DeleteUserCompanyTypes,
  DeleteUserGroupTypes,
  DeleteUserTypes,
  FetchUserTypes,
  ListUsersTypes,
  PaginateUsersTypes,
  UpdateUserTypes,
  CreateUserAvatarTypes,
  GetUserAvatarTypes,
  UpdateUserAvatarTypes,
} from "store/ducks/settings/users";

import { ListDistanceTypes } from "store/ducks/distance";

import { ListDownTimeTypes } from "store/ducks/report/downtime";

// Sagas
import {
  authRehydrateAccessToken,
  authLoginRequest,
  authLogoutRequest,
  forgotRequest,
} from "./auth";

import {
  createAlertRequest,
  deleteAlertRequest,
  fetchAlertRequest,
  listAlertsRequest,
  paginateAlertsRequest,
  updateAlertRequest,
} from "./settings/alerts";

import {
  createUserAlertRequest,
  deleteUserAlertRequest,
  fetchUserAlertRequest,
  listUserAlertsRequest,
  paginateUserAlertsRequest,
  updateUserAlertRequest,
} from "./settings/user-alerts";

import {
  createCompanyRequest,
  deleteCompanyRequest,
  fetchCompanyRequest,
  listCompaniesRequest,
  paginateCompaniesRequest,
  updateCompanyRequest,
} from "./settings/companies";

import {
  createCarrierRequest,
  deleteCarrierRequest,
  fetchCarrierRequest,
  listCarriersRequest,
  listCarriersTradeNameRequest,
  paginateCarriersRequest,
  updateCarrierRequest,
} from "./settings/carriers";

import {
  createClientRequest,
  deleteClientRequest,
  fetchClientRequest,
  listClientsRequest,
  paginateClientsRequest,
  updateClientRequest,
} from "./settings/clients";

import {
  createClientTypeRequest,
  deleteClientTypeRequest,
  fetchClientTypeRequest,
  listClientTypesRequest,
  paginateClientTypesRequest,
  updateClientTypeRequest,
} from "./settings/client-types";

import { listGroupsRequest } from "./settings/groups";

import { listCitiesRequest } from "./cities";
import { listRolesRequest } from "./roles";
import { fetchCepRequest } from "./cep";
import { listModulesRequest } from "./modules";

import {
  paginateInvoicesRequest,
  InvoicesDeliveryVoucherRequest,
  fetchInvoiceRequest,
  exportInvoicesRequest,
  updateInvoiceRequest,
  invoiceCountriesRequest,
  exportInvoicesDeliveryVouchersRequest,
} from "./tracking/invoices";

import {
  paginateIndentsRequest,
  fetchIndentRequest,
  exportIndentsRequest,
  updateIndentRequest,
} from "./tracking/indents";

import { fetchTripsRequest, paginateTripsRequest } from "./tracking/trips";

import {
  createDeliveryVoucherRequest,
  downloadDeliveryVoucherRequest,
  deleteDeliveryVoucherRequest,
  listDeliveryVouchersRequest,
  paginateDeliveryVouchersRequest,
  updateDeliveryVoucherRequest,
} from "./tracking/delivery-vouchers";

import {
  createInvoiceJustificationRequest,
  listInvoiceJustificationsRequest,
  paginateInvoiceJustificationsRequest,
  updateInvoiceJustificationRequest,
} from "./tracking/invoice-justifications";

import {
  createInvoiceJustificationCustomerRequest,
  listInvoiceJustificationCustomerRequest,
  paginateInvoiceJustificationCustomerRequest,
  updateInvoiceJustificationCustomerRequest,
} from "./tracking/invoice-justificationCustomer";

import {
  createIndentJustificationRequest,
  listIndentJustificationsRequest,
  paginateIndentJustificationsRequest,
  updateIndentJustificationRequest,
} from "./tracking/indent-justifications";

import {
  createIndentJustificationCustomerRequest,
  listIndentJustificationCustomerRequest,
  paginateIndentJustificationCustomerRequest,
  updateIndentJustificationCustomerRequest,
} from "./tracking/indent-justificationCustomer";

import { fetchRecalculateDeadlineRequest } from "./tracking/recalculate-deadline";
import { fetchIndentRecalculateDeadlineRequest } from "./tracking/indent-recalculate-deadline";

import { updateDeliveryDateRequest } from "./tracking/update-delivery-date";
import { updateIndentDeliveryDateRequest } from "./tracking/upIndent-delivery-date";

import {
  createHolidayRequest,
  deleteHolidayRequest,
  fetchHolidayRequest,
  paginateHolidaysRequest,
  updateHolidayRequest,
} from "./settings/holidays";

import {
  createIgnoredCfopRequest,
  deleteIgnoredCfopRequest,
  fetchIgnoredCfopRequest,
  paginateIgnoredCfopsRequest,
  updateIgnoredCfopRequest,
} from "./settings/ignoredCfop";

import { listRegionsRequest } from "./settings/regions";

import {
  createJustificationTypeRequest,
  deleteJustificationTypeRequest,
  fetchJustificationTypeRequest,
  listJustificationTypesRequest,
  paginateJustificationTypesRequest,
  updateJustificationTypeRequest,
} from "./settings/justification-types";
import {
  createJustificationCustomerTypeRequest,
  deleteJustificationCustomerTypeRequest,
  fetchJustificationCustomerTypeRequest,
  listJustificationCustomerTypesRequest,
  paginateJustificationCustomerTypesRequest,
  updateJustificationCustomerTypeRequest,
} from "./settings/justificationCustomer-types";

import {
  createTransitRequest,
  deleteTransitRequest,
  fetchTransitRequest,
  exportTransitsRequest,
  paginateTransitsRequest,
  updateTransitRequest,
  importTransitRequest,
} from "./settings/transits";

import {
  createInternacionalTransitRequest,
  deleteInternacionalTransitRequest,
  fetchInternacionalTransitRequest,
  exportInternacionalTransitsRequest,
  paginateInternacionalTransitsRequest,
  updateInternacionalTransitRequest,
  importInternacionalTransitRequest,
} from "./settings/internacional-transits";

import {
  createBusinessRequest,
  deleteBusinessRequest,
  fetchBusinessRequest,
  listBusinessRequest,
  paginateBusinessRequest,
  updateBusinessRequest,
} from "./settings/business";

import {
  createBusinessUnitRequest,
  deleteBusinessUnitRequest,
  fetchBusinessUnitRequest,
  listBusinessUnitsRequest,
  paginateBusinessUnitsRequest,
  updateBusinessUnitRequest,
} from "./settings/business-unit";

import {
  createOperationTypeRequest,
  deleteOperationTypeRequest,
  fetchOperationTypeRequest,
  listOperationTypesRequest,
  paginateOperationTypesRequest,
  updateOperationTypeRequest,
} from "./settings/operation-type";

import { listTypeCfopsRequest } from "./settings/type-cfop";

import {
  createOperationCfopRequest,
  deleteOperationCfopRequest,
  fetchOperationCfopRequest,
  listOperationCfopsRequest,
  paginateOperationCfopsRequest,
  updateOperationCfopRequest,
} from "./settings/operation-cfop";

import {
  createProductRequest,
  deleteProductRequest,
  fetchProductRequest,
  listProductsRequest,
  paginateProductsRequest,
  updateProductRequest,
} from "./settings/products";

import {
  createHarborRequest,
  deleteHarborRequest,
  fetchHarborRequest,
  listHarborsRequest,
  paginateHarborsRequest,
  updateHarborRequest,
} from "./settings/harbors";

import {
  createCountryRequest,
  deleteCountryRequest,
  fetchCountryRequest,
  listCountriesRequest,
  paginateCountriesRequest,
  updateCountryRequest,
} from "./settings/countries";

import {
  createProductCompanyRequest,
  deleteProductCompanyRequest,
  fetchProductCompanyRequest,
  listProductCompaniesRequest,
  paginateProductCompaniesRequest,
  updateProductCompanyRequest,
} from "./settings/product-companies";

import {
  createUserBusinessLineRequest,
  createUserCompanyRequest,
  createUserGroupRequest,
  createUserRequest,
  deleteUserBusinessLineRequest,
  deleteUserCompanyRequest,
  deleteUserGroupRequest,
  deleteUserRequest,
  fetchUserRequest,
  listUsersRequest,
  paginateUsersRequest,
  updateUserRequest,
  createUserAvatar,
  getUserAvatar,
  updateUserAvatar,
} from "./settings/users";

import { listDistanceRequest } from "./distance";

import { listDownTimeRequest } from "./report/downtime";


export default function* rootSaga() {
  yield all([
    // auth
    takeLatest("persist/REHYDRATE", authRehydrateAccessToken),
    takeLatest(AuthTypes.LOGIN_REQUEST, authLoginRequest),
    takeLatest(AuthTypes.LOGOUT_REQUEST, authLogoutRequest),

    // forgot
    takeLatest(ForgotTypes.REQUEST, forgotRequest),

    // settings-alerts
    takeLatest(CreateAlertTypes.REQUEST, createAlertRequest),
    takeLatest(DeleteAlertTypes.REQUEST, deleteAlertRequest),
    takeLatest(FetchAlertTypes.REQUEST, fetchAlertRequest),
    takeLatest(ListAlertsTypes.REQUEST, listAlertsRequest),
    takeLatest(PaginateAlertsTypes.REQUEST, paginateAlertsRequest),
    takeLatest(UpdateAlertTypes.REQUEST, updateAlertRequest),

    // settings-user-alerts
    takeLatest(CreateUserAlertTypes.REQUEST, createUserAlertRequest),
    takeLatest(DeleteUserAlertTypes.REQUEST, deleteUserAlertRequest),
    takeLatest(FetchUserAlertTypes.REQUEST, fetchUserAlertRequest),
    takeLatest(ListUserAlertsTypes.REQUEST, listUserAlertsRequest),
    takeLatest(PaginateUserAlertsTypes.REQUEST, paginateUserAlertsRequest),
    takeLatest(UpdateUserAlertTypes.REQUEST, updateUserAlertRequest),

    // settings-companies
    takeLatest(CreateCompanyTypes.REQUEST, createCompanyRequest),
    takeLatest(DeleteCompanyTypes.REQUEST, deleteCompanyRequest),
    takeLatest(FetchCompanyTypes.REQUEST, fetchCompanyRequest),
    takeLatest(ListCompaniesTypes.REQUEST, listCompaniesRequest),
    takeLatest(PaginateCompaniesTypes.REQUEST, paginateCompaniesRequest),
    takeLatest(UpdateCompanyTypes.REQUEST, updateCompanyRequest),

    // settings-carriers
    takeLatest(CreateCarrierTypes.REQUEST, createCarrierRequest),
    takeLatest(DeleteCarrierTypes.REQUEST, deleteCarrierRequest),
    takeLatest(FetchCarrierTypes.REQUEST, fetchCarrierRequest),
    takeLatest(ListCarriersTypes.REQUEST, listCarriersRequest),
    takeLatest(
      ListCarriersTradeNameTypes.REQUEST,
      listCarriersTradeNameRequest
    ),

    takeLatest(PaginateCarriersTypes.REQUEST, paginateCarriersRequest),
    takeLatest(UpdateCarrierTypes.REQUEST, updateCarrierRequest),

    // settings-clients
    takeLatest(CreateClientTypes.REQUEST, createClientRequest),
    takeLatest(DeleteClientTypes.REQUEST, deleteClientRequest),
    takeLatest(FetchClientTypes.REQUEST, fetchClientRequest),
    takeLatest(ListClientsTypes.REQUEST, listClientsRequest),
    takeLatest(PaginateClientsTypes.REQUEST, paginateClientsRequest),
    takeLatest(UpdateClientTypes.REQUEST, updateClientRequest),

    // settings-client-types
    takeLatest(CreateClientTypeTypes.REQUEST, createClientTypeRequest),
    takeLatest(DeleteClientTypeTypes.REQUEST, deleteClientTypeRequest),
    takeLatest(FetchClientTypeTypes.REQUEST, fetchClientTypeRequest),
    takeLatest(ListClientTypesTypes.REQUEST, listClientTypesRequest),
    takeLatest(PaginateClientTypesTypes.REQUEST, paginateClientTypesRequest),
    takeLatest(UpdateClientTypeTypes.REQUEST, updateClientTypeRequest),

    //settings-groups
    takeLatest(ListGroupsTypes.REQUEST, listGroupsRequest),

    // cities
    takeLatest(ListCitiesTypes.REQUEST, listCitiesRequest),

    // roles
    takeLatest(ListRolesTypes.REQUEST, listRolesRequest),

    // module
    takeLatest(ListModulesTypes.REQUEST, listModulesRequest),

    // cep
    takeLatest(CepTypes.REQUEST, fetchCepRequest),

    // invoices
    takeLatest(FetchInvoiceTypes.REQUEST, fetchInvoiceRequest),
    takeLatest(PaginateInvoicesTypes.REQUEST, paginateInvoicesRequest),
    takeLatest(ExportInvoicesTypes.REQUEST, exportInvoicesRequest),
    takeLatest(UpdateInvoiceTypes.REQUEST, updateInvoiceRequest),
    takeLatest(
      InvoiceDeliveryVoucherTypes.REQUEST,
      InvoicesDeliveryVoucherRequest
    ),
    takeLatest(ExportInvoiceDeliveryVoucherTypes.REQUEST, exportInvoicesDeliveryVouchersRequest),
    takeLatest(InvoiceCountriesTypes.REQUEST, invoiceCountriesRequest),

    // indents
    takeLatest(FetchIndentTypes.REQUEST, fetchIndentRequest),
    takeLatest(PaginateIndentsTypes.REQUEST, paginateIndentsRequest),
    takeLatest(ExportIndentsTypes.REQUEST, exportIndentsRequest),
    takeLatest(UpdateIndentTypes.REQUEST, updateIndentRequest),

    // trips
    takeLatest(FetchTripsTypes.REQUEST, fetchTripsRequest),
    takeLatest(PaginateTripsTypes.REQUEST, paginateTripsRequest),

    // delivery vouchers
    takeLatest(
      CreateDeliveryVoucherTypes.REQUEST,
      createDeliveryVoucherRequest
    ),
    takeLatest(
      DownloadDeliveryVoucherTypes.REQUEST,
      downloadDeliveryVoucherRequest
    ),
    takeLatest(
      DeleteDeliveryVoucherTypes.REQUEST,
      deleteDeliveryVoucherRequest
    ),
    takeLatest(ListDeliveryVouchersTypes.REQUEST, listDeliveryVouchersRequest),
    takeLatest(
      PaginateDeliveryVouchersTypes.REQUEST,
      paginateDeliveryVouchersRequest
    ),
    takeLatest(
      UpdateDeliveryVoucherTypes.REQUEST,
      updateDeliveryVoucherRequest
    ),

    // invoice justifications
    takeLatest(
      CreateInvoiceJustificationTypes.REQUEST,
      createInvoiceJustificationRequest
    ),
    takeLatest(
      ListInvoiceJustificationsTypes.REQUEST,
      listInvoiceJustificationsRequest
    ),
    takeLatest(
      PaginateInvoiceJustificationsTypes.REQUEST,
      paginateInvoiceJustificationsRequest
    ),
    takeLatest(
      UpdateInvoiceJustificationTypes.REQUEST,
      updateInvoiceJustificationRequest
    ),

     // invoice justifications customer
     takeLatest(
      CreateInvoiceJustificationCustomerTypes.REQUEST,
      createInvoiceJustificationCustomerRequest
    ),
    takeLatest(
      ListInvoiceJustificationCustomerTypes.REQUEST,
      listInvoiceJustificationCustomerRequest
    ),
    takeLatest(
      PaginateInvoiceJustificationCustomerTypes.REQUEST,
      paginateInvoiceJustificationCustomerRequest
    ),
    takeLatest(
      UpdateInvoiceJustificationCustomerTypes.REQUEST,
      updateInvoiceJustificationCustomerRequest
    ),

    // indent justifications
    takeLatest(
      CreateIndentJustificationTypes.REQUEST,
      createIndentJustificationRequest
    ),
    takeLatest(
      ListIndentJustificationsTypes.REQUEST,
      listIndentJustificationsRequest
    ),
    takeLatest(
      PaginateIndentJustificationsTypes.REQUEST,
      paginateIndentJustificationsRequest
    ),
    takeLatest(
      UpdateIndentJustificationTypes.REQUEST,
      updateIndentJustificationRequest
    ),

    // indent justifications customer
    takeLatest(
      CreateIndentJustificationCustomerTypes.REQUEST,
      createIndentJustificationCustomerRequest
    ),
    takeLatest(
      ListIndentJustificationCustomerTypes.REQUEST,
      listIndentJustificationCustomerRequest
    ),
    takeLatest(
      PaginateIndentJustificationCustomerTypes.REQUEST,
      paginateIndentJustificationCustomerRequest
    ),
    takeLatest(
      UpdateIndentJustificationCustomerTypes.REQUEST,
      updateIndentJustificationCustomerRequest
    ),

    // invoice recalculate deadline
    takeLatest(
      FetchRecalculateDeadlineTypes.REQUEST,
      fetchRecalculateDeadlineRequest
    ),

    // indent recalculate deadline
    takeLatest(
      FetchIndentRecalculateDeadlineTypes.REQUEST,
      fetchIndentRecalculateDeadlineRequest
      ),

    // invoice update delivery date
    takeLatest(UpdateDeliveryDateTypes.REQUEST, updateDeliveryDateRequest),

    // indent update delivery date
    takeLatest(UpdateIndentDeliveryDateTypes.REQUEST, updateIndentDeliveryDateRequest),

    // settings-holidays
    takeLatest(CreateHolidayTypes.REQUEST, createHolidayRequest),
    takeLatest(DeleteHolidayTypes.REQUEST, deleteHolidayRequest),
    takeLatest(FetchHolidayTypes.REQUEST, fetchHolidayRequest),
    takeLatest(ListBusinessUnitsTypes.REQUEST, listBusinessUnitsRequest),
    takeLatest(PaginateHolidaysTypes.REQUEST, paginateHolidaysRequest),
    takeLatest(UpdateHolidayTypes.REQUEST, updateHolidayRequest),

    // ignoredCfop
    takeLatest(CreateIgnoredCfopTypes.REQUEST, createIgnoredCfopRequest),
    takeLatest(DeleteIgnoredCfopTypes.REQUEST, deleteIgnoredCfopRequest),
    takeLatest(FetchIgnoredCfopTypes.REQUEST, fetchIgnoredCfopRequest),
    takeLatest(PaginateIgnoredCfopsTypes.REQUEST, paginateIgnoredCfopsRequest),
    takeLatest(UpdateIgnoredCfopTypes.REQUEST, updateIgnoredCfopRequest),

    // settings-regions
    takeLatest(ListRegionsTypes.REQUEST, listRegionsRequest),

    // settings-justification-types
    takeLatest(
      CreateJustificationTypeTypes.REQUEST,
      createJustificationTypeRequest
    ),
    takeLatest(
      DeleteJustificationTypeTypes.REQUEST,
      deleteJustificationTypeRequest
    ),
    takeLatest(
      FetchJustificationTypeTypes.REQUEST,
      fetchJustificationTypeRequest
    ),
    takeLatest(
      ListJustificationTypesTypes.REQUEST,
      listJustificationTypesRequest
    ),
    takeLatest(
      PaginateJustificationTypesTypes.REQUEST,
      paginateJustificationTypesRequest
    ),
    takeLatest(
      UpdateJustificationTypeTypes.REQUEST,
      updateJustificationTypeRequest
    ),

    //settings-justificationsCustomer-types
    takeLatest(
      CreateJustificationCustomerTypeTypes.REQUEST,
      createJustificationCustomerTypeRequest
    ),
    takeLatest(
      DeleteJustificationCustomerTypeTypes.REQUEST,
      deleteJustificationCustomerTypeRequest
    ),
    takeLatest(
      FetchJustificationCustomerTypeTypes.REQUEST,
      fetchJustificationCustomerTypeRequest
    ),
    takeLatest(
      ListJustificationCustomerTypesTypes.REQUEST,
      listJustificationCustomerTypesRequest
    ),
    takeLatest(
      PaginateJustificationCustomerTypesTypes.REQUEST,
      paginateJustificationCustomerTypesRequest
    ),
    takeLatest(
      UpdateJustificationCustomerTypeTypes.REQUEST,
      updateJustificationCustomerTypeRequest
    ),

    // settings-transits
    takeLatest(CreateTransitTypes.REQUEST, createTransitRequest),
    takeLatest(DeleteTransitTypes.REQUEST, deleteTransitRequest),
    takeLatest(FetchTransitTypes.REQUEST, fetchTransitRequest),
    takeLatest(ExportTransitsTypes.REQUEST, exportTransitsRequest),
    takeLatest(PaginateTransitsTypes.REQUEST, paginateTransitsRequest),
    takeLatest(UpdateTransitTypes.REQUEST, updateTransitRequest),
    takeLatest(ImportTransitTypes.REQUEST, importTransitRequest),

    // settings-internacional-transits
    takeLatest(
      CreateInternacionalTransitTypes.REQUEST,
      createInternacionalTransitRequest
    ),
    takeLatest(
      DeleteInternacionalTransitTypes.REQUEST,
      deleteInternacionalTransitRequest
    ),
    takeLatest(
      FetchInternacionalTransitTypes.REQUEST,
      fetchInternacionalTransitRequest
    ),
    takeLatest(
      ExportInternacionalTransitsTypes.REQUEST,
      exportInternacionalTransitsRequest
    ),
    takeLatest(
      PaginateInternacionalTransitsTypes.REQUEST,
      paginateInternacionalTransitsRequest
    ),
    takeLatest(
      UpdateInternacionalTransitTypes.REQUEST,
      updateInternacionalTransitRequest
    ),
    takeLatest(
      ImportInternacionalTransitTypes.REQUEST,
      importInternacionalTransitRequest
    ),

    // settings-business
    takeLatest(CreateBusinessTypes.REQUEST, createBusinessRequest),
    takeLatest(DeleteBusinessTypes.REQUEST, deleteBusinessRequest),
    takeLatest(FetchBusinessTypes.REQUEST, fetchBusinessRequest),
    takeLatest(ListBusinessTypes.REQUEST, listBusinessRequest),
    takeLatest(PaginateBusinessTypes.REQUEST, paginateBusinessRequest),
    takeLatest(UpdateBusinessTypes.REQUEST, updateBusinessRequest),

    // settings-business-units
    takeLatest(CreateBusinessUnitTypes.REQUEST, createBusinessUnitRequest),
    takeLatest(DeleteBusinessUnitTypes.REQUEST, deleteBusinessUnitRequest),
    takeLatest(FetchBusinessUnitTypes.REQUEST, fetchBusinessUnitRequest),
    takeLatest(
      PaginateBusinessUnitsTypes.REQUEST,
      paginateBusinessUnitsRequest
    ),
    takeLatest(UpdateBusinessUnitTypes.REQUEST, updateBusinessUnitRequest),

    // settings-operation-types
    takeLatest(CreateOperationTypeTypes.REQUEST, createOperationTypeRequest),
    takeLatest(DeleteOperationTypeTypes.REQUEST, deleteOperationTypeRequest),
    takeLatest(FetchOperationTypeTypes.REQUEST, fetchOperationTypeRequest),
    takeLatest(ListOperationTypesTypes.REQUEST, listOperationTypesRequest),
    takeLatest(
      PaginateOperationTypesTypes.REQUEST,
      paginateOperationTypesRequest
    ),
    takeLatest(UpdateOperationTypeTypes.REQUEST, updateOperationTypeRequest),

    // settings-type-cfops
    takeLatest(ListTypeCfopsTypes.REQUEST, listTypeCfopsRequest),

    // settings-operation-cfops
    takeLatest(CreateOperationCfopTypes.REQUEST, createOperationCfopRequest),
    takeLatest(DeleteOperationCfopTypes.REQUEST, deleteOperationCfopRequest),
    takeLatest(FetchOperationCfopTypes.REQUEST, fetchOperationCfopRequest),
    takeLatest(ListOperationCfopsTypes.REQUEST, listOperationCfopsRequest),
    takeLatest(
      PaginateOperationCfopsTypes.REQUEST,
      paginateOperationCfopsRequest
    ),
    takeLatest(UpdateOperationCfopTypes.REQUEST, updateOperationCfopRequest),

    // settings-products
    takeLatest(CreateProductTypes.REQUEST, createProductRequest),
    takeLatest(DeleteProductTypes.REQUEST, deleteProductRequest),
    takeLatest(FetchProductTypes.REQUEST, fetchProductRequest),
    takeLatest(ListProductsTypes.REQUEST, listProductsRequest),
    takeLatest(PaginateProductsTypes.REQUEST, paginateProductsRequest),
    takeLatest(UpdateProductTypes.REQUEST, updateProductRequest),

    // settings-harbors
    takeLatest(CreateHarborTypes.REQUEST, createHarborRequest),
    takeLatest(DeleteHarborTypes.REQUEST, deleteHarborRequest),
    takeLatest(FetchHarborTypes.REQUEST, fetchHarborRequest),
    takeLatest(ListHarborsTypes.REQUEST, listHarborsRequest),
    takeLatest(PaginateHarborsTypes.REQUEST, paginateHarborsRequest),
    takeLatest(UpdateHarborTypes.REQUEST, updateHarborRequest),

    // settings-countries
    takeLatest(CreateCountryTypes.REQUEST, createCountryRequest),
    takeLatest(DeleteCountryTypes.REQUEST, deleteCountryRequest),
    takeLatest(FetchCountryTypes.REQUEST, fetchCountryRequest),
    takeLatest(ListCountriesTypes.REQUEST, listCountriesRequest),
    takeLatest(PaginateCountriesTypes.REQUEST, paginateCountriesRequest),
    takeLatest(UpdateCountryTypes.REQUEST, updateCountryRequest),

    // settings-product-companies
    takeLatest(CreateProductCompanyTypes.REQUEST, createProductCompanyRequest),
    takeLatest(DeleteProductCompanyTypes.REQUEST, deleteProductCompanyRequest),
    takeLatest(FetchProductCompanyTypes.REQUEST, fetchProductCompanyRequest),
    takeLatest(ListProductCompaniesTypes.REQUEST, listProductCompaniesRequest),
    takeLatest(
      PaginateProductCompaniesTypes.REQUEST,
      paginateProductCompaniesRequest
    ),
    takeLatest(UpdateProductCompanyTypes.REQUEST, updateProductCompanyRequest),

    // settings-users
    takeLatest(
      CreateUserBusinessLineTypes.REQUEST,
      createUserBusinessLineRequest
    ),
    takeLatest(CreateUserCompanyTypes.REQUEST, createUserCompanyRequest),
    takeLatest(CreateUserGroupTypes.REQUEST, createUserGroupRequest),
    takeLatest(CreateUserTypes.REQUEST, createUserRequest),
    takeLatest(
      DeleteUserBusinessLineTypes.REQUEST,
      deleteUserBusinessLineRequest
    ),
    takeLatest(DeleteUserCompanyTypes.REQUEST, deleteUserCompanyRequest),
    takeLatest(DeleteUserGroupTypes.REQUEST, deleteUserGroupRequest),
    takeLatest(DeleteUserTypes.REQUEST, deleteUserRequest),
    takeLatest(FetchUserTypes.REQUEST, fetchUserRequest),
    takeLatest(ListUsersTypes.REQUEST, listUsersRequest),
    takeLatest(PaginateUsersTypes.REQUEST, paginateUsersRequest),
    takeLatest(UpdateUserTypes.REQUEST, updateUserRequest),

    // distance
    takeLatest(ListDistanceTypes.REQUEST, listDistanceRequest),

    // UserAvatar
    takeLatest(CreateUserAvatarTypes.REQUEST, createUserAvatar),
    takeLatest(GetUserAvatarTypes.REQUEST, getUserAvatar),
    takeLatest(UpdateUserAvatarTypes.REQUEST, updateUserAvatar),

    // Reports
    takeLatest(ListDownTimeTypes.REQUEST, listDownTimeRequest),


    
  ]);
}
