import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { usePermissions, useTranslation } from "hooks";
import { translations } from "./translations";

import {
  FetchInvoiceActions,
  FetchInvoiceState,
} from 'store/ducks/tracking/invoices';

import { cnpj } from 'utils/formatters';
import * as S from './styles';
import { Scaffold, Modal } from 'components/shared';
import {
  MapTrackingInvoice,
  InvoiceTrackingHeader,
  InvoiceTrackingBox,
  ListInvoiceItems,
  ListInvoiceJustifications,
  ListInvoiceJustificationsCustomer,
  TrackingProgress,
  UploadDeliveryVoucher,
  InvoiceInfoModal,
  InvoiceActivities,
} from 'components/tracking';
import { ActivityType, InvoiceActivity } from 'interfaces/inovice-activity';

interface IParams {
  id: string;
}

export const ShowInvoice: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);
  const { hasAnyPermission } = usePermissions();

  const { loading, data } = useSelector<RootStateOrAny, FetchInvoiceState>(
    (state) => state.fetchInvoice
  );

  const onError = useCallback(() => {
    history.goBack();
  }, [history]);

  const getData = useCallback(() => {
    dispatch(FetchInvoiceActions.request(id, null, onError));
  }, [dispatch, id, onError]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const renderActivities = (
    activities: InvoiceActivity[],
    type: 'activity' | 'justification'
  ) => {
    let filteredJustification = activities.filter(
      (activity) => activity.type === ActivityType.activity
    );
    if (type === 'justification') {
      filteredJustification = activities.filter(
        (activity) => activity.type === ActivityType.justification
      );
    }

    return <InvoiceActivities type={type} activities={filteredJustification} />;
  };

  const renderCarrier = useCallback(() => {
    if (data) {
      if (data.redespacho) {
        return (
          <>
            {data.carrier && (
              <InvoiceTrackingBox
                data={{
                  type: 'carrier',
                  title: getTranslation('transportadora'),
                  name: data.carrier.company_name,
                  cnpj: cnpj(data.carrier.cnpj),
                  address: `${data.carrier.address_neighborhood}, ${data.carrier.address_number}, ${data.carrier.address_city}/${data.carrier.address_state}`,
                }}
              />
            )}
            <InvoiceTrackingBox
              data={{
                type: 'redespacho',
                title: getTranslation('redespacho'),
                name: data.transp_razao_social,
                cnpj: cnpj(data.transp_cnpj),
                address: `${data.transp_endereco_completo}, ${data.transp_nome_municipio}/${data.transp_uf}`,
              }}
              // openModal={openModal}
            />
          </>
        );
      } else {
        if (data.carrier) {
          return (
            <InvoiceTrackingBox
              data={{
                type: 'carrier',
                title: getTranslation('transportadora'),
                name: data.carrier.company_name,
                cnpj: cnpj(data.carrier.cnpj),
                address: `${data.carrier.address_neighborhood}, ${data.carrier.address_number}, ${data.carrier.address_city}/${data.carrier.address_state}`,
              }}
            />
          );
        } else {
          return (
            <InvoiceTrackingBox
              data={{
                type: 'carrier',
                title: getTranslation('transportadora'),
                name: data.transp_razao_social,
                cnpj: cnpj(data.transp_cnpj),
                address: `${data.transp_endereco_completo}, ${data.transp_nome_municipio}/${data.transp_uf}`,
              }}
            />
          );
        }
      }
    }
  }, [data, getTranslation]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(FetchInvoiceActions.reset());
    };
  }, [dispatch, getData]);

  return (
    <Scaffold>
      {data ? (
        <Modal isOpen={modalOpen}>
          <InvoiceInfoModal data={data?.client} closeModal={closeModal} />
        </Modal>
      ) : (
        ''
      )}

      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          {getTranslation('notaFiscal')}{' '}
          <span>
            {data?.ide_numero_nf}-{data?.ide_serie}
          </span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini onClick={() => history.goBack()}>
          <S.IconArrowLeft />
            {getTranslation('voltar')}
        </S.ButtonMini>
      </S.PageHeader>
      {data && (
        <>
          <MapTrackingInvoice invoice={data} />
          <S.PageContent>
            <InvoiceTrackingHeader invoice={data} onActionSuccess={getData} />
            <TrackingProgress invoice={data} />
            {data.justifications && data.justifications.length > 0 && (
              <ListInvoiceJustifications
                justifications={data.justifications}
                onUpdate={getData}
              />
            )}
            {data.InvoiceJustificationCustomer &&
              data.InvoiceJustificationCustomer.length > 0 && (
                <ListInvoiceJustificationsCustomer
                  justifications={data.InvoiceJustificationCustomer}
                  onUpdate={getData}
                />
              )}
            {data.delivery_date && !hasAnyPermission(['Cliente']) && (
              <UploadDeliveryVoucher invoiceId={data.id} />
            )}
            <S.InvoiceBoxWrapper>
              <InvoiceTrackingBox
                data={{
                  type: 'sender',
                  title: getTranslation('remetente'),
                  name: data.emit_razao_social,
                  cnpj: cnpj(data.emit_cnpj),
                  address: `${data.emit_logradouro}, ${data.emit_numero}, ${data.emit_nome_municipio}/${data.emit_uf}, ${data.emit_cep}`,
                }}
              />
              {renderCarrier()}

              <InvoiceTrackingBox
                data={{
                  type: 'destination',
                  title: getTranslation('destinatario'),
                  name: data.dest_razao_social,
                  cnpj: cnpj(data.dest_cnpj),
                  address: `${data.dest_logradouro}, ${data.dest_numero}, ${data.dest_nome_municipio}/${data.dest_uf}, ${data.dest_cep}`,
                }}
                openModal={openModal}
              />
              {data.entrega_razao_social && (
                <InvoiceTrackingBox
                  data={{
                    type: 'recipient',
                    title: getTranslation('entrega'),
                    name: data.entrega_razao_social,
                    cnpj: cnpj(data.entrega_cnpj),
                    address: `${data.entrega_logradouro}, ${data.entrega_numero}, ${data.entrega_nome_municipio}, ${data.entrega_nome_municipio}/${data.entrega_uf}`,
                  }}
                  openModal={openModal}
                />
              )}
            </S.InvoiceBoxWrapper>
            <ListInvoiceItems invoice={data} />
            {data.activity && (
              <S.ActivitiesWrapper>
                {renderActivities(data.activity, 'activity')}
                {renderActivities(data.activity, 'justification')}
              </S.ActivitiesWrapper>
            )}
          </S.PageContent>
        </>
      )}
    </Scaffold>
  );
};
