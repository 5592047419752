import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateIndentJustificationsActions } from "store/ducks/tracking/indent-justifications";

export function* paginateIndentJustificationsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/indent-justifications?${queryString}`
    );

    const { indentJustifications, pagination } = responseBody;
    yield put(
      PaginateIndentJustificationsActions.success(
        indentJustifications,
        pagination
      )
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateIndentJustificationsActions.failure(errorMessage));
  }
}
