import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
  PaginateTransitsActions,
  PaginateTransitsState,
  ExportTransitsActions,
  ExportTransitsState,
} from "store/ducks/settings/transits";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import * as S from "./styles";
import { Scaffold, Paginator } from "components/shared";
import { GridTransits, FilterTransits } from "components/settings";

import { useTranslation } from "hooks";
import { translations } from "./translations";

export const ListTransits: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [exportActive, setExportActive] = useState(false);
  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
  });
  const { data, loading, pagination } = useSelector<RootStateOrAny>(
    (state) => state.paginateTransits
  ) as PaginateTransitsState;

  const { data: fileData, loading: fileLoading } = useSelector<RootStateOrAny>(
    (state) => state.exportTransits
  ) as ExportTransitsState;

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        ...value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const getData = useCallback(() => {
    dispatch(PaginateTransitsActions.request({ ...query, ...dataFilter }));
  }, [dispatch, query, dataFilter]);;

  const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getFileData = async () => {
    dispatch(ExportTransitsActions.request({ all: true, ...query, ...dataFilter }));
    setExportActive(true);
  };

  const generateFile = () => {
    if (fileData) {
      const newFile: any[] = [];
      const fileName = `TEMPO_DE_TRÂNSITO`;

      fileData.forEach((line) => {
        const {
          carrier,
          type,
          origin,
          destiny,
          km_initial,
          km_final,
          weight,
          deadline_dedicated,
          deadline_fractional,
        } = line;

        const TRANSPORTADORA = carrier.trade_name;
        const TIPO = type;
        const ORIGEM = origin.name;
        const DESTINO = destiny ? destiny.name : "-";
        const IBGE_ORIGEM = origin.ibge;
        const IBGE_DESTINO = destiny ? destiny.ibge : "-";
        const KM_INICIAL = km_initial || "-";
        const KM_FINAL = km_final || "-";
        const PESO = weight;
        const PRAZO_LOTACAO = deadline_dedicated;
        const PRAZO_FRACIONADO = deadline_fractional;

        const fileLine = {
          TRANSPORTADORA,
          TIPO,
          ORIGEM,
          IBGE_ORIGEM,
          DESTINO,
          IBGE_DESTINO,
          KM_INICIAL,
          KM_FINAL,
          PESO,
          PRAZO_LOTACAO,
          PRAZO_FRACIONADO,
        };

        newFile.push(fileLine);
      });

      exportToCSV(newFile, fileName);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!fileLoading && exportActive) {
      generateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLoading, exportActive]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation('titulo')}<span>{getTranslation('transitTime')}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
        <S.ButtonMini onClick={() => getFileData()} disabled={fileLoading}>
          {fileLoading ? (
            <ReactLoading type="spin" color="#fff" height={20} width={20} />
          ) : (
            getTranslation('csv')
          )}
        </S.ButtonMini>
        <S.ButtonMini onClick={() => history.push("/settings/transit/import")}>
          {getTranslation('importar')}
        </S.ButtonMini>
        <S.ButtonMini onClick={() => history.push("/settings/transit/new")}>
        {getTranslation('novoTransit')}
        </S.ButtonMini>

        <FilterTransits onFilter={handleSubmitFilter} />
        </S.HeaderButtons>
     
        
      </S.PageHeader>
      <S.PageContent>
        <GridTransits transits={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
