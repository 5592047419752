import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { FetchIndentRecalculateDeadlineActions } from "store/ducks/tracking/indent-recalculate-deadline";

export function* fetchIndentRecalculateDeadlineRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(
      api.get,
      `/indent/recalculete-deadline?${queryString}`
    );

    yield put(FetchIndentRecalculateDeadlineActions.success(data));
    if (onSuccess) return onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchIndentRecalculateDeadlineActions.failure(errorMessage));
  }
}
