import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { AuthState } from 'store/ducks/auth';
import { Scaffold } from 'components/shared';

import * as S from './styles';

export const CarrierPerformance: React.FC = () => {
  const [url, setUrl] = useState<string | null>(null);
  const { data } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  useEffect(() => {
    if (data) {
      if (data.rootCarrier) {
        setUrl(
          `https://analytics.zoho.com/open-view/1432280000041809614/658fd7335b95a3bdc2edea6646f41b96?ZOHO_CRITERIA=%22general_carriers%22.%22cnpj_root%22%20%3D%20${data.rootCarrier}`
        );
      } else {
        setUrl(
          'https://analytics.zoho.com/open-view/1432280000041809614/658fd7335b95a3bdc2edea6646f41b96'
        );
      }
    }
  }, [data]);

  return (
    <Scaffold>
      {url && <S.Iframe frameBorder={0} width="100%" height="100%" src={url} />}
    </Scaffold>
  );
};
