export const translations = {
  titulo: {
    pt: 'Tracking',
    en: 'Tracking',
    de: 'Verfolgung',
    es: 'Seguimiento',
  },
  subtitulo: {
    pt: 'Notas fiscais',
    en: 'Tracking by Invoices',
    de: 'Tracking nach Rechnungen',
    es: 'Seguimiento por Facturas',
  },
};
