import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateInternacionalTransitActions } from "store/ducks/settings/internacional-transits";

export function* updateInternacionalTransitRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.put,
      `/internacional-transit-times/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateInternacionalTransitActions.success(data));
    notify("success", "Tempo de trânsito atualizado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateInternacionalTransitActions.failure(errorMessage));
  }
}
