export const translations= {
  codigoP: {
    pt: 'Codigo Prod.',
    en: 'Product code',
    de: 'Produktcode',
    es: 'Código Producto',
  }, 
  desc: {
    pt: 'Descrição do produto',
    en: 'Product Description',
    de: 'Produktbeschreibung',
    es: 'Descripción del producto',
  },  
  pedido: {
    pt: 'Pedido',
    en: 'Request',
    de: 'Anfrage',
    es: 'Solicitud',
  }, 
  quantidade: {
    pt: 'Quantidade',
    en: 'Amount',
    de: 'Die Summe',
    es: 'La cantidad',
  }, 
  valorICMS: {
    pt: 'Valor ICMS',
    en: 'ICMS value',
    de: 'ICMS-Wert',
    es: 'valor ICMS',
  }, 
  ipi: {
    pt: 'IPI',
    en: 'IPI',
    de: 'IPI',
    es: 'IPI',
  }, 
  valorPis: {
    pt: 'Valor PIS',
    en: 'PIS value',
    de: 'PIS-Wert',
    es: 'valor PIS',
  }, 
  valorConfins: {
    pt: 'Valor COFINS',
    en: 'COFINS value',
    de: 'COFINS-Wert',
    es: 'valor COFINS',
  }, 
  valorUnitario: {
    pt: 'Valor unitário',
    en: 'Unitary value',
    de: 'Einheitswert',
    es: 'Valor unitario',
  },
  valorTotal: {
    pt: 'Valor Total',
    en: 'Amount',
    de: 'Menge',
    es: 'Valor Total',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'Id',
    es: 'Id',
  },
  veiculo: {
    pt: 'Veículo',
    en: 'Vehicle',
    de: 'Fahrzeug',
    es: 'Vehículo',
  },
  placa: {
    pt: 'Placa',
    en: 'Plate',
    de: 'Teller',
    es: 'Placa',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Portador',
  },
  inicio: {
    pt: 'Inicio',
    en: 'Start',
    de: 'Anfang',
    es: 'Inicio',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  entrega: {
    pt: 'Entrega',
    en: 'Delivery',
    de: 'Die Zustellung',
    es: 'Entrega',
  },  
  redespacho: {
    pt: 'Redespacho',
    en: 'Batch',
    de: 'Charge',
    es: 'Lote',
  },
  pesoTotal: {
    pt: 'Peso total',
    en: 'Total weight',
    de: 'Gesamtgewicht',
    es: 'Peso total',
  },
  valorNF: {
    pt: 'Valor NFS',
    en: 'Invalue',
    de: 'Rechnungswert',
    es: 'Valor de la factura',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Auftraggeber',
    es: 'Cliente',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  nf: {
    pt: 'NF',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Factura',
  },
  emissao: {
    pt: 'Emissão',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Factura',
  },
  localizacao: {
    pt: 'Localização',
    en: 'Localization',
    de: 'Lokalisierung',
    es: 'Localización',
  }, 
};