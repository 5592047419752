import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateDeliveryVouchersActions } from "store/ducks/tracking/delivery-vouchers";

export function* paginateDeliveryVouchersRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/delivery-vouchers?${queryString}`
    );

    const { deliveryVouchers, pagination } = responseBody;
    yield put(
      PaginateDeliveryVouchersActions.success(deliveryVouchers, pagination)
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateDeliveryVouchersActions.failure(errorMessage));
  }
}
