import styled from "styled-components";
import { Receipt } from "@styled-icons/boxicons-regular";
import { Loading } from "styles/styled-components";

export {
  PageContainer,
  PageHeader,
  PageContent,
  ButtonMini,
  Loading,
} from "styles/styled-components";

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const IconInvoice = styled(Receipt).attrs({ size: 20 })`
  margin-right: 8px;
`;
