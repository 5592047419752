export const translations = {
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  feriados: {
    pt: 'Feriados',
    en: 'Holidays',
    de: 'Feiertage',
    es: 'Vacaciones',
  },
  novoFeriado: {
    pt: 'Novo Feriado',
    en: 'New Holiday',
    de: 'Neuer Feiertag',
    es: 'Nuevas vacaciones',
  },


};