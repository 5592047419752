export const translations = {
  'Notas pendentes': {
    pt: 'Notas pendentes',
    en: 'Outstanding invoice',
    de: 'Ausstehende Rechnungen',
    es: 'Facturas pendientes'
  },
  'Notas em trânsito': {
    pt: 'Notas em trânsito',
    en: 'Invoices in transit',
    de: 'Rechnungen unterwegs',
    es: 'Facturas en tránsito'
  },
  'Notas entregues': {
    pt: 'Notas entregues',
    en: 'Invoices delivered',
    de: 'Rechnungen zugestellt',
    es: 'Facturas entregadas',
  },
  noPrazo: {
    pt: 'No prazo',
    en: 'Within the deadline',
    de: 'Innerhalb der Frist',
    es: 'En el plazo',
  },
  atraso: {
    pt: 'Atraso',
    en: 'Delay',
    de: 'Verzögern',
    es: 'Demora',
  },
  semPrazo: {
    pt: 'Sem prazo',
    en: 'No deadline',
    de: 'Keine Frist',
    es: 'Sin fecha límite',
  },
};
