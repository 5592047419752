import styled from "styled-components";
import { PageContainer } from "styles/styled-components";

export const Container = styled(PageContainer)``;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
