export const translations = {
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  tiposDeOperacao: {
    pt: 'Tipos de Operação',
    en: 'Operation Types',
    de: 'Operationsarten',
    es: 'Tipos de Operaciones',
  },
  novasOperacoes: {
    pt: 'Novo Tipo de Operação',
    en: 'New Type of Operation',
    de: 'Neue Art der Operation',
    es: 'Nuevo tipo de operación',
  },    
}