export const translations = {
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Mandatory',
    es: 'Obligatorio',
  },
  novaJustificativaCustomer: {
    pt: 'Nova justificativa Customer',
    en: 'New justification Customer',
    de: 'Neue Begründung Kunde',
    es: 'Nueva justificación Cliente',
  },
  justificativaCustomer: {
    pt: 'Justificativa Customer',
    en: 'Customer justifications',
    de: 'Kundenbegründung',
    es: 'Justificación del cliente',
  },
  comentario: {
    pt: 'Comentário',
    en: 'Comment',
    de: 'Kommentar',
    es: 'Comentario',
  },
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Stornieren',
    es: 'Cancelar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  }, 
};