export const translations = {
  obrigatorio: {
     pt: 'Obrigtório',
     en: 'Mandatory',
     de: 'Verpflichtend',
     es: 'Obligatorio',
  },
  segmentacao: {
    pt: 'Customer segmentation',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  justificativas: {
    pt: 'Justificativa',
    en: 'Justification',
    de: 'Rechtfertigung',
    es: 'Justificación',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  }, 
  clienteRecebe: {
    pt: 'Cliente recebe',
    en: 'Customer receives',
    de: 'Kunde erhält',
    es: 'El cliente recibe',
  }, 
  canceladas: {
    pt: 'Canceladas',
    en: 'Canceled',
    de: 'Abgesagt',
    es: 'Cancelado',
  },
  codigo_P: {
    pt: 'Código do Picking',
    en: 'Picking code',
    de: 'Auswahlcode',
    es: 'Código de picking',
  },
  devolucao: {
    pt: 'Devolução',
    en: 'Devolution',
    de: 'Übertragung',
    es: 'Devolución',
  },
  emissaoDe: {
    pt: 'Data emissão de',
    en: 'Issue date of',
    de: 'Ausgabedatum vom',
    es: 'Fecha de emisión de',
  },
  emissaoAte: {
    pt: 'Data emissão até',
    en: 'Issue date until',
    de: 'Ausstellungsdatum bis',
    es: 'Fecha de emisión hasta',
  },
  prazoDe: {
    pt: 'Prazo de',
    en: 'Term of',
    de: 'Laufzeit von',
    es: 'Plazo de entrega de',
  },
  prazoAte: {
    pt: 'Prazo até',
    en: 'Teadline until',
    de: 'Frist bis',
    es: 'Plazo de entrega de',
  },
  paisOrigem: {
    pt: 'Pais de Origem',
    en: 'Country of origin',
    de: 'Ursprungsland',
    es: 'País de origen',
  },
  ufOrigem: {
    pt: 'UF Origem',
    en: 'State of Origin',
    de: 'Herkunftsland',
    es: 'Estado de origen',
  },
  cidadeOrigem: {
    pt: 'Cidade de origem',
    en: 'City ​​of origin',
    de: 'Herkunftsstadt',
    es: 'ciudad de origen',
  },
  paisDestino: {
    pt: 'Pais de Destino',
    en: 'Destiny country',
    de: 'Land des Schicksals',
    es: 'País de destino',
  },
  ufDestino: {
    pt: 'UF Destino',
    en: 'State destination',
    de: 'Staatliches Ziel',
    es: 'Estado de destino',
  },
  cidadeDestino: {
    pt: 'Cidade de destino',
    en: 'Destination city',
    de: 'Zielstadt',
    es: 'Ciudad de destino',
  },
  veiculo: {
    pt: 'Tipo de veículo',
    en: 'Vehicle type',
    de: 'Fahrzeugtyp',
    es: 'Tipo de vehiculo',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  notaFiscal: {
    pt: 'Nota Fiscal',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Nota fiscal',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  segunda:{
    pt: 'Segunda',
    en: 'Monday',
    de: 'Montag',
    es: 'Lunes',
  },
  terca:{
    pt: 'Terça',
    en: 'Tuesday',
    de: 'Dienstag',
    es: 'Martes',
  },
  quarta:{
    pt: 'Quarta',
    en: 'Wednesday',
    de: 'Der Mittwoch',
    es: 'Miércoles',
  },
  quinta:{
    pt: 'Quinta',
    en: 'Thursday',
    de: 'Donnerstag',
    es: 'Jueves',
  },
  sexta:{
    pt: 'Sexta',
    en: 'Friday',
    de: 'Freitag',
    es: 'Viernes',
  },
  sabado:{
    pt: 'Sábado',
    en: 'Saturday',
    de: 'Samstag',
    es: 'Sábado',
  },
  domingo:{
    pt: 'Domingo',
    en: 'Sunday',
    de: 'Sonntag',
    es: 'Domingo',
  },
  sim:{
    pt: 'Sim',
    en: 'Yea',
    de: 'Ja',
    es: 'Sí',
  },
  nao:{
    pt: 'Não',
    en: 'No',
    de: 'Nein',
    es: 'No',
  },
  tipoOperacao:{
    pt: 'Tipo Operação',
    en: 'Operation Type',
    de: 'Operationstyp',
    es: 'Tipo de operación',
  },
  necessitaAgendamento: {
    pt: 'Necessita agendamento',
    en: 'Need scheduling',
    de: 'Termin brauchen',
    es: 'Horario requerido',
  },
  placaVeiculo: {
    pt: 'Placa do veículo',
    en: 'Vehicle plate',
    de: 'Fahrzeugkennzeichen',
    es: 'Matrícula del vehículo',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Portador',
  },
  transportadoraRaiz: {
    pt: 'Transportadora (Raiz)',
    en: 'Carrier (Root)',
    de: 'Träger (Wurzel)',
    es: 'Transportadora (Raíz)',
  },
  cnpjRaiz: {
    pt: 'CNPJ Raiz',
    en: 'CNPJ Root',
    de: 'CNPJ Wurzel',
    es: 'CNPJ Raíz',
  },
  inicioRecebimento:{
    pt: 'Início de recebimento',
    en: 'Start of receipt',
    de: 'Beginn der Rezeption',
    es: 'Inicio de recibo',
  },
  fimRecebimento:{
    pt: 'Fim de recebimento',
    en: 'End of receipt',
    de: 'Ende der Quittung',
    es: 'Fin de recibo',
  },
  inicioRefeicao:{
    pt: 'Início de refeição',
    en: 'Meal start',
    de: 'Mahlzeit beginnen',
    es: 'Comienzo de la comida',
  },
  fimRefeicao:{
    pt: 'Fim de refeição',
    en: 'End of meal',
    de: 'Ende der Mahlzeit',
    es: 'Final de la comida',
  },
  observacao: {
    pt: 'Observação',
    en: 'Observation',
    de: 'Überwachung',
    es: 'Observación',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Speichern',
    es: 'Guardar',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  csv: {
    pt: 'Exportar CSV',
    en: 'Export CSV',
    de: 'CSV-Datei',
    es: 'Exportar CSV',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
};