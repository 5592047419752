import styled from "styled-components";
import { Check, Time } from "@styled-icons/boxicons-regular";
import {Exclamation} from '@styled-icons/bootstrap/Exclamation';
import {ApprovalsApp} from '@styled-icons/fluentui-system-filled/ApprovalsApp'

import { colors, fonts } from "styles";

interface StatusStyle {
  status?: string | undefined;
}

export const Hint = styled.span`
  position: absolute;
  top: -24px;
  display: none;
  padding: 4px 8px;
  background-color: ${colors.gray4};
  border-radius: 4px;
  font-family: ${fonts.GilroySemiBold};
  font-size: 11px;
  text-align: center;
  color: white;
  width: max-content;
`;

export const Container = styled.div<StatusStyle>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => 
    status === 'Comprovante aprovado' ? `${colors.green}` :
    status === 'Comprovante reprovado' ?  `${colors.red}` :
    status === 'Aguardando comprovante' ? `${colors.orange}` :
    `${colors.petrol}` 
};

  &:hover {
    ${Hint} {
      display: flex;
    }
  }
`;

export const IconAprovado = styled(Check).attrs({ size: 16 })`
  color: white;
`;
export const IconAguardandoComp = styled(Time).attrs({ size: 16 })`
  color: white;
`;
export const IconAguardandoAprov = styled(ApprovalsApp).attrs({ size: 24 })`
  color: white;
`;
export const IconReprovado = styled(Exclamation).attrs({ size: 24 })`
  color: white;
`;
