import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateInvoiceJustificationActions } from "store/ducks/tracking/invoice-justifications";

export function* createInvoiceJustificationRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data: responseBody } = yield call(
      api.post,
      `/invoice-justifications`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateInvoiceJustificationActions.success(data));
    notify("success", "Justificativa cadastrada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateInvoiceJustificationActions.failure(errorMessage));
  }
}
