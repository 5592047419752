import { UserModel } from './user';

export enum ActivityType {
  activity = 'activity',
  justification = 'justification',
}

export interface IndentActivity {
  id: number;
  invoice_id: number;
  type: ActivityType;
  description: string;
  created_at: string;
  user: UserModel | null;
}
