import styled from "styled-components";
import { Calendar } from "@styled-icons/boxicons-solid";
import { ArrowRight } from "@styled-icons/bootstrap";
import { colors, fonts } from "styles";
export { FormRow, Button, Loading } from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 680px;
  max-height: 90vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  border-right: none;

  button:first-child {
    margin-right: 16px;
  }
`;

export const IconMessage = styled(Calendar).attrs({ size: 24 })`
  margin-right: 8px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 32px;
`;

export const Column = styled.div``;

export const Label = styled.h5`
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray3};
  margin-bottom: 4px;
`;

export const Value = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 16px;
  text-transform: uppercase;
  color: ${colors.gray4};
`;

export const IconArrowRight = styled(ArrowRight).attrs({ size: 32 })`
  color: ${colors.deepPurple};
  margin: 0 48px;
  align-self: center;
`;
