import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { ImportInternacionalTransitActions } from "store/ducks/settings/internacional-transits";

export function* importInternacionalTransitRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data } = yield call(api.post, `/internacional-transit-times/import`, postData);
    yield put(ImportInternacionalTransitActions.success(data));
    notify("success", "Tempo de trânsito importado com sucesso");
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ImportInternacionalTransitActions.failure(errorMessage));
  }
}
