import React from 'react';
import { Scaffold } from 'components/shared';
import * as S from './styles';

export const Revenues: React.FC = () => {
  return (
    <Scaffold>
      <S.Iframe
        frameBorder={0}
        width="100%"
        height="100%"
        src="https://analytics.zoho.com/open-view/1432280000042056757/aa9cc02e0763b59ecab2bc8fa8fff95c"
      />
    </Scaffold>
  );
};
