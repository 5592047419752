import styled from "styled-components";
import { FileEarmarkText } from "@styled-icons/bootstrap";
import { fonts, colors } from "styles";
import { Loading } from "styles/styled-components";
export {
  PageContainer,
  PageHeader,
  PageContent,
  ButtonMini,
  Loading,
} from "styles/styled-components";

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const WarningBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 48px;
  border-radius: 4px;
  font-family: ${fonts.GilroyBold};
  color: ${colors.darkRgba25};
`;

export const IconInvoice = styled(FileEarmarkText).attrs({ size: 20 })`
  margin-right: 8px;
`;
