export const translations = {
  alterar: {
    pt: 'Alterar Business Line',
    en: 'Change Business Line',
    de: 'Geschäftsbereich wechseln',
    es: 'Cambiar línea de negocio',
  },
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Stornieren',
    es: 'Cancelar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  }, 
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Mandatory',
    es: 'Obligatorio',
  },
};