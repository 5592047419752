import styled from "styled-components";
import { ArrowNarrowLeft } from "@styled-icons/heroicons-outline";
import { FileEarmarkText } from "@styled-icons/bootstrap";

import { Loading } from "styles/styled-components";
export {
  PageContainer,
  PageHeader,
  PageContent,
  ButtonMini,
  Loading,
} from "styles/styled-components";

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const IconInvoice = styled(FileEarmarkText).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const IconArrowLeft = styled(ArrowNarrowLeft).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const InvoiceBoxWrapper = styled.section`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  grid-row-gap: 32px;
  margin: 0 auto 48px auto;
`;

export const ActivitiesWrapper = styled.section`
  display: flex;
  width: 100%;
  column-gap: 96px;
  margin-bottom: 96px;
  padding: 0 40px;
`;

