import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { CargoInTransit, CarrierPerformance, Revenues } from 'pages/Dashboard';

interface Props {}

export const DashboardRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/dashboard/cargas-em-transito"
        component={CargoInTransit}
      />
      <PrivateRoute
        exact
        path="/dashboard/performace-transportadora"
        component={CarrierPerformance}
      />
      <PrivateRoute
        exact
        path="/dashboard/revenues"
        component={Revenues}
        hasAnyPermission={[
          'Administrador',
          'Administrador CD',
          'Customer Service',
        ]}
      />
    </Switch>
  );
};
