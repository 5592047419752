import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListBusinessUnitsActions } from "store/ducks/settings/business-unit";

import { BusinessUnit } from "interfaces/business-unit";

export function* listBusinessUnitsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/business-units?${queryString}`);
    const comboOptions = data.map((unit: BusinessUnit) => ({
      value: unit.id,
      label: unit.name,
    }));

    yield put(ListBusinessUnitsActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListBusinessUnitsActions.failure(errorMessage));
  }
}
