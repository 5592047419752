import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { Transit } from "interfaces/transit";
import { useTranslation } from "hooks";
import { translations } from "./translations";

interface IGridTransitsProps {
  transits: Transit[] | Record<string, any>[];
}

interface ITransitProps {
  transit: Transit | Record<string, any>;
}

const Item: React.FC<ITransitProps> = ({ transit }) => {
  const history = useHistory();

  return (
    <S.ItemContainer
      onClick={() => history.push(`/settings/transit/${transit.id}`)}
    >
      <S.ItemContent>
        <S.ItemValue>{transit.carrier.trade_name || "--"}</S.ItemValue>
        <S.ItemValue>{transit.type || "--"}</S.ItemValue>
        <S.ItemValue>{transit.origin ? transit.origin.name : "--"}</S.ItemValue>
        <S.ItemValue>{transit.destiny ? transit.destiny.name : "--"}</S.ItemValue>
        <S.ItemValue>{transit.km_initial || "--"}</S.ItemValue>
        <S.ItemValue>{transit.km_final || "--"}</S.ItemValue>
        <S.ItemValue>{transit.deadline_dedicated || "--"}</S.ItemValue>
        <S.ItemValue>{transit.deadline_fractional || "--"}</S.ItemValue>
        <S.ItemValue>{
          transit.weight ?
          transit.weight.toLocaleString('en-US', { minimumFractionDigits: 2 })
          : "--"
        }</S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridTransits: React.FC<IGridTransitsProps> = ({
  transits = [],
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation('transportadora')}</S.Label>
        <S.Label>{getTranslation('tipo')}</S.Label>
        <S.Label>{getTranslation('origem')}</S.Label>
        <S.Label>{getTranslation('destino')}</S.Label>
        <S.Label>{getTranslation('kmInicial')}</S.Label>
        <S.Label>{getTranslation('kmFinal')}</S.Label>
        <S.Label>{getTranslation('prazoLotacao')}</S.Label>
        <S.Label>{getTranslation('prazoFracionado')}</S.Label>
        <S.Label>{getTranslation('peso')}</S.Label>
      </S.Header>
      {transits.length > 0 &&
        transits.map((transit) => <Item transit={transit} key={transit.id} />)}
    </S.Container>
  );
};
