import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateInvoiceJustificationCustomerActions } from "store/ducks/tracking/invoice-justificationCustomer";

export function* updateInvoiceJustificationCustomerRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;
    const { data: responseBody } = yield call(
      api.put,
      `/invoice-justification-customer/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateInvoiceJustificationCustomerActions.success(data));
    notify("success", "Justificativa Customer atualizada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateInvoiceJustificationCustomerActions.failure(errorMessage));
  }
}
