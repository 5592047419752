import styled from "styled-components";
import { Eye, EyeSlash } from "@styled-icons/fa-solid";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  padding: 32px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 48px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px ${colors.darkRgba25} solid;
  margin-bottom: 32px;
`;

export const Total = styled.div`
  margin-right: 48px;

  &:last-child {
    margin-right: 0;
  }
`;

export const TotalLabel = styled.h6`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray4};
  margin-bottom: 4px;
`;

export const TotalValue = styled.article`
  font-family: ${fonts.GilroyBold};
  font-size: 24px;
  color: ${colors.gray4};
  display: flex;
  align-items: center;
`;

export const GridHeader = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    100px minmax(160px, auto) repeat(2, 80px) 100px 60px
    repeat(6, 90px);
  column-gap: 16px;
  margin-bottom: 8px;
`;

export const Label = styled.h6`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray4};
  margin-bottom: 4px;
`;

export const Value = styled.article`
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: ${colors.gray4};
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    100px minmax(160px, auto) repeat(2, 80px) 100px 60px
    repeat(6, 90px);
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 16px 0;
  border-bottom: 1px ${colors.darkRgba25} dotted;

  &:last-child {
    border-bottom: none;
  }


  .right{
    text-align: right;s
  }
`;

export const IconEye = styled(Eye).attrs({ size: 20 })`
  color: ${colors.gray4};
`;

export const IconEyeSlash = styled(EyeSlash).attrs({ size: 20 })`
  color: ${colors.gray4};
`;

export const ButtonEye = styled.button.attrs({ type: "button" })`
  margin-left: 16px;
`;
