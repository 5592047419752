import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { cnpj } from 'utils/formatters';

import { useTranslation } from 'hooks';
import { translations } from './translations';

import {
  PaginateInvoicesActions,
  PaginateInvoicesState,
  ExportInvoicesActions,
  ExportInvoicesState,
} from 'store/ducks/tracking/invoices';
import {
  FetchInvoiceFilterActions,
  FetchInvoiceFilterState,
} from 'store/ducks/tracking/invoice-filters';

import { AuthState } from 'store/ducks/auth';

import * as S from './styles';
import { Scaffold, Paginator } from 'components/shared';
import {
  GridInvoices,
  InvoiceFilter,
  InvoiceIndicators,
} from 'components/tracking';

export const ListInvoices: React.FC = () => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const [exportActive, setExportActive] = useState(false);

  const { data: userData } = useSelector<RootStateOrAny, AuthState>(
    state => state.auth
  );

  const { data: dataFilter } = useSelector<
    RootStateOrAny,
    FetchInvoiceFilterState
  >(state => state.fetchInvoiceFilter);

  const { data, loading, pagination, indicators } = useSelector<
    RootStateOrAny,
    PaginateInvoicesState
  >(state => state.paginateInvoices);

  const { data: fileData, loading: fileLoading } = useSelector<
    RootStateOrAny,
    ExportInvoicesState
  >(state => state.exportInvoices);

  const handlePageChange = useCallback(
    (value) => {
      dispatch(
        FetchInvoiceFilterActions.success({ ...dataFilter, page: value })
      );
    },
    [dataFilter, dispatch]
  );

  const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getFileData = async () => {
    dispatch(ExportInvoicesActions.request({ all: true, ...dataFilter }));
    setExportActive(true);
  };

  const generateFile = () => {
    if (fileData) {
      const newFile: any[] = [];
      const fileName = `TRACKING_NF`;

      fileData.forEach((line) => {
        const {
          ide_numero_nf,
          ide_serie,
          ide_data_emissao,
          deadline_date,
          delivery_date,
          emit_nome_municipio,
          emit_uf,
          dest_nome_municipio,
          dest_uf,
          dest_razao_social,
          status,
          businessLine,
          entrega_cnpj,
          entrega_razao_social,
          carrier,
          client,
          occurrences,
          deviveryVoucher,
          items,
          emit_cnpj,
          emit_razao_social,
          picking_id,
          harbor_description,
          carrierPrediction,
          picking,
        } = line;

        const NF = `${ide_numero_nf}-${ide_serie}`;
        const EMISSAO =
          ide_data_emissao && format(new Date(ide_data_emissao), 'dd/MM/yyyy');
        const PRAZO = deadline_date
          ? format(new Date(deadline_date), 'dd/MM/yyyy')
          : '---';
        const ENTREGA =
          delivery_date && format(new Date(delivery_date), 'dd/MM/yyyy');

        const ORIGEM = emit_nome_municipio
          ? `${emit_nome_municipio}/${emit_uf}`
          : '---';
        const DESTINO = dest_nome_municipio
          ? `${dest_nome_municipio}/${dest_uf}`
          : '---';
        const TRANSPORTADORA = carrier?.trade_name || '---';
        const CLIENTE = dest_razao_social || '---';
        const STATUS = status && !status.includes('sem-status') ? status : '-';
        const BL = businessLine ? businessLine.description : '';

        const TRANS_CNPJ = cnpj(carrier?.cnpj) || '---';
        const CLI_CNPJ = cnpj(client?.cnpj) || '---';

        const OCORRENCIA =
          occurrences?.length > 0 ? occurrences[0].occurrenceType.name : '---';
        const PREV_ENTREGA =
          carrierPrediction.length > 0
            ? format(
                new Date(carrierPrediction[0].delivery_prediction_date),
                'dd/MM/yyyy'
              )
            : '----';

        const CFOP = items?.length > 0 ? items[0].prod_cfop : '---';
        const COD_PICKING = picking && picking.code ? picking.code : '---';
        const ultimoComprovante = deviveryVoucher?.length > 0 ? deviveryVoucher.slice(-1)[0] : null;
        const fileLine = {
          NF,
          BL,
          CFOP,
          EMISSAO,
          PRAZO,
          PREV_ENTREGA,
          ENTREGA,
          OCORRENCIA,
          STATUS,
          EMITENTE_CPNJ: cnpj(emit_cnpj) || '---',
          EMITENTE: emit_razao_social || '---',
          ORIGEM,
          TRANS_CNPJ,
          TRANSPORTADORA,
          DESTINO,
          CLI_CNPJ,
          CLIENTE,
          ENTREGA_CNPJ: cnpj(entrega_cnpj) || '---',
          ENTREGA_RAZAO_SOCIAL: entrega_razao_social || '---',
          PICKING: picking_id || '---',
          PORTO: harbor_description || '---',
          COD_PICKING,
          COMPROVANTE_ANEXADO: ultimoComprovante ? format(
            new Date(ultimoComprovante?.created_at),
            'dd/MM/yyyy - HH:mm'
          ) : '---',
        };

        newFile.push(fileLine);
      });

      exportToCSV(newFile, fileName);
    }
  };

  const getData = useCallback(() => {
    const filter = {
      email: userData?.roles[0] === 'Cliente' ? userData?.email : false,
      ...dataFilter,
    };

    dispatch(PaginateInvoicesActions.request({ ...filter }));
  }, [dispatch, dataFilter, userData]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!fileLoading && exportActive) {
      generateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLoading, exportActive]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          {getTranslation('titulo')} <span>{getTranslation('subtitulo')}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <InvoiceFilter
          fileLoading={fileLoading}
          onExport={() => getFileData()}
        />
      </S.PageHeader>
      <S.PageContent>
        <InvoiceIndicators indicators={indicators} />
        <GridInvoices invoices={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
