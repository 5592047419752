import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateJustificationCustomerTypesActions } from "store/ducks/settings/justificationCustomer-types";

export function* paginateJustificationCustomerTypesRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/justification-customer-types?${queryString}`
    );

    const { justificationCustomerTypes, pagination } = responseBody;
    yield put(PaginateJustificationCustomerTypesActions.success(justificationCustomerTypes, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateJustificationCustomerTypesActions.failure(errorMessage));
  }
}
