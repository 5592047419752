import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Scaffold } from "components/shared";
import * as S from "./style";
import { Input, Select, ToggleInput } from "components/shared/Form";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useValidation } from "hooks";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
  ListCarriersActions,
  ListCarriersState,
  ListCarriersTradeNameActions,
  ListCarriersTradeNameState,
} from "store/ducks/settings/carriers";
import {
  ListClientsActions,
  ListClientsState,
} from "store/ducks/settings/clients";
import {
  ListDownTimeActions,
  ListDownTimeState,
} from "store/ducks/report/downtime";

export const Downtime: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [flagCarrier, setFlagCarrier] = useState<boolean>(false);
  const [exportActive, setExportActive] = useState(false);

  const { data: dataDownTime, loading: loadingDownTime } =
    useSelector<RootStateOrAny, ListDownTimeState>(
      state => state.listDownTime
    );

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny, ListCarriersState>(
      state => state.listCarriers
    );

  const { data: dataCarriersTradeName, loading: loadingCarriersTradeName } =
    useSelector<RootStateOrAny, ListCarriersTradeNameState>(
      state => state.listCarriersTradeName
    );

  const { data: dataClients, loading: loadingClients } =
    useSelector<RootStateOrAny, ListClientsState>(
      state => state.listClients
    );

  const getListCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const getListCarriersTradeName = useCallback(() => {
    dispatch(
      ListCarriersTradeNameActions.request({ trade_name_distinct: true })
    );
  }, [dispatch]);

  const getListClients = useCallback(() => {
    dispatch(ListClientsActions.request({ all: true }));
  }, [dispatch]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        dispatch(ListDownTimeActions.request(data));
        setExportActive(true);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors]
  );

  const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const generateFile = useCallback(() => {
    if (dataDownTime) {
      const newFile: any[] = [];
      const fileName = `Relatorio_Tempo_Parado`;

      dataDownTime.forEach((line) => {
        const {  
          hoursFullDiff,
          picking_id,
          businessLine,
          carrier,
          client,
          ide_numero_nf
        } = line;

        const BL = (businessLine) ? businessLine.description : '-'
        const TRANSPORTADORA = (carrier) ? carrier.trade_name : '-'
        const CLIENTE = (client) ? client.trade_name : '-'
        const fileLine = {
          NF: ide_numero_nf,
          HORA_PARADA: hoursFullDiff,
          PICKING: picking_id,
          BL,
          TRANSPORTADORA,
          CLIENTE
        };

        newFile.push(fileLine);
      });

      exportToCSV(newFile, fileName);
      
    }
  }, [dataDownTime]);

  const changeTypeCarrier = useCallback(() => {
    if (formRef.current) {
      const flag = formRef.current.getFieldValue("carrier_raiz");
      if (flag === "1") {
        formRef.current.setFieldValue("general_carrier_id", "");
        setFlagCarrier(true);
      } else {
        formRef.current.setFieldValue("carrier_trade_name", "");
        setFlagCarrier(false);
      }
    } else {
      setFlagCarrier(false);
    }
  }, [setFlagCarrier]);

  useEffect(() => getListCarriers(), [getListCarriers]);
  useEffect(() => getListCarriersTradeName(), [getListCarriersTradeName]);
  useEffect(() => getListClients(), [getListClients]);

  useEffect(() => {
    if (exportActive) {
      generateFile();
    }
  }, [exportActive, generateFile]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          Relatório <span>Tempo parado</span>
          {loadingDownTime && <S.LoadingPage />}
        </h1>
      </S.PageHeader>
      <S.Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="numero_nf" label="Número NF" />
              <Input name="picking_id" label="Número Picking" />
              <Input name="email" label="Email CS" />
              <Input name="qtdHours" label="Horas Parado" />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="general_client_id"
                label="Clientes"
                options={dataClients}
                isLoading={loadingClients}
                isDisabled={loadingClients}
              />

              {!flagCarrier ? (
                <Select
                  name="general_carrier_id"
                  label="Transportadora"
                  options={dataCarriers}
                  isLoading={loadingCarriers}
                  isDisabled={loadingCarriers}
                />
              ) : (
                <></>
              )}

              {flagCarrier ? (
                <Select
                  name="carrier_trade_name"
                  label="Transportadora (Raiz)"
                  options={dataCarriersTradeName}
                  isLoading={loadingCarriersTradeName}
                  isDisabled={loadingCarriersTradeName}
                />
              ) : (
                <></>
              )}

              <ToggleInput
                name="carrier_raiz"
                label="CNPJ Raiz"
                onChange={() => changeTypeCarrier()}
              />
            </S.FormRow>

            <S.FormRow>
              <Input name="date_start" label="Data emissão de" type="date" />
              <Input name="date_end" label="Data emissão até" type="date" />
              <Input name="deliverydate_start" label="Entrega de" type="date" />
              <Input name="deliverydate_end" label="Entrega até" type="date" />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button type="submit"> Gerar Relatorio </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.Container>
    </Scaffold>
  );
};
