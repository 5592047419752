import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListTypeCfopsActions } from "store/ducks/settings/type-cfop";

export function* listTypeCfopsRequest(action: any) {
  try {
    const { id, onSuccess } = action as IListRequest;
    const { data } = yield call(api.get, `/operation-cfops?operation_type_id=${id}`);
    yield put(ListTypeCfopsActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListTypeCfopsActions.failure(errorMessage));
  }
}
