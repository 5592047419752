import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IExportRequest } from "interfaces/export-duck";
import { requestErrorHandler } from "utils";
import { ExportInvoicesActions } from "store/ducks/tracking/invoices";

export function* exportInvoicesRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IExportRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(
      api.get,
      `/invoices/export-list?${queryString}`
    );
    yield put(ExportInvoicesActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ExportInvoicesActions.failure(errorMessage));
  }
}
