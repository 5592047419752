import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useTranslation } from "hooks";
import { translations } from "./translations";

import {
  CreateInternacionalTransitState,
  CreateInternacionalTransitActions,
} from "store/ducks/settings/internacional-transits";
import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import {
  ListHarborsActions,
  ListHarborsState,
} from "store/ducks/settings/harbors";
import {
  ListCountriesActions,
  ListCountriesState,
} from "store/ducks/settings/countries";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";

import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold } from "components/shared";
import { Input, Select } from "components/shared/Form";

import { statesOptions } from "utils/data/states";

export const NewInternacionalTransit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [type, setType] = useState("");

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createInternacionalTransit
  ) as CreateInternacionalTransitState;

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: harbors, loading: loadingHarbor } =
    useSelector<RootStateOrAny>(
      (state) => state.listHarbors
    ) as ListHarborsState;

    const { data: countries, loading: loadingCountry } =
    useSelector<RootStateOrAny>(
      (state) => state.listCountries
    ) as ListCountriesState;

  const { data: cities, loading: loadingCity } = (
  useSelector<RootStateOrAny>((state) => state.listCities)
  ) as ListCitiesState;

  const onSuccess = useCallback(() => {
    history.push("/settings/internacional-transits");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (values) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          carrier_id: Yup.string().required("Obrigatório"),
          origin_name: Yup.string().required("Obrigatório"),
          country_id: Yup.string(),
          harbor_id: Yup.string().when('country_id', (country_id:any, field:any) =>
            !country_id
              ? field.required('Um porto/aeroporto ou um país devem ser selecionados')
              : field
          ),
          dead_line: Yup.string().required("Obrigatório"),
        });

        await schema.validate(values, {
          abortEarly: false,
        });

        const data: {
          carrier_id: number,
          origin_name: string,
          dead_line: number,
          harbor_id?: number,
          country_id?: number,
        } = {
          carrier_id: values.carrier_id,
          origin_name: values.origin_name,
          dead_line: values.dead_line
        }

        if(type === "Porto") {
          data.harbor_id = values.harbor_id;
        }

        if(type === "País") {
          data.country_id = values.country_id;
        }

        dispatch(CreateInternacionalTransitActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleFormErrors, onSuccess, type]
  );

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchHarbors = useCallback(() => {
    dispatch(ListHarborsActions.request({ all: true }));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const onSetHarbor = () => {
    setType('Porto');
    if (formRef.current) {
      formRef.current.setFieldValue("harbor_id", {value: 0, label: ''});
    }
  }

  const onSetCountry = () => {
    setType('País');
    if (formRef.current) {
      formRef.current.setFieldValue("country_id", {value: 0, label: ''});
    }
  }

  useEffect(() => {
    fetchCarriers();
    fetchHarbors();
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation('titulo')} <span>{getTranslation('novoTempo')}</span>
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation('voltar')}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Select
                name="carrier_id"
                label={getTranslation('transportadora')}
                isDisabled={loadingCarriers}
                isLoading={loadingCarriers}
                options={dataCarriers}
                placeholder={getTranslation('selecione')}
              />
              <Input
                name="dead_line"
                type="number"
                label={getTranslation('prazo')}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="address_origin"
                label={getTranslation('uf')}
                options={statesOptions}
                placeholder={getTranslation('selecione')}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="origin_name"
                label={getTranslation('origem')}
                isLoading={loadingCity}
                isDisabled={!cities}
                placeholder={getTranslation('selecione')}
                options={cities}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="harbor_id"
                label={getTranslation('portoAeroporto')}
                isLoading={loadingHarbor}
                isDisabled={!harbors || type === "País"}
                options={harbors}
                placeholder={getTranslation('selecione')}
                onChange={onSetHarbor}
              />
              <Select
                name="country_id"
                label={getTranslation('pais')}
                isLoading={loadingCountry}
                isDisabled={!countries || type === "Porto"}
                options={countries}
                placeholder={getTranslation('selecione')}
                onChange={onSetCountry}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation('cancelar')}
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation('cadastrar')}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
