import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useValidation } from 'hooks';

import { statesOptions } from 'utils/data/states';

import {
  FetchInvoiceFilterActions,
  FetchInvoiceFilterState,
} from 'store/ducks/tracking/invoice-filters';
import {
  ListClientsActions,
  ListClientsState,
} from 'store/ducks/settings/clients';
import {
  ListClientTypesActions,
  ListClientTypesState,
} from 'store/ducks/settings/client-types';
import {
  ListCarriersActions,
  ListCarriersState,
  ListCarriersTradeNameActions,
  ListCarriersTradeNameState,
} from 'store/ducks/settings/carriers';
import {
  ListCompaniesActions,
  ListCompaniesState,
} from 'store/ducks/settings/companies';
import { ListCitiesActions, ListCitiesState } from 'store/ducks/cities';
import {
  ListBusinessActions,
  ListBusinessState,
} from 'store/ducks/settings/business';
import {
  InvoiceCountriesActions,
  InvoiceCountriesState,
} from 'store/ducks/tracking/invoices';

import {
  ListOperationTypesActions,
  ListOperationTypesState,
} from 'store/ducks/settings/operation-type';

import { AuthState } from 'store/ducks/auth';

import * as S from './styles';
import { Modal } from 'components/shared';
import { Select, Input, ToggleInput } from 'components/shared/Form';

import { ICitiesOptions } from 'interfaces/city';
import { IClientOption } from 'interfaces/client';
import { IClientTypeOption } from 'interfaces/client-type';
import { ICarrierOptions } from 'interfaces/carrier';
import { useTranslation } from 'hooks';
import { translations } from './translations';

const JustificationOptions = [
  {
    label: 'Pendentes',
    value: 'pendentes',
  },
  {
    label: 'Abonadas',
    value: 'abonadas',
  },
];

interface IInvoiceFilterProps {
  fileLoading: boolean;
  onExport: any;
}

export const InvoiceFilter: React.FC<IInvoiceFilterProps> = ({
  fileLoading,
  onExport,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [emitCountry, setEmitCountry] = useState<boolean>(false);
  const [destCountry, setDestCountry] = useState<boolean>(false);
  const [flagCarrier, setFlagCarrier] = useState<boolean>(false);
  const [flagScheduling, setFlagScheduling] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);

  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const { data, citiesOrigins, citiesDestinies } = useSelector<RootStateOrAny>(
    (state) => state.fetchInvoiceFilter
  ) as FetchInvoiceFilterState;

  const { data: dataClientTypes, loading: loadingClientTypes } =
    useSelector<RootStateOrAny>(
      (state) => state.listClientTypes
    ) as ListClientTypesState;

  const { data: dataClients, loading: loadingClients } =
    useSelector<RootStateOrAny>(
      (state) => state.listClients
    ) as ListClientsState;

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: dataCarriersTradeName, loading: loadingCarriersTradeName } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriersTradeName
    ) as ListCarriersTradeNameState;

  const { data: dataCompanies, loading: loadingCompanies } =
    useSelector<RootStateOrAny>(
      (state) => state.listCompanies
    ) as ListCompaniesState;

  const { loading: loadingCities } = useSelector<RootStateOrAny>(
    (state) => state.listCities
  ) as ListCitiesState;

  const { data: dataBusiness, loading: loadingBusiness } =
    useSelector<RootStateOrAny>(
      (state) => state.listBusiness
    ) as ListBusinessState;

  const { data: dataCountries } = useSelector<RootStateOrAny>(
    (state) => state.invoiceCountries
  ) as InvoiceCountriesState;

  const { data: dataOperationTypes, loading: loadingOperationTypes } =
    useSelector<RootStateOrAny>(
      (state) => state.listOperationTypes
    ) as ListOperationTypesState;

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          date_start: Yup.string().required(getTranslation('obrigatorio')),
          date_end: Yup.string().required(getTranslation('obrigatorio')),
          city_origin_uf: Yup.string(),
          city_origin: Yup.string().when('city_origin_uf', {
            is: (city_origin_uf: any) => city_origin_uf,
            then: Yup.string().required(getTranslation('obrigatorio')),
          }),
          city_destiny_uf: Yup.string(),
          city_destiny: Yup.string().when('city_destiny_uf', {
            is: (city_destiny_uf: any) => city_destiny_uf,
            then: Yup.string().required(getTranslation('obrigatorio')),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.canceled) {
          data.canceled = Number(data.canceled);
        }

        if (Number(data.devolution) === 1) {
          data.status = 'devolucao';
        }

        dispatch(FetchInvoiceFilterActions.success(data));
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, getTranslation, handleFormErrors]
  );

  const getListBusiness = useCallback(() => {
    dispatch(ListBusinessActions.request({ all: true }));
  }, [dispatch]);

  const getListCompanies = useCallback(() => {
    dispatch(ListCompaniesActions.request({ all: true }));
  }, [dispatch]);

  const getListClientTypes = useCallback(() => {
    dispatch(ListClientTypesActions.request({ all: true }));
  }, [dispatch]);

  const getListClients = useCallback(() => {
    dispatch(ListClientsActions.request({ all: true }));
  }, [dispatch]);

  const getListCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const getListCarriersTradeName = useCallback(() => {
    dispatch(
      ListCarriersTradeNameActions.request({ trade_name_distinct: true })
    );
  }, [dispatch]);

  const getListCountries = useCallback(() => {
    dispatch(InvoiceCountriesActions.request());
  }, [dispatch]);

  const fetchOperationTypes = useCallback(() => {
    dispatch(ListOperationTypesActions.request({ all: true }));
  }, [dispatch]);

  const onCityOriginSuccess = useCallback(
    (cityOptions) => {
      const cityOrigins = cityOptions.map((cityOption: ICitiesOptions) => ({
        label: cityOption.label,
        value: cityOption.ibge,
      }));
      dispatch(FetchInvoiceFilterActions.citiesOrigins(cityOrigins));
    },
    [dispatch]
  );

  const onCityDestinySuccess = useCallback(
    (cityOptions) => {
      const cityDestinies = cityOptions.map((cityOption: ICitiesOptions) => ({
        label: cityOption.label,
        value: cityOption.ibge,
      }));
      dispatch(FetchInvoiceFilterActions.citiesDestinies(cityDestinies));
    },
    [dispatch]
  );

  const handleStateOrigin = useCallback(
    (option) => {
      dispatch(
        ListCitiesActions.request(
          { state: option.value, all: true },
          onCityOriginSuccess
        )
      );
    },
    [dispatch, onCityOriginSuccess]
  );

  const handleChangeCountry = (option: any, type: string) => {
    if (type === 'origin' && option.value === 'Brasil') {
      setEmitCountry(true);
    } else if (type === 'origin') {
      setEmitCountry(false);
    }

    if (type === 'destiny' && option.value === 'Brasil') {
      setDestCountry(true);
    } else if (type === 'destiny') {
      setDestCountry(false);
    }
  };

  const handleStateDestiny = useCallback(
    (option) => {
      dispatch(
        ListCitiesActions.request(
          { state: option.value, all: true },
          onCityDestinySuccess
        )
      );
    },
    [dispatch, onCityDestinySuccess]
  );

  const onFetchFilter = useCallback(() => {
    if (formRef.current && dataClients && dataCarriers && dataClientTypes) {
      formRef.current.setFieldValue('date_start', data.date_start);
      formRef.current.setFieldValue('date_end', data.date_end);
      formRef.current.setFieldValue('invoice_number', data.invoice_number);
      formRef.current.setFieldValue('vehicle_plate', data.vehicle_plate);
      formRef.current.setFieldValue(
        'deadline_date_start',
        data.deadline_date_start
      );
      formRef.current.setFieldValue(
        'deadline_date_end',
        data.deadline_date_end
      );

      if (data.general_client_type_id) {
        const clientTypeOption = dataClientTypes.filter(
          (clientTypeOption: IClientTypeOption) =>
            clientTypeOption.value === data.general_client_type_id
        );

        if (clientTypeOption.length > 0) {
          formRef.current.setFieldValue(
            'general_client_type_id',
            clientTypeOption[0]
          );
        }
      }

      if (data.client_id) {
        const clientOption = dataClients.filter(
          (clientOption: IClientOption) => clientOption.value === data.client_id
        );

        if (clientOption.length > 0) {
          formRef.current.setFieldValue('client_id', clientOption[0]);
        }
      }

      if (data.general_carrier_id) {
        const carrierOption = dataCarriers.filter(
          (carrierOption: ICarrierOptions) =>
            carrierOption.value === data.general_carrier_id
        );

        if (carrierOption.length > 0) {
          formRef.current.setFieldValue('general_carrier_id', carrierOption[0]);
        }
      }

      if (data.city_origin_uf) {
        const stateOriginOption = statesOptions.filter(
          (stateOption: OptionTypeBase) =>
            stateOption.value === data.city_origin_uf
        );

        if (stateOriginOption.length > 0) {
          formRef.current.setFieldValue('city_origin_uf', stateOriginOption[0]);
        }
      }

      if (data.city_origin && citiesOrigins) {
        const cityOriginOption = citiesOrigins?.filter(
          (cityOption) => cityOption.value === data.city_origin
        );
        if (cityOriginOption && cityOriginOption?.length > 0) {
          formRef.current.setFieldValue('city_origin', cityOriginOption[0]);
        }
      }

      if (data.city_destiny_uf) {
        const stateDestinyOption = statesOptions.filter(
          (stateOption: OptionTypeBase) =>
            stateOption.value === data.city_destiny_uf
        );

        if (stateDestinyOption.length > 0) {
          formRef.current.setFieldValue(
            'city_destiny_uf',
            stateDestinyOption[0]
          );
        }
      }

      if (data.city_destiny && citiesDestinies) {
        const cityDestinyOption = citiesDestinies?.filter(
          (cityOption) => cityOption.value === data.city_destiny
        );
        if (cityDestinyOption && cityDestinyOption?.length > 0) {
          formRef.current.setFieldValue('city_destiny', cityDestinyOption[0]);
        }
      }

      if (data.justification) {
        const justificationOption = JustificationOptions.filter(
          (option) => option.value === data.justification
        );
        if (justificationOption?.length > 0) {
          formRef.current.setFieldValue(
            'justification',
            justificationOption[0]
          );
        }
      }
    }
  }, [
    citiesDestinies,
    citiesOrigins,
    data,
    dataCarriers,
    dataClientTypes,
    dataClients,
  ]);

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('city_origin_uf', '');
      formRef.current.setFieldValue('city_origin', '');
      formRef.current.setFieldValue('city_destiny_uf', '');
      formRef.current.setFieldValue('city_destiny', '');
      formRef.current.setFieldValue('general_client_type_id', '');
      formRef.current.setFieldValue('general_carrier_id', '');
      formRef.current.setFieldValue('client_id', '');
      formRef.current.setFieldValue('justification', '');
      formRef.current.setFieldValue('business_lines', '');
      formRef.current.setFieldValue('company_id', '');
      formRef.current.setFieldValue('country_origin', '');
      formRef.current.setFieldValue('country_destiny', '');
      formRef.current.setFieldValue('carrier_trade_name', '');
      formRef.current.setFieldValue('operation_type_id', '');
      formRef.current.clearField('devolution');
      formRef.current.clearField('canceled');
      formRef.current.clearField('carrier_raiz');
      formRef.current.clearField('scheduling_required');
      formRef.current.clearField('monday');
      formRef.current.clearField('tuesday');
      formRef.current.clearField('wednesday');
      formRef.current.clearField('thursday');
      formRef.current.clearField('friday');

      formRef.current.setFieldValue('receiving_start', '');
      formRef.current.setFieldValue('receiving_end', '');
      formRef.current.setFieldValue('meal_start', '');
      formRef.current.setFieldValue('meal_end', '');
      setFlagCarrier(false);
      setEmitCountry(false);
      setDestCountry(false);
      setFlagScheduling(false);
    }
    dispatch(FetchInvoiceFilterActions.reset());
    setModalOpen(false);
  }, [dispatch]);

  const renderButtonCleanFilter = useCallback(() => {
    if (data) {
      const { limit, page, date_start, date_end, ...rest } = data;

      const showClearFilter = Object.values(rest).some(
        (value) => ![value !== null, value !== ''].includes(false)
      );

      if (showClearFilter) {
        return (
          <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
            {getTranslation('limparFiltro')}
          </S.ButtonMini>
        );
      }
    }
  }, [data, handleFilterClean]);

  useEffect(() => getListBusiness(), [getListBusiness]);
  useEffect(() => getListClientTypes(), [getListClientTypes]);
  useEffect(() => getListClients(), [getListClients]);
  useEffect(() => getListCarriers(), [getListCarriers]);
  useEffect(() => getListCompanies(), [getListCompanies]);
  useEffect(() => onFetchFilter(), [onFetchFilter]);
  useEffect(() => getListCountries(), [getListCountries]);
  useEffect(() => getListCarriersTradeName(), [getListCarriersTradeName]);
  useEffect(() => fetchOperationTypes(), [fetchOperationTypes]);

  const changeTypeCarrier = useCallback(() => {
    if (formRef.current) {
      const flag = formRef.current.getFieldValue('carrier_raiz');
      if (flag === '1') {
        formRef.current.setFieldValue('general_carrier_id', '');
        setFlagCarrier(true);
      } else {
        formRef.current.setFieldValue('carrier_trade_name', '');
        setFlagCarrier(false);
      }
    } else {
      setFlagCarrier(false);
    }
  }, [setFlagCarrier]);

  const changeScheduling = useCallback(() => {
    if (formRef.current) {
      const flag = formRef.current.getFieldValue('scheduling_required');
      if (flag === '1') {
        setFlagScheduling(true);
      } else {
        setFlagScheduling(false);
      }
    } else {
      setFlagScheduling(false);
    }
  }, [setFlagScheduling]);

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation('filtrar')}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="invoice_number" label={getTranslation('notaFiscal')} />
              <Select
                name="company_id"
                label={getTranslation('centro')}
                isLoading={loadingCompanies}
                placeholder={getTranslation('selecione')}
                isDisabled={loadingCompanies}
                options={dataCompanies}
              />
              <Select
                name="justification"
                label={getTranslation('justificativas')}
                options={JustificationOptions}
                placeholder={getTranslation('selecione')}
              />
              <ToggleInput name="canceled" label={getTranslation('canceladas')} />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="business_lines"
                label="BL"
                isMulti
                isLoading={loadingBusiness}
                isDisabled={loadingBusiness}
                options={dataBusiness}
              />
              <Input
                name="picking_code"
                label={getTranslation('codigo_P')}
                type="number"
              />
              <ToggleInput name="devolution" label={getTranslation('devolucao')} />
            </S.FormRow>
            <S.FormRow>
              <Input name="date_start" label={getTranslation('emissaoDe')} type="date" />
              <Input name="date_end" label={getTranslation('emissaoAte')} type="date" />
              <Input name="deadline_date_start" label={getTranslation('prazoDe')} type="date" />
              <Input name="deadline_date_end" label={getTranslation('prazoAte')} type="date" />
            </S.FormRow>

            <S.FormRow>
              <Select
                name="country_origin"
                label={getTranslation('paisOrigem')}
                options={dataCountries?.emit}
                placeholder={getTranslation('selecione')}
                onChange={(e: OptionTypeBase) =>
                  handleChangeCountry(e, 'origin')
                }
              />

              {emitCountry ? (
                <S.FormRow>
                  <Select
                    name="city_origin_uf"
                    label={getTranslation('ufOrigem')}
                    options={statesOptions}
                    placeholder={getTranslation('selecione')}
                    onChange={(e: OptionTypeBase) => handleStateOrigin(e)}
                  />
                  <Select
                    name="city_origin"
                    label={getTranslation('cidadeOrigem')}
                    options={citiesOrigins || undefined}
                    placeholder={getTranslation('selecione')}
                    isLoading={loadingCities}
                  />
                </S.FormRow>
              ) : (
                <></>
              )}
            </S.FormRow>

            <S.FormRow>
              <Select
                name="country_destiny"
                label={getTranslation('paisDestino')}
                options={dataCountries?.dest}
                placeholder={getTranslation('selecione')}
                onChange={(e: OptionTypeBase) =>
                  handleChangeCountry(e, 'destiny')
                }
              />

              {destCountry ? (
                <S.FormRow>
                  <Select
                    name="city_destiny_uf"
                    label={getTranslation('ufDestino')}
                    options={statesOptions}
                    placeholder={getTranslation('selecione')}
                    onChange={(e: OptionTypeBase) => handleStateDestiny(e)}
                  />
                  <Select
                    name="city_destiny"
                    label={getTranslation('cidadeDestino')}
                    options={citiesDestinies || undefined}
                    placeholder={getTranslation('selecione')}
                    isLoading={loadingCities}
                  />
                </S.FormRow>
              ) : (
                <></>
              )}
            </S.FormRow>

            {userData?.roles[0] !== 'Cliente' ? (
              <S.FormRow>
                <Select
                  name="general_client_type_id"
                  label={getTranslation('segmentacao')}
                  options={dataClientTypes}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingClientTypes}
                  isDisabled={loadingClientTypes}
                />
                <Select
                  name="client_id"
                  label={getTranslation('cliente')}
                  placeholder={getTranslation('selecione')}
                  options={dataClients}
                  isLoading={loadingClients}
                  isDisabled={loadingClients}
                />
              </S.FormRow>
            ) : (
              ''
            )}

            <S.FormRow>
              <S.ButtonsToggle>
                <ToggleInput
                  name="scheduling_required"
                  label={flagScheduling ? getTranslation('sim') : getTranslation('nao')}
                  title={getTranslation('necessitaAgendamento')}
                  onChange={() => changeScheduling()}
                />
                <ToggleInput
                  name="monday"
                  label={getTranslation('segunda')}
                  title={getTranslation('clienteRecebe')}
                />
                <ToggleInput name="tuesday" label={getTranslation('terca')}/>
                <ToggleInput name="wednesday" label={getTranslation('quarta')} />
                <ToggleInput name="thursday" label={getTranslation('quinta')} />
                <ToggleInput name="friday" label={getTranslation('sexta')} />
              </S.ButtonsToggle>
            </S.FormRow>
            <S.FormRow>
              <Input name="receiving_start" label={getTranslation('inicioRecebimento')}/>
              <Input name="receiving_end" label={getTranslation('fimRecebimento')}/>
              <Input name="meal_start" label={getTranslation('inicioRefeicao')}/>
              <Input name="meal_end" label={getTranslation('fimRefeicao')}/>
            </S.FormRow>
            <S.FormRow>
              <Input name="vehicle_plate" label={getTranslation('placaVeiculo')}/>

              <Select
                name="operation_type_id"
                label={getTranslation('tipoOperacao')}
                options={dataOperationTypes}
                placeholder={getTranslation('selecione')}
                isLoading={loadingOperationTypes}
                isDisabled={loadingOperationTypes}
              />
              {flagCarrier ? (
                <Select
                  name="carrier_trade_name"
                  label={getTranslation('transportadoraRaiz')}
                  options={dataCarriersTradeName}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriersTradeName}
                  isDisabled={loadingCarriersTradeName}
                />
              ) : (
                <Select
                  name="general_carrier_id"
                  label={getTranslation('transportadora')}
                  options={dataCarriers}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriers}
                  isDisabled={loadingCarriers}
                />
              )}

              <ToggleInput
                name="carrier_raiz"
                label={getTranslation('cnpjRaiz')}
                onChange={() => changeTypeCarrier()}
              />
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                {getTranslation('cancelar')}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation('limparFiltro')}
              </S.Button>
              <S.Button type="submit">{getTranslation('filtrar')}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>
      <S.ButtonMini onClick={onExport} disabled={fileLoading}>
        {fileLoading ? (
          <ReactLoading type="spin" color="#fff" height={20} width={20} />
        ) : (
          getTranslation('csv')
        )}
      </S.ButtonMini>
      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
