export const translations = {
  recalcularPrazo: {
    pt: 'Recalcular Prazo',
    en: 'Recalculate deadline',
    de: 'Frist neu berechnen',
    es: 'Recalcular plazo',
  },
  PrazoAtual: {
    pt: 'Recalcular plazo',
    en: 'Current Term',
    de: 'Aktuelle Laufzeit',
    es: 'Término actual',
  },
  PrazoRecalculado: {
    pt: 'Prazo Recalculado',
    en: 'Recalculated Term',
    de: 'Neu berechnete Laufzeit',
    es: 'Término recalculado',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Enge',
    es: 'Cerrar',
  },
  recalcular: {
    pt: 'Recalcular prazo',
    en: 'Recalculate deadline',
    de: 'Frist neu berechnen',
    es: 'Recalcular plazo',
  },
};