import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListDeliveryVouchersActions } from "store/ducks/tracking/delivery-vouchers";

export function* listDeliveryVouchersRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/delivery-vouchers?${queryString}`);

    yield put(ListDeliveryVouchersActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListDeliveryVouchersActions.failure(errorMessage));
  }
}
