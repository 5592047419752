import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IExportRequest } from "interfaces/export-duck";
import { requestErrorHandler } from "utils";
import { ExportIndentsActions } from "store/ducks/tracking/indents";

export function* exportIndentsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IExportRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/indents/export-list?${queryString}`);
    yield put(ExportIndentsActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ExportIndentsActions.failure(errorMessage));
  }
}
