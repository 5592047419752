import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateJustificationCustomerTypeActions } from "store/ducks/settings/justificationCustomer-types";

export function* updateJustificationCustomerTypeRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.put,
      `/justification-customer-types/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateJustificationCustomerTypeActions.success(data));
    notify("success", "Tipo de justificativa customer atualizada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateJustificationCustomerTypeActions.failure(errorMessage));
  }
}
