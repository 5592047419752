import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "hooks";
import { translations } from "./translations";

import {
  PaginateOperationTypesActions,
  PaginateOperationTypesState,
} from "store/ducks/settings/operation-type";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import * as S from "./styles";
import { Scaffold, Paginator, Search } from "components/shared";
import { GridOperationTypes } from "components/settings";

export const ListOperationTypes: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [query, setQuery] = useState({
    search: "",
    limit: 10,
    page: 1,
  });

  const { data, loading, pagination } = useSelector<RootStateOrAny>(
    (state) => state.paginateOperationTypes
  ) as PaginateOperationTypesState;

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const handleSearchChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        search: value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const getData = useCallback(() => {
    dispatch(PaginateOperationTypesActions.request({ ...query, ...dataFilter }));
  }, [dispatch, query, dataFilter]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation('titulo')} <span>Tipos de Operação</span>
          {loading && <S.LoadingPage />}
        </h1>
        <Search onSearch={handleSearchChange} />
        <S.ButtonMini
          onClick={() => history.push("/settings/operation-types/new")}
        >
          {getTranslation('novasOperacoes')}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <GridOperationTypes units={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
