import styled from 'styled-components';
import { Pulse, MessageSquareEdit } from '@styled-icons/boxicons-regular';
import { ReactComponent as PopoverTriangleImage } from 'assets/images/left-popover-triangle.svg';
import dot from 'assets/images/activity-dot.svg';
import avatarDefault from 'assets/images/avatar-activity-default.svg';
import avatarUserDefault from 'assets/images/avatar-user-activity-default.svg';
import { colors, fonts } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding-left: 32px;
  border-left: 2px solid ${colors.line};
  min-height: 300px;
  position: relative;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  font-family: ${fonts.GilroySemiBold};
  font-size: 16px;
  color: ${colors.black};
  margin-bottom: 24px;
`;

export const IconActivity = styled(Pulse).attrs({ size: 24 })`
  color: ${colors.black};
  margin-right: 8px;
`;
export const IconJustification = styled(MessageSquareEdit).attrs({ size: 24 })`
  color: ${colors.black};
  margin-right: 8px;
`;

export const ActivityContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
`;

export const ActivityBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;

export const Triangle = styled(PopoverTriangleImage).attrs({
  className: 'triangle',
})`
  order: 1;
  fill: #fff;
  stroke: #fff;
  align-self: center;
  position: absolute;
  left: -8px;
`;

export const Dot = styled.img.attrs({ src: dot })`
  position: absolute;
  left: -43px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const AvatarDefault = styled.img.attrs({ src: avatarDefault })``;
export const AvatarUserDefault = styled.img.attrs({ src: avatarUserDefault })``;

export const AvatarUser = styled.figure`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;

  img {
    object-fit: cover;
  }
`;

export const ActivityTitle = styled.h6`
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: ${colors.gray3};
  margin-left: 8px;
`;

export const ActivityDescription = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 14px;
  color: ${colors.black};
  padding-left: 32px;
`;

export const ActivityDate = styled.span`
  display: block;
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  color: ${colors.darkRgba25};
`;
