import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateTransitsActions } from "store/ducks/settings/transits";

export function* paginateTransitsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/evonik-transit-times?${queryString}`
    );

    const { transits, pagination } = responseBody;
    yield put(PaginateTransitsActions.success(transits, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateTransitsActions.failure(errorMessage));
  }
}
