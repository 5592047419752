import styled from "styled-components";
import { colors,fonts } from "styles";
import { Receipt } from "@styled-icons/boxicons-regular";

const colorIndicadores = (status: string) => {

  if(status === "approved"){
    return `${colors.green}`;
  }

  if(status === "waitingApproval"){
    return `${colors.petrol}`;
  }

  if(status === "waitingVoucher"){
    return `${colors.orange}`;
  }

  if(status === "voucherFailed"){
    return `${colors.red}`;
  }

  return `${colors.gray}`;
};

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
  column-gap: 30px;
`;

export const BoxContainer = styled.div`
  width: 100%;
  max-width: 550px;
  height: 100px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  padding: 15px;
  display: flex;
  cursor: pointer
  
`;

export const BoxIcon = styled.div<{ status: string }>`
  width: 80px;
  height: 70px;
  background-color: ${({ status }) => colorIndicadores(status)};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconVoucher = styled(Receipt).attrs({ size: 30 })`
  color: ${colors.white};
`;

export const Boxinfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const Title = styled.h1`
  font-family: ${fonts.GilroyBold};
  font-size: 16px;
  color: ${colors.black};
`;

export const BackgroundProgressBar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.22);
  position: relative;
  margin: 10px 0px;
`;

export const ProgressBar = styled.div<{ progress: string; status: string }>`
  width: ${({ progress }) => progress};
  height: 6px;
  border-radius: 3px;
  background-color: ${({ status }) => colorIndicadores(status)};
  position: absolute;
  left: 0;
  top: 0;
`;

export const BoxNumberInfo = styled.div`
  width: 100%;
  display: flex;
  font-family: ${fonts.GilroyBold};
  font-size: 18px;
  justify-content: space-between;
  color: ${colors.black};
  font-weight: 700;
`;
