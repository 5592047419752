import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { format } from "date-fns";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation, usePermissions } from "hooks";

import {
  CreateDeliveryVoucherActions,
  CreateDeliveryVoucherState,
  DownloadDeliveryVoucherActions,
  DownloadDeliveryVoucherState,
  ListDeliveryVouchersActions,
  ListDeliveryVouchersState,
  UpdateDeliveryVoucherActions,
  DeleteDeliveryVoucherActions,
} from "store/ducks/tracking/delivery-vouchers";

import { FileInput } from "components/shared/Form";
import { Modal } from "components/shared";
import * as S from "./styles";
import { DeliveryVoucher } from "interfaces/delivery-voucher";

interface ItemProps {
  voucher: DeliveryVoucher;
  onDownload: Function;
  onUpdate: Function;
  onDelete: Function;
  loading: boolean;
}
interface Props {
  invoiceId: number;
}

type Action = "approve" | "reject";

const Item: React.FC<ItemProps> = ({
  voucher,
  onDownload,
  onUpdate,
  onDelete,
  loading,
}) => {
  const { hasAnyPermission } = usePermissions();
  const handleUpdate = useCallback(
    (id, action: Action) => {
      let data = {};
      if (action === "approve") {
        data = {
          approved_at: new Date(),
        };
      }
      if (action === "reject") {
        data = {
          rejected_at: new Date(),
        };
      }
      onUpdate(id, data);
    },
    [onUpdate]
  );

  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.IconFile />
        <S.ItemValue>{voucher.file_name}</S.ItemValue>
        <S.ItemValue>
          Criado em: {format(new Date(voucher.created_at), "dd/MM/yyyy")}
        </S.ItemValue>
        <S.ItemValue>
          {voucher.approved_at &&
            `Aprovado em: ${format(
              new Date(voucher.approved_at),
              "dd/MM/yyyy"
            )}`}
          {voucher.rejected_at &&
            `Reprovado em: ${format(
              new Date(voucher.rejected_at),
              "dd/MM/yyyy"
            )}`}
          {!voucher.approved_at &&
            !voucher.rejected_at &&
            hasAnyPermission(["Administrador", "Customer Service"]) && (
              <S.ButtonWrapper>
                <S.ButtonMini
                  onClick={() => handleUpdate(voucher.id, "approve")}
                >
                  aprovar
                </S.ButtonMini>
                <S.ButtonMini
                  btStyle="danger"
                  onClick={() => handleUpdate(voucher.id, "reject")}
                >
                  reprovar
                </S.ButtonMini>
              </S.ButtonWrapper>
            )}
        </S.ItemValue>
        {!voucher.approved_at && !voucher.rejected_at && <S.IconFileCheck />}
        {voucher.approved_at && <S.IconFileCheck active />}
        {voucher.rejected_at && <S.IconFileX />}
        <S.ButtonDownload
          onClick={() => onDownload(voucher.id, voucher.file_name)}
        >
          {loading ? <S.Loading /> : <S.IconFileDown />}
        </S.ButtonDownload>
        <S.ButtonDelete
          onClick={() => onDelete(voucher.id)}
          disabled={!!(voucher.approved_at || voucher.rejected_at)}
        >
          {loading ? <S.Loading /> : <S.IconTrash disabled={!!(voucher.approved_at || voucher.rejected_at)}/>}
        </S.ButtonDelete>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const UploadDeliveryVoucher: React.FC<Props> = ({ invoiceId }) => {
  const [showImage, setShowImage] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [canAddVoucher, setCanAddVoucher] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const { data: dataDeliveryVoucher, loading: loadingDeliveryVoucher } =
    useSelector<RootStateOrAny>(
      (state) => state.listDeliveryVouchers
    ) as ListDeliveryVouchersState;

  const { loading: loadingCreateVoucher } = useSelector<RootStateOrAny>(
    (state) => state.createDeliveryVoucher
  ) as CreateDeliveryVoucherState;

  const {
    data: dataDownloadDeliveryVoucher,
    loading: loadingDownloadDeliveryVoucher,
  } = useSelector<RootStateOrAny>(
    (state) => state.downloadDeliveryVoucher
  ) as DownloadDeliveryVoucherState;

  const onDownloadSuccess = useCallback(() => {
    setShowImage(true);
  }, []);

  const handleDownload = useCallback(
    (voucher_id, file_name) => {
      setSelectedItem(voucher_id);
      dispatch(
        DownloadDeliveryVoucherActions.request({ file_name }, onDownloadSuccess)
      );
    },
    [dispatch, onDownloadSuccess]
  );

  const getListDeliveryVoucher = useCallback(() => {
    dispatch(
      ListDeliveryVouchersActions.request({ invoice_id: invoiceId, all: true })
    );
  }, [dispatch, invoiceId]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          image: Yup.string().required(""),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append("image", data.image);
        formData.append("invoice_id", `${invoiceId}`);

        dispatch(
          CreateDeliveryVoucherActions.request(formData, getListDeliveryVoucher)
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, getListDeliveryVoucher, handleFormErrors, invoiceId]
  );

  const handleUpdate = useCallback(
    (id, data) => {
      dispatch(
        UpdateDeliveryVoucherActions.request(id, data, getListDeliveryVoucher)
      );
    },
    [dispatch, getListDeliveryVoucher]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        DeleteDeliveryVoucherActions.request(id, getListDeliveryVoucher)
      );
    },
    [dispatch, getListDeliveryVoucher]
  );

  useEffect(() => {
    getListDeliveryVoucher();
  }, [getListDeliveryVoucher]);

  useEffect(() => {
    if(dataDeliveryVoucher && dataDeliveryVoucher[0]) {
      const noRejectedItem = dataDeliveryVoucher.find(voucher => !voucher.rejected_at);
      if(!noRejectedItem) {
        setCanAddVoucher(true);
      } else {
        setCanAddVoucher(false);
      }
    } else {
      setCanAddVoucher(true);
    }
  }, [dataDeliveryVoucher]);

  return (
    <S.Container>
      <Modal isOpen={showImage}>
        {dataDownloadDeliveryVoucher?.url && (
          <S.ContentModal>
            <S.ButtonCloseModal onClick={() => setShowImage(false)}>
              <S.IconX />
            </S.ButtonCloseModal>
            <iframe title="invoice" src={`${dataDownloadDeliveryVoucher.url}#view=FitH`}></iframe>
          </S.ContentModal>
        )}
      </Modal>
      <S.Title>Upload canhoto</S.Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.BoxContainer>
          <S.FormRow>
            <FileInput
              name="image"
              label="Arquivo do canhoto (.jpg, .png ou .pdf)"
              containerStyle={{ marginBottom: 0 }}
            />
            <S.SubmitButton className="submitButton" disabled={!canAddVoucher}>
              {loadingCreateVoucher ? <S.Loading /> : "Enviar"}
            </S.SubmitButton>
          </S.FormRow>
        </S.BoxContainer>
      </Form>
      {loadingDeliveryVoucher && <S.Loading />}
      {dataDeliveryVoucher && dataDeliveryVoucher[0] && (
        <S.ItemsWrapper>
          {dataDeliveryVoucher.map((voucher) => (
            <Item
              key={voucher.id}
              voucher={voucher}
              onDownload={handleDownload}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              loading={
                loadingDownloadDeliveryVoucher && selectedItem === voucher.id
              }
            />
          ))}
        </S.ItemsWrapper>
      )}
    </S.Container>
  );
};
