import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { format, addSeconds } from 'date-fns';
import { moeda, peso } from 'utils';

import { IInvoice, IInvoiceItem } from 'interfaces/trips';
import { InvoiceStatusIcon } from 'components/tracking';
import { Popover } from 'components/shared';
import { useTranslation } from 'hooks';
import { translations } from './translations';

import * as S from './styles';

interface IGridTripsProps {
  invoices: IInvoice[];
}

interface IInvoiceProps {
  invoice: IInvoice;
}

interface IInvoiceItemProps {
  item: IInvoiceItem;
}

const InvoiceItem: React.FC<IInvoiceItemProps> = ({ item }) => {
  return (
    <S.ItemAccordion>
      <S.ItemValue>{item.prod_codigo_produto || '---'}</S.ItemValue>
      <S.ItemValue>{item.prod_descricao_rpoduto || '---'}</S.ItemValue>
      <S.ItemValue>{item.prod_numero_pedido_compra || '---'}</S.ItemValue>
      <S.ItemValue>
        {peso(item.prod_quantidade_comercial)} {item.prod_unidade_comercial}
      </S.ItemValue>
      <S.ItemValue>
        {moeda(item.imposto_icms_valor_imposto) || '---'}
      </S.ItemValue>
      <S.ItemValue>
        {moeda(item.imposto_ipi_valor_imposto) || '---'}
      </S.ItemValue>
      <S.ItemValue>{moeda(item.imposto_pis_valor_pis) || '---'}</S.ItemValue>
      <S.ItemValue>
        {moeda(item.imposto_cofins_valor_cofins) || '---'}
      </S.ItemValue>
      <S.ItemValue>
        {moeda(item.prod_valor_unitario_tributacao) || '---'}
      </S.ItemValue>
      <S.ItemValue>
        {moeda(item.prod_valor_total_bruto_produtos) || '---'}
      </S.ItemValue>
    </S.ItemAccordion>
  );
};
const Invoice: React.FC<IInvoiceProps> = ({ invoice }) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const redespachoEnd = 
    invoice.redespacho === 1 || invoice.redespacho_id !== null ?
    `${invoice.transp_nome_municipio}/${invoice.transp_uf}` : '---';

  return (
    <>
      <S.ItemContainer>
        <S.ItemContent>
          <S.ItemValue>{invoice.picking_order}</S.ItemValue>
          <S.ItemValue>
            {invoice.ide_numero_nf}-{invoice.ide_serie}
          </S.ItemValue>
          <S.ItemValue>
            {invoice.ide_data_emissao
              ? format(new Date(invoice.ide_data_emissao), 'dd/MM/yyyy HH:mm')
              : '---'}
          </S.ItemValue>
          <S.ItemValue>
            {invoice.deadline_date
              ? format(new Date(invoice.deadline_date), 'dd/MM/yyyy')
              : '---'}
          </S.ItemValue>
          <S.ItemValue>
            {invoice.delivery_date
              ? format(new Date(invoice.delivery_date), 'dd/MM/yyyy HH:mm')
              : '---'}
          </S.ItemValue>
          <S.ItemValue>
            {invoice.emit_nome_municipio}/{invoice.emit_uf}
          </S.ItemValue>
          <S.ItemValue>
            {invoice.dest_nome_municipio}/{invoice.dest_uf}
          </S.ItemValue>
          <S.ItemValue>
            {redespachoEnd}
          </S.ItemValue>
          <S.ItemValue>{invoice.client.trade_name}</S.ItemValue>
          <S.ItemValue>
            {invoice.last_city}
            {' - '}
            {invoice.geolocation.length > 0
              ? invoice.geolocation[0].created_at &&
                format(
                  new Date(invoice.geolocation[0].created_at),
                  'dd/MM/yyyy HH:mm'
                )
              : '---'}
          </S.ItemValue>
          {invoice.late ? (
            <Popover
              handler={<S.IconBell />}
              message={format(
                addSeconds(
                  new Date(invoice.deadline_date),
                  invoice.truck_duration
                ),
                'dd/MM/yyyy HH:mm'
              )}
              placement="left"
            />
          ) : (
            <S.Ghost />
          )}
          <InvoiceStatusIcon status={invoice.status} />
          <S.ButtonList onClick={() => setAccordionIsOpen(!accordionIsOpen)}>
            {invoice.items && invoice.items.length > 0 ? <S.IconList /> : '--'}
          </S.ButtonList>
          <S.IconDetail
            onClick={() => {
              history.push(`/invoice/${invoice.id}`);
            }}
          />
        </S.ItemContent>
      </S.ItemContainer>
      {invoice.items && invoice.items.length > 0 && (
        <S.Accordion isOpen={accordionIsOpen}>
          <S.ContentAccordion>
            <S.AccordionHeader>
              <S.Label>{getTranslation('codigoP')}</S.Label>
              <S.Label>{getTranslation('desc')}</S.Label>
              <S.Label>{getTranslation('pedido')}</S.Label>
              <S.Label>{getTranslation('quantidade')}</S.Label>
              <S.Label>{getTranslation('valorICMS')}</S.Label>
              <S.Label>{getTranslation('ipi')}</S.Label>
              <S.Label>{getTranslation('valorPis')}</S.Label>
              <S.Label>{getTranslation('valorConfins')}</S.Label>
              <S.Label>{getTranslation('valorUnitario')}</S.Label>
              <S.Label>{getTranslation('valorTotal')}</S.Label>
            </S.AccordionHeader>
            {invoice.items.map((item) => (
              <InvoiceItem key={item.id} item={item} />
            ))}
          </S.ContentAccordion>
        </S.Accordion>
      )}
    </>
  );
};
export const GridTrips: React.FC<IGridTripsProps> = ({ invoices }) => {
const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      <S.Header>
        <S.Label>#</S.Label>
        <S.Label>{getTranslation('nf')}</S.Label>
        <S.Label>{getTranslation('emissao')}</S.Label>
        <S.Label>{getTranslation('prazo')}</S.Label>
        <S.Label>{getTranslation('entrega')}</S.Label>
        <S.Label>{getTranslation('origem')}</S.Label>
        <S.Label>{getTranslation('destino')}</S.Label>
        <S.Label>{getTranslation('redespacho')}</S.Label>
        <S.Label>{getTranslation('cliente')}</S.Label>
        <S.Label>{getTranslation('localizacao')}</S.Label>
      </S.Header>
      {invoices.length > 0 &&
        invoices.map((invoice) => (
          <Invoice key={invoice.id} invoice={invoice} />
        ))}
    </S.Container>
  );
};
