export const translations = {
  localizacoes: {
    pt: 'Localizações',
    en: 'Locations',
    de: 'Standorte',
    es: 'Ubicaciones',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Enge',
    es: 'Cerrar',
  },
  oco: {
    pt: 'Ocorrências',
    en: 'Occurrences',
    de: 'Vorkommnisse',
    es: 'Ocurrencias',
  },
  localizacoesGPS: {
    pt: 'Localizações GPS',
    en: 'GPS locations',
    de: 'GPS-Standorte',
    es: 'Ubicaciones GPS',
  },
};