import * as S from "./styles";
import { useTranslation } from 'hooks';
import { translations } from './translations';

type TypeBox = "sender" | "driver" | "carrier";

interface IProps {
  data: {
    type: TypeBox;
    title: string;
    name: string | null;
    cnpj?: string | null;
    address?: string | null;
    vehicleType?: string;
    vehiclePlate?: string;
  };
}
const icons = {
  sender: <S.IconSender />,
  carrier: <S.IconCarrier />,
  driver: <S.IconDriver />,
};
export const TripsTrackingBox: React.FC<IProps> = ({ data }) => {
  const { getTranslation } = useTranslation(translations);
  
  return (
    <S.Container>
      <S.Header>
        {icons[data.type]}
        <S.Title>{data.title}</S.Title>
      </S.Header>

      <S.Label>{data.type === "driver" ? getTranslation('nome') : getTranslation('razaoSocial')}</S.Label>
      <S.Value>{data.name}</S.Value>

      {data.cnpj && (
        <>
          <S.Label>{getTranslation('cnpj')}</S.Label>
          <S.Value>{data.cnpj}</S.Value>
        </>
      )}

      {data.address && (
        <>
          <S.Label>{getTranslation('endereco')}</S.Label>
          <S.Value>{data.address}</S.Value>
        </>
      )}

      {data.vehicleType && (
        <>
          <S.Label>{getTranslation('veiculo')}</S.Label>
          <S.Value>{data.vehicleType}</S.Value>
        </>
      )}

      {data.vehiclePlate && (
        <>
          <S.Label>{getTranslation('placa')}</S.Label>
          <S.Value>{data.vehiclePlate}</S.Value>
        </>
      )}
    </S.Container>
  );
};
