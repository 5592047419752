import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useValidation } from "hooks";

import * as S from "./styles";

import { Modal } from "components/shared";
import { Select, Input } from "components/shared/Form";

import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import {
  ListRegionsActions,
  ListRegionsState,
} from "store/ducks/settings/regions";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";

import { typesOptions } from "utils/data/types";
import { statesOptions } from "utils/data/states";

interface IFilterProps {
  onFilter: Function;
}

export const FilterTransits: React.FC<IFilterProps> = ({ onFilter }) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cleanShow, setCleanShow] = useState<boolean>(false);

  const [queryType, setQueryType] = useState("");
  const [originOptions, setOriginOptions] = useState([{}]);
  const [destinyOptions, setDestinyOptions] = useState([{}]);
  const [originValue, setOriginValue] = useState("");
  const [destinyValue, setDestinyValue] = useState("");
  const [typeValue, setTypeValue] = useState(null);

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: dataRegions, loading: loadingRegions } =
    useSelector<RootStateOrAny>(
      (state) => state.listRegions
    ) as ListRegionsState;

  const { data: dataCities, loading: loadingCities } =
    useSelector<RootStateOrAny>((state) => state.listCities) as ListCitiesState;

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchRegions = useCallback(() => {
    dispatch(ListRegionsActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
      setQueryType("origin");
    },
    [dispatch]
  );

  const fetchDestinations = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
      setQueryType("destiny");
    },
    [dispatch]
  );

  useEffect(() => {
    fetchCarriers();
    fetchRegions();
  }, [fetchCarriers, fetchRegions ]);

  useEffect(() => {
    if (queryType === "origin") {
      setOriginOptions(dataCities);
    } else if (queryType === "destiny") {
      setDestinyOptions(dataCities);
    }
  }, [queryType, dataCities, loadingCities]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        
        const dataSubmit = {
          ...data,
          ibge_destiny: destinyValue,
          ibge_origin: originValue,
        }
  
        onFilter(dataSubmit);

        setCleanShow(true);
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, destinyValue, originValue, onFilter]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("address_destiny", "");
      formRef.current.setFieldValue("address_origin", "");
      formRef.current.setFieldValue("carrier_id", "");
      formRef.current.setFieldValue("deadline_dedicated", "");
      formRef.current.setFieldValue("deadline_fractional", "");
      formRef.current.setFieldValue("ibge_destiny", "");
      formRef.current.setFieldValue("ibge_origin", "");
      formRef.current.setFieldValue("km_final", "");
      formRef.current.setFieldValue("km_initial", "");
      formRef.current.setFieldValue("tracking_region_id", "");
      formRef.current.setFieldValue("type", "");
      formRef.current.setFieldValue("weight", "");

      const clean = {
        address_destiny: "",
        address_origin: "",
        carrier_id: "",
        deadline_dedicated: "",
        deadline_fractional: "",
        ibge_destiny: "",
        ibge_origin: "",
        km_final: "",
        km_initial: "",
        tracking_region_id: "",
        type: "",
        weight: "",
      };
    
      onFilter(clean);
      setOriginValue("");
      setDestinyValue("");
    }

    setCleanShow(false);
    setModalOpen(false);
  }, [onFilter]);

  const renderButtonCleanFilter = () => {
    if (cleanShow) {
      return (
        <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
          Limpar filtro
        </S.ButtonMini>
      );
    }
  };

  const setCarrier = useCallback((id) => {
    if (formRef.current) return formRef.current.setFieldValue("carrier_id", id);
  }, []);

  const setType = useCallback((type) => {
    setTypeValue(type.value);
    if (formRef.current) return formRef.current.setFieldValue("type", type);
  }, []);

  const setOrigin = useCallback((ibge) => {
    setOriginValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_origin", ibge);
  }, []);

  const setDestiny = useCallback((ibge) => {
    setDestinyValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_destiny", ibge);
  }, []);

  const setRegion = useCallback((region) => {
    if (formRef.current)
      return formRef.current.setFieldValue("tracking_region_id", region);
  }, []);

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>Filtrar</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Select
                name="carrier_id"
                label="Transportadora"
                isLoading={loadingCarriers}
                isDisabled={loadingCarriers}
                options={dataCarriers}
                onChange={(e: any) => setCarrier(e.value)}
              />

              <S.FormRow>
                <Select
                  name="type"
                  label="Tipo"
                  options={typesOptions}
                  onChange={(e: any) => setType(e)}
                />
              </S.FormRow>
            </S.FormRow>

            <S.FormRow>
              <Select
                name="address_origin"
                label="UF Origin"
                options={statesOptions}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="ibge_origin"
                label="Origem"
                isDisabled={!originOptions}
                options={originOptions}
                onChange={(e: any) => setOrigin(e.ibge)}
              />
            </S.FormRow>

            <S.FormRow>
              <Select
                name="address_destiny"
                label="UF"
                disabled={!originOptions}
                options={statesOptions}
                onChange={(e) => fetchDestinations(e)}
              />

              <Select
                name="ibge_destiny"
                label="Destino"
                isDisabled={!destinyOptions}
                options={destinyOptions}
                onChange={(e: any) => setDestiny(e.ibge)}
              />
            </S.FormRow>

            <S.FormRow>
              <Input
                disabled={typeValue !== "KM"}
                name="km_initial"
                type="number"
                label="KM Inicial"
              />
              <Input
                disabled={typeValue !== "KM"}
                name="km_final"
                type="number"
                label="KM Final"
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="weight" label="Peso" />
              <Select
                name="tracking_region_id"
                label="Região"
                disabled
                isDisabled={loadingRegions}
                isLoading={loadingRegions}
                options={dataRegions}
                onChange={(e: any) => setRegion(e.ibge)}
              />
            </S.FormRow>

            <S.FormRow>
              <Input
                name="deadline_fractional"
                type="number"
                label="Prazo para Fracionado"
              />
              <Input
                name="deadline_dedicated"
                type="number"
                label="Prazo para Lotação"
              />
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                Limpar filtro
              </S.Button>
              <S.Button type="submit">Filtrar</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>

      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
