import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateInvoiceJustificationCustomerActions } from "store/ducks/tracking/invoice-justificationCustomer";

export function* createInvoiceJustificationCustomerRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data: responseBody } = yield call(
      api.post,
      `/invoice-justification-customer`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateInvoiceJustificationCustomerActions.success(data));
    notify("success", "Justificativa Customer cadastrada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateInvoiceJustificationCustomerActions.failure(errorMessage));
  }
}
