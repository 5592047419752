import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  FetchInternacionalTransitState,
  FetchInternacionalTransitActions,
  UpdateInternacionalTransitActions,
  UpdateInternacionalTransitState,
  DeleteInternacionalTransitActions,
  DeleteInternacionalTransitState,
} from "store/ducks/settings/internacional-transits";
import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import {
  ListHarborsActions,
  ListHarborsState,
} from "store/ducks/settings/harbors";
import {
  ListCountriesActions,
  ListCountriesState,
} from "store/ducks/settings/countries";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";

import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold, Modal, Alert } from "components/shared";
import { Input, Select } from "components/shared/Form";

import { statesOptions } from "utils/data/states";

interface IParams {
  id: string;
}

export const EditInternacionalTransit: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = useState("");

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: harbors, loading: loadingHarbor } =
  useSelector<RootStateOrAny>(
    (state) => state.listHarbors
  ) as ListHarborsState;

  const { data: countries, loading: loadingCountry } =
  useSelector<RootStateOrAny>(
    (state) => state.listCountries
  ) as ListCountriesState;

  const { data: cities, loading: loadingCity } = (
  useSelector<RootStateOrAny>((state) => state.listCities)
  ) as ListCitiesState;

  const { data: dataFetchTransit, loading } = useSelector<RootStateOrAny>(
    (state) => state.fetchInternacionalTransit
  ) as FetchInternacionalTransitState;

  const { loading: loadingDeleteTransit } = useSelector<RootStateOrAny>(
    (state) => state.deleteInternacionalTransit
  ) as DeleteInternacionalTransitState;

  const { loading: loadingUpdateTransit } = useSelector<RootStateOrAny>(
    (state) => state.updateInternacionalTransit
  ) as UpdateInternacionalTransitState;

  const onSuccess = useCallback(() => {
    history.push("/settings/internacional-transits");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (values) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          carrier_id: Yup.string().required("Obrigatório"),
          origin_name: Yup.string().required("Obrigatório"),
          country_id: Yup.string(),
          harbor_id: Yup.string().when('country_id', (country_id:any, field:any) =>
            !country_id
              ? field.required('Um porto/aeroporto ou um país devem ser selecionados')
              : field
          ),
          dead_line: Yup.string().required("Obrigatório"),
        });
        await schema.validate(values, {
          abortEarly: false,
        });

        const data: {
          carrier_id: number,
          origin_name: string,
          dead_line: number,
          harbor_id?: number,
          country_id?: number,
        } = {
          carrier_id: values.carrier_id,
          origin_name: values.origin_name,
          dead_line: values.dead_line
        }

        if(type === "Porto") {
          data.harbor_id = values.harbor_id;
        }

        if(type === "País") {
          data.country_id = values.country_id;
        }

        dispatch(UpdateInternacionalTransitActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleFormErrors, id, onSuccess, type]
  );

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchHarbors = useCallback(() => {
    dispatch(ListHarborsActions.request({ all: true }));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const onSetHarbor = () => {
    setType('Porto');
    if (formRef.current) {
      formRef.current.setFieldValue("harbor_id", {value: 0, label: ''});
    }
  }

  const onSetCountry = () => {
    setType('País');
    if (formRef.current) {
      formRef.current.setFieldValue("country_id", {value: 0, label: ''});
    }
  }

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      data.carrier_id = {
        label: data.carrier.trade_name,
        value: data.carrier.id,
      };
      data.address_origin = {
        label: data.origin.state,
        value: data.origin.state,
      };
      data.origin_name = {
        label: data.origin.name,
        value: data.origin.name,
      };
      data.adress_origin = {
        label: data.origin.state,
        value: data.origin.state,
      };

      if (data.harbor_id) {
        setType('Porto');
        data.harbor_id = {
          label: data.harbor.description,
          value: data.harbor.id,
        }
      };
      if (data.country_id) {
        setType('País');
        data.country_id = {
          label: data.country.description,
          value: data.country.id,
        }
      };

      formRef.current.setData(data);
      fetchOrigins(data.address_origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchInternacionalTransitActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteInternacionalTransitActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchCarriers();
    fetchHarbors();
    fetchCountries();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Scaffold>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`Remover Tempo de Trânsito Internacional ${dataFetchTransit?.carrier.trade_name}`}
          text="Deseja realmente remover esse tempo de trânsito?"
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteTransit}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Tempo de Trânsito Internacional</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Select
                name="carrier_id"
                label="Transportadora"
                isDisabled={loadingCarriers}
                isLoading={loadingCarriers}
                options={dataCarriers}
              />
              <Input
                name="dead_line"
                type="number"
                label="Prazo"
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="address_origin"
                label="UF"
                options={statesOptions}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="origin_name"
                label="Origem"
                isLoading={loadingCity}
                isDisabled={!cities}
                options={cities}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="harbor_id"
                label="Porto/Aeroporto"
                isLoading={loadingHarbor}
                isDisabled={!harbors || type === "País"}
                options={harbors}
                onChange={onSetHarbor}
              />
              <Select
                name="country_id"
                label="País"
                isLoading={loadingCountry}
                isDisabled={!countries || type === "Porto"}
                options={countries}
                onChange={onSetCountry}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Remover
              </S.Button>
              <S.Button type="submit">
                {loadingUpdateTransit ? <S.Loading /> : "Salvar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
