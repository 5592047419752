import React from "react";
import { useHistory } from "react-router-dom";
import { InternacionalTransit } from "interfaces/internacional-transit";
import { cnpj } from "utils";
import * as S from "./styles";
import { useTranslation } from "hooks";
import { translations } from "./translations";

interface IGridInternacionalTransitsProps {
  transits: InternacionalTransit[] | Record<string, any>[];
}

interface IInternacionalTransitProps {
  transit: InternacionalTransit | Record<string, any>;
}

const Item: React.FC<IInternacionalTransitProps> = ({ transit }) => {
  const history = useHistory();

  return (
    <S.ItemContainer
      onClick={() =>
        history.push(`/settings/internacional-transit/${transit.id}`)
      }
    >
      <S.ItemContent>
        <S.ItemValue>
          {transit.carrier.cnpj ? cnpj(transit.carrier.cnpj) : "--"}
        </S.ItemValue>
        <S.ItemValue>{transit.carrier.trade_name || "--"}</S.ItemValue>
        <S.ItemValue>
          {transit.carrier.address_city && transit.carrier.address_state
            ? `${transit.carrier.address_city}/${transit.carrier.address_state}`
            : "--"}
        </S.ItemValue>
        <S.ItemValue>{transit.origin ? transit.origin.name : "--"}</S.ItemValue>
        <S.ItemValue>
          {transit.harbor ? transit.harbor.description : "--"}
        </S.ItemValue>
        <S.ItemValue>
          {transit.country ? transit.country.description : "--"}
        </S.ItemValue>
        <S.ItemValue>{transit.dead_line || "--"}</S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridInternacionalTransits: React.FC<IGridInternacionalTransitsProps> =
  ({ transits = [] }) => {
    const { getTranslation } = useTranslation(translations);
    return (
      <S.Container>
        <S.Header>
          <S.Label>{getTranslation('cnpj')}</S.Label>
          <S.Label>{getTranslation('transportadora')}</S.Label>
          <S.Label>{getTranslation('cidade')}</S.Label>
          <S.Label>{getTranslation('origem')}</S.Label>
          <S.Label>{getTranslation('portoAeroporto')}</S.Label>
          <S.Label>{getTranslation('pais')}</S.Label>
          <S.Label>{getTranslation('prazo')}</S.Label>
        </S.Header>
        {transits.length > 0 &&
          transits.map((transit) => (
            <Item transit={transit} key={transit.id} />
          ))}
      </S.Container>
    );
  };
