import React, { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { Form } from "@unform/web";

import { GridCfops } from "components/settings/OperationTypes";

import {
  ListOperationCfopsActions,
  ListOperationCfopsState,
  UpdateOperationCfopActions,
} from "store/ducks/settings/operation-cfop";
import {
  ListTypeCfopsActions,
  ListTypeCfopsState,
} from "store/ducks/settings/type-cfop";
import { useValidation } from "hooks";
import { Select } from "components/shared/Form";
import * as S from "./styles";

interface IParams {
  id: string;
}

const OperationCfops: React.FC = () => {
  const { id } = useParams<IParams>();
  const subFormRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { data: options, loading: optionLoading } = useSelector<RootStateOrAny>(
    (state) => state.listOperationCfops
  ) as ListOperationCfopsState;

  const { data: list, loading: loadingList } = useSelector<RootStateOrAny>(
    (state) => state.listTypeCfops
  ) as ListTypeCfopsState;

  const handleSubmit = useCallback<SubmitHandler>(
    async (values) => {
      setLoadingUpdate(true);

      try {
        subFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cfop_id: Yup.number().required("Obrigatório"),
        });
        await schema.validate(values, {
          abortEarly: false,
        });

        const data = {
          operation_type_id: id,
        };

        dispatch(
          UpdateOperationCfopActions.request(
            values.cfop_id,
            data,
            fetchCfopsList
          )
        );
      } catch (error) {
        setLoadingUpdate(false);
        handleFormErrors(error, subFormRef);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const fetchCfopsOptions = useCallback(() => {
    dispatch(ListOperationCfopsActions.request({ all: true }));
  }, [dispatch]);

  const fetchCfopsList = useCallback(() => {
    setLoadingUpdate(false);
    dispatch(ListTypeCfopsActions.request(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    fetchCfopsOptions();
    fetchCfopsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.PageContent>
      <Form ref={subFormRef} onSubmit={handleSubmit}>
        <S.BoxContainer>
          <S.FormRow>
            <Select
              name="cfop_id"
              label="Operação CFOP"
              isDisabled={optionLoading}
              isLoading={optionLoading}
              options={options}
            />
          </S.FormRow>
        </S.BoxContainer>
        <S.FormFooter>
          <S.FormRow>
            <S.Button type="submit">
              {loadingUpdate ? <S.Loading /> : "Adicionar"}
            </S.Button>
          </S.FormRow>
        </S.FormFooter>
      </Form>
      <GridCfops
        cfops={list}
        loading={loadingUpdate || loadingList}
        onDelete={fetchCfopsList}
      />
    </S.PageContent>
  );
};

export default OperationCfops;
