import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListHarborsActions } from "store/ducks/settings/harbors";

import { Harbor } from "interfaces/harbor";

export function* listHarborsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/harbors?${queryString}`);
    const comboOptions = data.map((harbor: Harbor) => ({
      value: harbor.id,
      label: `${harbor.description || "---"}`,
    }));

    yield put(ListHarborsActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListHarborsActions.failure(errorMessage));
  }
}
