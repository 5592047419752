import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { OptionTypeBase } from "react-select";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation,useTranslation } from "hooks";
import { translations } from "./translations";

import { statesOptions } from "utils/data/states";

import {
  FetchIndentFilterActions,
  FetchIndentFilterState,
} from "store/ducks/tracking/indent-filters";
import {
  ListCarriersActions,
  ListCarriersState,
  ListCarriersTradeNameActions,
  ListCarriersTradeNameState,
} from "store/ducks/settings/carriers";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";
import {
  InvoiceCountriesActions,
  InvoiceCountriesState,
} from "store/ducks/tracking/invoices";

import * as S from "./styles";
import { Modal } from "components/shared";
import { Select, Input, ToggleInput } from "components/shared/Form";

import { ICitiesOptions } from "interfaces/city";
import { ICarrierOptions } from "interfaces/carrier";
import { differenceInDays } from "date-fns";
import { notify } from "services";

interface IIndentFilterProps {}

export const IndentFilter: React.FC<IIndentFilterProps> = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [emitCountry, setEmitCountry] = useState<boolean>(false);
  const [destCountry, setDestCountry] = useState<boolean>(false);
  const [flagCarrier, setFlagCarrier] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);
  
  const { data, citiesOrigins, citiesDestinies } = useSelector<RootStateOrAny>(
    (state) => state.fetchIndentFilter
  ) as FetchIndentFilterState;

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

    const { data: dataCarriersTradeName, loading: loadingCarriersTradeName } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriersTradeName
    ) as ListCarriersTradeNameState;

  const { loading: loadingCities } = useSelector<RootStateOrAny>(
    (state) => state.listCities
  ) as ListCitiesState;

  const { data: dataCountries} =
    useSelector<RootStateOrAny>(
      (state) => state.invoiceCountries
    ) as InvoiceCountriesState;

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      const limit = 60;
      const {
        date_start, 
        date_end, 
        deadline_date_start, 
        deadline_date_end 
      } = data;
      let diferencaEmit = 0;
      let diferencaDeadline = 0;

      if(date_start && date_end){
        diferencaEmit = differenceInDays( new Date(date_end), new Date(date_start))
      }
      if(deadline_date_start && deadline_date_end){
        diferencaDeadline = differenceInDays( new Date(deadline_date_end), new Date(deadline_date_start))
      }

      try {
        if(diferencaEmit <= limit && diferencaDeadline <= limit){
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            date_start: Yup.string().required("Obrigatório"),
            date_end: Yup.string().required("Obrigatório"),
            city_origin_uf: Yup.string(),
            city_origin: Yup.string().when("city_origin_uf", {
              is: (city_origin_uf: any) => city_origin_uf,
              then: Yup.string().required("Obrigatório"),
            }),
            city_destiny_uf: Yup.string(),
            city_destiny: Yup.string().when("city_destiny_uf", {
              is: (city_destiny_uf: any) => city_destiny_uf,
              then: Yup.string().required("Obrigatório"),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (data.canceled) {
            data.canceled = Number(data.canceled);
          }
          dispatch(FetchIndentFilterActions.success(data));
          setModalOpen(false);
        }
        else {
          notify('error', 'Periodo maior que 60 dias');
        }
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors]
  );

  const getListCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  
  const getListCarriersTradeName = useCallback(() => {
    dispatch(
      ListCarriersTradeNameActions.request({ trade_name_distinct: true })
    );
  }, [dispatch]);

  const getListCountries = useCallback(() => {
    dispatch(InvoiceCountriesActions.request());
  }, [dispatch]);

  const onCityOriginSuccess = useCallback(
    (cityOptions) => {
      const cityOrigins = cityOptions.map((cityOption: ICitiesOptions) => ({
        label: cityOption.label,
        value: cityOption.ibge,
      }));
      dispatch(FetchIndentFilterActions.citiesOrigins(cityOrigins));
    },
    [dispatch]
  );

  const onCityDestinySuccess = useCallback(
    (cityOptions) => {
      const cityDestinies = cityOptions.map((cityOption: ICitiesOptions) => ({
        label: cityOption.label,
        value: cityOption.ibge,
      }));
      dispatch(FetchIndentFilterActions.citiesDestinies(cityDestinies));
    },
    [dispatch]
  );

  const handleStateOrigin = useCallback(
    (option) => {
      dispatch(
        ListCitiesActions.request(
          { state: option.value, all: true },
          onCityOriginSuccess
        )
      );
    },
    [dispatch, onCityOriginSuccess]
  );

  const handleChangeCountry = (option: any, type: string) => {
    if (type === "origin" && option.value === "Brasil") {
      setEmitCountry(true);
    } else if (type === "origin") {
      setEmitCountry(false);
    }

    if (type === "destiny" && option.value === "Brasil") {
      setDestCountry(true);
    } else if (type === "destiny") {
      setDestCountry(false);
    }
  };

  const handleStateDestiny = useCallback(
    (option) => {
      dispatch(
        ListCitiesActions.request(
          { state: option.value, all: true },
          onCityDestinySuccess
        )
      );
    },
    [dispatch, onCityDestinySuccess]
  );

  const onFetchFilter = useCallback(() => {
    if (formRef.current && dataCarriers) {
      formRef.current.setFieldValue("date_start", data.date_start);
      formRef.current.setFieldValue("date_end", data.date_end);
      formRef.current.setFieldValue("invoice_number", data.invoice_number);
      formRef.current.setFieldValue("vehicle_plate", data.vehicle_plate);
      formRef.current.setFieldValue(
        "deadline_date_start",
        data.deadline_date_start
      );
      formRef.current.setFieldValue(
        "deadline_date_end",
        data.deadline_date_end
      );

      if (data.general_carrier_id) {
        const carrierOption = dataCarriers.filter(
          (carrierOption: ICarrierOptions) =>
            carrierOption.value === data.general_carrier_id
        );

        if (carrierOption.length > 0) {
          formRef.current.setFieldValue("general_carrier_id", carrierOption[0]);
        }
      }

      if (data.city_origin_uf) {
        const stateOriginOption = statesOptions.filter(
          (stateOption: OptionTypeBase) =>
            stateOption.value === data.city_origin_uf
        );

        if (stateOriginOption.length > 0) {
          formRef.current.setFieldValue("city_origin_uf", stateOriginOption[0]);
        }
      }

      if (data.city_origin && citiesOrigins) {
        const cityOriginOption = citiesOrigins?.filter(
          (cityOption) => cityOption.value === data.city_origin
        );
        if (cityOriginOption && cityOriginOption?.length > 0) {
          formRef.current.setFieldValue("city_origin", cityOriginOption[0]);
        }
      }

      if (data.city_destiny_uf) {
        const stateDestinyOption = statesOptions.filter(
          (stateOption: OptionTypeBase) =>
            stateOption.value === data.city_destiny_uf
        );

        if (stateDestinyOption.length > 0) {
          formRef.current.setFieldValue(
            "city_destiny_uf",
            stateDestinyOption[0]
          );
        }
      }

      if (data.city_destiny && citiesDestinies) {
        const cityDestinyOption = citiesDestinies?.filter(
          (cityOption) => cityOption.value === data.city_destiny
        );
        if (cityDestinyOption && cityDestinyOption?.length > 0) {
          formRef.current.setFieldValue("city_destiny", cityDestinyOption[0]);
        }
      }
    }
  }, [
    citiesDestinies,
    citiesOrigins,
    data,
    dataCarriers,
  ]);

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("city_origin_uf", "");
      formRef.current.setFieldValue("city_origin", "");
      formRef.current.setFieldValue("city_destiny_uf", "");
      formRef.current.setFieldValue("city_destiny", "");
      formRef.current.setFieldValue("general_carrier_id", "");
      formRef.current.setFieldValue("country_origin", "");
      formRef.current.setFieldValue("country_destiny", "");
      formRef.current.setFieldValue("deadline_date_start","");
      formRef.current.setFieldValue("deadline_date_end","");
      formRef.current.clearField("canceled");

      setEmitCountry(false);
      setDestCountry(false);
    }
    dispatch(FetchIndentFilterActions.reset());
    setModalOpen(false);
  }, [dispatch]);

  const renderButtonCleanFilter = useCallback(() => {
    if (data) {
      const { limit, page, date_start, date_end, ...rest } = data;

      const showClearFilter = Object.values(rest).some(
        (value) => ![value !== null, value !== ""].includes(false)
      );

      if (showClearFilter) {
        return (
          <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
            {getTranslation('limparFiltro')}
          </S.ButtonMini>
        );
      }
    }
  }, [data, getTranslation, handleFilterClean]);

  const changeTypeCarrier = useCallback(() => {
    if (formRef.current) {
      const flag = formRef.current.getFieldValue("carrier_raiz");
      if (flag === "1") {
        formRef.current.setFieldValue("general_carrier_id", "");
        setFlagCarrier(true);
      } else {
        formRef.current.setFieldValue("carrier_trade_name", "");
        setFlagCarrier(false);
      }
    } else {
      setFlagCarrier(false);
    }
  }, [setFlagCarrier]);

  useEffect(() => getListCarriers(), [getListCarriers]);
  useEffect(() => onFetchFilter(), [onFetchFilter]);
  useEffect(() => getListCountries(), [getListCountries]);
  useEffect(() => getListCarriersTradeName(), [getListCarriersTradeName]);

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation('filtrar')}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="invoice_number" label={getTranslation('notaFiscal')}/>
              <ToggleInput name="canceled" label={getTranslation('canceladas')} />
            </S.FormRow>
            <S.FormRow>
              <Input name="date_start" label={getTranslation('emissaoDe')} type="date" />
              <Input name="date_end" label={getTranslation('emissaoAte')} type="date" />
              <Input name="deadline_date_start" label={getTranslation('prazoDe')} type="date" />
              <Input name="deadline_date_end" label={getTranslation('prazoAte')} type="date" />
            </S.FormRow>

            <S.FormRow>
              <Select
                name="country_origin"
                label={getTranslation('paisOrigem')}
                options={dataCountries?.emit}
                placeholder={getTranslation('selecione')}
                onChange={(e: OptionTypeBase) =>
                  handleChangeCountry(e, "origin")
                }
              />

              {emitCountry ? (
                <S.FormRow>
                  <Select
                    name="city_origin_uf"
                    label={getTranslation('ufOrigem')}
                    options={statesOptions}
                    placeholder={getTranslation('selecione')}
                    onChange={(e: OptionTypeBase) => handleStateOrigin(e)}
                  />
                  <Select
                    name="city_origin"
                    label={getTranslation('cidadeOrigem')}
                    options={citiesOrigins || undefined}
                    placeholder={getTranslation('selecione')}
                    isLoading={loadingCities}
                  />
                </S.FormRow>
              ) : (
                <></>
              )}
            </S.FormRow>

            <S.FormRow>
              <Select
                name="country_destiny"
                label={getTranslation('paisDestino')}
                options={dataCountries?.dest}
                placeholder={getTranslation('selecione')}
                onChange={(e: OptionTypeBase) =>
                  handleChangeCountry(e, "destiny")
                }
              />

              {destCountry ? (
                <S.FormRow>
                  <Select
                    name="city_destiny_uf"
                    label={getTranslation('ufDestino')}
                    options={statesOptions}
                    placeholder={getTranslation('selecione')}
                    onChange={(e: OptionTypeBase) => handleStateDestiny(e)}
                  />
                  <Select
                    name="city_destiny"
                    label={getTranslation('cidadeDestino')}
                    options={citiesDestinies || undefined}
                    placeholder={getTranslation('selecione')}
                    isLoading={loadingCities}
                  />
                </S.FormRow>
              ) : (
                <></>
              )}
            </S.FormRow>

            <S.FormRow>
              <Input name="vehicle_plate" label={getTranslation('placaVeiculo')} />
              {flagCarrier ? (
                <Select
                  name="carrier_trade_name"
                  label={getTranslation('transportadoraRaiz')}
                  options={dataCarriersTradeName}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriersTradeName}
                  isDisabled={loadingCarriersTradeName}
                />
              ) : (
                <Select
                  name="general_carrier_id"
                  label={getTranslation('transportadora')}
                  options={dataCarriers}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriers}
                  isDisabled={loadingCarriers}
                />
              )}

              <ToggleInput
                name="carrier_raiz"
                label={getTranslation('cnpjRaiz')}
                onChange={() => changeTypeCarrier()}
              />
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                {getTranslation('cancelar')}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation('limparFiltro')}
              </S.Button>
              <S.Button type="submit">{getTranslation('filtrar')}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>

      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
