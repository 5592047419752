import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { OperationType } from "interfaces/operation-type";

interface IGridOperationTypesProps {
  units: OperationType[] | Record<string, any>[];
}

interface IOperationTypeProps {
  unit: OperationType | Record<string, any>;
}

const Item: React.FC<IOperationTypeProps> = ({ unit }) => {
  const history = useHistory();
  return (
    <S.ItemContainer
      onClick={() => history.push(`/settings/operation-types/${unit.id}`)}
    >
      <S.ItemContent>
        <S.ItemValue>{unit.id || "--"}</S.ItemValue>
        <S.ItemValue>{unit.name || "--"}</S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridOperationTypes: React.FC<IGridOperationTypesProps> = ({
  units = [],
}) => {
  return (
    <S.Container>
      <S.Header>
        <S.Label>Id</S.Label>
        <S.Label>Nome</S.Label>
      </S.Header>
      {units.length > 0 &&
        units.map((unit) => <Item unit={unit} key={unit.id} />)}
    </S.Container>
  );
};
