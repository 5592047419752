import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useValidation, useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";

import { Modal } from "components/shared";
import { Select, Input } from "components/shared/Form";

import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import {
  ListHarborsActions,
  ListHarborsState,
} from "store/ducks/settings/harbors";
import {
  ListCountriesActions,
  ListCountriesState,
} from "store/ducks/settings/countries";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";

import { statesOptions } from "utils/data/states";

interface IFilterProps {
  onFilter: Function;
}

export const FilterInternacionalTransits: React.FC<IFilterProps> = ({
  onFilter,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const { getTranslation } = useTranslation(translations);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cleanShow, setCleanShow] = useState<boolean>(false);
  const [originValue, setOriginValue] = useState("");

  const { data: dataCarriers, loading: loadingCarriers } =
    useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: harbors, loading: loadingHarbor } = useSelector<RootStateOrAny>(
    (state) => state.listHarbors
  ) as ListHarborsState;

  const { data: countries, loading: loadingCountry } =
    useSelector<RootStateOrAny>(
      (state) => state.listCountries
    ) as ListCountriesState;

  const { data: cities, loading: loadingCity } = useSelector<RootStateOrAny>(
    (state) => state.listCities
  ) as ListCitiesState;

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchHarbors = useCallback(() => {
    dispatch(ListHarborsActions.request({ all: true }));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const setOrigin = useCallback((id) => {
    setOriginValue(id);
    if (formRef.current) return formRef.current.setFieldValue("origin_id", id);
  }, []);

  const onSetHarbor = () => {
    if (formRef.current) {
      formRef.current.setFieldValue("harbor_id", { value: 0, label: "" });
    }
  };

  const onSetCountry = () => {
    if (formRef.current) {
      formRef.current.setFieldValue("country_id", { value: 0, label: "" });
    }
  };

  useEffect(() => {
    fetchCarriers();
    fetchHarbors();
    fetchCountries();
  }, [fetchCarriers, fetchHarbors, fetchCountries]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        const dataSubmit = {
          ...data,
          origin_id: originValue,
        };

        onFilter(dataSubmit);
        setCleanShow(true);
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, onFilter, originValue]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("address_origin", "");
      formRef.current.setFieldValue("carrier_id", "");
      formRef.current.setFieldValue("country_id", "");
      formRef.current.setFieldValue("dead_line", "");
      formRef.current.setFieldValue("harbor_id", "");
      formRef.current.setFieldValue("origin_id", "");

      const clean = {
        address_origin: "",
        carrier_id: "",
        country_id: "",
        dead_line: "",
        harbor_id: "",
        origin_id: "",
      };

      onFilter(clean);
      setOriginValue("");
    }

    setCleanShow(false);
    setModalOpen(false);
  }, [onFilter]);

  const renderButtonCleanFilter = () => {
    if (cleanShow) {
      return (
        <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
         {getTranslation('limparFiltro')}
        </S.ButtonMini>
      );
    }
  };

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation('filtrar')}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Select
                name="carrier_id"
                label={getTranslation('transportadora')}
                isDisabled={loadingCarriers}
                isLoading={loadingCarriers}
                options={dataCarriers}
                placeholder={getTranslation('selecione')}
              />
              <Input name="dead_line" type="number" label={getTranslation('prazo')} />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="address_origin"
                label={getTranslation('uf')}
                options={statesOptions}
                placeholder={getTranslation('selecione')}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="origin_id"
                label={getTranslation('origem')}
                isLoading={loadingCity}
                isDisabled={!cities}
                options={cities}
                placeholder={getTranslation('selecione')}
                onChange={(e: any) => setOrigin(e.id)}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="harbor_id"
                label={getTranslation('portoAeroporto')}
                isLoading={loadingHarbor}
                isDisabled={!harbors}
                options={harbors}
                placeholder={getTranslation('selecione')}
                onChange={onSetHarbor}
              />
              <Select
                name="country_id"
                label={getTranslation('pais')}
                isLoading={loadingCountry}
                isDisabled={!countries}
                options={countries}
                placeholder={getTranslation('selecione')}
                onChange={onSetCountry}
              />
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                {getTranslation('cancelar')}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation('limparFiltro')}
              </S.Button>
              <S.Button type="submit">{getTranslation('filtrar')}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>

      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
