import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { format } from "date-fns";
import {
  FetchRecalculateDeadlineActions,
  FetchRecalculateDeadlineState,
} from "store/ducks/tracking/recalculate-deadline";
import {
  UpdateInvoiceActions,
  UpdateInvoiceState,
} from "store/ducks/tracking/invoices";
import { Modal } from "components/shared";
import { Invoice } from "interfaces/invoice";

import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface Props {
  invoice: Invoice;
  isOpen: boolean;
  close: () => void;
  onActionSuccess: () => void;
}

export const RecalculateDeadline: React.FC<Props> = ({
  isOpen,
  invoice,
  close,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);
  const dispatch = useDispatch();
  const { data: dataRecalculate, loading: loadingRecalculate } = useSelector<
    RootStateOrAny,
    FetchRecalculateDeadlineState
  >(state => state.fetchRecalculateDeadline);

  const { loading: loadingUpdateInvoice } = useSelector<
    RootStateOrAny,
    UpdateInvoiceState
  >(state => state.updateInvoice);

  const onSuccess = useCallback(() => {
    close();
    onActionSuccess();
  }, [close, onActionSuccess]);

  const handleUpdateInvoice = useCallback(() => {
    if (dataRecalculate) {
      const data = {
        deadline_date: dataRecalculate,
      };
      dispatch(UpdateInvoiceActions.request(invoice.id, data, onSuccess));
    }
  }, [dataRecalculate, dispatch, onSuccess, invoice]);

  const getNewDeadline = useCallback(() => {
    if (isOpen) {
      dispatch(FetchRecalculateDeadlineActions.request({
        id: invoice.id,
        idEstrangeiro: 
          (invoice.client && invoice.client.id_exterior) || 
          invoice.dest_uf === "EX"
      }));
    }
  }, [dispatch, invoice, isOpen]);

  useEffect(() => {
    getNewDeadline();
  }, [getNewDeadline]);

  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.IconMessage />
            {getTranslation('recalcularPrazo')}
          </S.Title>
        </S.Header>
        <S.Content>
          <S.Column>
            <S.Label>{getTranslation('PrazoAtual')}</S.Label>
            <S.Value>
              {invoice.deadline_date
                ? format(new Date(invoice.deadline_date), "dd/MM/yyyy")
                : "--------"}
            </S.Value>
          </S.Column>
          <S.IconArrowRight />
          <S.Column>
            <S.Label>{getTranslation('PrazoRecalculado')}</S.Label>
            {loadingRecalculate ? (
              <S.Loading />
            ) : (
              <S.Value>
                {dataRecalculate
                  ? format(new Date(dataRecalculate), "dd/MM/yyyy")
                  : "--------"}
              </S.Value>
            )}
          </S.Column>
        </S.Content>
        <S.ButtonsWrapper>
          <S.Button btStyle="cancel" onClick={close} type="button">
            {getTranslation('fechar')}
          </S.Button>
          <S.Button
            btStyle={dataRecalculate ? "primary" : "cancel"}
            onClick={handleUpdateInvoice}
            disabled={!dataRecalculate || false}
            type="submit"
          >
            {loadingUpdateInvoice ? <S.Loading /> : getTranslation('recalcular')}
          </S.Button>
        </S.ButtonsWrapper>
      </S.Container>
    </Modal>
  );
};
