import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { Downtime } from "pages/Reports";

interface Props {}

export const ReportRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/report/downtime" component={Downtime} />
    </Switch>
  );
};
