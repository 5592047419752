import styled, { css } from "styled-components";
import { colors } from "styles";

type ButtonStyleOption =
  | "dark"
  | "primary"
  | "secundary"
  | "transparent"
  | "cancel"
  | "danger";

interface ButtonStyle {
  btStyle?: ButtonStyleOption;
}

const styleButton = {
  dark: css`
    background-color: ${colors.gray4};
    color: #fff;
  `,
  primary: css`
    background-color: ${colors.darkGreen};
    color: #fff;
  `,
  secundary: css`
    background-color: #1a1a1a;
    color: #fff;
  `,
  outline: css`
    background-color: transparent;
    color: ${colors.red};
    padding: 16px 0;
    border: 2px ${colors.red} solid;
  `,
  transparent: css`
    background-color: transparent;
    color: #1a1a1a;
    padding: 16px 0;
  `,
  cancel: css`
    background-color: ${colors.gray};
    color: ${colors.gray3};
  `,
  danger: css`
    background-color: ${colors.red};
    color: #fff;
  `,
};

export const Button = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 16px 48px;
  height: 56px;
  border-radius: 4px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const ButtonMini = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;
