import * as S from "./style";

interface IProps {
  data: any;
  closeModal: () => void;
}

export const InvoiceInfoModal: React.FC<IProps> = ({ data, closeModal }) => {
  const receiving_start = (data && data.receiving_start)? data.receiving_start : "";
  const receiving_end = (data && data.receiving_end)? data.receiving_end : "";
  const meal_start = (data && data.meal_start)? data.meal_start : "";
  const meal_end = (data && data.meal_end)? data.meal_end : "";
  const receiving = receiving_start + ' - ' + receiving_end;
  const meal = meal_start + ' - ' + meal_end;

  return (
    <S.Container>
      <S.Header>
        <S.HeaderItem>
          <S.IconAlarm />
          <S.Title>Informações de entrega</S.Title>
        </S.HeaderItem>
        {
          (data && data.scheduling_required)?
          <S.HeaderItem>
            <S.IconCalendar />
            <S.TitleInfo>Cliente necessita de agendamento</S.TitleInfo>
          </S.HeaderItem>
          :
          ""
        }
      </S.Header>
      <S.ContainerTable>
        <S.ItemTableNotBorder>
          <S.Text className="weight">Semana</S.Text>
          <S.Text className="weight center">Refeição</S.Text>
          <S.Text className="weight rigth">Recebimento</S.Text>
        </S.ItemTableNotBorder>

        <S.ItemTable>
           <S.Text>Segunda</S.Text>
          <S.Text className="center"> {(data && data.monday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.monday)? receiving : '-' } </S.Text>
        </S.ItemTable>


        <S.ItemTable>
          <S.Text>Terça</S.Text>
          <S.Text className="center"> {(data && data.tuesday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.tuesday)? receiving : '-' } </S.Text>
        </S.ItemTable>

        <S.ItemTable>
          <S.Text>Quarta</S.Text>
          <S.Text className="center"> {(data && data.wednesday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.wednesday)? receiving : '-' } </S.Text>
        </S.ItemTable>

        <S.ItemTable>
          <S.Text>Quinta</S.Text>
          <S.Text className="center"> {(data && data.thursday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.thursday)? receiving : '-' } </S.Text>
        </S.ItemTable>

        <S.ItemTable>
          <S.Text>Sexta</S.Text>
          <S.Text className="center"> {(data && data.friday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.friday)? receiving : '-' } </S.Text>
        </S.ItemTable>

        <S.ItemTable>
          <S.Text>Sábado</S.Text>
          <S.Text className="center"> {(data && data.saturday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.saturday)? receiving : '-' } </S.Text>
        </S.ItemTable>

        <S.ItemTableNotBorder>
          <S.Text>Domingo</S.Text>
          <S.Text className="center"> {(data && data.sunday)? meal : '-' } </S.Text>
          <S.Text className="rigth"> {(data && data.sunday)? receiving : '-' } </S.Text>
        </S.ItemTableNotBorder>



      </S.ContainerTable>
      <S.ButtonsWrapper >
      <S.Button btStyle="cancel" onClick={closeModal}>
         Fechar
        </S.Button>
      </S.ButtonsWrapper>
    </S.Container>
  );
};
