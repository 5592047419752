import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { ListDeliveryVoucher } from "pages/DeliveryVoucher";

interface Props {}

export const DeliveryVoucherRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/comprovantes"
        component={ListDeliveryVoucher}
      />
    </Switch>
  );
};
