import styled from "styled-components";
import { Truck } from "@styled-icons/boxicons-solid";
import { Check } from "@styled-icons/boxicons-regular";
import { Cancel } from "@styled-icons/typicons";
import { colors, fonts } from "styles";


export const Container = styled.div< {status: string} >`
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: white;
  margin-right: 25px;
  background-color: ${({ status }) => (status === "entregue") ? `${colors.green}` :`${colors.orange}`  };
`;

export const IconTransito = styled(Truck).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
export const IconEntregue = styled(Check).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
export const IconCancel = styled(Cancel).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
