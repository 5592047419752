import styled, { css } from "styled-components";
import { fonts, colors } from "styles";
import { ChevronRightCircle, BellRing } from "@styled-icons/boxicons-solid";
import { ListUl } from "@styled-icons/boxicons-regular";

export const Container = styled.section`
  width: 100%;
  margin: 32px auto;
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 60px repeat(3, 100px) repeat(4, 115px) auto 24px 32px 24px 24px;
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const Label = styled.span`
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray3};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const ItemContent = styled.a`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 60px repeat(3, 100px) repeat(4, 115px) auto 24px 32px 24px 24px;
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;

  :hover {
    transform: scale(1.01);
  }
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray4};
`;

export const IconDetail = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: ${colors.gray3};
`;

export const ButtonDetail = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconList = styled(ListUl).attrs({ size: 22 })`
  color: ${colors.gray3};
`;

export const IconBell = styled(BellRing).attrs({ size: 22 })`
  color: ${colors.deepPurple};
  transform-origin: top center;
  animation: shake 1s ease infinite;

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export const Ghost = styled.div`
  width: 22px;
`;

export const ButtonList = styled.button.attrs({ type: "button" })`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const AccordionHeader = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 90px auto repeat(8, 100px);
  column-gap: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray3};
`;
interface AccordionProps {
  isOpen: boolean;
}
export const Accordion = styled.div<AccordionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  overflow: hidden;
  transition: 300ms ease;
  background-color: ${colors.gray2};
  border-radius: 4px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: auto;
      margin-bottom: 8px;
    `}
`;

export const ContentAccordion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const ItemAccordion = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 90px auto repeat(8, 100px);
  column-gap: 16px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px dotted ${colors.gray3};
`;

interface AccordionInnerProps {
  isOpen: boolean;
}

export const AccordionInner = styled.div<AccordionInnerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  overflow: hidden;
  transition: 300ms ease;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: auto;
      margin-bottom: 8px;
    `}
`;

export const ItemAccordionInner = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 176px 210px auto;
  column-gap: 16px;
  background-color: transparent;
  padding: 16px;
  margin-bottom: 8px;
  border-bottom: 1px dotted ${colors.gray1};

  &:last-child {
    border-bottom: none;
  }
`;
