import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import {
  IState,
  IFailureAction,
} from "interfaces/paginate-duck";
import { ITripList, Indicators } from "interfaces/trips";
import { Pagination } from "interfaces/pagination";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination", "indicators"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PAGINATE_TRIP_" }
);

export interface PaginateTripsState extends IState {
  data: ITripList[];
  indicators: Indicators | null;
}

export interface SuccessPaginateTripsAction {
  data: ITripList[];
  pagination: Pagination;
  indicators: Indicators;
}

const INITIAL_STATE: PaginateTripsState = {
  data: [],
  indicators: null,
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: PaginateTripsState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: PaginateTripsState,
  action: SuccessPaginateTripsAction
) =>
  update(state, {
    data: { $set: action.data },
    indicators: { $set: action.indicators },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (state: PaginateTripsState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateTrips = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateTripsTypes = Types;
export const PaginateTripsActions = Creators;
