import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import {
  PaginateIndentsActions,
  PaginateIndentsState,
} from "store/ducks/tracking/indents";

import {
  FetchIndentFilterActions,
  FetchIndentFilterState,
} from "store/ducks/tracking/indent-filters";

import { AuthState } from "store/ducks/auth";

import * as S from "./styles";
import { Scaffold, Paginator } from "components/shared";
import {
  GridIndent,
  IndentFilter,
  IndentIndicators,
} from "components/tracking";

export const ListIndent: React.FC = () => {
  const dispatch = useDispatch();

  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.fetchIndentFilter
  ) as FetchIndentFilterState;

  const { data, loading, pagination, indicators } = useSelector<RootStateOrAny>(
    (state) => state.paginateIndents
  ) as PaginateIndentsState;

  const handlePageChange = useCallback(
    (value) => {
      dispatch(
        FetchIndentFilterActions.success({ ...dataFilter, page: value })
      );
    },
    [dataFilter, dispatch]
  );

  const getData = useCallback(() => {
    const filter = {
      email: (userData?.roles[0] === "Cliente") ? userData?.email : false,
      ...dataFilter
    };

    dispatch(PaginateIndentsActions.request({ ...filter }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataFilter]);

  useEffect(() => {
    getData();
  }, [getData]);


  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          Tracking <span>indent</span>
          {loading && <S.LoadingPage />}
        </h1>
        <IndentFilter />
      </S.PageHeader>
      <S.PageContent>
        <IndentIndicators indicators={indicators} />
        <GridIndent indents={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
