import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "hooks";

import {
  CreateTransitState,
  CreateTransitActions,
} from "store/ducks/settings/transits";

import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";

import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";
import { Input, InputMask, Select } from "components/shared/Form";
import { Scaffold } from "components/shared";
import { statesOptions } from "utils/data/states";
import { typesOptions } from "utils/data/types";
import { countSelectData } from "../Data/countSelect";

import * as S from "./styles";

export const NewTransit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [queryType, setQueryType] = useState("");
  const [originOptions, setOriginOptions] = useState([{}]);
  const [destinyOptions, setDestinyOptions] = useState([{}]);
  const [originValue, setOriginValue] = useState("");
  const [destinyValue, setDestinyValue] = useState("");
  const [typeValue, setTypeValue] = useState(null);

  const { loading } = useSelector<RootStateOrAny, CreateTransitState>(
    (state) => state.createTransit
  );

  const { data: dataCarriers, loading: loadingCarriers } = useSelector<
    RootStateOrAny,
    ListCarriersState
  >((state) => state.listCarriers);

  const { data: dataCities, loading: loadingCities } = useSelector<
    RootStateOrAny,
    ListCitiesState
  >((state) => state.listCities);

  const onSuccess = useCallback(() => {
    history.push("/settings/transits");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          carrier_id: Yup.string().required("Obrigatório"),
          type: Yup.string().required("Obrigatório"),
          ibge_origin: Yup.string().required("Obrigatório"),
          weight: Yup.string().required("Obrigatório"),
          start: Yup.string().required("Obrigatório"),
          count_fractional: Yup.string().required("Obrigatório"),
          deadline_fractional: Yup.string().required("Obrigatório"),
          count_dedicated: Yup.string().required("Obrigatório"),
          deadline_dedicated: Yup.string().required("Obrigatório"),
          valid_until: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        delete data.address_origin;
        delete data.address_destiny;

        if (data.type === "CIDADE") {
          data.ibge_origin = originValue;
          data.ibge_destiny = destinyValue;
          data.km_initial = null;
          data.km_final = null;
        }

        if (data.type === "KM") {
          data.ibge_origin = originValue;
          data.ibge_destiny = null;
        }

        dispatch(CreateTransitActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [destinyValue, dispatch, handleFormErrors, onSuccess, originValue]
  );

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchOrigins = useCallback(
    (option) => {
      setQueryType("origin");
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const fetchDestinations = useCallback(
    (option) => {
      setQueryType("destiny");
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const setCarrier = useCallback((id) => {
    if (formRef.current) return formRef.current.setFieldValue("carrier_id", id);
  }, []);

  const setType = useCallback((type) => {
    setTypeValue(type.value);
    if (formRef.current) return formRef.current.setFieldValue("type", type);
  }, []);

  const setOrigin = useCallback((ibge) => {
    setOriginValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_origin", ibge);
  }, []);

  const setDestiny = useCallback((ibge) => {
    setDestinyValue(ibge);
    if (formRef.current)
      return formRef.current.setFieldValue("ibge_destiny", ibge);
  }, []);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    if (queryType === "origin") {
      setOriginOptions(dataCities);
    } else if (queryType === "destiny") {
      setDestinyOptions(dataCities);
    }
  }, [dataCities, loadingCities, queryType]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Novo Transit time</span>
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Select
                name="carrier_id"
                label="Transportadora"
                isDisabled={loadingCarriers}
                isLoading={loadingCarriers}
                options={dataCarriers}
                onChange={(e: any) => setCarrier(e.value)}
              />
              <Select
                name="type"
                label="Tipo"
                options={typesOptions}
                onChange={(e) => setType(e)}
              />
              <Select
                name="address_origin"
                label="UF"
                options={statesOptions}
                onChange={(e) => fetchOrigins(e)}
              />
              <Select
                name="ibge_origin"
                label="Origem"
                isDisabled={!originOptions}
                options={originOptions}
                onChange={(e: any) => setOrigin(e.ibge)}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="address_destiny"
                label="UF"
                disabled={!originOptions || typeValue !== "CIDADE"}
                options={statesOptions}
                onChange={(e) => fetchDestinations(e)}
              />
              <Select
                name="ibge_destiny"
                label="Destino"
                isDisabled={!destinyOptions || typeValue !== "CIDADE"}
                options={destinyOptions}
                onChange={(e: any) => setDestiny(e.ibge)}
              />
              <Input
                disabled={typeValue !== "KM"}
                name="km_initial"
                type="number"
                label="KM Inicial"
              />
              <Input
                disabled={typeValue !== "KM"}
                name="km_final"
                type="number"
                label="KM Final"
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="weight" label="Peso (Kg)" />
              <Input name="start" label="Início (Dias)" />
              <InputMask name="cut_hour" label="Hora de Corte" mask="99:99" />
              <Input name="valid_until" type="date" label="Validade" />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="count_fractional"
                label="Contagem Fracionado"
                options={countSelectData}
              />
              <Input
                name="deadline_fractional"
                type="number"
                label="Prazo para Fracionado"
              />
              <Select
                name="count_dedicated"
                label="Contagem Lotação"
                options={countSelectData}
              />
              <Input
                name="deadline_dedicated"
                type="number"
                label="Prazo para Lotação"
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : "Cadastrar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
