export const translations = {
  internationalTransitTime: {
    pt: 'Tempo de Trânsito Internacional',
    en: 'International transit time',
    de: 'Internationale Transitzeit',
    es: 'Tiempo de tránsito internacional',
  },
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  csv: {
    pt: 'Exportar CSV',
    en: 'Export CSV',
    de: 'CSV-Datei',
    es: 'Exportar CSV',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
  novoTempo: {
    pt: 'Novo Tempo de Trânsito',
    en: 'New transit time',
    de: 'Neue Laufzeit',
    es: 'Nuevo tiempo de tránsito',
  },
};