import React from "react";
import { format } from "date-fns";
import * as S from "./styles";
import { 
  IndentStatusLabel,
  IndentActions } from "components/tracking";
import { Indent } from "interfaces/indent";

import { useTranslation } from 'hooks';
import { translations } from './translations';

interface IProps {
  indent?: Indent;
  onActionSuccess: () => void;
}

export const IndentTrackingHeader: React.FC<IProps> = ({
  indent,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);

  // const formatLocation = (geo: any) => {
  //   return geo[0]
  //     ? format(new Date(geo[0].created_at), "dd/MM/yyyy HH:mm")
  //     : " ---";
  // };

  if (indent) {
    return (
      <S.Container>
        <IndentStatusLabel status={indent?.status} />
        <S.Wrapper>
        {/* <S.Item>
            <S.Label>BL</S.Label>
            <S.Value>
              {indent.businessLine
                ? indent.businessLine.activity_division
                : "--"}
            </S.Value>
          </S.Item> */}
          <S.Item>
            <S.Label>{getTranslation('origem')}</S.Label>
            <S.Value>
              {indent.emit_nome_municipio}/{indent?.emit_uf}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('destino')}</S.Label>
            <S.Value>
              {indent.dest_nome_municipio}/{indent?.dest_uf}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('dataEmissao')}</S.Label>
            <S.Value>
              {indent.ide_data_emissao
                ? format(
                    new Date(indent?.ide_data_emissao),
                    "dd/MM/yyyy HH:mm"
                  )
                : "----"}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('prazoC')}</S.Label>
            <S.Value>
              {indent.deadline_date
                ? format(new Date(indent?.deadline_date), "dd/MM/yyyy")
                : "----"}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('previsaoEntrega')}</S.Label>
            <S.Value>
              {indent.prediction?.length === 1
                ? format(
                    new Date(
                      indent.prediction[0].delivery_prediction_date
                    ),
                    "dd/MM/yyyy"
                  )
                : "----"}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('dataEntrega')}</S.Label>
            <S.Value>
              {indent.delivery_date
                ? format(new Date(indent.delivery_date), "dd/MM/yyyy")
                : "----"}
            </S.Value>
            {/* <S.Item>
              <S.Label>Localização</S.Label>
              <S.Value>
                {indent.last_city}-{formatLocation(indent.geolocation)}
            </S.Value>
           </S.Item> */}
          </S.Item>
        </S.Wrapper>
        <IndentActions indent={indent} onActionSuccess={onActionSuccess} />
      </S.Container>
    );
  }

  return <></>;
};
