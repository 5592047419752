import styled from "styled-components";
import { PieChartAlt } from "@styled-icons/boxicons-solid";
import { Loading } from "styles/styled-components";
export {
  PageContainer,
  PageHeader,
  PageContent,
  ButtonMini,
  Loading,
} from "styles/styled-components";

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const Icon = styled(PieChartAlt).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
