export const translations = {
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  remetente: {
    pt: 'Remetente',
    en: 'Sender',
    de: 'Absender',
    es: 'Remitente',
  },
  destinatario: {
    pt: 'Destinatário',
    en: 'Recipient',
    de: 'Empfänger',
    es: 'Recipiente',
  },
};