import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation, usePermissions } from 'hooks';
import { translations } from './translations';

import { PopoverMenu } from 'components/shared';

import { SelectedFilterActions } from 'store/ducks/filter';

import * as S from './styles';

const Menu: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { hasEveryPermission } = usePermissions();
  const { getTranslation } = useTranslation(translations);

  const handleOption = useCallback(
    (route) => {
      dispatch(SelectedFilterActions.reset());
      history.push(`/${route}`);
    },
    [history, dispatch]
  );

  const checkPathname = useCallback((path: String, option: Array<String>) => {
    const arrayPath = path.split('/');
    if (option.includes(arrayPath[1])) return true;
    return false;
  }, []);

  const trackingOption = useMemo(() => {
    const trackingList = [
      {
        label: getTranslation('menu', 'tracking', 'notaFiscal'),
        action: () => handleOption('tracking'),
      }
    ];

    if (!hasEveryPermission(['Cliente'])) {
      trackingList.push({
        label: getTranslation('menu', 'tracking', 'indent'),
        action: () => handleOption('indent'),
      });
    }

    return [
      ...trackingList,
      {
        label: getTranslation('menu', 'tracking', 'viagem'),
        action: () => handleOption('trip'),
      }
    ];
  }, [getTranslation, handleOption, hasEveryPermission]);

  const dashboardOptions = useMemo(
    () => {
      const dashboardList = [
        {
          label: getTranslation('menu', 'dashboard', 'performance'),
          action: () => handleOption('dashboard/performace-transportadora'),
          permissions: [
            'Administrador',
            'Administrador CD',
            'Transportadora',
            'Customer Service',
          ],
        },
        {
          label: getTranslation('menu', 'dashboard', 'revenues'),
          action: () => handleOption('dashboard/revenues'),
          permissions: ['Administrador', 'Administrador CD', 'Customer Service'],
        },
      ];

      if (hasEveryPermission(['Transportadora'])) {
        return dashboardList.filter((option) =>
          option.permissions.includes('Transportadora')
        );
      }
  
      return dashboardList;
    }, [getTranslation, hasEveryPermission, handleOption]);

  const settingOption = [
    {
      label: getTranslation('menu', 'configuracoes', 'feriados'),
      action: () => handleOption('settings/holidays'),
    },
    {
      label: getTranslation(
        'menu',
        'configuracoes',
        'internationalTransitTime'
      ),
      action: () => handleOption('settings/internacional-transits'),
    },
    {
      label: getTranslation('menu', 'configuracoes', 'cfops'),
      action: () => handleOption('settings/cfops-restrictions'),
    },
    {
      label: getTranslation('menu', 'configuracoes', 'transitTime'),
      action: () => handleOption('settings/transits'),
    },
    {
      label: getTranslation('menu', 'configuracoes', 'tiposDeJustificativas'),
      action: () => handleOption('settings/justification-types'),
    },
    {
      label: getTranslation(
        'menu',
        'configuracoes',
        'customerJustificationTypes'
      ),
      action: () => handleOption('settings/justificationCustomer-types'),
    },
    {
      label: getTranslation('menu', 'configuracoes', 'tiposDeOperacao'),
      action: () => handleOption('settings/operation-types'),
    },
  ];

  return (
    <S.Container>
      {!hasEveryPermission(['Cliente']) && (
        <PopoverMenu menuItems={dashboardOptions}>
          <S.Option
            active={checkPathname(history.location.pathname, ['dashboard'])}
          >
            {getTranslation('menu', 'dashboard')}
          </S.Option>
        </PopoverMenu>
      )}
      <PopoverMenu menuItems={trackingOption}>
        <S.Option
          active={checkPathname(history.location.pathname, ['tracking'])}
        >
          {getTranslation('menu', 'tracking')}
        </S.Option>
      </PopoverMenu>
      {!hasEveryPermission(['Cliente']) && (
        <S.Option
          active={checkPathname(history.location.pathname, ['comprovantes'])}
          onClick={() => handleOption('comprovantes')}
        >
          {getTranslation('menu', 'comprovantes')}
        </S.Option>
      )}
      {hasEveryPermission(['Administrador']) && (
        <PopoverMenu menuItems={settingOption}>
          <S.Option
            active={checkPathname(history.location.pathname, ['settings'])}
          >
            {getTranslation('menu', 'configuracoes')}
          </S.Option>
        </PopoverMenu>
      )}
    </S.Container>
  );
};

export default Menu;
