import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListCarriersTradeNameActions } from "store/ducks/settings/carriers";

import { Carrier } from "interfaces/carrier";

export function* listCarriersTradeNameRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/carriers?${queryString}`);
    const comboOptions = data.map((carrier: Carrier) => ({
      value: carrier.trade_name,
      label: `${carrier.trade_name}`,
    }));

    yield put(ListCarriersTradeNameActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListCarriersTradeNameActions.failure(errorMessage));
  }
}
