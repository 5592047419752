import React from "react";

import * as S from "./styles";

export const Signature: React.FC = () => {
  return (
    <S.Container>
      <S.Logo />® is a Gera Sinergia platform
    </S.Container>
  );
};
