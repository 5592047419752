export const translations = {
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'Id',
    es: 'Id',
  },
  data: {
    pt: 'Data',
    en: 'Date',
    de: 'Datum',
    es: 'Fecha',
  },
  nome: {
    pt: 'Nome ',
    en: 'Name ',
    de: 'Name ',
    es: 'Nombre ',
   },
};