import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateDeliveryDateActions } from "store/ducks/tracking/update-delivery-date";

export function* updateDeliveryDateRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.put,
      `/invoices-delivery-date/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateDeliveryDateActions.success(data));
    notify("success", "Nota fiscal atualizada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateDeliveryDateActions.failure(errorMessage));
  }
}
