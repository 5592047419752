import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.nav`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Option = styled.button.attrs({
  type: "button",
})<{ active: boolean }>`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  padding: 0 16px;
  transition: 300ms ease;
  color: ${colors.white};
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  &:hover {
    opacity: 0.8;
  }
`;
