import React, { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  CreateOperationCfopState,
  CreateOperationCfopActions,
} from "store/ducks/settings/operation-cfop";
import {
  ListOperationTypesActions,
  ListOperationTypesState,
} from "store/ducks/settings/operation-type";
import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold } from "components/shared";
import { Input, Select, ToggleInput } from "components/shared/Form";

export const NewOperationCfop: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector<
    RootStateOrAny, 
    CreateOperationCfopState
  >(state => state.createOperationCfop);

  const { data: dataOperationTypes, loading: loadingOperationTypes } =
    useSelector<
      RootStateOrAny,
      ListOperationTypesState
    >(state => state.listOperationTypes);

  const onSuccess = useCallback(() => {
    history.push("/settings/cfops-restrictions");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        cfop: Yup.number().required("Obrigatório"),
        category: Yup.string().required("Obrigatório"),
        description: Yup.string().required("Obrigatório"),
      });
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateOperationCfopActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const fetchOperationTypes = useCallback(() => {
    dispatch(ListOperationTypesActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    fetchOperationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Nova Operação CFOP</span>
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input 
                name="cfop"
                label="Código CFOP"
                type="number"
                step="1"
                min="1"
              />
              <Select
                name="operation_type_id"
                label="Tipo de Operação"
                isLoading={loadingOperationTypes}
                isDisabled={loadingOperationTypes}
                options={dataOperationTypes}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="category" label="Categoria" multiline />
              <Input name="description" label="Descrição" multiline />
            </S.FormRow>
            <S.FormRow>
              <ToggleInput
                name="tracking_ignored"
                label="Ignorar esse CFOP no Tracking"
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : "Cadastrar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
