import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateIgnoredCfopsActions } from "store/ducks/settings/ignoredCfop";

export function* paginateIgnoredCfopsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/ignored-cfops?${queryString}`
    );

    const { ignoredCfops, pagination } = responseBody;
    yield put(PaginateIgnoredCfopsActions.success(ignoredCfops, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateIgnoredCfopsActions.failure(errorMessage));
  }
}
