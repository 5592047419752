export const translations = {
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Art',
    es: 'Escribe',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  kmInicial: {
    pt: 'KM Inicial',
    en: 'Initial KM',
    de: 'Anfangs-KM',
    es: 'KM inicial',
  },
  kmFinal: {
    pt: 'KM Final',
    en: 'Final KM',
    de: 'Schluss-KM',
    es: 'Schluss-KM',
  },
  prazoLotacao: {
    pt: 'Prazo para Lotação',
    en: 'Term for Capacity',
    de: 'Begriff für Kapazität',
    es: 'Plazo de Capacidad',
  },
  prazoFracionado: {
    pt: 'Prazo para Fracionado',
    en: 'Term for Fractionated',
    de: 'Begriff für fraktioniert',
    es: 'Término para Fraccionado',
  },
  peso: {
    pt: 'Peso',
    en: 'Weight',
    de: 'Gewicht',
    es: 'Peso',
  },
};