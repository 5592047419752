import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";

import { ListInvoices } from "pages/Tracking";

import { LoginRoutes } from "./login";
import { TrackingRoutes } from "./tracking";
import { SettingsRoutes } from "./settings";
import { DashboardRoutes } from "./dashboard";
import { DeliveryVoucherRoutes } from "./DeliveryVoucher";
import { ReportRoutes } from "./reports";

export const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <PrivateRoute path="/" exact component={ListInvoices} />
      </Switch>
      <LoginRoutes />
      <TrackingRoutes />
      <SettingsRoutes />
      <DashboardRoutes />
      <DeliveryVoucherRoutes />
      <ReportRoutes />
    </>
  );
};
