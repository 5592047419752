import styled, { css } from "styled-components";
import { colors } from "styles";

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  margin-bottom: 72px;
  z-index: 1;
`;

export const LineBackground = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.lightBlueGray};
  position: absolute;
  z-index: 1;
`;

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Step = styled.div<{ active?: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${colors.lightBlueGray};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;

  &:nth-child(3) {
    transform: scale(1.3);
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.orange};
    `}
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
`;

export const StepLine = styled.div<{ active?: boolean }>`
  flex: 1;
  height: 2px;
  background-color: ${colors.lightBlueGray};
  position: relative;
  z-index: 2;

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.orange};
    `}
`;
