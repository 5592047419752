import React from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Indent } from "interfaces/indent";
import * as S from "./styles";
import { IndentStatusIcon } from "components/tracking";
import { useTranslation } from 'hooks';
import { translations } from './translations';

interface IGridIndentsProps {
  indents: Indent[] | Record<string, any>[];
}

interface IIndentProps {
  indent: Indent | Record<string, any>;
}

const Item: React.FC<IIndentProps> = ({ indent }) => {
  const history = useHistory();

  return (
    <S.ItemContainer onClick={() => history.push(`/indent/${indent.id}`)}>
      <S.ItemContent>
      <S.ItemValue>
          {indent.ide_numero_cte || "---"}
        </S.ItemValue>

        <S.ItemValue>
          {indent.ide_data_emissao
            ? format(new Date(indent.ide_data_emissao), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {indent.deadline_date
            ? format(new Date(indent.deadline_date), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {indent.delivery_date
            ? format(new Date(indent.delivery_date), "dd/MM/yyyy HH:mm")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {
            indent.dest_nome_fantasia ?
            indent.dest_nome_fantasia :
            "---"
          }
        </S.ItemValue>
        
        <S.ItemValue>
          {indent.emit_nome_municipio
            ? `${indent.emit_nome_municipio}/${indent.emit_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {indent.dest_nome_municipio
            ? `${indent.dest_nome_municipio}/${indent.dest_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {indent.carrier ? indent.carrier.trade_name : "---"}
        </S.ItemValue>
        <IndentStatusIcon status={indent.status} />
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridIndent: React.FC<IGridIndentsProps> = ({
  indents = [],
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      <S.Header>
        <S.Label>CTe</S.Label>
        <S.Label>{getTranslation('emissao')}</S.Label>
        <S.Label>{getTranslation('prazo')}</S.Label>
        <S.Label>{getTranslation('entrega')}</S.Label>
        <S.Label>{getTranslation('cliente')}</S.Label>
        <S.Label>{getTranslation('origem')}</S.Label>
        <S.Label>{getTranslation('destino')}</S.Label>
        <S.Label>{getTranslation('transportadora')}</S.Label>
      </S.Header>
      {indents.length > 0 &&
        indents.map((indent) => <Item indent={indent} key={indent.id} />)}
    </S.Container>
  );
};
