import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";

import { requestErrorHandler } from "utils";
import {
  ListClientsActions,
  IListClientsRequest,
} from "store/ducks/settings/clients";

import { Client } from "interfaces/client";

export function* listClientsRequest(action: any) {
  try {
    const { query = {}, onSuccess, group } = action as IListClientsRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/clients?${queryString}`);

    let comboOptions: any[] = [];
    if (group) {
      const clientsWithCnpj = data.filter(
        (client: Client) => client.cnpj !== null
      );
      const clientsWithoutCnpj = data.filter(
        (client: Client) => client.cnpj === null
      );

      clientsWithCnpj.map((client: Client) => {
        const sameClients = clientsWithCnpj.filter(
          (sameClient: Client) =>
            sameClient.cnpj.slice(0, 8) === client.cnpj.slice(0, 8)
        );
        let clientIds: number[] = [];
        sameClients.map((sameClient: Client) => clientIds.push(sameClient.id));

        if (
          comboOptions.filter(
            (option) => option.cnpjRoot === client.cnpj.slice(0, 8)
          ).length === 0
        ) {
          return comboOptions.push({
            cnpjRoot: client.cnpj.slice(0, 8),
            label: !client.client_code ? 
            client.trade_name : `${client.client_code} - ${client.trade_name}`,
            value: clientIds,
          });
        }
        return client;
      });

      clientsWithoutCnpj.map((client: Client) =>
        comboOptions.push({
          cnpjRoot: null,
          label: !client.client_code ? 
          client.trade_name : `${client.client_code} - ${client.trade_name}`,
          value: [client.id],
        })
      );
    } else {
      const uniqueData: Client[] = [];
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const sameRootCnpj = (i: any) => {
          if (i.cnpj && item.cnpj) {
            return i.cnpj.slice(0, 8) === item.cnpj.slice(0, 8);
          }
        };

        if (!uniqueData.some(sameRootCnpj)) {
          uniqueData.push(item);
        }
      }

      comboOptions = uniqueData.map((client: Client) => ({
        value: client.id,
        label: !client.client_code ? 
        client.company_name : `${client.client_code} - ${client.company_name}`,
      }));
    }

    yield put(ListClientsActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListClientsActions.failure(errorMessage));
  }
}
