export const translations = {
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
   },
  editarFeriado: {
    pt: 'Editar Feriado',
    en: 'Edit Holiday',
    de: 'Feiertag bearbeiten',
    es: 'Editar vacaciones',
  },
  data: {
    pt: 'Data',
    en: 'Date',
    de: 'Datum',
    es: 'Fecha',
   },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Entfernen',
    es: 'Eliminar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  sim:{
    pt: 'Sim',
    en: 'Yea',
    de: 'Ja',
    es: 'Sí',
  },
  nao:{
    pt: 'Não',
    en: 'No',
    de: 'Nein',
    es: 'No',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  novoFeriado: {
    pt: 'Novo Feriado',
    en: 'New Holiday',
    de: 'Neuer Feiertag',
    es: 'Nuevas vacaciones',
  },
};
