export const translations = {
  menu: {
    dashboard: {
      pt: 'Dashboard',
      en: 'Dashboard',
      de: 'Armaturenbrett',
      es: 'Tablero',
      cargasEmTransito: {
        pt: 'Cargas em Trânsito',
        en: 'Cargos in transit',
        de: 'Ladungen im Transit',
        es: 'Cargas en transito',
      },
      painelPerformance: {
        pt: 'Painel de Perfomance',
        en: 'Performance panel',
        de: 'Leistungspanel',
        es: 'Panel de rendimiento',
      },
      performance: {
        pt: 'Perfomance',
        en: 'Performance',
        de: 'Leistungs',
        es: 'Rendimiento',
      },
      revenues: {
        pt: 'Faturamento',
        en: 'Revenues',
        de: 'Einnahmen',
        es: 'Ingresos',
      },
    },
    tracking: {
      pt: 'Tracking',
      en: 'Tracking',
      de: 'Verfolgung',
      es: 'Ingresos',
      
      notaFiscal: {
        pt: 'Tracking por Notas Fiscais',
        en: 'Tracking by Invoices',
        de: 'Tracking nach Rechnungen',
        es: 'Seguimiento por Facturas',
      },
      viagem: {
        pt: 'Tracking por viagem',
        en: 'Tracking by trip',
        de: 'Tracking - pro Fahrt',
        es: 'Seguimiento por ruta',
      },
      indent: {
        pt: 'Tracking por INDENT',
        en: 'Tracking by INDENT',
        de: 'Tracking durch INDENT',
        es: 'Seguimiento por INDENT',
      },
    },
    comprovantes: {
      pt: 'Comprovantes',
      en: 'Proof',
      de: 'Lieferschein',
      es: 'Recibo'
    },
    configuracoes: {
      pt: 'Configurações',
      en: 'Settings',
      de: 'Einstellungen',
      es: 'Ajustes',
      feriados: {
        pt: 'Feriados',
        en: 'Holidays',
        de: 'Feiertage',
        es: 'Vacaciones',
      },
      cfops: {
        pt: 'Restrições de CFOPs',
        en: 'CFOPs restrictions',
        de: 'Typenbeschränkungen',
        es: 'Restricciones de CFOP',
      },
      transitTime: {
        pt: 'Transit time',
        en: 'Transit time',
        de: 'Transitzeit',
        es: 'Tiempo de transito',
      },
      internationalTransitTime: {
        pt: 'Tempo de Trânsito Internacional',
        en: 'International transit time',
        de: 'Internationale Transitzeit',
        es: 'Tiempo de tránsito internacional',
      },
      tiposDeJustificativas: {
        pt: 'Tipos de Justificativa',
        en: 'Justification Types',
        de: 'Begründungstypen',
        es: 'Tipos de justificación',
      },
      customerJustificationTypes: {
        pt: 'Tipos de Justificativa Customer',
        en: 'Justification Costumer types',
        de: 'Begründungstypen',
        es: 'Tipos de justificación',
      },
      tiposDeOperacao: {
        pt: 'Tipos de Operação',
        en: 'Operation Types',
        de: 'Operationsarten',
        es: 'Tipos de Operaciones',
      },
    },
    relatorio: {
      pt: 'Relatório',
      en: 'Report',
      de: 'Prüfbericht',
      es: 'Informe',
      downtime: {
        pt: 'Tempo parado',
        en: 'DownTime',
        de: 'Ausfallzeit',
        es: 'Falta del tiempo',
      },
    },
  },
};
