import styled from "styled-components";
import logo from "assets/images/logo-menu.png";
import { colors } from "styles";

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: ${colors.deepPurple};

  .profile {
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
`;

export const Logo = styled.img.attrs({
  src: logo,
})``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
`;
