import React from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Invoice } from "interfaces/invoice";
import { InvoiceStatusIcon } from "components/tracking";

import { useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";

interface IGridInvoicesProps {
  invoices: Invoice[] | Record<string, any>[];
}

interface IInvoiceProps {
  invoice: Invoice | Record<string, any>;
}

const Item: React.FC<IInvoiceProps> = ({ invoice }) => {
  const history = useHistory();

  return (
    <S.ItemContainer onClick={() => history.push(`/invoice/${invoice.id}`)}>
      <S.ItemContent>
        <S.ItemValue>
          {invoice.ide_numero_nf}-{invoice.ide_serie}
        </S.ItemValue>

        <S.ItemValue>
          {invoice.ide_data_emissao
            ? format(new Date(invoice.ide_data_emissao), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.deadline_date
            ? format(new Date(invoice.deadline_date), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.delivery_date
            ? format(new Date(invoice.delivery_date), "dd/MM/yyyy HH:mm")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.emit_nome_municipio
            ? `${invoice.emit_nome_municipio}/${invoice.emit_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.dest_nome_municipio
            ? `${invoice.dest_nome_municipio}/${invoice.dest_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.carrier ? invoice.carrier.trade_name : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.businessLine ? invoice.businessLine.description : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {(invoice.client?.trade_name
            ? invoice.client?.trade_name
            : invoice.dest_razao_social &&
              invoice.dest_razao_social.substring(0, 25)) || "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.picking ? invoice.picking.code : "---"}
        </S.ItemValue>
        <InvoiceStatusIcon status={invoice.status} />
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridInvoices: React.FC<IGridInvoicesProps> = ({
  invoices = [],
}) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation("nf")}</S.Label>
        <S.Label>{getTranslation("emissao")}</S.Label>
        <S.Label>{getTranslation("prazo")}</S.Label>
        <S.Label>{getTranslation("entrega")}</S.Label>
        <S.Label>{getTranslation("origem")}</S.Label>
        <S.Label>{getTranslation("destino")}</S.Label>
        <S.Label>{getTranslation("transportadora")}</S.Label>
        <S.Label>{getTranslation("businessLine")}</S.Label>
        <S.Label>{getTranslation("cliente")}</S.Label>
        <S.Label>{getTranslation("picking")}</S.Label>
      </S.Header>
      {invoices.length > 0 &&
        invoices.map((invoice) => <Item invoice={invoice} key={invoice.id} />)}
    </S.Container>
  );
};
