export const translations = {
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  nf: {
    pt: 'NF',
    en: 'Invoice',
    de: 'Rechnung',
    es: 'Facturas',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Portador',
  },
  inicio: {
    pt: 'Inicio',
    en: 'Start',
    de: 'Anfang',
    es: 'Inicio',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  entrega: {
    pt: 'Entrega',
    en: 'Delivery',
    de: 'Lieferung',
    es: 'Entrega',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Auftraggeber',
    es: 'Cliente',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  emissao: {
    pt: 'Emissão',
    en: 'Issue',
    de: 'Problem',
    es: 'Asunto',
  },
  picking: {
    pt: 'Cód. Picking',
    en: 'Picking Code',
    de: 'Code Pflücken',
    es: 'Código cosecha',
  },
  comprovanteAguardando: {
    pt: 'Aguardando comprovante',
    en: 'Waiting for proof',
    de: 'Warten auf Beweis',
    es: 'Esperando pruebas',
  },
  comprovanteAprovado: {
    pt: 'Comprovante aprovado',
    en: 'Approved proofe',
    de: 'Anerkannter Nachweis',
    es: 'Comprobante aprobado',
  },
  comprovanteReprovado: {
    pt: 'Comprovante reprovado',
    en: 'Failed proof',
    de: 'Fehlgeschlagener Beweis',
    es: 'Cupón fallido',
  },
  aguardandoAprovado: {
    pt: 'Aguardando aprovação',
    en: 'Waiting for approval',
    de: 'Warten auf die Bestätigung',
    es: 'Esperando aprobación',
  },
};