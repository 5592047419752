import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { InvoiceCountriesActions } from "store/ducks/tracking/invoices";

export function* invoiceCountriesRequest(action: any) {
  try {
    const { onSuccess } = action as IFechRequest;
    const { data } = yield call(api.get, `/invoices/countries`);
 
    const emitOptions = data.emit.map( (value: any) => ({
      value: value.emit_nome_pais,
      label: value.emit_nome_pais
    }))

    const destOptions = data.dest.map( (value: any) => ({
      value: value.dest_nome_pais,
      label: value.dest_nome_pais
    }))

    const countriesOptions = {
      emit: emitOptions,
      dest: destOptions
    }

    yield put(InvoiceCountriesActions.success(countriesOptions));
    if (onSuccess) onSuccess(countriesOptions);
  } catch (error) {
    const { onFailure } = action as IFechRequest;
    if (onFailure) onFailure();
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(InvoiceCountriesActions.failure(errorMessage));
  }
}
