export const translations = {
  emissao: {
    pt: 'Emissão',
    en: 'Issue',
    de: 'Problem',
    es: 'Asunto',
  },
  dataEmissao: {
    pt: 'Data emissão',
    en: 'Issuance date',
    de: 'Ausstellungsdatum',
    es: 'Asunto',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  dataEntrega: {
    pt: 'Data Entrega',
    en: 'Delivery date',
    de: 'Liefertermin',
    es: 'Fecha de entrega',
  },
  prazoC: {
    pt: 'Prazo contratado',
    en: 'Contracted term',
    de: 'Vertragslaufzeit',
    es: 'Plazo contratado',
  }, 
  previsaoEntrega: {
    pt: 'Previsão de entrega',
    en: 'Delivery forecast',
    de: 'Lieferprognose',
    es: 'Previsión de entrega',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Bestimmung',
    es: 'Destino',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  },
};