import React, { useState } from "react";

import * as S from "./styles";
import { Invoice } from "interfaces/invoice";
import { moeda } from "utils/formatters";
import { translations } from "./translations";
import { useTranslation } from "hooks";
import { peso } from "utils";

interface IProps {
  invoice: Invoice;
}

export const ListInvoiceItems: React.FC<IProps> = ({ invoice }) => {
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations)

  return (
    <S.Container>
      <S.Header>
        <S.Total>
          <S.TotalLabel>{getTranslation('valorTotal')}</S.TotalLabel>
          <S.TotalValue>
            {showPrice ? moeda(invoice.total_valor_total_nf) : "••••••••••••"}
            <S.ButtonEye onClick={() => setShowPrice(!showPrice)}>
              {showPrice ? <S.IconEyeSlash /> : <S.IconEye />}
            </S.ButtonEye>
          </S.TotalValue>
        </S.Total>
        <S.Total>
          <S.TotalLabel>{getTranslation('pesoLiquido')}</S.TotalLabel>
          <S.TotalValue>{peso(invoice.transp_peso_liquido_kg)} KG</S.TotalValue>
        </S.Total>
        <S.Total>
          <S.TotalLabel>{getTranslation('pesoBruto')}</S.TotalLabel>
          <S.TotalValue>{peso(invoice.transp_peso_bruto) } KG</S.TotalValue>
        </S.Total>
        <S.Total>
          <S.TotalLabel>{getTranslation('volume')}</S.TotalLabel>
          <S.TotalValue>
            {invoice.transp_qtd_volumes_transportados
              .toString()
              .padStart(3, "0")}
          </S.TotalValue>
        </S.Total>
      </S.Header>
      <S.GridHeader>
        <S.Label>{getTranslation('codigoP')}</S.Label>
        <S.Label>{getTranslation('desc')}</S.Label>
        <S.Label>{getTranslation('lote')}</S.Label>
        <S.Label>{getTranslation('loteQuant')}</S.Label>
        <S.Label>{getTranslation('pedido')}</S.Label>
        <S.Label>{getTranslation('quantidade')}</S.Label>
        <S.Label>{getTranslation('valorICMS')}</S.Label>
        <S.Label>{getTranslation('ipi')}</S.Label>
        <S.Label>{getTranslation('valorPis')}</S.Label>
        <S.Label>{getTranslation('valorConfins')}</S.Label>
        <S.Label>{getTranslation('valorUnitario')}</S.Label>
        <S.Label>{getTranslation('valorTotal')}</S.Label>
      </S.GridHeader>
      {invoice.items.map((item) => (
        <S.Item key={item.id}>
          <S.Value>{item.prod_codigo_produto}</S.Value>
          <S.Value>{item.prod_descricao_rpoduto}</S.Value>
          <S.Value>{item.prod_numero_lote_produto || "---"}</S.Value>
          <S.Value className="right">{peso(item.prod_quantidade_lote_produto) || "---"}</S.Value>
          <S.Value>{item.prod_numero_pedido_compra}</S.Value>
          <S.Value className="right">
            {peso(item.prod_quantidade_comercial)} {item.prod_unidade_comercial}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.imposto_icms_valor_imposto) : "••••••"}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.imposto_ipi_valor_imposto) : "••••••"}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.imposto_pis_valor_pis) : "••••••"}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.imposto_cofins_valor_cofins) : "••••••"}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.prod_valor_unitario_tributacao) : "••••••"}
          </S.Value>
          <S.Value  className="right">
            {showPrice ? moeda(item.prod_valor_total_bruto_produtos) : "••••••"}
          </S.Value>
        </S.Item>
      ))}
    </S.Container>
  );
};
