import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { format, addMinutes } from "date-fns";

import {
  UpdateIndentJustificationCustomerActions,
} from "store/ducks/tracking/indent-justificationCustomer";

import * as S from "./styles";
import { IndentJustificationCustomer } from "interfaces/indent-justificationCustomer";

interface ItemProps {
  indentJustification: IndentJustificationCustomer;
  onUpdate: Function;
}

interface Props {
  justifications: IndentJustificationCustomer[];
  onUpdate: () => void;
}

const Item: React.FC<ItemProps> = ({ indentJustification }) => {
  
  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.ItemValue>{indentJustification.justificationCustomerType.description}</S.ItemValue>
        <S.ItemValue>
          Criado em:{" "}
          {format(new Date(indentJustification.created_at), "dd/MM/yyyy HH:mm")}
        </S.ItemValue>
        <S.ItemValue>
          {indentJustification.user && indentJustification.user.name ? `Usuário: ${indentJustification.user.name}` : 'Usuário: -'}
        </S.ItemValue>
        <S.ItemValue>
          {`Prorrogação: ${format(addMinutes(new Date(indentJustification.created_at), parseInt(indentJustification.interval)), "dd/MM/yyyy HH:mm")}`}
        </S.ItemValue>
        {!indentJustification.approved_at &&
          !indentJustification.rejected_at && <S.IconFileCheck />}
        {indentJustification.approved_at && <S.IconFileCheck active />}
        {indentJustification.rejected_at && <S.IconFileX />}
      </S.ItemContent>
      {indentJustification.commentary && (
        <S.Commentary>{indentJustification.commentary}</S.Commentary>
      )}
    </S.ItemContainer>
  );
};

export const ListIndentJustificationsCustomer: React.FC<Props> = ({
  justifications,
  onUpdate,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    (id, data) => {
      dispatch(UpdateIndentJustificationCustomerActions.request(id, data, onUpdate));
    },
    [dispatch, onUpdate]
  );
  return (
    <S.Container>
      <S.Title>Justificativas Customer</S.Title>
      {justifications && (
        <S.ItemsWrapper>
          {justifications.map((indentJustification) => (
            <Item
              key={indentJustification.id}
              indentJustification={indentJustification}
              onUpdate={handleUpdate}
            />
          ))}
        </S.ItemsWrapper>
      )}
    </S.Container>
  );
};
