import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { InvoiceDeliveryVoucherActions } from "store/ducks/tracking/invoices";

export function* InvoicesDeliveryVoucherRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/invoices/delivery-voucher?${queryString}`
    );

    const { invoices, pagination, indicators } = responseBody;
    yield put(
      InvoiceDeliveryVoucherActions.success(invoices, pagination, indicators)
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(InvoiceDeliveryVoucherActions.failure(errorMessage));
  }
}
