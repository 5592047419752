import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateJustificationTypeActions } from "store/ducks/settings/justification-types";

export function* createJustificationTypeRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      api.post,
      `/justification-types`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateJustificationTypeActions.success(data));
    notify("success", "Tipo de justificativa cadastrada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateJustificationTypeActions.failure(errorMessage));
  }
}
