import { call, put } from "redux-saga/effects";
import { api, queryBuilder, notify } from "services";
import { requestErrorHandler } from "utils";
import { IFechRequest } from "interfaces/fetch-duck";
import { DownloadDeliveryVoucherActions } from "store/ducks/tracking/delivery-vouchers";

interface IDownloadDeliveryVoucherRequest extends IFechRequest {
  query?: Record<string, any>;
}

export function* downloadDeliveryVoucherRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IDownloadDeliveryVoucherRequest;
    const queryString = queryBuilder(query);
    const { data: responseBody } = yield call(
      api.get,
      `/download-voucher?${queryString}`
    );

    yield put(DownloadDeliveryVoucherActions.success(responseBody));
    if (onSuccess) onSuccess(responseBody);
  } catch (error) {
    const { onFailure } = action as IDownloadDeliveryVoucherRequest;
    if (onFailure) onFailure();
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(DownloadDeliveryVoucherActions.failure(errorMessage));
  }
}
