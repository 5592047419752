import styled from "styled-components";
import { PackageDependents } from "@styled-icons/octicons";
import { Truck, IdCard } from "@styled-icons/boxicons-solid";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  min-width: 270px;
  min-height: 220px;
  padding: 32px;
  border-radius: 4px;
  background-color: white;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px ${colors.darkRgba25} solid;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  font-family: ${fonts.GilroyBold};
  font-size: 16px;
  color: ${colors.gray4};
`;

export const Label = styled.h6`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray4};
  margin-bottom: 4px;
`;

export const Value = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.gray4};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 25px;
`;

export const IconSender = styled(PackageDependents).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconCarrier = styled(Truck).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;

export const IconDriver = styled(IdCard).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.gray4};
`;
