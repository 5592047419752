import React, { useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api';

import marker from 'assets/images/markerTruck.png';
import markerWarehouse from 'assets/images/marker-warehouse.png';
import markerClient from 'assets/images/marker-client.png';
import { useTranslation } from 'hooks';
import { translations } from './translations';
import * as S from './styles';
import { Invoice } from 'interfaces/invoice';

interface IProps {
  invoice: Invoice;
}

const containerMapStyle = {
  width: '100%',
  height: '100%',
};

interface IDirectionOptions {
  origin: string;
  destination: string;
  travelMode: string;
}

interface ITruckPosition {
  lat: number;
  lng: number;
}

const DELIVERED_OCCURRENCES_IDS = [1, 2, 100, 25];
const KEY = process.env.REACT_APP_GOOGLE_API_KEY_SINERGIA;

export const MapTrackingInvoice: React.FC<IProps> = ({ invoice }) => {
  const [truckPositions, setTruckPositions] = useState<ITruckPosition[]>([]);
  const [directions, setDirections] = useState<any>(null);
  const [route, setRoute] = useState<any>(null);
  const [lastPosition, setLastPosition] = useState<any>(null);
  const [destinationMarker, setDestinationMarker] = useState<any>(null);
  const [infoStart, setInfoStart] = useState<boolean>(false);
  const [infoEnd, setInfoEnd] = useState<boolean>(false);
  const [infoCarrier, setInfoCarrier] = useState<boolean>(false);
  const [realDestiny, setRealDestiny] = useState<any>('');
  const [isRedespacho, setIsRedespacho] = useState(false);
  const { getTranslation } = useTranslation(translations);

  const [directionOptions, setDirectionOptions] =
    useState<IDirectionOptions | null>(null);

  const fetchTruckPositions = useCallback(() => {
    const { geolocation } = invoice;
    if (geolocation && geolocation?.length > 0 && route) {
      let positions = [];

      geolocation.forEach(
        ({ longitude, latitude, occurrence_type_id }, index) => {
          if (longitude && latitude) {
            if (Number(longitude) !== 0 || Number(latitude) !== 0) {
              positions.push({
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              });
            }
            if (DELIVERED_OCCURRENCES_IDS.includes(occurrence_type_id)) {
              positions.unshift(route.endLocation);
            }
          }
        }
      );

      positions.push(route.startLocation);
      return setTruckPositions(positions);
    }
  }, [invoice, route]);

  const onDirectionService = useCallback(
    (result) => {
      if (result?.status === 'OK') {
        const startLocation = {
          lat: result.routes[0].legs[0].start_location.lat(),
          lng: result.routes[0].legs[0].start_location.lng(),
        };
        const endLocation = {
          lat: result.routes[0].legs[0].end_location.lat(),
          lng: result.routes[0].legs[0].end_location.lng(),
        };
        if (!destinationMarker) {
          setDestinationMarker(endLocation);
        }
        setRoute({ startLocation, endLocation });
        setDirections(result);
      }
    },
    [destinationMarker]
  );

  const fetchLastPosition = useCallback(() => {
    if (invoice) {
      if (
        invoice.status === 'entregue' ||
        invoice.status === 'entregue-cliente' ||
        invoice.status === 'entregue-atraso'
      ) {
        const position = destinationMarker;
        setLastPosition(position);
      } else {
        if (invoice.geolocation?.length) {
          const position = {
            lat: Number(invoice.geolocation[0].latitude),
            lng: Number(invoice.geolocation[0].longitude),
          };
          setLastPosition(position);
        }
      }
    }
  }, [invoice, destinationMarker]);

  const fetchDirectionOptions = useCallback(
    (destination: any) => {
      const options = {
        origin: `${invoice.emit_nome_pais}, ${
          invoice.emit_cep &&
          invoice.emit_cep.replace(/^(\d{5})(\d{3})/, '$1-$2')
        }`,
        destination,
        travelMode: 'DRIVING',
      };

      setDirectionOptions(options);
    },
    [invoice.emit_cep, invoice.emit_nome_pais]
  );

  const renderInfoStartLocation = useCallback(() => {
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoStart(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>Centro</S.Label>
        <S.Value>
          {invoice.company?.code} - {invoice.company?.trade_name}
        </S.Value>
        <S.Label>Endereço</S.Label>
        <S.Value>
          {realDestiny
            ? realDestiny
            : `${invoice.company?.address_street}, ${invoice.company?.address_number}, ${invoice.company?.address_neighborhood}, ${invoice.company?.address_city}/${invoice.company?.address_state}`}
        </S.Value>
      </S.Box>
    );
  }, [invoice, realDestiny]);

  const renderInfoEndLocation = useCallback(() => {
    let destiny = '';
    if (invoice.redespacho && invoice.transp_razao_social) {
      destiny = invoice.transp_razao_social;
    } else {
      destiny =
        invoice.dest_uf !== 'EX' && invoice.client
          ? `${invoice.client.client_code} - ${invoice.client.company_name}`
          : `${invoice?.dest_razao_social}`;
    }

    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoEnd(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>{invoice.redespacho ? 'Redespacho' : 'Cliente'}</S.Label>
        <S.Value>{destiny}</S.Value>
        <S.Label>Endereço</S.Label>
        <S.Value>{realDestiny}</S.Value>
      </S.Box>
    );
  }, [invoice, realDestiny]);

  const renderInfoCarrier = useCallback(() => {
    return (
      <S.Box>
        <S.ButtonClose onClick={() => setInfoCarrier(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Label>{getTranslation('transportadora')}</S.Label>
        <S.Value>
          {invoice.carrier?.carrier_code} - {invoice.carrier?.trade_name}
        </S.Value>
      </S.Box>
    );
  }, [getTranslation, invoice]);

  useEffect(() => {
    if (invoice) {
      let destination_adress: any = null;
      if (invoice.redespacho) {
        destination_adress = `${invoice.transp_endereco_completo}, ${invoice.transp_nome_municipio}/${invoice.transp_uf}`;
        setIsRedespacho(true);
      } else if (invoice.entrega_cep) {
        destination_adress = `${invoice.entrega_logradouro}, ${invoice.entrega_numero}, ${invoice.entrega_nome_municipio}, ${invoice.entrega_nome_municipio}/${invoice.entrega_uf}`;
      } else if (invoice.dest_cep) {
        destination_adress = `${invoice.dest_logradouro}, ${invoice.dest_numero}, ${invoice.dest_nome_municipio}/${invoice.dest_uf}, ${invoice.dest_cep}`;
      } else if (invoice.harbor) {
        if (invoice.harbor.lat && invoice.harbor.long) {
          destination_adress = {
            lat: invoice.harbor.lat,
            lng: invoice.harbor.long,
          };
        } else {
          destination_adress = `Brasil, ${invoice.harbor_description}`;
        }
      } else if (invoice.harbor_description) {
        destination_adress = `Brasil, ${invoice.harbor_description}`;
        if (invoice.harbor_description === 'SANTOS') {
          destination_adress = `Brasil, PORTO DE SANTOS`;
        }
      }

      setRealDestiny(destination_adress);
      fetchDirectionOptions(destination_adress);
    }
  }, [invoice, fetchDirectionOptions]);

  useEffect(() => {
    fetchLastPosition();
  }, [fetchLastPosition]);

  useEffect(() => {
    fetchTruckPositions();
  }, [fetchTruckPositions]);

  return (
    <S.Container>
      <S.LegendBox>
        <S.Legend>
          <S.Line className="blue" /> {getTranslation('rotaG')}
        </S.Legend>
        <S.Legend>
          <S.Line className="purple" /> {getTranslation('rotaTransportadora')}
        </S.Legend>
      </S.LegendBox>
      {KEY && (
        <LoadScript googleMapsApiKey={KEY} mapIds={['87412a572f003751']}>
          <GoogleMap
            mapContainerStyle={containerMapStyle}
            zoom={10}
            clickableIcons={false}
            options={{ mapId: '87412a572f003751' }}
          >
            {route && (
              <>
                {infoStart && (
                  <OverlayView
                    mapPaneName="floatPane"
                    position={route.startLocation}
                  >
                    {renderInfoStartLocation()}
                  </OverlayView>
                )}
                <Marker
                  position={route.startLocation}
                  icon={markerWarehouse}
                  zIndex={990}
                  clickable
                  onClick={() => setInfoStart(true)}
                />
                {infoEnd && (
                  <OverlayView
                    mapPaneName="floatPane"
                    position={route.endLocation}
                  >
                    {renderInfoEndLocation()}
                  </OverlayView>
                )}
                <Marker
                  position={route.endLocation}
                  icon={isRedespacho ? markerWarehouse : markerClient}
                  zIndex={991}
                  clickable
                  onClick={() => setInfoEnd(true)}
                />
                <Marker
                  position={route.startLocation}
                  icon={markerWarehouse}
                  zIndex={990}
                  clickable
                  onClick={() => setInfoStart(true)}
                />
                {invoice.redespacho && (
                  <Marker
                    position={destinationMarker}
                    icon={markerClient}
                    zIndex={990}
                  />
                )}
              </>
            )}
            {lastPosition && (
              <>
                {infoCarrier && (
                  <OverlayView mapPaneName="floatPane" position={lastPosition}>
                    {renderInfoCarrier()}
                  </OverlayView>
                )}
                <Marker
                  position={lastPosition}
                  icon={marker}
                  zIndex={992}
                  clickable
                  onClick={() => setInfoCarrier(true)}
                />
              </>
            )}

            {directions && (
              <DirectionsRenderer options={{ directions: directions }} />
            )}
            {directionOptions && (
              <DirectionsService
                options={directionOptions}
                callback={onDirectionService}
              />
            )}
            {truckPositions.length > 0 && (
              <Polyline
                visible={true}
                path={truckPositions}
                options={{
                  geodesic: true,
                  strokeColor: '#991781',
                  strokeOpacity: 1.0,
                  strokeWeight: 4,
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </S.Container>
  );
};
