import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { JustificationType } from "interfaces/justification-type";

interface IGridJustificationTypesProps {
  types: JustificationType[] | Record<string, any>[];
}

interface IJustificationTypeProps {
  type: JustificationType | Record<string, any>;
}

const Item: React.FC<IJustificationTypeProps> = ({ type }) => {
  const history = useHistory();
  return (
    <S.ItemContainer
      onClick={() => history.push(`/settings/justification-types/${type.id}`)}
    >
      <S.ItemContent>
        <S.ItemValue>{type.id || "--"}</S.ItemValue>
        <S.ItemValue>{type.name || "--"}</S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridJustificationTypes: React.FC<IGridJustificationTypesProps> = ({
  types = [],
}) => {
  return (
    <S.Container>
      <S.Header>
        <S.Label>Id</S.Label>
        <S.Label>Nome</S.Label>
      </S.Header>
      {types.length > 0 &&
        types.map((type) => <Item type={type} key={type.id} />)}
    </S.Container>
  );
};
