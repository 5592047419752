import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { isValid } from "date-fns";

import {
  CreateHolidayState,
  CreateHolidayActions,
} from "store/ducks/settings/holidays";

import { useValidation,useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";
import { Scaffold, Toggle } from "components/shared";
import { Input, InputMask } from "components/shared/Form";

export const NewHoliday: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [fixed, setFixed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createHoliday
  ) as CreateHolidayState;

  const onSuccess = useCallback(() => {
    history.push("/settings/holidays");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          holiday_date: Yup.mixed()
            .required("Obrigatório")
            .test({
              name: "test-date",
              message: "Data inválida",
              test: async (value) => {
                const inputDate = value.split("/");
                const date = new Date(
                  `${inputDate[2]}-${inputDate[1]}-${inputDate[0]}`
                );
                if (isValid(date)) return true;
                return false;
              },
            }),
          name: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (fixed) {
          data.fixed = true;
        } else {
          data.fixed = null;
        }

        const date = data.holiday_date.split("/");
        data.holiday_date = new Date(`${date[2]}-${date[1]}-${date[0]}`);

        dispatch(CreateHolidayActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, fixed, handleFormErrors, onSuccess]
  );

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("titulo")} <span>{getTranslation("novoFeriado")}</span>
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation("voltar")}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <InputMask name="holiday_date" label={getTranslation("data")} mask="99/99/9999" />
              <Input name="name" label={getTranslation("nome")} />
            </S.FormRow>
            <S.FormRow>
              <Toggle
                label="Fixo"
                options={[getTranslation("sim"),getTranslation("nao")]}
                onSelect={setFixed}
                active={fixed}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
