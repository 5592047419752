export const translations = {
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  novotipoJusti: {
    pt: 'Tipo de Justificativa Customer',
    en: 'Customer Justification Type',
    de: 'Kundenbegründungstyp',
    es: 'Tipo de justificación de cliente',
  },
  novoJusti: {
    pt: 'Novo Tipo de Justificativa Customer',
    en: 'New Customer Justification Type',
    de: 'Neuer Kundenbegründungstyp',
    es: 'Nuevo tipo de justificación de cliente',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'Id',
    es: 'Id',
  },
  nome: {
    pt: 'Nome: ',
    en: 'Name: ',
    de: 'Name: ',
    es: 'Nombre: ',
   },
   aprovado: {
    pt: 'Aprovação',
    en: 'Approval',
    de: 'Bestätigung',
    es: 'Aprobación',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Speichern',
    es: 'Guardar',
  },
};