import styled from "styled-components";
import { colors } from "styles";
import { Receipt } from "@styled-icons/boxicons-regular";
import { Button } from "styles/styled-components";
export {
  Button,
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
} from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 680px;
  max-height: 90vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px ${colors.darkRgba25} solid;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const HeaderItem = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
  margin-left: 16px;
`;

export const TitleInfo = styled.h1`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.deepPurple};
`;

export const Text = styled.div`
  font-size: 16px;
  color: ${colors.dark};
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  border-right: none;
  margin-top: 25px;

  button:first-child {
    margin-right: 16px;
    background: ${colors.warmGray6};
    color: #000;
  }
`;

export const SubmitButton = styled(Button)`
  border: 1px;
`;

export const IconHeader = styled(Receipt).attrs({ size: 25 })`
  color: ${colors.black};
`;
