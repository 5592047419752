import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/list-duck";
import { OperationCfop } from "interfaces/operation-cfop";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_OPERATION_CFOPS_" }
);

export interface ListOperationCfopsState extends IState {
  data: OperationCfop[];
}

interface ISuccessListOperationCfopsAction extends ISuccessAction {
  data: OperationCfop[];
}

const INITIAL_STATE: ListOperationCfopsState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ListOperationCfopsState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: ListOperationCfopsState, action: ISuccessListOperationCfopsAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: ListOperationCfopsState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listOperationCfops = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListOperationCfopsTypes = Types;
export const ListOperationCfopsActions = Creators;
