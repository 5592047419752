import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  FetchIndentActions,
  FetchIndentState,
} from "store/ducks/tracking/indents";
import { Invoice } from 'interfaces/invoice';

import { cnpj } from "utils/formatters";
import * as S from "./styles";

import { useTranslation } from "hooks";
import { translations } from "./translations";

import { Scaffold, Modal } from "components/shared";
import {
  IndentTrackingHeader,
  MapTrackingIndent,
  IndentTrackingProgress,
  IndentTrackingBox,
  ListIndentJustifications,
  ListIndentJustificationsCustomer,
  InvoiceInfoModal,
  IndentActivities
} from "components/tracking";
import { ActivityType, IndentActivity } from 'interfaces/indent-activity';


interface IParams {
  id: string;
}

export const ShowIndent: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [, setInvoice] = useState<Invoice | null>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { loading, data } = useSelector<RootStateOrAny>(
    (state) => state.fetchIndent
  ) as FetchIndentState;

  const onError = useCallback(() => {
    history.goBack();
  }, [history]);

  const onFetchIndent = useCallback((indent:any) => {
    if(indent.invoices && indent.invoices[0]) {
      setInvoice(indent.invoices[0].invoice);
    }
  }, []);

  const getData = useCallback(() => {
    dispatch(FetchIndentActions.request(id, onFetchIndent, onError));
  }, [dispatch, id, onFetchIndent, onError]);

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const renderActivities = (
    activities: IndentActivity[],
    type: 'activity' | 'justification'
  ) => {
    let filteredJustification = activities.filter(
      (activity) => activity.type === ActivityType.activity
    );
    if (type === 'justification') {
      filteredJustification = activities.filter(
        (activity) => activity.type === ActivityType.justification
      );
    }

    return <IndentActivities type={type} activities={filteredJustification} />;
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(FetchIndentActions.reset());
    };
  }, [dispatch, getData]);

  return (
    <Scaffold>
      {data && (
        <Modal isOpen={modalOpen}>
          <InvoiceInfoModal 
          data={data?.client}
          closeModal={closeModal}
          />
        </Modal>
      )}

      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          CTE{" "}
          <span>
            {data?.ide_numero_cte}-{data?.ide_serie}
          </span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation('voltar')}
        </S.ButtonMini>
      </S.PageHeader>
      {
        data && (
          <>
            <MapTrackingIndent indent={data} />
            <S.PageContent>
              <IndentTrackingHeader indent={data} onActionSuccess={getData} />
              <IndentTrackingProgress indent={data} />
              {data.justification && data.justification.length > 0 && (
              <ListIndentJustifications
                justifications={data.justification}
                onUpdate={getData}
              />
            )}
            {data.justificationCustomer &&
              data.justificationCustomer.length > 0 && (
                <ListIndentJustificationsCustomer
                  justifications={data.justificationCustomer}
                  onUpdate={getData}
                />
              )}
              <S.InvoiceBoxWrapper>
                <IndentTrackingBox
                  data={{
                    type: 'carrier',
                    title: getTranslation('transportadora'),
                    name: data.emit_nome_fantasia,
                    cnpj: cnpj(data.emit_cnpj),
                    address: `${data.emit_nome_municipio}/${data.emit_uf}, ${data.emit_cep}`,
                  }}
                />
                {data.carrier && (
                  <IndentTrackingBox
                    data={{
                      type: 'sender',
                      title: getTranslation('remetente'),
                      name: data.rem_nome_fantasia,
                      cnpj: cnpj(data.rem_cnpj),
                      address: `${data.rem_logradouro}, ${data.rem_numero}, ${data.rem_nome_municipio}/${data.rem_uf}, ${data.rem_cep}`,
                    }}
                  />
                )}
                <IndentTrackingBox
                  data={{
                    type: 'destination',
                    title: getTranslation('destinatario'),
                    name: data.dest_nome_fantasia,
                    cnpj: cnpj(data.dest_cnpj),
                    address: `${data.dest_logradouro}, ${data.dest_numero}, ${data.dest_nome_municipio}/${data.dest_uf}, ${data.dest_cep}`,
                  }}
                  openModal={openModal}
                />
                {/* {data.toma_cnpj && (
                  <IndentTrackingBox
                    data={{
                      type: "tomador",
                      title: "Tomador",
                      name: data.toma_nome_fantasia,
                      cnpj: cnpj(data.toma_cnpj),
                      address: `${data.toma_logradouro}, ${data.toma_numero}, ${data.toma_nome_municipio}, ${data.toma_nome_municipio}/${data.toma_uf}`,
                    }}
                  />
                )} */}
              </S.InvoiceBoxWrapper>
              {data.activity && (
              <S.ActivitiesWrapper>
                {renderActivities(data.activity, 'activity')}
                {renderActivities(data.activity, 'justification')}
              </S.ActivitiesWrapper>
            )}
            </S.PageContent>
          </>
        )
      }
    </Scaffold>
  );
};
