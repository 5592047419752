import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { OperationCfop } from "interfaces/operation-cfop";

interface IGridOperationCfopsProps {
  cfops: OperationCfop[];
}

interface IOperationCfopProps {
  cfop: OperationCfop;
}

const Item: React.FC<IOperationCfopProps> = ({ cfop }) => {
  const { 
    cfop: code, 
    operation_type_id, 
    type, 
    description, 
    tracking_ignored,
  } = cfop;
  const history = useHistory();
  return (
    <S.ItemContainer
      onClick={() => history.push(`/settings/cfops-restrictions/${cfop.id}`)}
    >
      <S.ItemContent>
        <S.ItemValue>{code || "---"}</S.ItemValue>
        <S.ItemValue>
          {operation_type_id ? type.name : "---"}
        </S.ItemValue>
        <S.ItemValue>
          <div className="ellipsis">{description || "---"}</div>
        </S.ItemValue>
        <S.ItemValue>
          <span className={`badged ${tracking_ignored === 1 ? 'actived' : ''}`}>
            {tracking_ignored === 1 ? "SIM" : "NÃO"}
          </span>
        </S.ItemValue>
        <S.ButtonDetail>
          <S.IconDetail />
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridOperationCfops: React.FC<IGridOperationCfopsProps> = ({
  cfops = [],
}) => {
  return (
    <S.Container>
      <S.Header>
        <S.Label>CFOP</S.Label>
        <S.Label>Operação</S.Label>
        <S.Label>Descrição</S.Label>
        <S.Label>Restringir no Tracking</S.Label>
      </S.Header>
      {cfops.length > 0 &&
        cfops.map(cfop => <Item cfop={cfop} key={cfop.id} />)}
    </S.Container>
  );
};
