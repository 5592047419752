import React from "react";
import { format } from "date-fns";
import { Invoice } from "interfaces/invoice";

import * as S from "./styles";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import { DeliveryVoucherStatusIcon } from "../DeliveryVoucherStatusIcon";
interface IGridInvoicesProps {
  invoices: Invoice[] | Record<string, any>[];
  onClickUpload: Function;
  onClickExpand: Function;
}

interface IInvoiceProps {
  invoice: any;
  index: number;
  onUpload: () => void;
  onExpand: () => void;
}

const Item: React.FC<IInvoiceProps> = ({
  invoice,
  onUpload,
  onExpand,
}) => {
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  var uploadStatus = invoice.deviveryVoucher.length === 0 ? true : false;
  if (!uploadStatus) {
    const voucher = invoice.deviveryVoucher[0];
    uploadStatus = voucher.rejected_at ? true : false;
  }

  var status;
    if(invoice.deviveryVoucher.length === 0 ){
      status = "Aguardando comprovante"
    } else if(invoice.deviveryVoucher[0].approved_at) {
      status = "Comprovante aprovado"
    } else if(invoice.deviveryVoucher[0].rejected_at) {
      status = "Comprovante reprovado"
    } else {
      status = "Aguardando aprovação"
    }

  return (
    <S.ItemContainer onClick={() => ""}>
      <S.ItemContent width={windowWidth}>
        <S.ItemValue>
          {invoice.ide_numero_nf}-{invoice.ide_serie}
        </S.ItemValue>

        <S.ItemValue>
          {invoice.ide_data_emissao
            ? format(new Date(invoice.ide_data_emissao), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.deadline_date
            ? format(new Date(invoice.deadline_date), "dd/MM/yyyy")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.delivery_date
            ? format(new Date(invoice.delivery_date), "dd/MM/yyyy HH:mm")
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.emit_nome_municipio
            ? `${invoice.emit_nome_municipio}/${invoice.emit_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
          {invoice.dest_nome_municipio
            ? `${invoice.dest_nome_municipio}/${invoice.dest_uf}`
            : "---"}
        </S.ItemValue>
        <S.ItemValue>
        {invoice.carrier?.trade_name || "---"}
        </S.ItemValue>
        <S.ItemValue>{invoice.client?.trade_name || "---"}</S.ItemValue>
        <S.ItemValue>{invoice.picking ? invoice.picking.code : "---"}</S.ItemValue>
        <DeliveryVoucherStatusIcon status={status} />

        {uploadStatus ? (
          <S.IconUpload onClick={() => onUpload()}></S.IconUpload>
        ) : (
          <S.ItemValue />
        )}

        <S.IconExpand onClick={() => onExpand()}></S.IconExpand>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridInvoicesDeliveryVoucher: React.FC<IGridInvoicesProps> = ({
  invoices = [],
  onClickUpload,
  onClickExpand,
}) => {
  const { getTranslation } = useTranslation(translations);
  function handleClickExpand(index: number) {
    if (onClickExpand) onClickExpand(index);
  }

  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation('nf')}</S.Label>
        <S.Label>{getTranslation('emissao')}</S.Label>
        <S.Label>{getTranslation('prazo')}</S.Label>
        <S.Label>{getTranslation('entrega')}</S.Label>
        <S.Label>{getTranslation('origem')}</S.Label>
        <S.Label>{getTranslation('destino')}</S.Label>
        <S.Label>{getTranslation('transportadora')}</S.Label>
        <S.Label>{getTranslation('cliente')}</S.Label>
        <S.Label>{getTranslation('picking')}</S.Label>
      </S.Header>
      {invoices.length > 0 &&
        invoices.map((invoice, index: number) => (
          <Item
            invoice={invoice}
            key={invoice.id}
            index={index}
            onUpload={() => onClickUpload(index)}
            onExpand={() => handleClickExpand(index)}
          />
        ))}
    </S.Container>
  );
};
