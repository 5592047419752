import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListOperationCfopsActions } from "store/ducks/settings/operation-cfop";

import { OperationCfop } from "interfaces/operation-cfop";

export function* listOperationCfopsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/operation-cfops?${queryString}`);
    const comboOptions = data.map((unit: OperationCfop) => ({
      value: unit.id,
      label: unit.cfop,
    }));

    yield put(ListOperationCfopsActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListOperationCfopsActions.failure(errorMessage));
  }
}
