import React, { useState } from "react";
import { useTranslation,usePermissions } from 'hooks';
import { translations } from './translations';
import { PopoverMenu } from "components/shared";
import {
  ListGeolocations,
  NewInvoiceJustification,
  NewDeliveryDate,
  RecalculateDeadline,
  UpdateCarrier,
  UpdateBusinessLine,
  NewInvoiceJustificationCustomer,
} from "components/tracking";
import { Invoice } from "interfaces/invoice";
import * as S from "./styles";

interface Props {
  invoice: Invoice;
  onActionSuccess: () => void;
}

export const InvoiceActions: React.FC<Props> = ({
  invoice,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);
  const [modalJustification, setModalJustification] = useState<boolean>(false);
  const [modalJustificationCustomer, setModalJustificationCustomer] = useState<boolean>(false);
  const [modalGeolocations, setModalGeolocations] = useState<boolean>(false);
  const [modalDeadline, setModalDeadline] = useState<boolean>(false);
  const [modalDeliveryDate, setModalDeliveryDate] = useState<boolean>(false);
  const [modalUpdateCarrier, setModalUpdateCarrier] = useState<boolean>(false);
  const [modalUpdateBusinessLine, setModalUpdateBusinessLine] =  
  useState<boolean>(false);
  const { hasEveryPermission } = usePermissions();

  const options = [
    {
      label: getTranslation('localizacoes'),
      action: () => setModalGeolocations(true),
    },
    {
      label: getTranslation('justificativaTrans'),
      action: () => setModalJustification(true),
    },
  ];

  const customerOptions = [
    {
      label: getTranslation('justificativaCustomer'),
      action: () => setModalJustificationCustomer(true),
    },
  ];

  const adminOptions = [
    {
      label: getTranslation('justificativaCustomer'),
      action: () => setModalJustificationCustomer(true),
    },
    {
      label: getTranslation('dataEntrega'),
      action: () => setModalDeliveryDate(true),
    },
    {
      label: getTranslation('businessLine'),
      action: () => setModalUpdateBusinessLine(true),
    },
    {
      label: getTranslation('recalculaPrazo'),
      action: () => setModalDeadline(true),
    },
    {
      label: getTranslation('transportadora'),
      action: () => setModalUpdateCarrier(true),
    },
  ];

  if (hasEveryPermission(["Administrador"])) {
    options.push(...adminOptions);
  } else if(hasEveryPermission(["Customer Service"])){
    options.push(...customerOptions);
  }

  return (
    <S.Container>
      <NewInvoiceJustification
        isOpen={modalJustification}
        close={() => setModalJustification(false)}
        invoiceId={invoice.id}
        onActionSuccess={onActionSuccess}
      />
      <NewInvoiceJustificationCustomer
        isOpen={modalJustificationCustomer}
        close={() => setModalJustificationCustomer(false)}
        invoiceId={invoice.id}
        onActionSuccess={onActionSuccess}
      />
      <ListGeolocations
        isOpen={modalGeolocations}
        close={() => setModalGeolocations(false)}
        geolocations={invoice.geolocation || null}
        occurrences={invoice.occurrences || null}
      />
      <RecalculateDeadline
        isOpen={modalDeadline}
        close={() => setModalDeadline(false)}
        invoice={invoice}
        onActionSuccess={onActionSuccess}
      />
      <NewDeliveryDate
        isOpen={modalDeliveryDate}
        close={() => setModalDeliveryDate(false)}
        invoiceId={invoice.id}
        onActionSuccess={onActionSuccess}
      />
      <UpdateCarrier
        isOpen={modalUpdateCarrier}
        close={() => setModalUpdateCarrier(false)}
        invoiceId={invoice.id}
        onActionSuccess={onActionSuccess}
      />
      <UpdateBusinessLine
        isOpen={modalUpdateBusinessLine}
        close={() => setModalUpdateBusinessLine(false)}
        invoiceId={invoice.id}
        onActionSuccess={onActionSuccess}
      />

      <PopoverMenu menuItems={options}>
        <S.ButtonMenu>
          <S.IconMenu />
        </S.ButtonMenu>
      </PopoverMenu>
    </S.Container>
  );
};
