import React from 'react';
import { format } from 'date-fns';
import * as S from './styles';
import { InvoiceStatusLabel, InvoiceActions } from 'components/tracking';
import { Invoice } from 'interfaces/invoice';
import { usePermissions, useTranslation } from 'hooks';
import { translations } from './translations';

interface IProps {
  invoice?: Invoice;
  onActionSuccess: () => void;
}

export const InvoiceTrackingHeader: React.FC<IProps> = ({
  invoice,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);
  const { hasEveryPermission } = usePermissions();
  const formatLocation = (geo: any) => {
    return geo[0]
      ? format(new Date(geo[0].created_at), 'dd/MM/yyyy HH:mm')
      : ' ---';
  };

  if (invoice) {
    return (
      <S.Container>
        <InvoiceStatusLabel status={invoice.status} />
        <S.Wrapper>
          <S.Item>
            <S.Label>{getTranslation('bl')}</S.Label>
            <S.Value>
              {invoice.businessLine
                ? invoice.businessLine.activity_division
                : '--'}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('origem')}</S.Label>
            <S.Value>
              {invoice.emit_nome_municipio}/{invoice?.emit_uf}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('destino')}</S.Label>
            <S.Value>
              {invoice.dest_nome_municipio}/{invoice?.dest_uf}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('dataEmissao')}</S.Label>
            <S.Value>
              {invoice.ide_data_emissao
                ? format(
                    new Date(invoice?.ide_data_emissao),
                    'dd/MM/yyyy HH:mm'
                  )
                : '----'}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('prazoC')}</S.Label>
            <S.Value>
              {invoice.deadline_date
                ? format(new Date(invoice?.deadline_date), 'dd/MM/yyyy')
                : '----'}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>{getTranslation('previsaoEntrega')}</S.Label>
            <S.Value>
              {invoice.carrierPrediction?.length === 1
                ? format(
                    new Date(
                      invoice.carrierPrediction[0].delivery_prediction_date
                    ),
                    'dd/MM/yyyy'
                  )
                : '----'}
            </S.Value>
          </S.Item>
          {invoice.delivery_date && (
            <S.Item>
              <S.Label>{getTranslation('dataEntrega')}</S.Label>
              <S.Value>
                {invoice.delivery_date
                  ? format(new Date(invoice.delivery_date), 'dd/MM/yyyy')
                  : '----'}
              </S.Value>
            </S.Item>
          )}
          {invoice.devolution_date && (
            <S.Item>
              <S.Label>{getTranslation('dataDevolucao')}</S.Label>
              <S.Value>
                {invoice.devolution_date
                  ? format(new Date(invoice.devolution_date), 'dd/MM/yyyy')
                  : '----'}
              </S.Value>
            </S.Item>
          )}
          <S.Item>
            <S.Label>{getTranslation('localizacao')}</S.Label>
            <S.Value>
              {invoice.last_city}-{formatLocation(invoice.geolocation)}
            </S.Value>
          </S.Item>
        </S.Wrapper>
        {!hasEveryPermission(['Cliente']) && (
          <InvoiceActions invoice={invoice} onActionSuccess={onActionSuccess} />
        )}
      </S.Container>
    );
  }

  return <></>;
};
