import styled from "styled-components";
import { MenuAlt3 } from "@styled-icons/heroicons-outline";
import { colors } from "styles";

export const Container = styled.div`
  position: relative;
`;

export const ButtonMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconMenu = styled(MenuAlt3).attrs({ size: 24 })`
  color: ${colors.gray4};
`;
