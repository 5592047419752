export const translations = {
  pesoLiquido: {
    pt: 'Peso Líquido',
    en: 'Net weight',
    de: 'Reingewicht',
    es: 'Peso neto',
  }, 
  pesoBruto: {
    pt: 'Peso Bruto',
    en: 'Gross weight',
    de: 'Bruttogewicht',
    es: 'Peso bruto',
  },
  valor: {
    pt: 'Valor',
    en: 'Price',
    de: 'Preis',
    es: 'Precio',
  },
  inicio: {
    pt: 'Inicio',
    en: 'Start',
    de: 'Anfang',
    es: 'Inicio',
  },
  fim: {
    pt: 'Fim',
    en: 'End',
    de: 'Ende',
    es: 'Fin',
  },
  qCliente: {
    pt: 'Qtd. Cliente',
    en: 'Customer Quantity',
    de: 'Kundenmenge',
    es: 'Cantidad de clientes',
  },
};