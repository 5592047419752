export const translations = {
  entregues: {
    pt: 'Entregues',
    en: 'Handed out',
    de: 'Verteilt',
    es: 'Repartido',
  },
  entregas: {
    pt: 'Entregas ',
    en: 'deliveries ',
    de: 'Lieferungen ',
    es: 'Entregas ',
  },
  entregue: {
    pt: 'Entregue',
    en: 'Delivered',
    de: 'Geliefert',
    es: 'Entregado',
  },

};