import React from "react";

import * as S from "./styles";
import { useTranslation } from "hooks";
import { translations } from "./translations";

export type TTripStatus = "transito" | "entregue";
interface IProps {
  status: TTripStatus;
}

export const TripStatusIcon: React.FC<IProps> = ({ status }) => {
  const { getTranslation } = useTranslation(translations);
  const renderStatus = {
    transito: { label: getTranslation('transito'), icon: <S.IconTransito /> },
    "transito-atraso": {
      label: getTranslation('transitoAtraso'),
      icon: <S.IconTransito />,
    },
    entregue: { label: getTranslation('entregue'), icon: <S.IconEntregue /> },
  };
  return (
    <S.Container status={status}>
      <S.Hint>{status ? renderStatus[status].label : getTranslation('semStatus')}</S.Hint>
      {status && renderStatus[status].icon}
    </S.Container>
  );
};
