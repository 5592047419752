import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateIndentJustificationCustomerActions } from "store/ducks/tracking/indent-justificationCustomer";

export function* updateIndentJustificationCustomerRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;
    const { data: responseBody } = yield call(
      api.put,
      `/indent-justification-customer/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateIndentJustificationCustomerActions.success(data));
    notify("success", "Justificativa Customer atualizada com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateIndentJustificationCustomerActions.failure(errorMessage));
  }
}
