import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import { subDays, format } from 'date-fns';
import { ICitiesOptions } from 'interfaces/city';

const { Types, Creators } = createActions(
  {
    success: ['data'],
    citiesOrigins: ['data'],
    citiesDestinies: ['data'],
    reset: [],
  },
  { prefix: 'FETCH_INVOICE_FILTER_' }
);

export interface InvoiceFilter {
  limit: number;
  page: number;
  date_start: string | null;
  date_end: string | null;
  invoice_number: number | null;
  vehicle_plate: string | null;
  city_origin_uf: string | null;
  city_origin: number | null;
  city_destiny_uf: string | null;
  city_destiny: number | null;
  general_carrier_id: number | null;
  company_id: number | null;
  client_id: number | null;
  deadline_date_start: string | null;
  deadline_date_end: string | null;
  general_client_type_id: number | null;
  status: string | null;
  justification: string | null;
  business_lines: number[] | null;
  canceled: boolean | null;
  devolution: boolean | null;
}

export interface IFechSuccess {
  data: InvoiceFilter;
}

export interface IFechCities {
  data: ICitiesOptions[];
}

export interface FetchInvoiceFilterState {
  data: InvoiceFilter;
  citiesOrigins: ICitiesOptions[] | null;
  citiesDestinies: ICitiesOptions[] | null;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: FetchInvoiceFilterState = {
  data: {
    limit: 10,
    page: 1,
    date_start: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
    date_end: format(new Date(), 'yyyy-MM-dd'),
    invoice_number: null,
    vehicle_plate: null,
    city_origin_uf: null,
    city_origin: null,
    city_destiny_uf: null,
    city_destiny: null,
    general_carrier_id: null,
    company_id: null,
    client_id: null,
    deadline_date_start: null,
    deadline_date_end: null,
    general_client_type_id: null,
    status: null,
    justification: null,
    business_lines: null,
    canceled: null,
    devolution: null,
  },
  citiesOrigins: null,
  citiesDestinies: null,
  loading: false,
  error: null,
};

const success = (state: FetchInvoiceFilterState, action: IFechSuccess) =>
  update(state, {
    data: { $set: action.data },
  });

const citiesOrigins = (state: FetchInvoiceFilterState, action: IFechCities) =>
  update(state, {
    citiesOrigins: { $set: action.data },
  });

const citiesDestinies = (state: FetchInvoiceFilterState, action: IFechCities) =>
  update(state, {
    citiesDestinies: { $set: action.data },
  });

const reset = () => INITIAL_STATE;

export const fetchInvoiceFilter = createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: success,
  [Types.CITIES_ORIGINS]: citiesOrigins,
  [Types.CITIES_DESTINIES]: citiesDestinies,
  [Types.RESET]: reset,
});

export const FetchInvoiceFilterTypes = Types;
export const FetchInvoiceFilterActions = Creators;
