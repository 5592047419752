export const translations = {
  viagem: {
    pt: 'Viagem',
    en: 'Trip',
    de: 'Reise',
    es: 'Viaje',
  },
  motorista: {
    pt: 'Motorista',
    en: 'Driver',
    de: 'Treiber',
    es: 'Conductor',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  remetente: {
    pt: 'Remetente',
    en: 'Sender',
    de: 'Absender',
    es: 'Remitente',
  },
};