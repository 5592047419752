import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { isValid, format } from "date-fns";

import {
  FetchHolidayState,
  FetchHolidayActions,
  UpdateHolidayActions,
  UpdateHolidayState,
  DeleteHolidayActions,
  DeleteHolidayState,
} from "store/ducks/settings/holidays";

import { useValidation,useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";
import { Scaffold, Modal, Alert, Toggle } from "components/shared";
import { Input, InputMask } from "components/shared/Form";

interface IParams {
  id: string;
}

export const EditHoliday: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fixed, setFixed] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const { getTranslation } = useTranslation(translations);
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: dataFetchHoliday, loading } = useSelector<RootStateOrAny>(
    (state) => state.fetchHoliday
  ) as FetchHolidayState;

  const { loading: loadingDeleteHoliday } = useSelector<RootStateOrAny>(
    (state) => state.deleteHoliday
  ) as DeleteHolidayState;

  const { loading: loadingUpdateHoliday } = useSelector<RootStateOrAny>(
    (state) => state.updateHoliday
  ) as UpdateHolidayState;

  const onSuccess = useCallback(() => {
    history.push("/settings/holidays");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          holiday_date: Yup.mixed()
            .required("Obrigatório")
            .test({
              name: "test-date",
              message: "Data inválida",
              test: async (value) => {
                const inputDate = value.split("/");
                const date = new Date(
                  `${inputDate[2]}-${inputDate[1]}-${inputDate[0]}`
                );
                if (isValid(date)) return true;
                return false;
              },
            }),
          name: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (fixed) {
          data.fixed = true;
        } else {
          data.fixed = null;
        }

        const date = data.holiday_date.split("/");
        data.holiday_date = new Date(`${date[2]}-${date[1]}-${date[0]}`);

        dispatch(UpdateHolidayActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, fixed, handleFormErrors, id, onSuccess]
  );

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      if (!data.holiday_date) {
        data.holiday_date = "";
      } else {
        data.holiday_date = format(new Date(data.holiday_date), "dd/MM/yyyy");
      }
      if (data.fixed) {
        setFixed(true);
      }
      formRef.current.setData(data);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchHolidayActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteHolidayActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Scaffold>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`Remover Feriado ${dataFetchHoliday?.name}`}
          text="Deseja realmente remover esse feriado?"
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteHoliday}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("titulo")} <span>{getTranslation("editarFeriado")}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation("voltar")}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <InputMask name="holiday_date" label={getTranslation("data")} mask="99/99/9999" />
              <Input name="name" label={getTranslation("nome")} />
            </S.FormRow>
            <S.FormRow>
              <Toggle
                label="Fixo"
                options={[getTranslation("sim"),getTranslation("nao")]}
                onSelect={setFixed}
                active={fixed}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                {getTranslation("remover")}
              </S.Button>
              <S.Button type="submit">
                {loadingUpdateHoliday ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
