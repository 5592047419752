import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  FetchJustificationCustomerTypeState,
  FetchJustificationCustomerTypeActions,
  UpdateJustificationCustomerTypeActions,
  UpdateJustificationCustomerTypeState,
  DeleteJustificationCustomerTypeActions,
  DeleteJustificationCustomerTypeState,
} from "store/ducks/settings/justificationCustomer-types";
import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold, Modal, Alert, Toggle } from "components/shared";
import { Input } from "components/shared/Form";

interface IParams {
  id: string;
}

export const EditJustificationCustomerType: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [blocked, setBlocked] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: dataFetchJustificationCustomerType,
    loading,
  } = useSelector<RootStateOrAny>(
    (state) => state.fetchJustificationCustomerType
  ) as FetchJustificationCustomerTypeState;

  const {
    loading: loadingDeleteJustificationCustomerType,
  } = useSelector<RootStateOrAny>(
    (state) => state.deleteJustificationCustomerType
  ) as DeleteJustificationCustomerTypeState;

  const {
    loading: loadingUpdateJustificationCustomerType,
  } = useSelector<RootStateOrAny>(
    (state) => state.updateJustificationCustomerType
  ) as UpdateJustificationCustomerTypeState;

  const onSuccess = useCallback(() => {
    history.push("/settings/justificationCustomer-types");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          description: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        data.blocked_at = blocked ? new Date() : null;

        dispatch(UpdateJustificationCustomerTypeActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [blocked, dispatch, handleFormErrors, id, onSuccess]
  );

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      if (!data.blocked_at) {
        setBlocked(false);
      } else {
        setBlocked(true);
      }
      formRef.current.setData(data);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchJustificationCustomerTypeActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteJustificationCustomerTypeActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Scaffold>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`Remover Tipo de Justificativa Customer ${dataFetchJustificationCustomerType?.description}`}
          text="Deseja realmente remover esse tipo de justificativa customer?"
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteJustificationCustomerType}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          Configurações <span>Editar Tipo de Justificativa Customer</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          Voltar
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="description" label="Descrição" />
            </S.FormRow>
            <S.FormRow>
              <Toggle
                active={blocked}
                label="Bloquear"
                onSelect={setBlocked}
                options={["Bloqueado", "Desbloqueado"]}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
              >
                Remover
              </S.Button>
              <S.Button type="submit">
                {loadingUpdateJustificationCustomerType ? <S.Loading /> : "Salvar"}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </Scaffold>
  );
};
