import styled from 'styled-components';
import { LogoutCircleR } from '@styled-icons/remix-line';
import { colors } from 'styles';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.h6`
  font-size: 14px;
  color: ${colors.white};
`;

export const Logout = styled.button.attrs({
  type: 'button',
})`
  color: ${colors.white};
  margin-left: 16px;
  transition: 300ms ease;
  :hover {
    transform: scale(1.2);
  }
`;

export const LogoutIcon = styled(LogoutCircleR).attrs({ size: 20 })``;

export const Avatar = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${colors.gray2};
  margin-right: 8px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;
