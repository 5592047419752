import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { DeleteInternacionalTransitActions } from "store/ducks/settings/internacional-transits";

export function* deleteInternacionalTransitRequest(action: any) {
  try {
    const { id, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(api.delete, `/internacional-transit-times/${id}`);

    const { data } = responseBody;
    yield put(DeleteInternacionalTransitActions.success(data));
    notify("success", "Tempo de trânsito deletado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(DeleteInternacionalTransitActions.failure(errorMessage));
  }
}
