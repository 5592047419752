import styled from "styled-components";
import { colors, fonts } from "styles";
import { Button } from "styles/styled-components";
import {
  FileImage,
  FileCheckFill,
  FileXFill,
  FileArrowDownFill,
  TrashFill
} from "@styled-icons/bootstrap/";
import { Close } from "@styled-icons/evaicons-solid";

interface IconChecked {
  active?: boolean;
}

export {
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
  ButtonMini,
} from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto 48px auto;

  form {
    button.submitButton {
      align-self: flex-end;
      :disabled {
        cursor: not-allowed;
        background-color: ${colors.gray4};
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  color: ${colors.gray4};
  margin-bottom: 24px;
`;

export const SubmitButton = styled(Button)`
  height: 48px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const ItemContent = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto 140px 230px 24px 24px 24px;
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  transition: 200ms ease;
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  color: ${colors.gray4};
`;

export const IconFile = styled(FileImage).attrs({ size: 24 })``;
export const IconFileDown = styled(FileArrowDownFill).attrs({ size: 24 })``;
export const IconTrash = styled(TrashFill).attrs({ size: 24 })<{disabled: boolean}>`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.red)};
`;

export const IconFileCheck = styled(FileCheckFill).attrs({
  size: 24,
})<IconChecked>`
  color: ${({ active }) => (active ? colors.green : colors.gray)};
`;

export const IconFileX = styled(FileXFill).attrs({ size: 24 })`
  color: ${colors.red};
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button:last-child {
    margin-left: 8px;
  }
`;

export const ButtonDownload = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(0.95);
  }
`;

export const ButtonDelete = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(0.95);
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const ContentModal = styled.div`
  height: 100%;
  width: 90%;
  padding: 48px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
  position: relative;

  iframe {
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }
`;

export const ButtonCloseModal = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconX = styled(Close).attrs({ size: 32 })``;
