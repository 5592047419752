import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IExportRequest } from "interfaces/export-duck";
import { requestErrorHandler } from "utils";
import { ExportTransitsActions } from "store/ducks/settings/transits";

export function* exportTransitsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IExportRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/evonik-transit-times?${queryString}`);
    yield put(ExportTransitsActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ExportTransitsActions.failure(errorMessage));
  }
}