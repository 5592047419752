import styled from "styled-components";
import { colors, fonts } from "styles";
export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
  column-gap: 48px;
`;

export const BoxHeader = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 6px 6px 0 0;
  background-color: ${colors.warmGray10};
`;

export const BoxContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);

  /* &:nth-child(2) {
    ${BoxHeader} {
      background-color: ${colors.deepPurple};
    }
  }
  &:last-child {
    ${BoxHeader} {
      background-color: ${colors.deepPurple};
    }
  } */
`;

export const BoxTitle = styled.h2`
  width: 100%;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PercentTotal = styled.span`
  font-family: ${fonts.GilroyBold};
  font-size: 20px;
  color: white;
`;

export const BackgroundProgressBar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.22);
  position: relative;
`;

export const ProgressBar = styled.div<{ progress: string }>`
  width: ${({ progress }) => progress};
  height: 6px;
  border-radius: 3px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
`;

export const BoxContent = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 0 0 6px 6px;
  background-color: white;
`;

export const Item = styled.button.attrs({ type: "button" })`
  display: grid;
  align-items: center;
  grid-template-columns: 70px auto 70px;
  text-align: left;
  width: 100%;
  padding: 8px;
  border-bottom: 2px ${colors.darkRgba25} dotted;

  &:hover {
    background-color: ${colors.darkRgba5};
  }
  &:last-child {
    margin-bottom: 0;
    border: none;
  }
`;

export const Label = styled.h3`
  font-size: 12px;
  color: ${colors.gray3};
`;

export const Value = styled.h4`
  font-size: 16px;
  color: ${colors.gray3};
  text-align: center;

  &:last-child {
    text-align: right;
  }
`;
