import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import {
  IState,
  ISuccessAction,
  IFailureAction,
} from "interfaces/fetch-recalculate-deadline";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "FETCH_RECALCULATE_DEADLINE_" }
);

export interface FetchRecalculateDeadlineState extends IState {}

const INITIAL_STATE: FetchRecalculateDeadlineState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: FetchRecalculateDeadlineState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: FetchRecalculateDeadlineState,
  action: ISuccessAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (
  state: FetchRecalculateDeadlineState,
  action: IFailureAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const fetchRecalculateDeadline = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const FetchRecalculateDeadlineTypes = Types;
export const FetchRecalculateDeadlineActions = Creators;
