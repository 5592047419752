import React, { useState } from "react";
import { useTranslation,usePermissions } from 'hooks';
import { translations } from './translations';

import { PopoverMenu } from "components/shared";

import {
  IndentListGeolocations,
  NewIndentDeliveryDate,
  UpdateIndentBusinessLine,
  NewIndentJustification,
  NewIndentJustificationCustomer,
  RecalculateIndentDeadline
  // IndentUpdateCarrier,
} from "components/tracking";

import * as S from "./styles";

import { Indent } from "interfaces/indent";
interface Props {
  indent: Indent;
  onActionSuccess: () => void;
}

export const IndentActions: React.FC<Props> = ({
  indent,
  onActionSuccess,
}) => {
  const [modalJustification, setModalJustification] = useState<boolean>(false);
  const [modalJustificationCustomer, setModalJustificationCustomer] = useState<boolean>(false);
  const [modalGeolocations, setModalGeolocations] = useState<boolean>(false);
  const [modalDeadline, setModalDeadline] = useState<boolean>(false);
  const [modalDeliveryDate, setModalDeliveryDate] = useState<boolean>(false);
  const [modalUpdateBusinessLine, setModalUpdateBusinessLine] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);
  // const [modalUpdateCarrier, setModalUpdateCarrier] = useState<boolean>(false);

  const { hasEveryPermission } = usePermissions();


  const options = [
    {
      label: getTranslation('localizacoes'),
      action: () => setModalGeolocations(true),
    },
    {
      label: getTranslation('justificativaTrans'),
      action: () => setModalJustification(true),
    },
  ];

  const customerOptions = [
    {
      label: getTranslation('justificativaCustomer'),
      action: () => setModalJustificationCustomer(true),
    },
  ];

  const adminOptions = [
    {
      label: getTranslation('justificativaCustomer'),
      action: () => setModalJustificationCustomer(true),
    },
    {
      label: getTranslation('dataEntrega'),
      action: () => setModalDeliveryDate(true),
    },
    {
      label: getTranslation('businessLine'),
      action: () => setModalUpdateBusinessLine(true),
    },
    {
      label: getTranslation('recalculaPrazo'),
      action: () => setModalDeadline(true),
    },
    // {
    //   label: "Transportadora",
    //   action: () => setModalUpdateCarrier(true),
    // },
  ];

  if (hasEveryPermission(["Administrador"])) {
    options.push(...adminOptions);
  } else if(hasEveryPermission(["Customer Service"])){
    options.push(...customerOptions);
  }

  return (
    <S.Container>
      <IndentListGeolocations
        isOpen={modalGeolocations}
        close={() => setModalGeolocations(false)}
        geolocations={indent.geolocation || null}
        occurrences={indent.occurrence || null}
      />
      <NewIndentJustification
        isOpen={modalJustification}
        close={() => setModalJustification(false)}
        indentId={indent.id}
        onActionSuccess={onActionSuccess}
      />
      <NewIndentJustificationCustomer
        isOpen={modalJustificationCustomer}
        close={() => setModalJustificationCustomer(false)}
        indentId={indent.id}
        onActionSuccess={onActionSuccess}
      />
      <RecalculateIndentDeadline
        isOpen={modalDeadline}
        close={() => setModalDeadline(false)}
        indent={indent}
        onActionSuccess={onActionSuccess}
      />
      <NewIndentDeliveryDate
        isOpen={modalDeliveryDate}
        close={() => setModalDeliveryDate(false)}
        indentId={indent.id}
        onActionSuccess={onActionSuccess}
      />
      <UpdateIndentBusinessLine
        isOpen={modalUpdateBusinessLine}
        close={() => setModalUpdateBusinessLine(false)}
        indentId={indent.id}
        onActionSuccess={onActionSuccess}
      />
      {/* <IndentUpdateCarrier
        isOpen={modalUpdateCarrier}
        close={() => setModalUpdateCarrier(false)}
        indentId={indent.id}
        onActionSuccess={onActionSuccess}
      /> */}

      <PopoverMenu menuItems={options}>
        <S.ButtonMenu>
          <S.IconMenu />
        </S.ButtonMenu>
      </PopoverMenu>
    </S.Container>
  );
};
