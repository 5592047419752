import styled from "styled-components";
import { Truck } from "@styled-icons/boxicons-solid";
import { Check } from "@styled-icons/boxicons-regular";
import { Cancel } from "@styled-icons/typicons";
import { colors, fonts } from "styles";
import { StatusOptions } from "interfaces/indent";

interface StatusStyle {
  status?: StatusOptions | undefined;
}
const backgroundStatus = {
  "EM TRANSITO": `${colors.orange}`,
  "ENTREGUE": `${colors.green}`,
  "SEM STATUS": `${colors.gray}`,
};

export const Container = styled.div<StatusStyle>`
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: white;
  background-color: ${({ status }) => status && backgroundStatus[status]};
`;

export const IconTransito = styled(Truck).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;

export const IconEntregue = styled(Check).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;

export const IconCancel = styled(Cancel).attrs({ size: 24 })`
  color: white;
  margin-right: 8px;
`;
