import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    success: ["data"],
    reset: [],
  },
  { prefix: "FILTER_TRIPS_" }
);

export interface TripFilter {
  limit: number;
  page: number;
  general_company_id: number | null;
  general_business_line_id: number | null;
  general_client_ids: number | null;
  general_carrier_ids: number | null;
  emission_start: string | null;
  emission_end: string | null;
  ide_numero_nf: number | null;
  prod_numero_pedido_compra: number | null;
}
export interface FilterTripsState {
  data: TripFilter;
}

interface SuccessFilterTrips {
  data: TripFilter;
}

const INITIAL_STATE: FilterTripsState = {
  data: {
    limit: 10,
    page: 1,
    general_company_id: null,
    general_business_line_id: null,
    general_client_ids: null,
    general_carrier_ids: null,
    emission_start: null,
    emission_end: null,
    ide_numero_nf: null,
    prod_numero_pedido_compra: null,
  },
};

const success = (state: FilterTripsState, action: SuccessFilterTrips) =>
  update(state, {
    data: { $set: action.data },
  });

const reset = () => INITIAL_STATE;

export const filterTrips = createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: success,
  [Types.RESET]: reset,
});

export const FilterTripsTypes = Types;
export const FilterTripsActions = Creators;
