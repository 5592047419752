import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { FetchInvoiceActions } from "store/ducks/tracking/invoices";

export function* fetchInvoiceRequest(action: any) {
  try {
    const { id, onSuccess } = action as IFechRequest;
    const { data } = yield call(api.get, `/invoices/${id}`);

    yield put(FetchInvoiceActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { onFailure } = action as IFechRequest;
    if (onFailure) onFailure();
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchInvoiceActions.failure(errorMessage));
  }
}
