import styled from "styled-components";
import { colors, fonts } from "styles";
import { Button } from "styles/styled-components";
import { Close } from "@styled-icons/evaicons-solid";
import { MessageAltDetail, Share } from "@styled-icons/boxicons-solid";
import { FileImage, FileCheckFill, FileXFill } from "@styled-icons/bootstrap/";

import { Loading } from "styles/styled-components";

export {
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
  ButtonMini,
} from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto 48px auto;
`;

export const Title = styled.h2`
  font-size: 18px;
  color: ${colors.gray4};
  margin-bottom: 24px;
`;

export const SubmitButton = styled(Button)`
  height: 48px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin-bottom: 8px; */
`;

export const ItemContent = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto repeat(2, 250px) 24px;
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  transition: 200ms ease;
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  color: ${colors.gray4};
`;

export const IconFile = styled(FileImage).attrs({ size: 24 })``;
export const IconFileCheck = styled(FileCheckFill).attrs({ size: 24 })<{
  active?: boolean;
}>`
  color: ${({ active }) => (active ? colors.green : colors.gray)};
`;

export const IconFileX = styled(FileXFill).attrs({ size: 24 })`
  color: ${colors.red};
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button:last-child {
    margin-left: 8px;
  }
`;

export const LoadingAction = styled(Loading).attrs({ size: 16 })``;

export const Commentary = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  background-color: ${colors.darkRgba5};
  padding: 16px;
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  font-family: ${fonts.OpenSans};
`;

export const Reason = styled.p`
  display: flex;
  align-items: end;
  width: 100%;
  padding: 4px 8px;
  font-size: 12px;
`;

export const IconReason = styled(Share).attrs({ size: 18 })<{
  rejected?: boolean;
}>`
  margin-right: 4px;
  transform: rotate(180deg);
  color: ${({ rejected }) => (rejected ? colors.red : colors.green)};
`;

export const ContentModal = styled.div`
  height: 400px;
  width: 610px;
  padding: 48px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
  position: relative;
`;

export const HeaderModal = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;

export const TitleModal = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
`;

export const IconMessage = styled(MessageAltDetail).attrs({ size: 24 })`
  margin-right: 8px;
`;

export const ButtonCloseModal = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconX = styled(Close).attrs({ size: 32 })``;