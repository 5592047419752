import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ITripList, IInvoice } from "interfaces/trips";
import { TripStatusIcon } from "components/tracking";
import { peso, moeda } from "utils";
import * as S from "./styles";
import { useTranslation } from "hooks";
import { translations } from "./translations";

interface IGridTripsListProps {
  trip: ITripList[];
}

interface ITripItem {
  trip: ITripList;
}

interface IInvoiceItemProps {
  invoice: IInvoice;
  picking: any
}
const InvoiceItem: React.FC<IInvoiceItemProps> = ({ invoice, picking }) => {
  return (
    <S.ItemAccordion>
      <S.ItemValue>
        {picking.order}
      </S.ItemValue>
      <S.ItemValue>
        {invoice.ide_numero_nf}-{invoice.ide_serie}
      </S.ItemValue>
      <S.ItemValue>
        {invoice.ide_data_emissao &&
          format(new Date(invoice.ide_data_emissao), "dd/MM/yyyy")}
      </S.ItemValue>
      <S.ItemValue>
        {invoice.deadline_date &&
          format(new Date(invoice.deadline_date), "dd/MM/yyyy")}
      </S.ItemValue>
      <S.ItemValue>
        {invoice.dest_nome_municipio}/{invoice.dest_uf}
      </S.ItemValue>
      <S.ItemValue>{invoice.client.trade_name}</S.ItemValue>
    </S.ItemAccordion>
  );
};

const Item: React.FC<ITripItem> = ({ trip }) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const countDeliveries = useCallback((transit: ITripList) => {
    if (transit.pickings.length === 0) {
      return {
        totalDeliveries: 0,
        totalPickings: 0,
      };
    }
    const totalPickings = transit.pickings.length;
    const totalDeliveries = transit.pickings.filter(
      (picking) => picking.picking_data.invoice.delivery_date
    ).length;
    return {
      totalDeliveries,
      totalPickings,
    };
  }, []);

  const getStatus = useCallback(() => {
    const { totalDeliveries, totalPickings } = countDeliveries(trip);
    if (totalDeliveries === totalPickings) {
      return "entregue";
    }
    return "transito";
  }, [countDeliveries, trip]);

  const deliveriesVsPickings = useCallback(() => {
    const { totalDeliveries, totalPickings } = countDeliveries(trip);
    return `${totalDeliveries}/${totalPickings}`;
  }, [countDeliveries, trip]);


  const BtnListAccordion = (ev: any) => {
    ev.stopPropagation();
    setAccordionIsOpen(!accordionIsOpen)
  }

  return (
    <>
      <S.ItemContainer>
        <S.ItemContent onClick={() => history.push(`/trip/${trip.id}`)}>
          <S.ItemValue>{trip.id}</S.ItemValue>
          <S.ItemValue>
            {trip.exit_at
              ? format(new Date(trip.exit_at), "dd/MM/yyyy")
              : "---"}
          </S.ItemValue>
          <S.ItemValue>
            {trip.pickings[0]?.picking_data?.invoice?.deadline_date
              ? format(
                  new Date(trip.pickings[0].picking_data.invoice.deadline_date),
                  "dd/MM/yyyy"
                )
              : "---"}
          </S.ItemValue>
          <S.ItemValue>{deliveriesVsPickings()}</S.ItemValue>
          <S.ItemValue className="right">
            {trip.valor_total_NFs ? moeda(trip.valor_total_NFs) : "---"}
          </S.ItemValue>
          <S.ItemValue  className="right">
            {trip.peso_bruto_total ? peso(trip.peso_bruto_total) : "---"}
          </S.ItemValue>
          <S.ItemValue>{trip.company.trade_name}</S.ItemValue>
          <S.ItemValue>{trip.vehicle?.vehicleType?.name || "---"}</S.ItemValue>
          <S.ItemValue>{trip.vehicle_plate}</S.ItemValue>
          <S.ItemValue>{trip.carrier.trade_name}</S.ItemValue>
          <TripStatusIcon status={getStatus()} />
          <S.ButtonList onClick={(e) => BtnListAccordion(e)}>
            <S.IconList />
          </S.ButtonList>
          <S.ButtonDetail onClick={() => history.push(`/trip/${trip.id}`)}>
            <S.IconDetail />
          </S.ButtonDetail>
        </S.ItemContent>
      </S.ItemContainer>
      {trip.pickings.length > 0 && (
        <S.Accordion isOpen={accordionIsOpen}>
          <S.ContentAccordion>
            <S.AccordionHeader>
              <S.Label>#</S.Label>
              <S.Label>{getTranslation('nf')}</S.Label>
              <S.Label>{getTranslation('emissao')}</S.Label>
              <S.Label>{getTranslation('prazo')}</S.Label>
              <S.Label>{getTranslation('destino')}</S.Label>
              <S.Label>{getTranslation('cliente')}</S.Label>
            </S.AccordionHeader>
            {trip.pickings.map((picking) => (
              <InvoiceItem
                key={picking.id}
                invoice={picking.picking_data.invoice}
                picking={picking}
              />
            ))}
          </S.ContentAccordion>
        </S.Accordion>
      )}
    </>
  );
};

export const GridTripsList: React.FC<IGridTripsListProps> = ({ trip }) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation('id')}</S.Label>
        <S.Label>{getTranslation('inicio')}</S.Label>
        <S.Label>{getTranslation('prazo')}</S.Label>
        <S.Label>{getTranslation('entregues')}</S.Label>
        <S.Label>{getTranslation('valorNF')}</S.Label>
        <S.Label>{getTranslation('pesoTotak')}</S.Label>
        <S.Label>{getTranslation('origem')}</S.Label>
        <S.Label>{getTranslation('veiculo')}</S.Label>
        <S.Label>{getTranslation('placa')}</S.Label>
        <S.Label>{getTranslation('transportadora')}</S.Label>
      </S.Header>
      {trip &&
        trip.map((routers: any) => <Item trip={routers} key={routers.id} />)}
    </S.Container>
  );
};
