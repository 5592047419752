import React, { useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  FetchInvoiceFilterActions,
  FetchInvoiceFilterState,
} from "store/ducks/tracking/invoice-filters";
import { Indicators, IndicatorsProps } from "interfaces/invoice";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface Props {
  indicators: Indicators | null;
}

interface BoxProps {
  title: string;
  indicators: IndicatorsProps | undefined;
  prefix: string;
}

const Box: React.FC<BoxProps> = ({ title, indicators, prefix }) => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const { data: dataFilter } = useSelector<
    RootStateOrAny,
    FetchInvoiceFilterState
  >((state) => state.fetchInvoiceFilter);

  const handleFilter = useCallback(
    (status) => {
      dispatch(
        FetchInvoiceFilterActions.success({
          ...dataFilter,
          status: status,
          page: 1,
        })
      );
    },
    [dataFilter, dispatch]
  );

  if (!indicators) return <></>;
  return (
    <S.BoxContainer>
      <S.BoxHeader>
        <S.BoxTitle>
          {getTranslation(title)}
          <S.PercentTotal>
            {(
              indicators.percent +
              indicators.percentLate +
              indicators.percentDeadline
            ).toFixed(2)}
            %
          </S.PercentTotal>
        </S.BoxTitle>
        <S.BackgroundProgressBar>
          <S.ProgressBar
            progress={`${
              indicators.percent +
              indicators.percentLate +
              indicators.percentDeadline
            }%`}
          />
        </S.BackgroundProgressBar>
      </S.BoxHeader>
      <S.BoxContent>
        <S.Item onClick={() => handleFilter(`${prefix}`)}>
          <S.Label>{getTranslation("noPrazo")}</S.Label>
          <S.Value>{indicators.total}</S.Value>
          <S.Value>{indicators.percent}%</S.Value>
        </S.Item>
        <S.Item onClick={() => handleFilter(`${prefix}-atraso`)}>
          <S.Label>{getTranslation("atraso")}</S.Label>
          <S.Value>{indicators.totalLate}</S.Value>
          <S.Value>{indicators.percentLate}%</S.Value>
        </S.Item>
        <S.Item onClick={() => handleFilter(`${prefix}-sem-prazo`)}>
          <S.Label>{getTranslation("semPrazo")}</S.Label>
          <S.Value>{indicators.totalDeadline}</S.Value>
          <S.Value>{indicators.percentDeadline}%</S.Value>
        </S.Item>
      </S.BoxContent>
    </S.BoxContainer>
  );
};
export const InvoiceIndicators: React.FC<Props> = ({ indicators }) => {
  return (
    <S.Container>
      <Box
        title="Notas pendentes"
        indicators={indicators?.noStatus}
        prefix="sem-status"
      />
      <Box
        title="Notas em trânsito"
        indicators={indicators?.transit}
        prefix="transito"
      />
      <Box
        title="Notas entregues"
        indicators={indicators?.delivered}
        prefix="entregue"
      />
    </S.Container>
  );
};
