import styled from "styled-components";
import logo from "assets/images/logo-webcol-signature-cor.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
  opacity: 0.7;
`;

export const Logo = styled.img.attrs({ src: logo })`
  margin-right: 1px;
`;
