export const translations = {
  novaJustificativa: {
    pt: 'Nova justificativa',
    en: 'New justification',
    de: 'Neue Begründung',
    es: 'Nueva justificación',
  },
  justificativa: {
    pt: 'Justificativa',
    en: 'Justification',
    de: 'Rechtfertigung',
    es: 'Justificación',
  },
  comentario: {
    pt: 'Comentário',
    en: 'Comment',
    de: 'Kommentar',
    es: 'Comentario',
  },
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Stornieren',
    es: 'Cancelar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
};