import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListJustificationCustomerTypesActions } from "store/ducks/settings/justificationCustomer-types";

import { JustificationCustomerType } from "interfaces/justificationCustomer-type";

export function* listJustificationCustomerTypesRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/justification-customer-types?${queryString}`);
    const comboOptions = data.map((justification: JustificationCustomerType) => ({
      value: justification.id,
      label: justification.description,
    }));

    yield put(ListJustificationCustomerTypesActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListJustificationCustomerTypesActions.failure(errorMessage));
  }
}
