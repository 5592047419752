import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-radius: 4px;
  background-color: white;
  margin: -72px auto 72px auto;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Item = styled.div`
  margin-right: 8px;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.h6`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray3};
  margin-bottom: 4px;
`;

export const Value = styled.p`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${colors.gray4};
`;
