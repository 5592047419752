export const translations = {
  remetente: {
    pt: 'Remetente',
    en: 'Sender',
    de: 'Absender',
    es: 'Remitente',
  },
  endereco: {
    pt: 'Endereço',
    en: 'Address',
    de: 'Adresse',
    es: 'Habla a',
  },
  destinatario: {
    pt: 'Destinatário',
    en: 'Recipient',
    de: 'Empfänger',
    es: 'Recipiente',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  rotaG: {
    pt: 'Rota Google',
    en: 'Google route',
    de: 'Google-Route',
    es: 'Ruta de Google',
  },
  rotaTransportadora: {
    pt: 'Rota Transportadora',
    en: 'Carrier Route',
    de: 'Trägerstrecke',
    es: 'Ruta del transportador',
  },
  
};