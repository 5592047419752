import React from 'react';
import { format } from 'date-fns';
import { IndentActivity } from 'interfaces/indent-activity';

import * as S from './styles';
import { UserModel } from 'interfaces/user';
import { useTranslation } from 'hooks';
import { translations } from './translations';

interface ActivityProps {
  activity: IndentActivity;
}
const Activity: React.FC<ActivityProps> = ({ activity }) => {
  const { getTranslation } = useTranslation(translations);
  const renderUser = (user: UserModel | null | undefined) => {
    if (!user) {
      return (
        <>
          <S.AvatarDefault />
          <S.ActivityTitle>{getTranslation('processo')}</S.ActivityTitle>
        </>
      );
    }

    return (
      <>
        {/* {user.avatar ? (
          <S.AvatarUser>
            <img src={user.avatar.url_image} alt={user.name} />
          </S.AvatarUser>
        ) : (
          <S.AvatarUserDefault />
        )} */}
        <S.AvatarUserDefault />
        <S.ActivityTitle>{user.name}</S.ActivityTitle>
      </>
    );
  };
  return (
    <S.ActivityContainer>
      <S.ActivityBox>
        <S.Dot />
        <S.Triangle />
        <S.AvatarWrapper>{renderUser(activity.user)}</S.AvatarWrapper>
        <S.ActivityDescription>{activity.description}</S.ActivityDescription>
      </S.ActivityBox>
      <S.ActivityDate>
        {format(new Date(activity.created_at), 'dd/MM/yyyy HH:mm:ss')}
      </S.ActivityDate>
    </S.ActivityContainer>
  );
};

interface IndentActivitiesProps {
  type: 'activity' | 'justification';
  activities: IndentActivity[];
}
export const IndentActivities: React.FC<IndentActivitiesProps> = ({
  type,
  activities,
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      {type === 'activity' && (
        <S.Title>
          <S.IconActivity />
          {getTranslation('atividades')}
        </S.Title>
      )}
      {type === 'justification' && (
        <S.Title>
          <S.IconJustification />
          {getTranslation('justificativas')}
        </S.Title>
      )}
      {activities.map((activity) => (
        <Activity activity={activity} key={activity.id} />
      ))}
    </S.Container>
  );
};
