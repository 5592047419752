import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { format } from "date-fns";
import {
  FetchIndentRecalculateDeadlineActions,
  FetchIndentRecalculateDeadlineState,
} from "store/ducks/tracking/indent-recalculate-deadline";
import {
  UpdateIndentActions,
  UpdateIndentState,
} from "store/ducks/tracking/indents";
import { Modal } from "components/shared";
import { Indent } from "interfaces/indent";

import * as S from "./styles";

interface Props {
  indent: Indent;
  isOpen: boolean;
  close: () => void;
  onActionSuccess: () => void;
}

export const RecalculateIndentDeadline: React.FC<Props> = ({
  isOpen,
  indent,
  close,
  onActionSuccess,
}) => {
  const dispatch = useDispatch();
  const { data: dataRecalculate, loading: loadingRecalculate } = useSelector<
    RootStateOrAny,
    FetchIndentRecalculateDeadlineState
  >((state) => state.fetchIndentRecalculateDeadline);

  const { loading: loadingUpdateIndent } = useSelector<
    RootStateOrAny,
    UpdateIndentState
  >((state) => state.updateIndent);

  const onSuccess = useCallback(() => {
    close();
    onActionSuccess();
  }, [close, onActionSuccess]);

  const handleUpdateIndent = useCallback(() => {
    if (dataRecalculate) {
      const data = {
        deadline_date: dataRecalculate,
      };
      dispatch(UpdateIndentActions.request(indent.id, data, onSuccess));
    }
  }, [dataRecalculate, dispatch, onSuccess, indent]);

  const getNewDeadline = useCallback(() => {
    if (isOpen) {
      dispatch(FetchIndentRecalculateDeadlineActions.request({
        id: indent.id,
        idEstrangeiro: indent.client && indent.client.id_exterior 
      }));
    }
  }, [dispatch, indent, isOpen]);

  useEffect(() => {
    getNewDeadline();
  }, [getNewDeadline]);

  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.IconMessage />
            Recalcular Prazo indent
          </S.Title>
        </S.Header>
        <S.Content>
          <S.Column>
            <S.Label>Prazo atual</S.Label>
            <S.Value>
              {indent.deadline_date
                ? format(new Date(indent.deadline_date), "dd/MM/yyyy")
                : "--------"}
            </S.Value>
          </S.Column>
          <S.IconArrowRight />
          <S.Column>
            <S.Label>Prazo recalculado</S.Label>
            {loadingRecalculate ? (
              <S.Loading />
            ) : (
              <S.Value>
                {dataRecalculate
                  ? format(new Date(dataRecalculate), "dd/MM/yyyy")
                  : "--------"}
              </S.Value>
            )}
          </S.Column>
        </S.Content>
        <S.ButtonsWrapper>
          <S.Button btStyle="cancel" onClick={close} type="button">
            Fechar
          </S.Button>
          <S.Button
            btStyle={dataRecalculate ? "primary" : "cancel"}
            onClick={handleUpdateIndent}
            disabled={!dataRecalculate || false}
            type="submit"
          >
            {loadingUpdateIndent ? <S.Loading /> : "Recalcular"}
          </S.Button>
        </S.ButtonsWrapper>
      </S.Container>
    </Modal>
  );
};
