import React from 'react';

import * as S from './styles';

import { StatusOptions } from 'interfaces/invoice';
import { useTranslation } from 'hooks';
import { translations } from './translations';

interface IProps {
  status: StatusOptions;
}

export const InvoiceStatusIcon: React.FC<IProps> = ({ status }) => {
  const { getTranslation } = useTranslation(translations);

  const renderStatus = {
    transito: { label: getTranslation('transito'), icon: <S.IconTransito /> },
    'transito-atraso': {
      label: getTranslation('transitoAtraso'),
      icon: <S.IconTransito />,
    },
    'transito-sem-prazo': { label: getTranslation('transito'), icon: <S.IconTransito /> },
    entregue: { label: getTranslation('entregue'), icon: <S.IconEntregue /> },
    'entregue-cliente': {
      label: 'Entregue ao cliente',
      icon: <S.IconEntregue />,
    },
    'entregue-atraso': { label: getTranslation('entregue'), icon: <S.IconEntregue /> },
    'entregue-sem-prazo': { label: getTranslation('entregue'), icon: <S.IconEntregue /> },
    'sem-status': {
      label: getTranslation('semStatus'),
      icon: <></>,
    },
    'sem-status-atraso': {
      label: getTranslation('semStatusAtraso'),
      icon: <></>,
    },
    'sem-status-sem-prazo': {
      label: getTranslation('semStatusprazo'),
      icon: <></>,
    },
    cancelada: {
      label: getTranslation('cancelada'),
      icon: <S.IconCancel />,
    },
    devolucao: {
      label: getTranslation('devolucao'),
      icon: <S.IconDevolucao />,
    },
  };
  return (
    <S.Container status={status}>
      <S.Hint>{status ? renderStatus[status].label : getTranslation('emStatus')}</S.Hint>
      {status && renderStatus[status].icon}
    </S.Container>
  );
};
