import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useTranslation } from 'hooks';
import { translations } from './translations';

import {
  PaginateTripsActions,
  PaginateTripsState,
  FilterTripsActions,
  FilterTripsState,
} from "store/ducks/tracking/trips";

import { Scaffold, Paginator } from "components/shared";
import { GridTripsList, TripFilter } from "components/tracking";
import * as S from "./styles";

export const ListTrips: React.FC = () => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  
  const { data: Filterdata } = useSelector<RootStateOrAny, FilterTripsState>(
    (state) => state.filterTrips
  );

  const { data, loading, pagination } = useSelector<
    RootStateOrAny,
    PaginateTripsState
  >((state) => state.paginateTrips);

  const handlePageChange = useCallback(
    (value) => {
      const filter = {
        ...Filterdata,
        page: value,
      };
      dispatch(FilterTripsActions.success(filter));
    },
    [Filterdata, dispatch]
  );

  const getData = useCallback(() => {
    const filter = {
      ...Filterdata,
    };
    dispatch(PaginateTripsActions.request(filter));
  }, [dispatch, Filterdata]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(PaginateTripsActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          Tracking <span>{getTranslation('viagens')}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <TripFilter />
      </S.PageHeader>
      <S.PageContent>
        {data ? <GridTripsList trip={data} /> : ""}

        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </Scaffold>
  );
};
