export const translations = {
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'CNPJ',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  uf: {
    pt: 'UF',
    en: 'State',
    de: 'Bundesland',
    es: 'Estado',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origin',
  },
  portoAeroporto: {
    pt: 'Porto/Aeroporto',
    en: 'Harbor/Airport',
    de: 'Hafen/Flughafen',
    es: 'Puerto/aeropuerto',
  },
  pais: {
    pt: 'País',
    en: 'Country',
    de: 'Land',
    es: 'País',
  },
  prazo: {
    pt: 'Prazo',
    en: 'Deadline',
    de: 'Termin',
    es: 'Fecha límite',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Stadt',
    es: 'Ciudad',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
};