export const translations = {
  dataEntrega: {
    pt: 'Data de entrega',
    en: 'Delivery date',
    de: 'Liefertermin',
    es: 'Fecha de entrega',
  }, 
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Stornieren',
    es: 'Cancelar',
  },
};