import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { format } from 'date-fns';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useValidation, usePermissions } from 'hooks';
import { Textarea } from 'components/shared/Form';
import { Modal } from 'components/shared';

import {
  UpdateInvoiceJustificationActions,
  UpdateInvoiceJustificationState,
} from 'store/ducks/tracking/invoice-justifications';

import * as S from './styles';
import { InvoiceJustification } from 'interfaces/invoice-justification';

interface ItemProps {
  invoiceJustification: InvoiceJustification;
  onUpdate: Function;
}

interface Props {
  justifications: InvoiceJustification[];
  onUpdate: () => void;
}

type Action = 'approve' | 'reject';

interface updateData {
  id: number;
  reason: string;
  updated_at?: Date;
  rejected_at?: Date;
}

const Item: React.FC<ItemProps> = ({ invoiceJustification, onUpdate }) => {
  const { hasAnyPermission } = usePermissions();
  const { loading: loadingUpdate } = useSelector<RootStateOrAny>(
    (state) => state.updateInvoiceJustification
  ) as UpdateInvoiceJustificationState;
  const { user, creator } = invoiceJustification;

  const handleUpdate = useCallback(
    (id, action: Action) => {
      let data = {};
      if (action === 'approve') {
        data = {
          approved_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        };
      }
      if (action === 'reject') {
        data = {
          rejected_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        };
      }
      onUpdate(id, data);
    },
    [onUpdate]
  );
  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.ItemValue>{invoiceJustification.justificationType.name}</S.ItemValue>
        <S.ItemValue>
          Criado em:{' '}
          {format(
            new Date(invoiceJustification.created_at),
            'dd/MM/yyyy HH:mm'
          )}
          {creator && (
            <>
              <br />
              por {creator.name}
            </>
          )}
        </S.ItemValue>
        <S.ItemValue>
          {invoiceJustification.approved_at &&
            `Aprovado em: ${format(
              new Date(invoiceJustification.approved_at),
              'dd/MM/yyyy HH:mm'
            )}`}
          {invoiceJustification.rejected_at &&
            `Reprovado em: ${format(
              new Date(invoiceJustification.rejected_at),
              'dd/MM/yyyy HH:mm'
            )}`}
          {user && (
            <>
              <br />
              por {user.name}
            </>
          )}
          {!invoiceJustification.approved_at &&
            !invoiceJustification.rejected_at &&
            hasAnyPermission(['Administrador', 'Customer Service']) && (
              <S.ButtonWrapper>
                {loadingUpdate ? (
                  <S.LoadingAction />
                ) : (
                  <>
                    <S.ButtonMini
                      onClick={() =>
                        handleUpdate(invoiceJustification.id, 'approve')
                      }
                    >
                      aprovar
                    </S.ButtonMini>
                    <S.ButtonMini
                      btStyle="danger"
                      onClick={() =>
                        handleUpdate(invoiceJustification.id, 'reject')
                      }
                    >
                      reprovar
                    </S.ButtonMini>
                  </>
                )}
              </S.ButtonWrapper>
            )}
        </S.ItemValue>
        {!invoiceJustification.approved_at &&
          !invoiceJustification.rejected_at && <S.IconFileCheck />}
        {invoiceJustification.approved_at && <S.IconFileCheck active />}
        {invoiceJustification.rejected_at && <S.IconFileX />}
      </S.ItemContent>
      {invoiceJustification.commentary && (
        <S.Commentary>
          {invoiceJustification.commentary}
          {invoiceJustification.reason && (
            <S.Reason>
              <S.IconReason rejected={!!invoiceJustification.rejected_at} />
              Motivo: {invoiceJustification.reason}
            </S.Reason>
          )}
        </S.Commentary>
      )}
    </S.ItemContainer>
  );
};

export const ListInvoiceJustifications: React.FC<Props> = ({
  justifications,
  onUpdate,
}) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [dataUpdate, setDataUpdate] = useState<updateData>({
    id: 0,
    reason: '',
  });
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const handleUpdate = useCallback<SubmitHandler>(
    async (data) => {
      try {
        if (dataUpdate.rejected_at) {
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            reason: Yup.string()
              .min(10)
              .required('É preciso informar uma resposta'),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const { id, ...dataBody } = dataUpdate;
        dispatch(
          UpdateInvoiceJustificationActions.request(id, dataBody, onUpdate)
        );
        setDataUpdate({ id: 0, reason: '' });
        setShowDescription((prev) => !prev);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dataUpdate, dispatch, handleFormErrors, onUpdate]
  );

  const handleUpdateModal = useCallback((id, data) => {
    setDataUpdate((prev) => ({ ...prev, id, ...data }));
    setShowDescription((prev) => !prev);
  }, []);

  const handleCloseModal = useCallback(() => {
    setDataUpdate({ id: 0, reason: '' });
    setShowDescription((prev) => !prev);
  }, []);

  return (
    <>
      <Modal isOpen={showDescription}>
        <S.ContentModal>
          <S.ButtonCloseModal onClick={handleCloseModal}>
            <S.IconX />
          </S.ButtonCloseModal>
          <S.HeaderModal>
            <S.TitleModal>
              <S.IconMessage />
              Motivo da {dataUpdate.rejected_at ? 'rejeição' : 'aprovação'}{' '}
              justificativa.
            </S.TitleModal>
          </S.HeaderModal>
          <Form ref={formRef} onSubmit={handleUpdate}>
            <S.FormRow>
              <Textarea
                name="reason"
                label="Motivo"
                required={!!dataUpdate.rejected_at}
                value={dataUpdate.reason}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setDataUpdate((prev) => ({ ...prev, reason: e.target.value }))
                }
                placeholder="Informe um motivo para aprovação ou rejeição da justificativa"
              />
            </S.FormRow>
            <S.SubmitButton
              className="submitButton"
              disabled={
                dataUpdate.rejected_at && dataUpdate.reason.length <= 10
              }
              btStyle={
                dataUpdate.rejected_at && dataUpdate.reason.length <= 10
                  ? 'cancel'
                  : 'primary'
              }
            >
              Enviar
            </S.SubmitButton>
          </Form>
        </S.ContentModal>
      </Modal>
      <S.Container>
        <S.Title>Justificativas</S.Title>
        {justifications && (
          <S.ItemsWrapper>
            {justifications.map((invoiceJustification) => (
              <Item
                key={invoiceJustification.id}
                invoiceJustification={invoiceJustification}
                onUpdate={handleUpdateModal}
              />
            ))}
          </S.ItemsWrapper>
        )}
      </S.Container>
    </>
  );
};
