import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import ReactLoading from "react-loading";
import { Form } from "@unform/web";
import { useValidation,useTranslation } from "hooks";
import { translations } from "./translations";
import * as Yup from "yup";
import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import {
  ListClientsState,
  ListClientsActions,
} from "store/ducks/settings/clients";

import {
  ListCarriersState,
  ListCarriersActions,
  ListCarriersTradeNameActions,
  ListCarriersTradeNameState,
} from "store/ducks/settings/carriers";

import {
  ListBusinessState,
  ListBusinessActions,
} from "store/ducks/settings/business";

import {
  ListCompaniesState,
  ListCompaniesActions,
} from "store/ducks/settings/companies";

import { AuthState } from "store/ducks/auth";

import * as S from "./styles";
import { Modal } from "components/shared";
import { Select, ToggleInput, Input } from "components/shared/Form";

interface IInvoiceFilterProps {
  onFilter: Function;
  indicatorFilter: boolean;
  fileLoading: boolean;
  onExport: any;
}

export const InvoiceFilterDeliveryVoucher: React.FC<IInvoiceFilterProps> = ({
  onFilter, 
  indicatorFilter,
  fileLoading,
  onExport
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cleanShow, setCleanShow] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);
  const [flagCarrier, setFlagCarrier] = useState<boolean>(false);

  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const { data: dataClients, loading: loadingClients } = useSelector<RootStateOrAny>(
      (state) => state.listClients
    ) as ListClientsState;

  const { data: dataCarriers, loading: loadingCarriers } = useSelector<RootStateOrAny>(
      (state) => state.listCarriers
    ) as ListCarriersState;

  const { data: dataCarriersTradeName, loading: loadingCarriersTradeName } = useSelector<RootStateOrAny>(
      (state) => state.listCarriersTradeName
    ) as ListCarriersTradeNameState;

    const { data: listBusinessData, loading: listBusinessLoading } = useSelector<
    RootStateOrAny,
    ListBusinessState
  >((state) => state.listBusiness);

  const { data: listCompaniesData, loading: listCompaniesLoading } =
  useSelector<RootStateOrAny, ListCompaniesState>(
    (state) => state.listCompanies
  );

  const getListCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const getListClients = useCallback(() => {
    dispatch(ListClientsActions.request({ all: true }));
  }, [dispatch]);

  const getBusinessLine = useCallback(() => {
    dispatch(ListBusinessActions.request({ all: true }));
  }, [dispatch]);

  const getListCompanies = useCallback(() => {
    dispatch(ListCompaniesActions.request({ all: true }));
  }, [dispatch]);

  const getListCarriersTradeName = useCallback(() => {
    dispatch(
      ListCarriersTradeNameActions.request({ trade_name_distinct: true })
    );
  }, [dispatch]);

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const onFetchFilter = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("ide_data_emissao_inicio", dataFilter.ide_data_emissao_inicio);
      formRef.current.setFieldValue("ide_data_emissao_final", dataFilter.ide_data_emissao_final);
    } 
  },[dataFilter])

  useEffect(() => getListCarriers(), [getListCarriers]);
  useEffect(() => getListClients(), [getListClients]);
  useEffect(() => getBusinessLine(), [getBusinessLine]);
  useEffect(() => getListCompanies(), [getListCompanies]);
  useEffect(() => getListCarriersTradeName(), [getListCarriersTradeName]);
  useEffect(() => onFetchFilter(), [onFetchFilter]);

  const changeTypeCarrier = useCallback(() => {
    if (formRef.current) {
      const flag = formRef.current.getFieldValue("carrier_raiz");
      if (flag === "1") {
        formRef.current.setFieldValue("general_carrier_id", "");
        setFlagCarrier(true);
      } else {
        formRef.current.setFieldValue("carrier_trade_name", "");
        setFlagCarrier(false);
      }
    } else {
      setFlagCarrier(false);
    }
  }, [ setFlagCarrier]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          general_client_id: Yup.string(),
          general_carrier_id: Yup.string(),         
          carrier_trade_name: Yup.string(),
          ide_data_emissao_inicio: Yup.string().required("Obrigatório"),
          ide_data_emissao_final: Yup.string().required("Obrigatório"),
          delivery_date_inicio: Yup.string(),
          delivery_date_final: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        onFilter(data);

        setCleanShow(true);
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, onFilter]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
          formRef.current.setFieldValue("ide_numero_nf","");
          formRef.current.setFieldValue("general_client_id", "");
          formRef.current.setFieldValue("general_carrier_id", "");
          formRef.current.setFieldValue("carrier_trade_name", "");
          formRef.current.setFieldValue("general_company_id", "");
          formRef.current.setFieldValue("general_business_line_id", "");
          formRef.current.setFieldValue("delivery_date_inicio", "");
          formRef.current.setFieldValue("delivery_date_final", "");
          formRef.current.setFieldValue("picking_code", "");
          formRef.current.clearField("carrier_raiz");
    }
    dispatch(SelectedFilterActions.reset());
    setCleanShow(false);
    setModalOpen(false);
  }, [dispatch]);

  const handleFilterIndicatorClean =  useCallback(() =>{
    onFilter({indicators:  null});
  }, [onFilter])

  const renderButtonCleanFilter = () => {
    if (cleanShow) {
      return (
        <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
          {getTranslation("limparFiltro")}
        </S.ButtonMini>
      );
    }
  };

  const renderButtonCleanFilterIndicator = () => {
    if (indicatorFilter) {
      return (
        <S.ButtonMini btStyle="dark" onClick={handleFilterIndicatorClean}>
          {getTranslation("limparIndicador")}
        </S.ButtonMini>
      );
    }
  };

  return (
    <S.Container>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation("filtrar")}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {userData?.roles[0] !== "Cliente" ? (
              <>
              <S.FormRow>
                <Input 
                  type="date" 
                  label={getTranslation("inicio")}
                  name="ide_data_emissao_inicio"
                  />
                <Input 
                  type="date" 
                  label={getTranslation("fim")} 
                  name="ide_data_emissao_final"
                  />
              </S.FormRow>
              <S.FormRow>
                <Input 
                  type="date" 
                  label={getTranslation("inicioEntrega")} 
                  name="delivery_date_inicio"
                />
                <Input 
                  type="date" 
                  label={getTranslation("finalEntrega")}  
                  name="delivery_date_final"
                />
              </S.FormRow>
              <S.FormRow>
                <Input label={getTranslation("numeroNota")}  name="ide_numero_nf"/>
                <Input 
                  label={getTranslation("codigoP")}
                  name="picking_code" 
                  type="number"
                />
              </S.FormRow>
              <S.FormRow>
                <Select
                  name="general_client_id"
                  label={getTranslation("cliente")}
                  options={dataClients}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingClients}
                  isDisabled={loadingClients}
                />
              </S.FormRow>
              <S.FormRow>
                <Select
                  name="general_company_id"
                  label={getTranslation("centro")}
                  options={listCompaniesData}
                  placeholder={getTranslation('selecione')}
                  isLoading={listCompaniesLoading}
                  isDisabled={listCompaniesLoading}
                />
              </S.FormRow>
              <S.FormRow>
                <Select
                  name="general_business_line_id"
                  label={getTranslation("bl")}
                  options={listBusinessData}
                  placeholder={getTranslation('selecione')}
                  isLoading={listBusinessLoading}
                  isDisabled={listBusinessLoading}
                />
              </S.FormRow>
              
              </>
            ) : (
              ""
            )}
            <S.FormRow>
              {flagCarrier ? (
                <Select
                  name="carrier_trade_name"
                  label={getTranslation("transportadoraRaiz")}
                  options={dataCarriersTradeName}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriersTradeName}
                  isDisabled={loadingCarriersTradeName}
                />
              ) : (
                <Select
                  name="general_carrier_id"
                  label={getTranslation("transportadora")}
                  options={dataCarriers}
                  placeholder={getTranslation('selecione')}
                  isLoading={loadingCarriers}
                  isDisabled={loadingCarriers}
                />
              )}

              <ToggleInput
                name="carrier_raiz"
                label={getTranslation("cnpjRaiz")}
                onChange={() => changeTypeCarrier()}
              />
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation("limparFiltro")}
              </S.Button>
              <S.Button type="submit">{getTranslation("filtrar")}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>
      <S.ButtonMini onClick={onExport} disabled={fileLoading}>
        {fileLoading ? (
          <ReactLoading type="spin" color="#fff" height={20} width={20} />
        ) : (
          getTranslation("csv")
        )}
      </S.ButtonMini>        
      {renderButtonCleanFilterIndicator()}
      {renderButtonCleanFilter()}
      <S.ButtonFilter onClick={() => setModalOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
    </S.Container>
  );
};
