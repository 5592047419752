import React from "react";
import { Scaffold } from "components/shared";
import * as S from "./styles";

export const CargoInTransit: React.FC = () => {
  return (
    <Scaffold>
      <S.Iframe
        frameBorder={0}
        width="100%"
        height="100%"
        src="https://analytics.zoho.com/open-view/1432280000027183457/e13b4bce54c586d234d76029e24856b2"
      />
    </Scaffold>
  );
};
