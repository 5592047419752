import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateInvoiceJustificationsActions } from "store/ducks/tracking/invoice-justifications";

export function* paginateInvoiceJustificationsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/invoice-justifications?${queryString}`
    );

    const { invoiceJustifications, pagination } = responseBody;
    yield put(
      PaginateInvoiceJustificationsActions.success(
        invoiceJustifications,
        pagination
      )
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateInvoiceJustificationsActions.failure(errorMessage));
  }
}
