import React, { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as Yup from "yup";

import { useTranslation,useValidation } from "hooks";
import { translations } from "./translations";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import {
  ListBusinessState,
  ListBusinessActions,
} from "store/ducks/settings/business";
import {
  UpdateInvoiceActions,
  UpdateInvoiceState,
} from "store/ducks/tracking/invoices";

import { Select } from "components/shared/Form";
import { Modal } from "components/shared";

import * as S from "./styles";

interface Props {
  invoiceId: number;
  isOpen: boolean;
  close: () => void;
  onActionSuccess: () => void;
}

export const UpdateBusinessLine: React.FC<Props> = ({
  isOpen,
  close,
  invoiceId,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const { loading: loadingBusiness, data: dataBusiness } = useSelector<
    RootStateOrAny,
    ListBusinessState
  >((state) => state.listBusiness);

  const { loading: loadingUpdateInvoice } = useSelector<
    RootStateOrAny,
    UpdateInvoiceState
  >((state) => state.updateInvoice);

  const onSuccess = useCallback(
    (reset) => {
      reset();
      close();
      onActionSuccess();
    },
    [close, onActionSuccess]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data, { reset }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          general_business_line_id: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(
          UpdateInvoiceActions.request(invoiceId, data, () => onSuccess(reset))
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, invoiceId, onSuccess]
  );

  const getListBusiness = useCallback(() => {
    dispatch(ListBusinessActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    getListBusiness();
  }, [getListBusiness]);

  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.Icon />
            {getTranslation('alterar')}
          </S.Title>
        </S.Header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.FormRow>
            <Select
              name="general_business_line_id"
              label={getTranslation('alterar')}
              options={dataBusiness}
              isLoading={loadingBusiness}
              isDisabled={loadingBusiness}
              placeholder={getTranslation('selecione')}
            />
          </S.FormRow>
          <S.FormRow></S.FormRow>
          <S.ButtonsWrapper>
            <S.Button btStyle="cancel" onClick={close} type="button">
            {getTranslation('cancelar')}
            </S.Button>
            <S.Button type="submit">
              {loadingUpdateInvoice ? <S.Loading /> : getTranslation('salvar')}
            </S.Button>
          </S.ButtonsWrapper>
        </Form>
      </S.Container>
    </Modal>
  );
};
