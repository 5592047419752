export const translations = {
  transito: {
    pt: 'Trânsito',
    en: 'Transit',
    de: 'Transit',
    es: 'Tránsito',
  },
  entregue: {
    pt: 'Entregue',
    en: 'Delivered',
    de: 'Geliefert',
    es: 'Entregado',
  },
  semStatus: {
    pt: 'Sem Status ',
    en: 'Without status',
    de: 'Ohne Status',
    es: 'sin estatus',
  }, 
  transitoAtraso: {
    pt: 'Trânsito em atraso',
    en: 'Delayed transit',
    de: 'verspäteter Transit',
    es: 'Trânsito retrasado',
  },

};