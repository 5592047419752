import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import {
  IState,
  ISuccessAction,
  IFailureAction,
} from "interfaces/paginate-duck";
import { JustificationCustomerType } from "interfaces/justificationCustomer-type";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PAGINATE_JUSTIFICATION_CUSTOMER_TYPE_" }
);

export interface PaginateJustificationCustomerTypesState extends IState {
  data: JustificationCustomerType[] | Record<string, any>[];
}

const INITIAL_STATE: PaginateJustificationCustomerTypesState = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: PaginateJustificationCustomerTypesState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: PaginateJustificationCustomerTypesState, action: ISuccessAction) =>
  update(state, {
    data: { $set: action.data },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (state: PaginateJustificationCustomerTypesState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateJustificationCustomerTypes = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateJustificationCustomerTypesTypes = Types;
export const PaginateJustificationCustomerTypesActions = Creators;
