export const translations = {
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  csv: {
    pt: 'Exportar CSV',
    en: 'Export CSV',
    de: 'CSV-Datei',
    es: 'Exportar CSV',
  },
  inicio: {
    pt: 'Data de emissão inicial',
    en: 'Initial issue date',
    de: 'Erstausgabedatum',
    es: 'Fecha de emisión inicial',
  },
  fim: {
    pt: 'Data de emissão final',
    en: 'Final issue date',
    de: 'Endgültiges Ausgabedatum',
    es: 'Última fecha de emisión',
  },
  inicioEntrega: {
    pt: 'Data de entrega inicial',
    en: 'Initial delivery date',
    de: 'Irster Liefertermin',
    es: 'Fecha de entrega inicial',
  },
  finalEntrega: {
    pt: 'Data de entrega final',
    en: 'Final delivery date',
    de: 'Endgültiger Liefertermin',
    es: 'Fecha de entrega final',
  },
  numeroNota: {
    pt: 'Número da nota',
    en: 'Note number',
    de: 'Notiznummer',
    es: 'Número de nota',
  },
  cliente: {
    pt: 'Cliente',
    en: 'Client',
    de: 'Klient',
    es: 'Cliente',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  bl: {
    pt: 'BL',
    en: 'BL',
    de: 'Geschäftszweig',
    es: 'línea de negocio',
  }, 
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Portador',
  },
  transportadoraRaiz: {
    pt: 'Transportadora (Raiz)',
    en: 'Carrier (Root)',
    de: 'Träger (Wurzel)',
    es: 'Transportadora (Raíz)',
  },
  cnpjRaiz: {
    pt: 'CNPJ Raiz',
    en: 'CNPJ Root',
    de: 'CNPJ Wurzel',
    es: 'CNPJ Raíz',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  codigoP: {
    pt: 'Codigo Prod.',
    en: 'Product code',
    de: 'Produktcode',
    es: 'Código Producto',
  },
  limparIndicador: {
    pt: 'Limpar Indicador',
    en: 'Clear indicator',
    de: 'klarer Indikator',
    es: 'claro indicador',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
};