import React, { useCallback } from "react";
import { v4 } from "uuid";
import { ITrip, IInvoice, IInvoiceClient } from "interfaces/trips";
import * as S from "./styles";
interface IProps {
  trip: ITrip;
}

interface IClient extends IInvoiceClient {
  invoices: IInvoice[];
}

export const TripsProgress: React.FC<IProps> = ({ trip }) => {
  const getUniqueClients = useCallback(() => {
    const uniqueClients: IClient[] = [];

    trip.invoices.map((invoice) => {
      const sameClient = trip.invoices.filter(
        (sameInvoice) => sameInvoice.client.id === invoice.client.id
      );
      let invoices: IInvoice[] = [];
      sameClient.map((sameClient) => invoices.push(sameClient));

      if (
        uniqueClients.filter((unique) => unique.id === invoice.client.id)
          .length === 0
      ) {
        return uniqueClients.push({
          ...invoice.client,
          invoices,
        });
      }
      return invoice;
    });

    return uniqueClients;
  }, [trip.invoices]);

  const renderPacks = useCallback(() => {
    const clients = getUniqueClients();

    return clients.map((client: IClient) => (
      <S.Item key={client.id}>
        <S.IllustrationPacks />
      </S.Item>
    ));
  }, [getUniqueClients]);

  const isAllDelivered = useCallback(() => {
    const invoices = trip.invoices.map((invoice) => ({
      active: invoice.delivery_date ? true : false,
    }));

    return invoices.every((invoice) => invoice.active);
  }, [trip.invoices]);

  const renderClientLabels = useCallback(() => {
    const clients = getUniqueClients();
    const checkDelivery = (invoices: IInvoice[]) => {
      const everyDelivered = invoices.map((invoice) =>
        invoice.delivery_date ? true : false
      );

      return everyDelivered.every((delivery) => delivery === true);
    };
    return clients.map((client) => (
      <S.Client key={client.id}>
        <S.BallItem key={v4()} check={checkDelivery(client.invoices)} />
        <S.LabelItem key={v4()}>{client.trade_name}</S.LabelItem>
      </S.Client>
    ));
  }, [getUniqueClients]);

  return (
    <S.Container>
      <S.IllustrationTree2 />
      <S.IllustrationTree1 />
      <S.IllustrationBg1 />
      <S.IllustrationBg2 />
      <S.IllustrationBg3 />
      <S.ItemsWrapper>{renderPacks()}</S.ItemsWrapper>
      <S.Line />
      <S.DistanceContainer>
        <S.BallItemsWrapper>{renderClientLabels()}</S.BallItemsWrapper>
        <S.DistanceLine full={isAllDelivered()} />
      </S.DistanceContainer>
    </S.Container>
  );
};
