import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FetchTripsActions, FetchTripsState } from "store/ducks/tracking/trips";
import { cnpj } from "utils/formatters";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import { Scaffold } from "components/shared";
import {
  MapTrackingTrip,
  TripsTrackingHeader,
  TripsTrackingBox,
  TripsProgress,
  GridTrips,
} from "components/tracking";

import * as S from "./styles";
interface IParams {
  id: string;
}

export const ShowTrips: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const { loading, data } = useSelector<RootStateOrAny, FetchTripsState>(
    (state) => state.fetchTrips
  );

  const onError = useCallback(() => {
    history.goBack();
  }, [history]);

  const getData = useCallback(() => {
    dispatch(FetchTripsActions.request(id, null, onError));
  }, [dispatch, id, onError]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Scaffold>
      <S.PageHeader>
        <h1>
          <S.IconInvoice />
          {getTranslation('viagem')} <span>{data?.id}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation('voltar')}
        </S.ButtonMini>
      </S.PageHeader>
      {data && (
        <>
          <MapTrackingTrip trip={data} />
          <S.PageContent>
            <TripsTrackingHeader trip={data} />
            <TripsProgress trip={data} />
            <GridTrips invoices={data.invoices} />
            <S.InvoiceBoxWrapper>
              <TripsTrackingBox
                data={{
                  type: "sender",
                  title: getTranslation('remetente'),
                  name: data.company.company_name,
                  cnpj: cnpj(data.company.address_zipcode),
                  address: `${data.company.address_street}, ${data.company.address_number}, ${data.company.address_city}/${data.company.address_state}, ${data.company.address_zipcode}`,
                }}
              />
              <TripsTrackingBox
                data={{
                  type: "carrier",
                  title: getTranslation('transportadora'),
                  name: data.carrier.company_name,
                  cnpj: cnpj(data.carrier.address_zipcode),
                  address: `${data.carrier.address_street}, ${data.carrier.address_number}, ${data.carrier.address_city}/${data.carrier.address_state}, ${data.carrier.address_zipcode}`,
                }}
              />
              <TripsTrackingBox
                data={{
                  type: "driver",
                  title: getTranslation('motorista'),
                  name: data.driver_name,
                  vehicleType: data.vehicle.vehicleType.name,
                  vehiclePlate: data.vehicle_plate,
                }}
              />
            </S.InvoiceBoxWrapper>
          </S.PageContent>
        </>
      )}
    </Scaffold>
  );
};
