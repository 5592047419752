export const translations = {
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'CNPJ',
  },
  endereco: {
    pt: 'Endereço',
    en: 'Address',
    de: 'Adresse',
    es: 'Habla a',
  },
  nome: {
    pt: 'Nome: ',
     en: 'Name: ',
     de: 'Name: ',
     es: 'Nombre: ',
   },
};