import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useTranslation,useValidation } from 'hooks';
import { translations } from './translations';
import {
  ListJustificationTypesActions,
  ListJustificationTypesState,
} from "store/ducks/settings/justification-types";
import {
  CreateInvoiceJustificationActions,
  CreateInvoiceJustificationState,
} from "store/ducks/tracking/invoice-justifications";

import * as S from "./styles";
import { Modal } from "components/shared";
import { Select, Textarea } from "components/shared/Form";

interface Props {
  invoiceId: number;
  isOpen: boolean;
  close: () => void;
  onActionSuccess: () => void;
}

export const NewInvoiceJustification: React.FC<Props> = ({
  isOpen,
  close,
  invoiceId,
  onActionSuccess,
}) => {
  const { getTranslation } = useTranslation(translations);
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const { data: dataJustificationType, loading: loadingJustificationType } =
    useSelector<RootStateOrAny>(
      (state) => state.listJustificationTypes
    ) as ListJustificationTypesState;

  const { loading: loadingCreateInvoiceJustification } =
    useSelector<RootStateOrAny>(
      (state) => state.createInvoiceJustification
    ) as CreateInvoiceJustificationState;

  const onSuccess = useCallback(
    (reset) => {
      reset();
      close();
      onActionSuccess();
    },
    [close, onActionSuccess]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data, { reset }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          justification_type_id: Yup.string().required(getTranslation('obrigatorio')),
          commentary: Yup.string().required(getTranslation('obrigatorio')),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        data.invoice_id = invoiceId;
        dispatch(
          CreateInvoiceJustificationActions.request(data, () =>
            onSuccess(reset)
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, getTranslation, handleFormErrors, invoiceId, onSuccess]
  );

  const getListJustificationTypes = useCallback(() => {
    dispatch(ListJustificationTypesActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => getListJustificationTypes(), [getListJustificationTypes]);

  return (
    <Modal isOpen={isOpen}>
      <S.Container>
        <S.Header>
          <S.Title>
            <S.IconMessage />
            {getTranslation('novaJustificativa')}
          </S.Title>
        </S.Header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.FormRow>
            <Select
              name="justification_type_id"
              label={getTranslation('justificativa')}
              isLoading={loadingJustificationType}
              isDisabled={loadingJustificationType}
              options={dataJustificationType}
              placeholder={getTranslation('selecione')}
            />
          </S.FormRow>
          <S.FormRow>
            <Textarea name="commentary" label={getTranslation('comentario')}/>
          </S.FormRow>
          <S.ButtonsWrapper>
            <S.Button btStyle="cancel" onClick={close} type="button">
              {getTranslation('cancelar')}
            </S.Button>
            <S.Button type="submit">
              {loadingCreateInvoiceJustification ? <S.Loading /> : getTranslation('salvar')}
            </S.Button>
          </S.ButtonsWrapper>
        </Form>
      </S.Container>
    </Modal>
  );
};
