export const translations = {
  alterarTrans: {
    pt: 'Alterar transportadora',
    en: 'Change carrier',
    de: 'Träger wechseln',
    es: 'Cambiar de compañía',
  },
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Träger',
    es: 'Transportadora',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
 },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Stornieren',
    es: 'Cancelar',
  },
};