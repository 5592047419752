import { call, put } from "redux-saga/effects";

import { api, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateHolidaysActions } from "store/ducks/settings/holidays";

export function* paginateHolidaysRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/holidays?${queryString}`
    );

    const { holidays, pagination } = responseBody;
    yield put(PaginateHolidaysActions.success(holidays, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateHolidaysActions.failure(errorMessage));
  }
}
