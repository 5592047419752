import * as S from './styles';
import { translations } from './translations';
import { useTranslation } from 'hooks';
type TypeBox =
  | 'sender'
  | 'recipient'
  | 'carrier'
  | 'redespacho'
  | 'destination'
  | 'tomador';
interface IProps {
  data: {
    type: TypeBox;
    title: string;
    name: string | null;
    cnpj: string | null;
    address: string | null;
  };

  openModal?: () => void;
}
const icons = {
  sender: <S.IconSender />,
  carrier: <S.IconCarrier />,
  redespacho: <S.IconCarrier />,
  recipient: <S.IconRecipient />,
  destination: <S.IconRecipient />,
  tomador: <S.IconTomador />,
};
export const InvoiceTrackingBox: React.FC<IProps> = ({ data, openModal }) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.HeaderItem>
          {icons[data.type]}
          <S.Title>{data.title}</S.Title>
        </S.HeaderItem>
        {openModal ? <S.IconDetail onClick={() => openModal()} /> : ''}
      </S.Header>
      <S.Label>{getTranslation('razaoSocial')}</S.Label>
      <S.Value>{data.name}</S.Value>
      <S.Label>{getTranslation('cnpj')}</S.Label>
      <S.Value>{data.cnpj}</S.Value>
      <S.Label>{getTranslation('endereco')}</S.Label>
      <S.Value>{data.address}</S.Value>
    </S.Container>
  );
};
