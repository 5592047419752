import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { DeleteOperationTypeActions } from "store/ducks/settings/operation-type";

export function* deleteOperationTypeRequest(action: any) {
  try {
    const { id, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      api.delete,
      `/operation-types/${id}`
    );

    const { data } = responseBody;
    yield put(DeleteOperationTypeActions.success(data));
    notify("success", "Tipo deletado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(DeleteOperationTypeActions.failure(errorMessage));
  }
}
