import React from "react";
import { useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";


interface IProps {
  status: any;
}

export const TripsStatusLabel: React.FC<IProps> = ({ status }) => {
  const total = status.qtd;
  const delivered = status.delivered;
  const { getTranslation } = useTranslation(translations);

  const renderStatus = {
    label: getTranslation('entregas') + delivered + "/" + total ,
    icon: <S.IconTransito />,
    style: "transito"
  }

  if (delivered === total){
    renderStatus.label = getTranslation('entregues');
    renderStatus.icon = <S.IconEntregue />
    renderStatus.style = getTranslation('entrega')
  }

  return (
    <S.Container status={renderStatus.style}>
      {status && (
        <>
          {renderStatus.icon}
          {renderStatus.label}
        </>
      )}
    </S.Container>
  );
};
