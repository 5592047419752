import styled from "styled-components";
import { Settings } from "@styled-icons/ionicons-outline";
import { ArrowNarrowLeft } from "@styled-icons/heroicons-outline";
import { Loading } from "styles/styled-components";

export {
  PageContainer,
  PageHeader,
  PageContent,
  Button,
  ButtonMini,
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
} from "styles/styled-components";

export const IconSetting = styled(Settings).attrs({ size: 24 })``;

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const IconArrowLeft = styled(ArrowNarrowLeft).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const ListAlerts = styled.div`
  margin: 40px 0;
  height: 400px;
  overflow-y: auto;

  big {
    font-size: 20px;
    padding: 20px;
    display: block;
    background: no-repeat 98% #0067A0;
    border-radius: 6px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul li {
    list-style: none;
    padding: 16px;
    border: 0;
    border-radius: 4px;
    background: #f2f2f2;
    margin-bottom: 10px;
    border-radius: 6px;
    p {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 300;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: 40px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TitList = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const MessageList = styled.p`
  font-size: 18px;
  margin-top: 16px;
`;

export const MessageItem = styled.p<{background: string}>`
  list-style: none;
  padding: 16px;
  border: 0;
  border-radius: 4px;
  background: ${props => props.background};
  color: #fff;
  margin-bottom: 10px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 300;
`;

export const ErroTxt = styled.p`
  color: #9B0833;
  font-size: 14px;
`;
